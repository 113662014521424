import React, { useState } from 'react';
import TopBar from "./TopBar";
import AllMessages from "./AllMessages";
import { Container, Row, Col } from 'reactstrap';
// Tab component
const Tab = ({ label, activeTab, onClick }) => {
    return (
        <button
      onClick={() => onClick(label)}
      style={{
        backgroundColor: activeTab === label ? '#efeff1' : 'transparent',
        color: activeTab === label ? '#008037' : '#000',
        borderBottom: activeTab === label ? '2px solid #008037' : '2px solid transparent',
        fontWeight: activeTab === label ? '700' : 'Normal',
        border: 'none',
        padding: '5px 10px',
        cursor: 'pointer',
      }}
    >
      {label}
    </button>
        );
    };
    const Dropdown = () => {
        const [isOpen, setIsOpen] = useState(false);
        const [selectedOption, setSelectedOption] = useState('');
      
        const toggleDropdown = () => {
          setIsOpen(!isOpen);
        };
      
        const handleOptionClick = (option) => {
          setSelectedOption(option);
          setIsOpen(false); // Close dropdown after selection
        };
      
        const options = ['Oldest', 'Newest'];
      
        return (
          <div className="dropdown">
            <button onClick={toggleDropdown} className="dropdown-button">
              {selectedOption}
            </button>
            {isOpen && (
              <ul className="dropdown-list">
                {options.map((option) => (
                  <li key={option} onClick={() => handleOptionClick(option)} className="dropdown-item">
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      };
    // Main App component
    const Messages = () => {
        const [activeTab, setActiveTab] = useState('All');
        const handleTabClick = (tab) => {
            setActiveTab(tab);
        };
        const renderContent = () => {
            switch (activeTab) {
                case 'All': return <div className='row'><AllMessages/></div>;
                case 'Unread': return <div className='row'>Unread</div>;
                case 'Read': return <div className='row'>Read</div>;
                case 'Send': return <div className='row'>Send</div>;
                case 'Starred': return <div className='row'>Starred</div>;
                default:
                    return null;
                }
            };
                
            return (
                <>
                    <TopBar/>
                    <Container>
                        <Row>
                            <div className='d-flex justify-content-between align-items-center position-relative'>
                                <div className='MessageTabs' style={{ display: 'flex', marginBottom: '10px' }}>
                                    {['All', 'Unread', 'Read', 'Send', 'Starred'].map((tab) => (
                                        <Tab key={tab} label={tab} activeTab={activeTab} onClick={handleTabClick}/>
                                    ))}
                                </div>
                                <div className='DropDownBtn'>
                                    <div className="DropDownBtnMenu"><i className="mdi mdi-dots-vertical"></i><Dropdown /></div>
                                </div>
                            </div>
                            <div>
                                {renderContent()}
                            </div>
                        </Row>
                    </Container>
                </>
            );
        };

export default Messages;
