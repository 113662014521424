import React, { useContext, useRef, useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import WayBookContext from './WaybookContext';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from "reactstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { bcrypt, dcrypt, uInfo, uToken } from '../../useToken';
import { ApiUrl, AttachementsUrl } from '../../config';
import swal from "sweetalert";
export default function WaySideBarCoach(props) {

	const params = React.useContext(WayBookContext);
	const [isOpen, setIsOpen] = useState('sub' + params.category_i + '-' + params.subject_i);
	const [isDocOpen, setIsDocOpen] = useState('doc' + params.category_i + '-' + params.subject_i + '-' + params.documents_i);
	const [defaultIndex] = useState(params.category_i);
	const [lists, setLists] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const { search } = useLocation();
	const url_params = new URLSearchParams(search);
	const is_coach_section = url_params.get('sec') ? 'Yes' : "No";
	const [coursePaid, setCoursePaid] = useState(uInfo().type === 'Coach' ? JSON.parse(localStorage.getItem("course_free_paid")) : false);
	const [canEditCocah, setCanEditCocah] = useState(uInfo().type === 'Coach' ? JSON.parse(localStorage.getItem("can_edit_coach")) : false);
	const toggleOpen = (id) => () => setIsOpen((prevOpen) => (prevOpen === id ? null : id));
	const toggleDocOpen = (id) => () =>
		setIsDocOpen((isDocOpen) => (isDocOpen === id ? null : id));
	const Subject = ({ cat_id, index, subindex, content, subject, percentage, isOpen, isDocOpen, toggle, toggleDoc, status, image, user_id, training_for, tag_training, master_id, disabled }) => {


		return (
			<></>
		);
	};


	const dragItem = useRef();
	const dragOverItem = useRef();

	const dragStart = (e, position) => {
		e.preventDefault();


		if (!params.can_edit_coach) {
			e.preventDefault();
		}
		dragItem.current = position;
	};

	const dragEnter = (e, position) => {
		dragOverItem.current = position;
	};

	const dropTwo = (e, i) => {

		const copyListItems = [...lists[i].sub_cat];
		const dragItemContent = copyListItems[dragItem.current];
		copyListItems.splice(dragItem.current, 1);
		copyListItems.splice(dragOverItem.current, 0, dragItemContent);
		dragItem.current = null;
		dragOverItem.current = null;
		let newState = [...lists];
		newState[i].sub_cat = copyListItems;
		setLists(newState);
	};

	useEffect(() => {
		const fetchData = async () => {
			await getWayBookLists();
		};
		fetchData();
	}, []);
	const getWayBookLists = async () => {
		setLoaded(false);
		fetch(`${ApiUrl}` + "get/waybook/category", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ` + uToken(),
			},
			body: JSON.stringify({ can_edit: canEditCocah, is_coach_section: 'Yes' }),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.status === true) {
					setLists(response.data.record);
					params.setWayBookCoachList(response.data.record);
				} else {
					swal({
						title: response.message,
						icon: "warning",
					});
				}
				setLoaded(true);

			})
			.catch((error) => {
				setLoaded(true);

				console.error("Error:", error);
			});
	};

	const courseFreePaidHanlder = (e) => {
		console.log(e)

	}

	return (
		<>

			{/* <div className='CourseArea'>
				<div className='d-flex  w-100 WayBookDropDown CourseToggle mt-1 py-1 justify-content-between pe-2 ps-1'>
					{
						canEditCocah && uInfo().coach_can_sell_trainings === "active_paid" ? (
							<>
								<Link to={'#'} className="p-1 OpenTraining" > Course</Link>
							</>
						) : (
							<>
								{
									uInfo().type == 'Client' ?
										<>
											<Link to={'#'} className="p-1" >Course </Link>
											<Link to={'#'} className="p-1" > Back</Link>
										</>
										:
										<Link to={'#'} className="p-1" > Course </Link>
								}
							</>
						)
					}
					<div className='d-flex justify-content-between align-items-center'>
						<div className="form-check form-switch py-0 d-flex px-0" >
							<label className='mb-0 me-2'>Free</label>
							<input type="checkbox" className="form-check-input ms-0" id="customSwitch2" checked={JSON.parse(coursePaid)} onChange={e => { setCoursePaid(e.target.checked); localStorage.setItem("course_free_paid", e.target.checked); window.location.reload(); }} />
							<label className='mb-0 ms-2'>Paid</label>
						</div>
					</div>
				</div>
				{
					coursePaid && (
						<>
							<div className='CoursePrice d-flex justify-content-end align-items-center mt-2'>
								<label>Course Price</label>
								<input type='number' className='form-control'></input>
							</div></>
					)
				}

				<div className='subjects side-change-dropdown'>
					<Accordion>
						<Accordion.Item eventKey="0">
							<div className='CourseName px-2'>
								<Accordion.Header className='border-0'>Module 1</Accordion.Header>
								<Dropdown>
									<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
										<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
										<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
										<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<Accordion.Body className='px-2 py-0'>
								<div class="content-way collapse show">
									<div class="step-lists content-page-documents-list-item highlight-parent list-group">
										<div class="active  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i><a id="1547" class="upcoming-step" href="#">Lesson 1</a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
										<div class="highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i><a id="1548" class="upcoming-step" href="#">Lesson 2</a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
										<div class="  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i>
													<a id="1677" class="upcoming-step" href="#">Lesson 3<div class="text-danger"><small>unpublished</small></div></a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<div className='CourseName px-2'>
								<Accordion.Header className='border-0'>Module 2</Accordion.Header>
								<Dropdown>
									<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
										<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
										<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
										<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<Accordion.Body className='px-2 py-0'>
								<div class="content-way collapse show">
									<div class="step-lists content-page-documents-list-item highlight-parent list-group">
										<div class="active  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i><a id="1547" class="upcoming-step" href="#">Lesson 1</a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
										<div class="  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i><a id="1548" class="upcoming-step" href="#">Lesson 2</a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
										<div class="  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i>
													<a id="1677" class="upcoming-step" href="#">Lesson 3<div class="text-danger"><small>unpublished</small></div></a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<div className='CourseName px-2'>
								<Accordion.Header className='border-0'>Module 2</Accordion.Header>
								<Dropdown>
									<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
										<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
										<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
										<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<Accordion.Body className='px-2 py-0'>
								<div class="content-way collapse show">
									<div class="step-lists content-page-documents-list-item highlight-parent list-group">
										<div class="active  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i><a id="1547" class="upcoming-step" href="#">Lesson 1</a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
										<div class="  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i><a id="1548" class="upcoming-step" href="#">Lesson 2</a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
										<div class="  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i>
													<a id="1677" class="upcoming-step" href="#">Lesson 3<div class="text-danger"><small>unpublished</small></div></a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<div className='CourseName px-2'>
								<Accordion.Header className='border-0'>Module 3</Accordion.Header>
								<Dropdown>
									<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
										<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
										<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
										<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<Accordion.Body className='px-2 py-0'>
								<div class="content-way collapse show">
									<div class="step-lists content-page-documents-list-item highlight-parent list-group">
										<div class="active  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i><a id="1547" class="upcoming-step" href="#">Lesson 1</a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
										<div class="  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i><a id="1548" class="upcoming-step" href="#">Lesson 2</a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
										<div class="  highlight list-group-item">
											<div class="d-flex justify-content-between gap-1 AddBorder">
												<div class="steps-list d-flex align-items-center">
													<i class="fas fa-circle"></i>
													<a id="1677" class="upcoming-step" href="#">Lesson 3<div class="text-danger"><small>unpublished</small></div></a>
												</div>
												<div class="step-action">
													<Dropdown>
														<Dropdown.Toggle className='bg-white border-0 text-dark px-2' id="dropdown-basic"><i className="fas fa-ellipsis-h"></i></Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item href="#/action-1">Switch to Unpublish</Dropdown.Item>
															<Dropdown.Item href="#/action-2">Rename</Dropdown.Item>
															<Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
															<Dropdown.Item href="#/action-4">Add Module</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>


				<div className='d-flex  w-100 WayBookDropDown CourseToggle mt-1 py-1 justify-content-between pe-2 ps-1'>
					{
						canEditCocah && uInfo().coach_can_sell_trainings === "active_paid" ? (
							<>
								<Link to={'/trainings'} className="p-1 OpenTraining" > Course 1</Link>
							</>
						) : (
							<>
								{
									uInfo().type == 'Client' ?
										<>
											<Link to={'/trainings'} className="p-1" >Course </Link>
											<Link to={'/client/profile'} className="p-1" > Back</Link>
										</>
										:
										<Link to={'/trainings'} className="p-1" > Course </Link>
								}
							</>
						)
					}
					<div className='d-flex justify-content-between align-items-center'>
						<div className="form-check form-switch py-0 d-flex px-0" >
							Free
						</div>
					</div>
				</div>
			</div> */}
		</>
	);
}
