import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvInput,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uTheme,
  uToken,
} from "../../useToken";
import {
  ApiUrl,
  bgColor,
  ProductName,
  navTextColor,
  navTextHoverColor,
  btnPrimaryBgColor,
  btnPrimaryBgHoverColor,
  btnPrimaryHoverText,
  btnPrimaryTextColor,
  logoLightPng,
  getLocalStorage,
  ToastAutoClose
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

import ProfileSettings from "../Authentication/profile-sidebar";
import * as file from "../../WL/localhost/info";

import preval from "babel-plugin-preval/macro";

// const fileLastModifiedDate = preval.require('../../zuck.js.txt');
// const ProductName1 = preval`
//   const fs = require('fs').promises
//   module.exports = fs.readFile(('../wl/localhost/info_copy.js'),'utf8')`;

export default class UserOperation extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      id: obj.id,
      record: {},
      page_name: "Theme Settings",
      count: 0,
    };
  }

  //its old work for theme submit
  handleSubmit = async (event, values) => {
    const formData = new FormData();
    formData.append("bgColor", values.bgColor);
    formData.append("navTextColor", values.navTextColor);
    formData.append("navTextHoverColor", values.navTextHoverColor);
    formData.append("btnPrimaryTextColor", values.btnPrimaryTextColor);
    formData.append("btnPrimaryBgColor", values.btnPrimaryBgColor);
    formData.append("btnPrimaryHoverText", values.btnPrimaryHoverText);
    formData.append("btnPrimaryBgHoverColor", values.btnPrimaryBgHoverColor);
    formData.append("ProductName", values.ProductName);
    formData.append(
      "logoLightPng",
      document.getElementById("file-id").files[0]
    );

    fetch(`${ApiUrl}` + "update/theme", {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'multipart/form-data',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ` + uToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          localStorage.removeItem("theme");
          localStorage.setItem("theme", response.data.theme);
          window.location.reload(true);
          // this.getData();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  //its new work for theme
  changeTheme = (num) => {
    fetch(`${ApiUrl}` + "update/theme", {
      
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ select_theme: num }),
    
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          localStorage.removeItem("theme");
          localStorage.setItem("theme", response.data.theme);
          window.location.reload(true);
          // this.getData();
         // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

   
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    //console.log(this.props, "this.state.count");
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>
        <Row>
          <Col lg={2} className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg={10}>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>

            <ToastContainer />
            <Row className="wldomain_setting">
            <Row className="mt-1">
              <Col lg={4}>
                <div className="card  text-white" style={{background:'#00002A'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 1</p>
                    <button
                      onClick={() => this.changeTheme(1)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="card text-white" style={{background:'#fb6704'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 2</p>
                    <button 
                      onClick={() => this.changeTheme(2)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="card bg-success text-white">
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 3</p>
                    <button
                      onClick={() => this.changeTheme(3)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              </Row>
              <Row className="mt-1">
                <Col lg={4}>
                  <div className="card text-white" style={{background:'#106e63'}}>
                    <div className="card-header h4 font-16 mt-0 card-header">
                      Featured
                    </div>
                    <div className="card-body">
                      <div className="h4 card-title">Pick Your Theme</div>
                      <p className="card-text">Theme 4</p>
                      <button
                        onClick={() => this.changeTheme(4)}
                        className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                      >
                        Pick
                      </button>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="card text-white" style={{background:'#f54254'}}>
                    <div className="card-header h4 font-16 mt-0 card-header">
                      Featured
                    </div>
                    <div className="card-body">
                      <div className="h4 card-title">Pick Your Theme</div>
                      <p className="card-text">Theme 5</p>
                      <button
                        onClick={() => this.changeTheme(5)}
                        className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                      >
                        Pick
                      </button>
                    </div>
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="card text-white" style={{background:'#f5c542'}}>
                    <div className="card-header h4 font-16 mt-0 card-header">
                      Featured
                    </div>
                    <div className="card-body">
                      <div className="h4 card-title">Pick Your Theme</div>
                      <p className="card-text">Theme 6</p>
                      <button
                        onClick={() => this.changeTheme(6)}
                        className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                      >
                        Pick
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-1">
              <Col lg={4}>
                <div className="card text-white" style={{background:'#ff3636'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 7</p>
                    <button
                      onClick={() => this.changeTheme(7)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="card text-white"  style={{background:'#239933'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 8</p>
                    <button
                      onClick={() => this.changeTheme(8)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="card text-white" style={{background:'#236299'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 9</p>
                    <button
                      onClick={() => this.changeTheme(9)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              </Row>

              <Row className="mt-1">
              <Col lg={4}>
                <div className="card text-white" style={{background:'#992364'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 10</p>
                    <button
                      onClick={() => this.changeTheme(10)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="card text-white" style={{background:'#992323'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 11</p>
                    <button
                      onClick={() => this.changeTheme(11)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="card text-white" style={{background:'#762399'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 12</p>
                    <button
                      onClick={() => this.changeTheme(12)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              </Row>

              <Row className="mt-1">
              <Col lg={4}>
                <div className="card text-white" style={{background:'#dba902'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 13</p>
                    <button
                      onClick={() => this.changeTheme(13)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>

              <Col lg={4}>
                <div className="card text-white" style={{background:'#6bdb02'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 14</p>
                    <button
                      onClick={() => this.changeTheme(14)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="card text-white" style={{background:'#db0202'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 15</p>
                    <button
                      onClick={() => this.changeTheme(15)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              </Row>

              <Row className="mt-1">
              <Col lg={4}>
                <div className="card text-white" style={{background:'#000000'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 16</p>
                    <button
                      onClick={() => this.changeTheme(16)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="card text-white" style={{background:'#454444'}}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 167</p>
                    <button
                      onClick={() => this.changeTheme(17)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
              </Row>
              <Col md={8}>
                {/* <AvForm encType="multipart/form-data" onValidSubmit={this.handleSubmit}
                                        ref={c => (this.form = c)}
                                        className="needs-validation">

                                        <div className="row">

                                            <div className="mb-3 col-md-12">
                                                <AvField
                                                    id="file-id"
                                                    type="file"
                                                    name="logoLightPng"
                                                    label="Site Logo"
                                                    className="form-control"
                                                    
                                                />
                                                <img className="img mt-3 img-rounded" width={80} src={logoLightPng} alt="No logo found" />
                                            </div>

                                            <div className="mb-3 col-md-12">
                                                <AvField
                                                    type="color"
                                                    name="bgColor"
                                                    label="Top navbar background color"
                                                    value={bgColor}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>


                                            <div className="mb-3 col-md-6">
                                                <AvField
                                                    type="color"
                                                    name="navTextColor"
                                                    label="Top navbar text color"
                                                    value={navTextColor}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <AvField
                                                    type="color"
                                                    name="navTextHoverColor"
                                                    label="Top navbar text hover color"
                                                    value={navTextHoverColor}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3 col-md-6">
                                                <AvField
                                                    type="color"
                                                    name="btnPrimaryTextColor"
                                                    label="Primary button text color"
                                                    value={btnPrimaryTextColor}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <AvField
                                                    type="color"
                                                    name="btnPrimaryBgColor"
                                                    label="Primary button background color"
                                                    value={btnPrimaryBgColor}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3 col-md-6">
                                                <AvField
                                                    type="color"
                                                    name="btnPrimaryHoverText"
                                                    label="Primary button hover text color"
                                                    value={btnPrimaryHoverText}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <AvField
                                                    type="color"
                                                    name="btnPrimaryBgHoverColor"
                                                    label="Primary button hover background color"
                                                    value={btnPrimaryBgHoverColor}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3 col-md-12">
                                                <AvField
                                                    type="text"
                                                    name="ProductName"
                                                    label="Footer Text"
                                                    value={ProductName}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <button className="btn btn-primary waves-effect waves-light mt-3"
                                            type="submit"> Submit </button>

                                    </AvForm> */}
              </Col>
              <Col md={4}></Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
