import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup } from "availity-reactstrap-validation";
import {
    convertPhoneFormat,
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select"
import countries from "../../common/data/countries";
import AsyncSelect from 'react-select/async';

export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id != '' ? this.props.id : '',
            record: {},
            clients: [],
            clients_id: 0,
            permissions: [],
            permission: [],
            emails: [],
            phoneNumbers: [],
            email_reminder: false,
            sms_reminder: false,
            is_important: false,
        };
    }

    componentDidMount() {
        if (this.state.id != '') {
            this.getData();
        }
        this.getFormData(this.state.id);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.id != this.props.id) {
            this.setState({
                id: this.props.id != '' ? this.props.id : '',
                record: {},
            }, () => this.getData())
        }
    }

    getFormData = (id = '') => {
        fetch(`${ApiUrl}` + "get/task/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        emails: response.data.emails,
                        phoneNumbers: response.data.phoneNumbers,
                        permissions: response.data.coachPartners,
                        clients: response.data.clients
                    });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    getData = () => {
        fetch(`${ApiUrl}` + "get/edit/task", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ id: this.state.id, coach_id: this.state.coach_id },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    if (response.data.record.is_important == 'Yes') {
                        this.setState({
                            is_important: true
                        })
                    }
                    if (response.data.record.email_reminder == 'Yes') {
                        this.setState({
                            email_reminder: true
                        })
                    } else {

                    }

                    if (response.data.record.sms_reminder == 'Yes') {
                        this.setState({
                            sms_reminder: true
                        })
                    }
                    this.setState({
                        record: response.data.record,
                        client_id: response.data.record.client_id,
                        assign_to: response.data.record.assign_to,


                    });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleSubmit = async (event, values) => {
        if (this.state.id) {
            values.id = this.state.record.task_id ? this.state.record.task_id : -1;
        }

        values.email_reminder = values.email_reminder == false ? 'No' : 'Yes';
        values.sms_reminder = values.sms_reminder == false ? 'No' : 'Yes';
        values.is_important = values.is_important == false ? 'No' : 'Yes';
        fetch(`${ApiUrl}` + "add/task", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, 'Editing');
                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    this.props.cancelSlider();

                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleInputChange = (inputValue) => {
        // const inputValue = newValue.replace(/\W/g, '');
        if (inputValue.length > 3) {
            fetch(`${ApiUrl}` + "search/clients", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },

                body: JSON.stringify({ search: inputValue }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        this.setState({
                            clients: response.data.clients
                        });
                    } else {
                                  
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        };
        console.log(inputValue.length);
    };

    render() {
        console.log(this.props)
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                // model={this.state.record}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <ModalBody className="row">
                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="title"
                                            value={this.state.record.title}
                                            label="Task Title"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <AvGroup check>
                                            <AvInput checked={this.state.record.is_important == 'Yes' ? true : false} onClick={(e) => this.setState({
                                                is_important: !this.state.is_important
                                            })}
                                                type="checkbox" name="sms_reminder" />
                                            <label className="control-label"> Important</label>
                                        </AvGroup>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <AvField
                                            name="server_date"
                                            value={this.state.record.server_date}
                                            label="Select Date"
                                            className="form-control"
                                            required
                                            type="date"
                                        />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <AvField
                                            name="server_time"
                                            value={this.state.record.server_time}
                                            label="Select Time"
                                            className="form-control"
                                            required
                                            type="time"
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="control-label">Client</label>
                                        <div className="MainTZone Task One">
                                            <Select
                                               getOptionLabel={option =>  option.client_id + ' # ' + option.first_name + ' ' + option.last_name  + ((option.email != '') ? ' - ' + option.email : ' - '+option.country_code +option.mobile)}
                                                getOptionValue={option => option.client_id}
                                                isMulti={false}
                                                value={this.state.clients.filter(({ client_id }) => client_id === this.state.client_id)}
                                                options={this.state.clients}
                                                classNamePrefix="select2-selection"
                                                onInputChange={this.handleInputChange}
                                                onChange={(e) => { this.setState({ client_id: e.client_id }); }}
                                            />
                                        </div>

                                        <AvField required type="hidden" name="client_id" value={this.state.client_id} />


                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="control-label">Assign to</label>
                                        <div className="MainTZone Task Two">
                                            <Select
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.user_id}
                                                isMulti={false}
                                                value={this.state.permissions.filter(({ user_id }) => user_id === this.state.assign_to)}
                                                options={this.state.permissions}
                                                classNamePrefix="select2-selection"
                                                onChange={(e) => { this.setState({ assign_to: e.user_id }); }}
                                            />
                                        </div>
                                        <AvField required type="hidden" name="assign_to" value={this.state.assign_to} />


                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <AvGroup check>
                                            <AvInput checked={this.state.record.email_reminder == 'Yes' ? true : false} onClick={(e) => this.setState({
                                                email_reminder: !this.state.email_reminder
                                            })}
                                                type="checkbox" name="email_reminder" />
                                            <label className="control-label">Email Reminder</label>
                                        </AvGroup>
                                    </div>

                                    {this.state.email_reminder && (
                                        <div className="col-md-6 mb-3">
                                            <AvField
                                                className="form-control"
                                                name="from_email"
                                                label="From Email"
                                                value={this.state.record.from_email}
                                                required
                                            />
                                        </div>
                                    )}


                                    {/* {
                                        this.state.email_reminder && (
                                            <div className="col-md-6 mb-3">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="from_email"
                                                    label="From Email (Optional)"
                                                    value={this.state.record.from_email}
                                                    required
                                                >
                                                    <option value=""> Select From Email </option>
                                                    {
                                                        this.state.emails.map((row, i) => {
                                                            return <option key={i} value={row.other_settings.from_email}>{row.other_settings.from_email}</option>;
                                                        })
                                                    }
                                                </AvField>
                                            </div>
                                        )
                                    } */}


                                    <div className="col-md-12 mb-3">
                                        <AvGroup check>
                                            <AvInput checked={this.state.record.sms_reminder == 'Yes' ? true : false} onClick={(e) => this.setState({
                                                sms_reminder: !this.state.sms_reminder
                                            })}
                                                type="checkbox" name="sms_reminder" />
                                            <label className="control-label"> SMS Reminder</label>
                                        </AvGroup>
                                    </div>



                                    {
                                        this.state.sms_reminder && (

                                            <div className="col-md-6 mb-3">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="from_number"
                                                    label=" Phone Number (Optional)"
                                                    required
                                                    value={this.state.record.from_number}
                                                >
                                                    <option value=""> Select Phone Number </option>
                                                    {
                                                        this.state.phoneNumbers.map((row, i) => {
                                                            return <option key={i} value={row.phone_number}>{convertPhoneFormat(row.phone_number)}</option>;
                                                        })
                                                    }
                                                </AvField>
                                            </div>
                                        )
                                    }






                                </ModalBody>
                                {/* <ModalFooter>
                                    <button
                                        className="btn btn-primary waves-effect waves-light"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </ModalFooter> */}
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
