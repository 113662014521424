import React, { useState, useEffect } from 'react';

import { ApiUrl, AttachementsUrl } from '../../config';

import { bcrypt, dcrypt, uInfo, uToken } from '../../useToken';
import { Link } from 'react-router-dom';


import "suneditor/dist/css/suneditor.min.css";

// useEffect(() => {
//     getsearch();

// }, []);

function App(props) {


}

const SearchBar = (props) => {
    return (
    <div className='SearchResults'>
        <div className='border-bottom'>
            <h5><i class="mdi mdi-magnify"></i> Found {props.count_record} steps matching “{props.search_word}”</h5>
        </div>
<div className='SearchResultItem'>
{/* <div className='SearchResultItemTitle bg-gray p-2 mb-3 d-flex align-items-center justify-content-center'>
    <i className='mdi mdi-alert-circle-outline'></i>&nbsp; <p>Showing results for published content only. Click here to search all content.
        </p></div> */}
        {/* <ul className='mt-3'>
            <li className='InContent mb-2'>
                <a>
                    <strong>Showing results for published content only. "{props.search_word}"</strong>
                </a>
            </li>
        </ul> */}
        {
            props.search_result.length > 0 ? (
                props.search_result.map((row, i) => (
                    <ul key={i} className='SearchList mb-3'>
                        <li className='InContent HeadingSearch mb-0'>
                            <a href={row.link}>
                                {row.step_title}
                            </a>
                        </li>
                        <li className='InContent InDocumentsSearch mb-0'>
                            <a href={row.link}>
                                In Document: <span>{row.document_title}</span>
                            </a>
                        </li>
                        <li className='InContent InDocumentsSearch mb-2'>
                            <a href={row.link}>
                                In Subject: <span>{row.subject_title}</span>
                            </a>
                        </li>
                            {row.search_subject !== '' && (
                                <li className='InSubjectFind'>
                                    <a href={row.link} className='alert alert-info px-2 w-100 mb-0'>
                                        <div><i className=' fas fa-search'></i> Subject Name: <span dangerouslySetInnerHTML={{ __html: row.search_subject }} /></div>
                                    </a>
                                </li>
                            )}
                            {row.search_step !== '' && (
                                <li className='InSubjectFind'>
                                    <a href={row.link} className='alert alert-info px-2 w-100 mb-0'>
                                    <div><i className=' fas fa-search'></i> Step Name:<span dangerouslySetInnerHTML={{ __html: row.search_step }} /></div>
                                    </a>
                                </li>
                            )}
                            {row.search_content !== '' && (
                                <li className='InSubjectFind'>
                                    <a href={row.link} className='alert alert-info px-2 w-100 mb-0'>
                                        <div><i className=' fas fa-search'></i> Step Content:  <span dangerouslySetInnerHTML={{ __html: row.search_content }} /></div>
                                    </a>
                                </li>
                            )}

                    </ul>
                ))
            ) : (
                <ul className='mt-3'>
                    <li className='InContent mb-2'>
                        No record found
                    </li>
                </ul>
            )
        }

    </div>
    </div>
    );
  }
  
  export default SearchBar;
