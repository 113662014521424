import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Helmet from "react-helmet";
import { bcrypt, isAllowed, uInfo, uToken, convert_cent_to_doller } from "../../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import Select from "react-select";
import countries from "../../../../common/data/countries";
import { values } from "lodash";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import CreditsSidebar from "./credits-sidebar";
export default class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_name: "Credit Consumption Criteria",
      currentCountry: countries[0],
      countryListArray: [],
      allListArray: [],
      rowUpdate: {},
      consumptionList: {},
    };
  }

  componentDidMount() {
    this.getCreditRates();

  }

  getCreditRates = () => {
    fetch(`${ApiUrl}` + "credit/consumptionRates", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          consumptionList: response,
        });
        if (this.state.consumptionList) {
          this.countryChange(this.state.currentCountry);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  countryChange = (country) => {

    this.setState({
      currentCountry: country,
    });

    var list = this.state.consumptionList["c"];

    var array = [];
    for (var key in list) {
      if (list[key][country.id] != undefined) {
        array.push({
          label: list[key].text,
          value: list[key][country.id],
          all: list[key]["all"],
          key: key,
        });
      }
    }

    var list2 = this.state.consumptionList["all"];
    var array2 = [];
    for (var key in list2) {
      array2.push({
        label: list2[key].text,
        value: "",
        all: list2[key]["all"],
        key: key,
      });
    }

    this.setState({
      countryListArray: array,
      allListArray: array2,
    });


  };

  handleSubmit = async (event, values) => {
    var file = this.state.consumptionList;
    var list = file.c;

    var country = this.state.currentCountry.id;
    for (var key in list) {
      if (list[key][this.state.currentCountry.id] != undefined) {
        list[key][country] = values[key];
      }
    }
    file.c = list;

    this.requestSubmit(file);
  };

  handleSubmitAll = async (event, values) => {
    var file = this.state.consumptionList;
    var list = file.all;
    for (var [i, value] of Object.entries(values)) {
      list[i]["all"] = value;
    }
    file.all = list;
    this.requestSubmit(file);
  };

  requestSubmit = async (file) => {
    fetch(`${ApiUrl}` + "update/credits/consume/criteria", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ json: file }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            modal: false,
            modal2: false,
          });
          this.countryChange(this.state.currentCountry);
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>

        <Row>
          <Col lg="2" className="pl-0">
            <CreditsSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">{this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <ToastContainer />
            <Card>
              <CardBody>
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    console.log(e, v);
                  }}
                >
                  <Row className="align-items-center">
                    <Col md={1}>
                      <label>Country:</label>
                    </Col>
                    <Col md={7}>
                      <div className="CoachesDrop Criteria">
                        <div className="DropDownCoh">
                          <Select
                            value={this.state.currentCountry}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.id}
                            isMulti={false}
                            options={countries}
                            classNamePrefix="select2-selection"
                            name="time_zone"
                            onChange={(e) => {
                              this.countryChange(e);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <button
                        onClick={() =>
                          this.setState({
                            modal: true,
                          })
                        }
                        className="btn btn-primary btn-sm text-transform-capitalize"
                      >
                        Edit Price For ({this.state.currentCountry.id.toUpperCase()})
                      </button>
                    </Col>

                    <Col md={12}>
                      <div className="table-responsive mt-3">
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Amount </th>
                              <th>For All</th>
                              <th className="hidden-xs"> Action </th>
                            </tr>
                            <tr>
                              <th colSpan={3}>
                                Prices For country (
                                {this.state.currentCountry.id.toUpperCase()})
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.countryListArray.map((list, i) => {
                              return (
                                <tr key={i}>
                                  <td>{list.label}</td>
                                  <td>{convert_cent_to_doller(list.value)}</td>
                                  <td>{convert_cent_to_doller(list.all)}</td>
                                  <td className="hidden-xs"></td>
                                </tr>
                              );
                            })}
                            <tr>
                              <th className="border-0" colSpan={3}>For All Countries</th>
                            </tr>

                            {this.state.allListArray.map((list, i) => {
                              return (
                                <tr key={i}>
                                  <td>{list.label}</td>
                                  <td>{convert_cent_to_doller(list.value)}</td>
                                  <td>{convert_cent_to_doller(list.all)}</td>
                                  <td className="hidden-xs">
                                    <button
                                      onClick={() =>
                                        this.setState({
                                          modal2: true,
                                          rowUpdate: list,
                                        })
                                      }
                                      className="btn btn-primary btn-sm text-transform-capitalize"
                                    >
                                      <i className="mdi mdi-pencil"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>

              <Modal
                isOpen={this.state.modal2}
                role="dialog"
                centered={true}
                className="exampleModal"
                tabIndex="-1"
                toggle={() => {
                  this.setState({
                    modal2: !this.state.modal2,
                  });
                }}
              >
                <div className="modal-content">
                  <ModalHeader
                    toggle={() => {
                      this.setState({
                        modal2: !this.state.modal2,
                      });
                    }}
                    className="text-transform-capitalize"
                  >
                    {this.state.rowUpdate.label}
                  </ModalHeader>
                  <AvForm
                    onValidSubmit={this.handleSubmitAll}
                    className="needs-validation"
                  >
                    <ModalBody>
                      <div className="mb-3" key={0}>
                        <label>{this.state.rowUpdate.label}</label>
                        <div className="group-addnon">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="validationTooltipUsernamePrepend"
                              >
                                ¢
                              </span>

                            </div>
                            <AvField
                              name={this.state.rowUpdate.key}
                              // label={this.state.rowUpdate.label}
                              value={this.state.rowUpdate.all}
                              className="form-control"
                              placeholder={`Enter ${this.state.rowUpdate.label}`}
                              validate={{
                                // required: { value: true, errorMessage: 'This field is invalid' },
                                pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter className="border-0 pt-0">
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            modal2: !this.state.modal2,
                          });
                        }}
                      >
                        Close
                      </Button>
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Save
                      </button>
                    </ModalFooter>
                  </AvForm>
                </div>
              </Modal>

              <Modal
                isOpen={this.state.modal}
                role="dialog"
                centered={true}
                className="exampleModal"
                tabIndex="-1"
                toggle={() => {
                  this.setState({
                    modal: !this.state.modal,
                  });
                }}
              >
                <div className="modal-content">
                  <ModalHeader
                    toggle={() => {
                      this.setState({
                        modal: !this.state.modal,
                      });
                    }}
                    className="text-transform-capitalize"
                  >
                    Prices For country ({this.state.currentCountry.id.toUpperCase()})
                  </ModalHeader>
                  <AvForm
                    onValidSubmit={this.handleSubmit}
                    className="needs-validation"
                  >
                    <ModalBody>
                      {this.state.countryListArray.map((list, i) => {
                        return (
                          <div className="mb-3" key={i}>
                            <label> {list.label} </label>
                            <div className="group-addnon">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                  >¢
                                  </span>
                                </div>
                                <AvField
                                  name={list.key}
                                  value={list.value}
                                  className="form-control"
                                  placeholder={`Enter ${list.label}`}
                                  validate={{
                                    pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </ModalBody>
                    <ModalFooter className="border-0 pt-0">
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            modal: !this.state.modal,
                          });
                        }}
                      >
                        Close
                      </Button>
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Save
                      </button>
                    </ModalFooter>
                  </AvForm>
                </div>
              </Modal>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
