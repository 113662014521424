import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import TrainingSidebar from "./training-sidebar";
export default class TrainingCategoryCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cat_id: this.props.match.params.cat_id
        ? dcrypt(this.props.match.params.cat_id)
        : null,
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {},
      page_name: this.props.match.params.id
        ? "Edit Product Section "
        : "Create Product Section",
      roles: [],
      packages: [],
      resellers: [],
      image: "",
      categories: [],
      category_name: "",
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.getData();
    }
    if (this.props.match.params.cat_id) {
      this.gettrainingcategory();
    }

  }

  gettrainingcategory = () => {
    fetch(`${ApiUrl}` + "get/edit/training/Category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.cat_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            category_name: response.data.record.title,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/training/Category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            cat_id: response.data.record.master_id,
            image: response.data.record.image,
            path: response.data.path,
          });
          this.gettrainingcategory();
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    if (this.state.id) {
      submitData.append('id', this.state.id ? this.state.id : -1);
    }

    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }
    submitData.append('cat_id', this.state.cat_id);

    fetch(`${ApiUrl}` + "edit/training/categorysection", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }

          this.props.history.goBack();
          // this.getData();
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  changeHandler = (e) => {
    console.log(e.target.files[0])
    if (e.target.files[0].type.match('image.*')) {
      const preview = document.querySelector('#preview_img');
      var reader = new FileReader();
      console.log(reader, 'readrt', preview)
      reader.onload = () => {
        preview.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      toast('Olny image allowed', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          <Col lg="2" className="pl-0">
            <TrainingSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />
            <Row>
              <Col className="col-12">
                <Card>
                  <form onSubmit={this.handleSubmit} method="post"
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                    <ModalBody>
                      <h6>Product Name: {this.state.category_name} </h6>
                      <div className="form-group mb-3">
                        <label>Title</label>
                        <input
                          name="title"
                          defaultValue={this.state.record.title}
                          className="form-control"
                          placeholder="Enter Title"
                          required
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label>Enter Short Description</label>
                        <textarea
                          name="short_description"
                          label="Enter Short Description"
                          defaultValue={this.state.record.short_description}
                          className="form-control"
                          placeholder="Enter Description"
                          rows={3}
                        />
                      </div>

                      <div className="form-group mb-3">
                      <label>Enter Long Description</label>
                        <textarea
                          name="description"
                          label="Enter Long Description"
                          defaultValue={this.state.record.description}
                          className="form-control"
                          placeholder="Enter Description"
                          rows={7}
                        />
                      </div>

                      <div className="form-group mb-3">
                        Image Name: {this.state.record.image}
                        <input
                          name="image"
                          //  value={this.state.record.image}
                          label="Image"
                          accept="image/*"
                          className="form-control"
                          type="file"
                          onChange={(e) => this.changeHandler(e)}
                        />

                        <div className="m-1">
                          <img id="preview_img" src={this.state.path} height="200" alt="Image preview" />
                        </div>

                      </div>


                      {this.state.id && (
                        <div className="form-group mb-3">
                          <select
                            className="select form-control"
                            type="select"
                            id="s"
                            name="status"
                            label="Status"
                            required
                            value={this.state.record.status}
                            onChange={(e) => this.setState(prevState => {
                              let record = Object.assign({}, prevState.record);
                              record.status = e.target.value;
                              return { record };
                            })}
                          >
                            <option value={""}> Select Status </option>
                            <option value={"Active"}> Active </option>
                            <option value={"Inactive"}> Inactive </option>
                          </select>
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Submit
                      </button>
                    </ModalFooter>
                  </form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

      </React.Fragment>
    );
  }
}
