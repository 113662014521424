import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Table,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import Helmet from "react-helmet";
import {
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import $ from "jquery";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import swal from 'sweetalert';
import Loader from "react-loader";
import Select from "react-select";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default class TrainingCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCheckAll: {},
      isCheck: {},
      records: [],
      roles: [],
      pages: ['theme', 'groups', 'tags', 'lead_custom_fields'],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Clone Coach Settings",
      customDate: true,
      loader: true,
      coach_users: [],
      existing_options: [],
      from_coach_user: "",
      to_coach_ids: [],
      to_coach_user: [],
      clone_sections: [
      ],
      CloneData: { clone_modules: [] },
      checkAllTrue: false,
      loaderToUsers: false,
      cloneLoader: true,
    };
    

  }

  componentDidMount() {
    this.getData();
  
  }

  

  getData = (type = "default") => {  
    fetch(`${ApiUrl}` + "get/coaches", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ type: this.state.path }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (type == 'filter') {
            const to_coach_ids = data.data.records.map(user => user);
            this.setState({
              to_coach_ids: to_coach_ids,
         
              to_coach_user: data.data.records,
              loaderToUsers: true
            });
          }else{
            const existingOptions = [...data.data.records];
            existingOptions.push({ user_id: -1, name: "All Coaches", email: "The selected module data will be cloned for all coach users.", from_email_status: -1 });
            this.setState({
              coach_users: data.data.records,
              existing_options: existingOptions,
            });
          }

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  handleSubmit = async (event, values) => { 
    this.setState({ cloneLoader: false });
    var action_arr = this.state.CloneData.clone_modules;
    values.from = this.state.from_coach_user.user_id;
   

    const to_coach_ids_arr = this.state.to_coach_ids.map(record => record.user_id);
    if (to_coach_ids_arr && to_coach_ids_arr.length === 0) {
      this.state.clone_sections.map((row, i) => {
        this.handleSelectAll(false, [], row.index)
      })
      this.setState({ cloneLoader: true });
      swal({
        text: "Please select to coach user(s) to perform this aciton.",
        icon: "warning",
      });
      return false;
    }

    const invalidRecordCount = this.state.to_coach_ids.length;
    
    if(invalidRecordCount < 1){
      this.state.clone_sections.map((row, i) => {
        this.handleSelectAll(false, [], row.index)
      })
      this.setState({ cloneLoader: true });
      swal({
        text: "Please select coach users who have verified their email settings to proceed with this action.",
        icon: "warning",
      });
      return false;
    }
    values.to_coach_ids = to_coach_ids_arr.join(',');
    values.action = action_arr;
    values.action_type = 'all';

    swal({
      title: "Are you sure?",
      text: "You want to perform this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        this.setState({ cloneLoader: true });
        if (confirmation) {
          this.setState({ loader: false });
          fetch(`${ApiUrl}` + "save/clone/action", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === true) {
                this.state.clone_sections.map((row, i) => {
                  this.handleSelectAll(false, [], row.index)
                })
                this.getCloneData(this.state.from_coach_user.user_id); 
                // this.setState({
                //   to_coach_ids: [],
                //   to_coach_user: [],
                //   clone_sections: [],
                // })
                swal({
                  text: "Data cloned successfully.",
                  icon: "success",
                });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }

              this.setState({ loader: true });

            })
            .catch((error) => {
              this.setState({ loader: true });

              console.error("Error:", error);
            });
        }
      });
  };

  getCloneData(from, to) {

    if (from == '') {
      return false;
    }
    fetch(`${ApiUrl}` + "get/coach/cloneData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ from: from}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            clone_sections: response.data.records.other_settings,
          });



        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });

    //console.log(this.state.coach_users)
  }

  handleChange(e) {
    const { name, value, checked } = e.target;
    const { clone_modules } = this.state.CloneData;

    // If the checkbox is unchecked, filter out the value from clone_modules
    if (!checked) {
      const updatedCloneModules = clone_modules.filter(color => color !== value);
      this.setState(prevState => ({
        CloneData: {
          ...prevState.CloneData,
          clone_modules: updatedCloneModules
        }
      }));
    } else {
      // If the checkbox is checked, add the value to clone_modules
      const updatedCloneModules = [...clone_modules, value];
      this.setState(prevState => ({
        CloneData: {
          ...prevState.CloneData,
          clone_modules: updatedCloneModules
        }
      }));
    }

  
   
}


  handleSubClick = (e, key, index) => {
    //console.log(e, key, index, 'e, key,index', this.state.isCheck[index]);

    if (e) {
      if (this.state.isCheck[index] != undefined && this.state.isCheck[index].length > 0) {
        this.setState(prevState => ({
          isCheck: {                   // object that we want to update
            ...prevState.isCheck,    // keep all other key-value pairs
            [index]: [...this.state.isCheck[index], key]     // update the value of specific key
          }
        }))
      } else {
        this.setState(prevState => ({
          isCheck: {                   // object that we want to update
            ...prevState.isCheck,    // keep all other key-value pairs
            [index]: [key]    // update the value of specific key
          }
        }))
      }
      // console.log(this.state.isCheck, 'this.state.isCheck')
    } else {
      // console.log('here')
      this.setState((prevState) => ({
        isCheck: {
          ...prevState.isCheck,
          [index]: this.state.isCheck[index].filter(
            (color) => color !== key
          ),
        },
      }));
    }

    setTimeout(() => {
     
      if (!this.state.isCheckAll[index]) {
        this.setState(prevState => ({
          isCheckAll: {                   // object that we want to update
            ...prevState.isCheckAll,    // keep all other key-value pairs
            [index]: !this.state.isCheckAll[index]    // update the value of specific key
          }
        }))
        this.setState((prevState) => ({
          CloneData: {
            ...prevState.CloneData,
            ['clone_modules']: [...prevState.CloneData['clone_modules'], index],
          },
        }));

        $('#sub_main_' + index).prop("checked", true);
       // console.log("testing timeout");

      } else {

        $('#sub_main_' + index).prop("checked", true);

      }

      if (this.state.isCheck[index] != undefined && this.state.isCheck[index].length < 1) {
        //console.log(this.state.isCheckAll, 'this.state.isCheckAll[index]');
        //console.log(this.state.isCheckAll, 'this.state.isCheckAll[index]')


        this.setState(prevState => {
          const { isCheckAll } = prevState;
          const updatedIsCheckAll = { ...isCheckAll };  

          delete updatedIsCheckAll[index]; 

          return {
            isCheckAll: updatedIsCheckAll  
          };
        });

        this.setState(prevState => {
          const { CloneData } = prevState;

          const updatedCloneModules = CloneData['clone_modules'].filter(item => item !== index);

          return {
            CloneData: {
              ...CloneData,
              ['clone_modules']: updatedCloneModules
            }
          };
        });

        $('#sub_main_' + index).prop("checked", false);

      }
    }, 100);

  }


  handleSelectAll = (e, sub_tabs, key) => {
    //console.log(e, sub_tabs, key, 'e, sub_tabs, key')

    this.setState(prevState => ({
      isCheckAll: {                   // object that we want to update
        ...prevState.isCheckAll,    // keep all other key-value pairs
        [key]: !this.state.isCheckAll[key]    // update the value of specific key
      }
    }))


    if (!e) {
      this.setState(prevState => ({
        isCheck: {                   
          ...prevState.isCheck,
      
          [key]: []   
        }
      }))


      let a = this.state.pages;
      let b = sub_tabs?.map(li => li.id)

      let c = a.filter(item => !b.includes(item));
      let d = c.filter(function (ele) {
        return ele != key;
      });

      this.setState(prevState => ({
        record: {                   // object that we want to update
          ...prevState.record,    // keep all other key-value pairs
          pages: d    // update the value of specific key
        }
      }))
     



    }


    if (e) {
      this.setState(prevState => ({
        isCheck: {                   // object that we want to update
          ...prevState.isCheck,    
          [key]: sub_tabs?.map(li => li.id)    // update the value of specific key
        }
      }))
    }


  }

  handleUsers = (selectedUsers) => {
    this.setState({ cloneLoader: false });
    let updatedUsers = [...selectedUsers]; // Create a new array to avoid mutation
    let filter = [];

    if (selectedUsers) {
        filter = selectedUsers.filter(user => user.user_id === -1);
  
        if (filter.length > 0) {
            this.getData('filter');
            updatedUsers = filter;
        } else {
            updatedUsers = selectedUsers.filter(user => user.user_id !== -1);
        }
    }
   
    const existingToCoachIds = this.state.to_coach_ids;
    const updatedToCoachIds = updatedUsers.map(user => {
        if (existingToCoachIds.some(item => item.user_id === user.user_id)) {
            return user;
        } else {
            return user;
        }
    }).filter(user_id => user_id !== null); // Filter out null values

       
    this.setState({
      to_coach_ids: updatedToCoachIds,
      to_coach_user: updatedUsers,
      loaderToUsers: true
    });
    this.state.clone_sections.map((row, i) => {
      this.handleSelectAll(false, [], row.index)
    })
    this.setState({ cloneLoader: true });
};

deleteRecord = (record, index) => {
  const { to_coach_user } = this.state;
  const recordIndex = to_coach_user.findIndex(item => item.user_id === record.user_id);
  
  if (recordIndex !== -1) {
    const updatedToCoachUser = to_coach_user.filter((item, idx) => idx !== recordIndex);

    if (this.state.to_coach_ids.some(item => item.user_id === record.user_id)) {
      this.setState(prevState => ({
        to_coach_ids: prevState.to_coach_ids.filter(user => user.user_id !== record.user_id)
      }));
    }
   
    this.setState({ to_coach_user: updatedToCoachUser });
  }
}
handleChangeToChoch(row, i) {
  if (this.state.to_coach_ids.some(item => item.user_id === row.user_id)) {
    this.setState(prevState => ({
      to_coach_ids: prevState.to_coach_ids.filter(user => user.user_id !== row.user_id)
    }));
  } else {
    this.setState(prevState => ({
      to_coach_ids: [...prevState.to_coach_ids, row]
    }));
  }
}
  render() {
    return (
      <>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          <Col lg="12">
            <Loader loaded={this.state.loader}></Loader>

            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">{this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <ToastContainer />

            <Row>
              <Col xl="12">
                <Card className="mb-0">
                  <CardBody className="pb-0">
                    <AvForm
                      ref={(c) => (this.form = c)}
                      onValidSubmit={this.handleSubmit}
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <AvGroup>
                              <label className="m-0"> From Coach </label>
                              <div className="CoachesDrop">
                                <div className="DropDownCoh">
                                  <Select
                                    value={this.state.from_coach_user}
                                    getOptionLabel={(option) => option.name + ' - ' + option.email}
                                    getOptionValue={(option) => option.user_id}
                                    isMulti={false}
                                    options={this.state.coach_users}
                                    classNamePrefix="select2-selection"
                                    className={"is-touched is-dirty av-valid"}
                                    name="admin"
                                    onChange={(e) => {
                                      this.setState({ from_coach_user: e });
                                      this.getCloneData(e.user_id, this.state.to_coach_user);
                                    }}
                                  />
                                </div>
                              </div>
                            </AvGroup>
                          </div>
                          {this.state.from_coach_user != '' && (
                            <div className="form-group mb-3">
                              <div className="table-responsive user-info">
                                <Table className="table table-bordered mb-0 border-0">
                                  <tbody>
                                    <tr>
                                      <td>UserId</td>
                                      <td>{this.state.from_coach_user.user_id}</td>
                                    </tr>
                                    <tr>
                                      <td>Name</td>
                                      <td>{this.state.from_coach_user.name}</td>
                                    </tr>
                                    <tr>
                                      <td>Email</td>
                                      <td>{this.state.from_coach_user.email}</td>
                                    </tr>
                                    <tr>
                                      <td>Status</td>
                                      <td>{this.state.from_coach_user.status}</td>
                                    </tr>
                                    <tr>
                                      <td>Created At</td>
                                      <td>{this.state.from_coach_user.created_at}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          )
                          }
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <AvGroup>
                              <label className="m-0"> To Coach </label>
                              <div className="CoachesDrop ToCoach">
                                <div className="DropDownCoh ClonePage">
                                    <Select
                                        value={this.state.to_coach_user}
                                        getOptionLabel={(option) => option.name + ' - ' + option.email}
                                        getOptionValue={(option) => option.user_id}
                                        isMulti={true}
                                        options={this.state.existing_options}
                                        classNamePrefix="select2-selection"
                                        className={"is-touched is-dirty av-valid"}
                                        name="to_users"
                                        onChange={(e) => {
                                          this.setState({loaderToUsers:false});
                                          this.handleUsers(e);
                                        }}
                                    />
                                </div>
                              </div>
                            </AvGroup>
                          </div>
                          
                            {this.state.loaderToUsers && this.state.to_coach_user.length > 0 && (
                                <div className="form-group mb-3 row">
                                    <div className="table-responsive user-info FixHeightTable">
                                        <Table className="table table-bordered mb-0 border-0">
                                            <tbody>
                                                <tr>
                                                    <td></td> {/* Empty cell for checkbox */}
                                                    <td>Name</td>
                                                    <td>Email</td>
                                                    <td></td>
                                                </tr>
                                                {this.state.to_coach_user.map((row, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <input
                                                              type="checkbox"
                                                              checked={this.state.to_coach_ids.some(item => item.user_id === row.user_id)}
                                                              onChange={this.handleChangeToChoch.bind(this, row, i)}
                                                            />
                                                        </td>
                                                        <td>{row.name}</td>
                                                        <td>{row.email}</td>
                                                        <td>
                                                            <i  onClick={this.deleteRecord.bind(this, row, i)} className="fas fa-times" style={{ cursor: 'pointer' }}></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}



                        </Col>
                      </Row>
                      {this.state.cloneLoader && this.state.from_coach_user != '' && this.state.to_coach_user.length > 0  && (
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <div className="table-responsive user-info">
                                <div class="h4 card-title">Clone Settings</div>

                                <Table className="table-bordered table-striped border-0 CloneTable mb-2">
                                  <thead>
                                    <th>
                                   
                                      <input
                                        type='checkbox'
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            
                                            $(".lead-checkbox").prop("checked", false);
                                            this.setState((prevState) => ({
                                              CloneData: {
                                                ...prevState.CloneData,
                                                ['clone_modules']: [],
                                              },
                                            }));
                                            let x = this.state.clone_sections.map(row => row.index.toString());
                                            this.setState((prevState) => ({
                                              CloneData: {
                                                ...prevState.CloneData,
                                                ['clone_modules']: [...prevState.CloneData['clone_modules'], ...x],
                                              },
                                            }));
                                            this.state.clone_sections.map((row, i) => {

                                              this.handleSelectAll(true, row.records_arr, row.index);
                                              
                                            });
                                            $(".lead-checkbox").prop("checked", true);
                                          } else {
                                            $(".lead-checkbox").prop("checked", false);
                                            this.state.clone_sections.map((row, i) => {
                                              this.handleSelectAll(false, row.records_arr, row.index);
                                            });
                                            this.setState((prevState) => ({
                                              CloneData: {
                                                ...prevState.CloneData,
                                                ['clone_modules']: [],
                                              },
                                            }));
                                          }
                                        
                                        
                                        }}
                                      />


                                    </th>
                                    <th>Section</th>


                                  </thead>
                                    <tbody>
                                      {this.state.loader && this.state.clone_sections.map((row, i) => {
                                        const isCampaign = row.index === 'campaign';
                                        const invalidRecordCount = this.state.to_coach_ids.filter(record => record.from_email_status !== 1).length;
                                      
                                        const disableCheckbox = isCampaign && invalidRecordCount >= 1;
                                        const showWarning = isCampaign && invalidRecordCount >= 1;

                                        return (
                                          <tr key={i}>
                                            <td>
                                              <label>
                                                <input
                                                  className="lead-checkbox"
                                                  id={`sub_main_${row.index}`}
                                                  onClick={(e) => this.handleSelectAll(e.target.checked, row.records_arr, row.index)}
                                                  onChange={this.handleChange.bind(this)}
                                                  type="checkbox"
                                                  name="client_ids"
                                                  value={row.index}
                                                  // disabled={disableCheckbox}
                                                />
                                              </label>
                                            </td>

                                            <td colSpan={4}>
                                              <strong>{row.name}</strong>
                                              {
                                                row.record_status === 'Yes' && row.records_arr && row.records_arr.length > 0 && (
                                                  <div className="row mx-0">
                                                    {row.records_arr.map((sub_row, j) => (
                                                      <div className="col-md-2 mb-2" key={j}>
                                                        <AvGroup check>
                                                          <AvInput
                                                            checked={
                                                              this.state.pages.indexOf(sub_row.id) > -1 ||
                                                              (this.state.isCheck[row.index] && this.state.isCheck[row.index].includes(sub_row.id))
                                                                ? 'checked'
                                                                : ''
                                                            }
                                                            type="checkbox"
                                                            name={`sub_pages_${row.index}[${sub_row.id}]`}
                                                            id={`${row.index}`}
                                                            onClick={(e) => this.handleSubClick(e.target.checked, sub_row.id, row.index)}
                                                          />
                                                          <Label check htmlFor="checkbox">{sub_row.name}</Label>
                                                        </AvGroup>
                                                      </div>
                                                    ))}
                                                  </div>
                                                )
                                              }
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                </Table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}

                      {this.state.CloneData.clone_modules.length > 0 && this.state.to_coach_user.length > 0 && (
                        <Row>
                          <Col xl="12" className="mb-2">
                            <div className="full-width">
                              <button
                                className={`btn btn-danger btn-sm mb-1`}
                                type="submit"
                              //onClick={() => this.handleSubmit('all')}
                              >
                                Clone / Re-Clone Selected Modules
                              </button>
                            </div>
                          </Col>
                        </Row>
                      )
                      }
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row >
      </>
    );
  }
}
