import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
	bcrypt,
	dcrypt,
	isValidJSONString,
	uToken,
} from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import swal from 'sweetalert';
import Dropdown from 'react-bootstrap/Dropdown';
const TraningQuiz = (props) => {
	//console.log(props)
	const [questions, setQuestions] = useState([]);
	const [totalQuestions, setTotalQuestions] = useState(0);
	const [activeKeys, setActiveKeys] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
	const [userAnswers, setUserAnswers] = useState({});
	const [submitedDate, setSubmitedDate] = useState("");
	const [totalScore, setTotalScore] = useState(0);
	const [traininguizSurvey, setTraininguizSurvey] = useState({});
	const [passScoreRate, setPassScoreRate] = useState(100);

	const [cat_id, setCat_id] = useState(props.cat_id ? props.cat_id : null);
	const [step_id, setStep_id] = useState(props.step_id ? props.step_id : null);
	useEffect(() => {
		setTotalScore(props.trainingquizsurvey.traingscoreEarn)
		setTotalQuestions(props.trainingquizsurvey.total_questions)
		setSubmitedDate(props.trainingquizsurvey.submitedDate)
		setCat_id(props.cat_id)
		setStep_id(props.step_id)
		setQuestions(props.questions)
		setTraininguizSurvey(props.trainingquizsurvey)
		setPassScoreRate(props.trainingquizsurvey.quiz_passing_percentage ? parseInt(props.trainingquizsurvey.quiz_passing_percentage) : 100);
		//console.log(props.trainingquizsurvey.quiz_passing_percentage)
	}, [props]);

	const handleAnswerChange = (e, index, option) => {
		const { type, name, value, checked } = e.target;
		console.log(option)
		setUserAnswers((prevAnswers) => {
			return { ...prevAnswers, [name]: option };
		});
	}

	const handleShowAlert = (percentage = 0) => {
		let msg = '';
		if (percentage >= passScoreRate) {
			console.log('inside true')
			// props.params.markAsComplete(cat_id, step_id) 
			msg = `You have successfully completed this step with a score of ${passScoreRate}%`;
		} else {
			msg = `Your current score is ${percentage}%.
			Note: Once your score reaches ${passScoreRate}%, this quiz will be marked as complete`;
		}
		swal({
			title: 'Your\'s quiz have been submitted',
			text: msg,
			icon: "success",
		});
	};
	const handleSubmitAnswers = (e) => {
		e.preventDefault();
		let bodyParamsforResult = {
			step_id: step_id,
			cat_id: cat_id,
		};

		if (Object.keys(userAnswers).length == 0) {
			fetch(`${ApiUrl}` + "training/quiz/anwers/results", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ` + uToken(),
				},
				body: JSON.stringify(bodyParamsforResult),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.status === true) {
						setTotalScore(response.data.result)
						//console.log(response.data.result);
						handleShowAlert(response.data.result)
					} else {
						toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
					}
				})
			//toast("You've not selected any options", { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

		} else {
			const tifOptions = Object.keys(userAnswers).map(key => {
				//console.log('key ', key)
				let bodyParams = {
					id: key.split("-")[1],
					cat_id: cat_id,
					step_id: step_id,
					answer: userAnswers[key],
				};
				fetch(`${ApiUrl}` + "training/quiz/anwers", {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ` + uToken(),
					},
					body: JSON.stringify(bodyParams),
				})
					.then((response) => response.json())
					.then((response) => {
						if (response.status === true) {
							toast.success("Your's quiz have been submitted", { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
							fetch(`${ApiUrl}` + "training/quiz/anwers/results", {
								method: "POST",
								headers: {
									Accept: "application/json",
									"Content-Type": "application/json",
									Authorization: `Bearer ` + uToken(),
								},
								body: JSON.stringify(bodyParamsforResult),
							})
								.then((response) => response.json())
								.then((response) => {
									if (response.status === true) {
										setTotalScore(response.data.result)
										handleShowAlert(response.data.result)
										// window.location.reload();
									} else {
										toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
									}
								})
						} else {
							toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
						}
					})
					.catch((error) => {
						console.error("Error:", error);
					});
			})

		}

	};
	return (
		<div className="TraningsQuiz">
			<div className="d-flex">
			<div className='QuestionTopBar'>
				<span><strong>Questions: </strong>{totalQuestions}</span>
				<span><strong>Your Score: </strong>{totalScore}%</span>
				<span><strong>Pass Rate: </strong>{passScoreRate}%</span>
				{/* <span><strong>Subset: </strong>No</span> */}
			</div>
			<div className='SettingIconDropDown'>
			<Dropdown>
				<Dropdown.Toggle variant="link">
					<i className='fas fa-ellipsis-h'></i>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					
						<Dropdown.Item eventKey="Draft">Switch to Draft</Dropdown.Item>
						<Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
					<Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
					<Dropdown.Item eventKey="Move">Move</Dropdown.Item>
					<Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
				</Dropdown.Menu>
				</Dropdown>
			</div>
			</div>
			{
				totalScore >= passScoreRate ? <div className='TestPass mt-4'>
					<h5>Test Passed</h5>
					<p>You successfully passed this test on {submitedDate} with a Score of {totalScore}%.</p>
				</div> : <></>
			}

			{/* client side */}
			<div className='MultipleQuestion'>
				<div className='QuestionAnwser mt-4'>
					<Accordion defaultActiveKey={activeKeys} alwaysOpen>

						{questions ? questions.map((ques, questionIndex) => (
							<Accordion.Item eventKey={questionIndex}>
								<div className="QuizCheckbox">
								<Accordion.Header>
									<div className='QuestionMove QNumber'>{questionIndex + 1}</div>
									<div className='QuizHeading d-flex justify-content-between align-items-center'>
										<Link>{ques.question}</Link>
										{/* <i className="mdi mdi-close"></i> */}
									</div>
								</Accordion.Header>
									<input type='hidden' className='form-control' name="quiz_id" defaultValue={ques.quiz_id} />
								</div>
								<Accordion.Body>
									<div className='QuestionBody p-3' key={questionIndex}>
										<div className='QuestionBodyLeft'>
											<h5>{ques.question}</h5>
											<small>Select the one correct answer from the following option:</small>
										</div>
										<div className='QuestionChoice'>
											{
												ques.type === 'radio' && ques.options.map((option, optionIndex) => (
													<div className='AnswerQuestion PickOne mb-2'>
														<div class="form-check">
															<input className="form-check-input" type="radio" name={`answer-${ques.quiz_id}`}
																id="formRadios3" onChange={(e) => handleAnswerChange(e, questionIndex, option[`option`])} defaultChecked={ques.useranswer === option[`option`] && option[`option`] !== null ? true : false}
															/>
															<label className="form-check-label" for="formRadios3">{`${option[`option`]}` || ''}</label>
														</div>
													</div>

												))
											}
										</div>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						)) : <></>
						}
					</Accordion>
				</div>
				<div className='MultipleSubmit text-center mt-4 py-4 px-5 pb-4'>
					<p>Submit your test answers to receive your grade. <b>Note:</b> if you meet the pass grade of 100% this step will be marked as complete.</p>
					<button className='btn btn-primary mt-3' onClick={(e) => handleSubmitAnswers(e)} disabled={totalScore >= passScoreRate ? true : false}>Submit Answers</button>
				</div>
			</div>
		</div>
	);
}
export default TraningQuiz;
