import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";

import { currencies } from "../../../common/Common-Currency";
import AsyncSelect from "react-select/async";
export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.product_id != "" ? this.props.product_id : "",
      record: {},
      currency_arr: {},
      module_arr: [],
      module_set_arr: {},
      currency_code_value: {},
      clients: [],
      client_id: this.props.client_id ? this.props.client_id : 0,
      permissions: [],
      permission: [],
      list_tags: [],
      selectedTagslist: [],
      selectedModule: [],
      selectedTags: [],
    };
    //console.log(this.props);
  }

  componentDidMount() {
    this.getFormData();
    if (this.state.id != "") {
      this.getData();
      this.getWayBookLists();
      this.getTagLists();
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.product_id != this.props.product_id) {
      this.setState({
        id: this.props.product_id != '' ? this.props.product_id : '',
        record: {},
      }, () => this.getData())
    }
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/trainingproduct/data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: this.state.id,
        // client_id: this.state.client_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedModule: response.data.record.training_module,
            selectedTags: response.data.record.training_tags,
            currency_code: response.data.record.currency_code,

          });
          this.handleSelectedValues(response.data.record.training_tags, response.data.record.training_module)
          this.getCurrency();
          this.changeformat(response.data.record.currency_code);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/assessment/formdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {

        if (response.status === true) {
          this.setState({
            clients: response.data.clients,
            permissions: response.data.coachPartners,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getCurrency = () => {
    var currency_arr = [];
    Object.keys(currencies).map((item) => {
      currency_arr.push({
        label:
          currencies[item].symbol +
          "- (" +
          currencies[item].name_plural +
          ") (" +
          currencies[item].code +
          ")",
        value: item,
      });
    });

    this.setState({ currency_arr: currency_arr });
  };
  changeformat = (currencycode) => {
    console.log(currencycode, 'dddddd');
    var currency_code_value = [];
    Object.keys(currencies).map((item) => {
      if (item == currencycode) {
        currency_code_value.push({
          label:
            currencies[item].symbol +
            "- (" +
            currencies[item].name_plural +
            ") (" +
            currencies[item].code +
            ")",
          value: item,
        });
        this.setState({
          currency_code: currencycode
        });
      }
    });

    this.setState({ currency_code_value: currency_code_value });
  };

  handleSubmit = async (event, values) => {
    console.log(values);
    if (this.state.client_id != "") {
      values.client_id = this.state.client_id;
    }
    if (this.state.id) {
      values.id = this.props.product_id ? this.props.product_id : -1;
    }
    fetch(`${ApiUrl}` + "add/trainingproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          this.props.cancelSlider();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleCurrency = (selectedCurrency) => {
    this.setState({
      currency_code: selectedCurrency,
    });
  };

  handleInputChange = (inputValue) => {
    // const inputValue = newValue.replace(/\W/g, '');
    if (inputValue.length > 3) {
      fetch(`${ApiUrl}` + "search/clients", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },

        body: JSON.stringify({ search: inputValue }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            this.setState({
              clients: response.data.clients
            });
          } else {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    console.log(inputValue.length);
  };
  handlePermission = (selectedPermission) => {
    var filter = [];
    if (selectedPermission) {
      selectedPermission.map(fil => {
        filter.push(fil.user_id);
      })
    }

    this.setState({
      permission: filter.toString(),
      selectedPermission: selectedPermission
    })
  }

  getWayBookLists = async () => {
    fetch(`${ApiUrl}` + "get/trainingproduct/category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ can_edit: this.state.canEditCocah, is_coach_section: 'Yes' }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            module_arr: response.data.record
          })
        }
      })
      .catch((error) => {


        console.error("Error:", error);
      });
  };
  getTagLists = async () => {
    fetch(`${ApiUrl}` + "get/products/trainingtags", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ user_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({ list_tags: response.data.records });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleChangeModule = (selectedOptions) => {
    this.setState({
      module_set_arr: selectedOptions.map((option) => option.value),
      selectedModule: selectedOptions
    });
  }
  handleChangeTags = (selectedOptions) => {
    console.log(selectedOptions);
    this.setState({
      selectedTagslist: selectedOptions.map((option) => option.id),
      selectedTags: selectedOptions
    });
  }
  handleSelectedValues = (selectedtags, selectedModule) => {
    this.setState({

      selectedTagslist: selectedtags.map((option) => option.id),
      module_set_arr: selectedModule.map((option) => option.value)

    });
  }
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <AvForm
              onValidSubmit={this.handleSubmit}
              ref={(c) => (this.form = c)}
              className="needs-validation"
            >
              <ModalBody className="row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="name"
                    value={this.state.record.name}
                    label="Product Title"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="price"
                    value={this.state.record.price}
                    label="Price"
                    type="number"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <div className="MainTZone ClientDetailsUpdate Two">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      isMulti={false}
                      options={this.state.currency_arr}
                      value={this.state.currency_code_value}
                      onChange={(e) => {
                        this.changeformat(e.value);
                      }}
                      classNamePrefix="select form-control"
                      className={"is-touched is-dirty av-valid"}
                    />
                  </div>
                  <AvField
                    type="hidden"
                    name="assessment_currency"
                    value={this.state.currency_code}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label className="control-label">Training Module/Category(s)</label>
                  <div className="MainTZone Three">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      isMulti={true}
                      options={this.state.module_arr}
                      value={this.state.selectedModule}
                      onChange={this.handleChangeModule}
                      classNamePrefix="select form-control"
                      className={"is-touched is-dirty av-valid"}
                    />
                  </div>
                  <AvField type="hidden" name="module[]" value={this.state.module_set_arr} />
                </div>
                {/* <div className="col-md-12 mb-3">
                  <label className="control-label">Training tags</label>
                  <div className="MainTZone Four">
                    <Select getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isMulti={true}
                      options={this.state.list_tags || []} // Simplified condition
                      onChange={this.handleChangeTags}
                      value={this.state.selectedTags}
                      classNamePrefix="select2-selection position-relative"
                      name="tags[]"
                      isDisabled={!this.state.list_tags || this.state.list_tags.length === 0}
                      noOptionsMessage={() => null}
                    />
                  </div>
                  <AvField type="hidden" name="tags[]" value={this.state.selectedTagslist} />
                </div> */}


              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-primary waves-effect waves-light"
                  type="submit"
                >
                  Submit
                </button>
              </ModalFooter>
            </AvForm>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
