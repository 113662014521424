import React, { useState, useCallback,useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory  } from 'react-router-dom';
import { bcrypt, uInfo, uToken,dcrypt } from '../../useToken';
import ReactPlayer from 'react-player'





const VideoStatic = (props) => {

    
    const history = useHistory();

    const location = useLocation();
    const segments = location.pathname.split('/').filter(Boolean); // Split and filter empty strings

    // console.log(segments[2]);

    console.log(dcrypt(segments[2]));
    
    // segments.shift();
    // segments.shift();
    // segments.shift();
    // const url = segments.join('/');

    const url = dcrypt(segments[2]);

    React.useEffect(() => {
        if (!(window.self !== window.top)) {
            history.push('/pages-404');
        }
      }, []);
  



  return (
    <div>
      <ReactPlayer 
      url={url} 
      class="fr-draggable"  
      controls="true" 
      config={{ 
        file: { 
          attributes: {
            onContextMenu: e => e.preventDefault(),
            controlsList: 'nodownload',

          } 
        } 
      }}
      />

         {/* <video src={url} class="fr-draggable" controls>Your browser does not support HTML5 video.</video> */}
    </div>
  );
};
export default VideoStatic;

