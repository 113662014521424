import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import { ProductName, logoLightPng, ApiUrl } from "../../config";
import { NumberWithCommas, bcrypt, dcrypt, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";

const SipupPackage = props => {
    const [package_id] = useState(dcrypt(props.match.params.package_id));
    const [record, setRecord] = useState([]);
    useEffect(() => {
        document.body.classList.add('bgCustomCls');
        getData();
    }, []);

    const getData = () => {
        fetch(`${ApiUrl}` + "get/edit/package", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: package_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    //console.log(response.data.record, 'recordddd')
                    setRecord(response.data.record);
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleValidSubmit = (event, values) => {
        values.package_id = package_id;
        fetch(`${ApiUrl}` + 'signup/manual', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((res) => {
                if (res.status === true) {
                    props.history.push({
                        pathname: `/signup/payment/${bcrypt(package_id)}/${bcrypt(res.data.user_id)}`,
                    });
                } else {
                    toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                }

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title> Register </title>
            </Helmet>


            <div className="account-pages my-5 pt-sm-5" >

                <Container >
                    <ToastContainer />
                    <Row className="justify-content-center" >

                        <Col md={8}>
                            <Card className="overflow-hidden" >
                                <CardBody className="pt-0" >

                                    <h3 className="text-center mt-5 mb-4" >

                                        <Link to="/"
                                            className="d-block auth-logo" >

                                            <img src={logoLightPng}
                                                alt=""
                                                height="50"
                                                className="auth-logo-dark" />
                                        </Link>
                                    </h3>
                                    <div className="p-3" >
                                        <h4 className="text-muted font-size-18 mb-1 text-center" >  Register </h4>
                                        <p className="text-muted text-center" > Get your {ProductName} account now. </p>
                                        <AvForm className="form-horizontal mt-4"
                                            onValidSubmit={
                                                (e, v) => {
                                                    handleValidSubmit(e, v)
                                                }
                                            }>
                                            {
                                                props.user && props.user ? (<Alert color="success" >
                                                    Register User Successfully </Alert>
                                                ) : null
                                            }

                                            {
                                                props.registrationError &&
                                                    props.registrationError ? (<Alert color="danger" > {props.registrationError} </Alert>
                                                ) : null
                                            }

                                            <div className="mb-3">
                                                <AvField
                                                    name="first_name"
                                                    label="First Name"
                                                    className="form-control"
                                                    placeholder="Enter First Name"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    name="last_name"
                                                    label="Last Name"
                                                    className="form-control"
                                                    placeholder="Enter Last Name"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    required
                                                />
                                            </div>


                                            <div className="mb-3">
                                                <AvField
                                                    name="phone"
                                                    label="Phone"
                                                    className="form-control"
                                                    placeholder="Enter phone with country code"
                                                    required
                                                />
                                            </div>


                                            <div className="mb-3">
                                                <AvField
                                                    name="password"
                                                    label="Password"
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                    minLength={8}
                                                    errorMessage="Password must be at least 8 characters"
                                                    required
                                                    type="password"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <AvField
                                                    minLength={8}
                                                    errorMessage="Confirm password must be at least 8 characters and same as password"
                                                    name="confirm_password"
                                                    label="Confirm Password"
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Enter Confirm Password"
                                                    validate={{ match: { value: "password" } }}
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3 row mt-4" >
                                                <div className="col-12 text-end" >
                                                    <button className="btn btn-primary waves-effect waves-light"
                                                        type="submit" > Register
                                                    </button>
                                                </div>
                                            </div>

                                            {/* <div className="mb-0 row" >
                                                <div className="col-12 mt-4" >
                                                    <p className="text-muted mb-0 font-size-14" >
                                                        By registering you agree to the PG Virtual Office
                                                        <Link to="#"
                                                            className="text-primary" > Terms of Use </Link></p>
                                                </div>
                                            </div> */}
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center" >
                                <p>
                                    Already have an account ? {" "}
                                    <Link to="/login"
                                        className="text-primary" > {" "}
                                        Login
                                    </Link>{" "}
                                </p>
                                <p> ©{new Date().getFullYear()} {ProductName}
                                    <span className="d-none d-sm-inline-block" > </span>
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Card className="overflow-hidden" >
                                <CardBody className="mt-5" >
                                    <h5 className="card-title text-muted text-uppercase text-center">{record.name}</h5>
                                    <h6 className="card-price text-center">{record.price_symbol}<span className="period">/month</span></h6>
                                    <h6 className="card-price text-center">{record.setup_price_symbol}<span className="period">/ Setup Fee</span></h6>
                                    {record.has_coach_license == 'Yes' && (
                                        <h6 className="card-price text-center">{record.additional_licence_fee_symbol}<span className="period">/Per Licence Additional Fee</span></h6>
                                    )}
                                    <hr />
                                    <ul className="fa-ul">
                                        {/* {record.has_coach_license == 'Yes' && (
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>{record.licence_limit} Coach License</li>
                                        )}
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>{record.monthly_credits} Monthly Credits</li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>{record.clients_limit} Leads</li> */}

                                        {record?.description?.map((desc, i) => {
                                            return (
                                                <li key={i} >
                                                    <span className="fa-li">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                    {desc.description_meta}
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    <div class="alert alert-info" role="alert">

                                        <h4 class="alert-heading">This package's setup price has been divided into {record.setup_fee_installment} installments, each costing ${NumberWithCommas(Math.round(record.setup_price / record.setup_fee_installment))} per month</h4>
                                        <hr />
                                        <p>After {record.setup_fee_installment} months, payment will be deducted based on the terms of your package, either on a monthly or yearly basis.</p>
                                    </div>





                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SipupPackage