import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    Alert
} from "reactstrap";
import Select from "react-select";

import { ApiUrl, logoLightPng, ProductName } from "../../config.js";
import { bcrypt, isValidJSONString, uInfo, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Helmet } from "react-helmet";
import { AvField, AvForm } from "availity-reactstrap-validation";
import swal from 'sweetalert';
import Badge from "react-bootstrap/Badge";
import countries from "../../common/data/countries";


const VerificationFromEmail = (props) => {
    const [vData, setVData] = useState({});
    const [loaded, setLoaded] = useState(true);
    const [msg, setMsg] = useState('');
    const [showCode, setShowCode] = useState(false);
    const [type] = useState('phone_verified');
    const [code_key] = useState('phone_verify_code');
    const [user, setUser] = useState("");

    let history = useHistory();
    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setUser(obj);
        }
    }, [!user]);
    const resendCode = (event, values) => {
        setLoaded(false);
        fetch(`${ApiUrl}` + 'verify/fromemail/link', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    let newtoken = {
                        from_email_status: data.data.from_email_status,
                    };
                    const authUser = {
                        ...JSON.parse(localStorage.getItem("authUser")),
                        ...newtoken,
                    };
                    localStorage.setItem("authUser", JSON.stringify(authUser));
                    setUser(authUser);
                    setMsg(data.message);
                    setShowCode(true);
                    setLoaded(true);
                    setTimeout(history.push("/dashboard"), 4000);

                } else {
                    setLoaded(true);
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }


            }).catch((error) => {
                setLoaded(true);
                console.error('Error:', error);
            });
    }

    const verifyCode = (event, values) => {
        values.setting = 'email';
        values.name = user.first_name + " " + user.last_name;
        values.email = user.email;
        fetch(`${ApiUrl}` + "update/profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    let newtoken = {
                        from_email: data.data.from_email,
                        from_name: data.data.from_name,
                        from_email_status: data.data.from_email_status,
                    };
                    const authUser = {
                        ...JSON.parse(localStorage.getItem("authUser")),
                        ...newtoken,
                    };
                    localStorage.setItem("authUser", JSON.stringify(authUser));
                    setUser(authUser);
                    var msg = 'Action performed successfully';
                    if (data.data.is_from_email_updated === true) {
                        setShowCode(true);
                        var msg = 'An activation link has been sent to your email. Please first open the link received in your email and then click the refresh button below to check your sender ID status.';
                    }
                    let wrapper = document.createElement('div');
                    wrapper.innerHTML = msg;
                    swal({
                        title: "Success!",
                        content: wrapper,
                        icon: "success"
                    });

                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    const skipSetting = () => {
        swal({
            title: "Are you sure?",
            text: "You want to perform this action.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "skip/fromEmail/Setting", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.status === true) {
                                // let newtoken = {
                                //     from_email: data.data.from_email,
                                //     from_name: data.data.from_name,
                                //     from_email_status: data.data.from_email_status,
                                // };
                                // const authUser = {
                                //     ...JSON.parse(localStorage.getItem("authUser")),
                                //     ...newtoken,
                                // };
                                // localStorage.setItem("authUser", JSON.stringify(authUser));
                                // setUser(authUser);
                                setTimeout(history.push("/dashboard"), 1000);

                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };
    return (
        <React.Fragment>
            {
                msg && msg != '' && (
                    <Alert color="info">{msg}</Alert>

                )
            }

            {
                !showCode && (
                    <>
                        <div className="mb-3">
                            <>
                                <label className="mr-3">From Email Sender Identity Status:</label>
                                {user.from_email_status == "0" && (
                                    <Badge bg="danger"> Un-Verified </Badge>
                                )}
                                {user.from_email_status == "1" && (
                                    <Badge bg="success"> Verified </Badge>
                                )}
                                {user.from_email_status == "2" && (
                                    <Badge bg="warning"> Pending for Approval </Badge>
                                )}
                                {user.from_email_status == "3" && (
                                    <Badge bg="danger"> Diclined </Badge>
                                )}
                            </>

                        </div>
                        <AvForm className="form-horizontal mt-4"
                            onValidSubmit={
                                (e, v) => {
                                    verifyCode(e, v)
                                }
                            }>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <AvField
                                            name="from_name"
                                            label="From Name"
                                            className="form-control"
                                            value={user.from_name}
                                            placeHolder="Enter your from name"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <AvField
                                            name="from_email"
                                            label="From Email"
                                            className="form-control"
                                            placeHolder="Enter your from email"
                                            value={user.from_email}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <label className="control-label">Country</label>
                                    <div className="TimeZoneMain">
                                        <Select
                                            getOptionLabel={(option) =>
                                                option.label + " (" + option.code + ")"
                                            }
                                            getOptionValue={(option) => option.code}
                                            value={countries.filter(
                                                ({ code }) => code === user.country_code
                                            )}
                                            isMulti={false}
                                            options={countries}
                                            classNamePrefix="select2-selection"
                                            name="country_id"
                                            onChange={(e) => {
                                                setUser(user => ({
                                                    ...user,
                                                    country_code: e.code
                                                }))
                                            }}
                                        />
                                    </div>
                                    <AvField
                                        type="hidden"
                                        name="country_code"
                                        required
                                        value={user.country_code}
                                    />
                                </Col>

                                <Col md={6} className="mt-3">
                                    <div className="form-group">
                                        <AvField
                                            name="city"
                                            label="City"
                                            value={user.city}
                                            className="form-control"
                                            placeholder="Enter city"
                                            required
                                            type="text"
                                        />
                                    </div>
                                </Col>

                                <Col md={6} className="mt-3">
                                    <div className="form-group">
                                        <AvField
                                            name="state"
                                            label="State"
                                            value={user.state}
                                            className="form-control"
                                            required
                                            placeholder="Enter state"
                                            type="text"
                                        />
                                    </div>
                                </Col>

                                <Col md={6} className="mt-3">
                                    <div className="form-group">
                                        <AvField
                                            name="zip"
                                            label="Zip"
                                            value={user.zip}
                                            className="form-control"
                                            required
                                            placeholder="Enter zip"
                                            type="text"
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className="mt-3">
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="address"
                                            label="Address"
                                            value={user.address}
                                            className="form-control"
                                            placeholder="Enter Address"
                                            required
                                            type="textarea"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mt-1" >
                                <div className="col-12 text-start" >
                                    <button disabled={loaded ? false : true} className="btn btn-primary waves-effect waves-light"
                                        type="submit" >  Verify From Email Setting
                                    </button>
                                    <button onClick={() => {
                                        skipSetting();
                                    }}
                                        className="btn btn-info waves-effect waves-light ml-3"
                                        type="button"
                                    >  Skip
                                    </button>
                                </div>
                            </div>
                        </AvForm>
                    </>
                )
            }
            {
                showCode && (
                    <AvForm className="form-horizontal"
                        onValidSubmit={
                            (e, v) => {
                                resendCode(e, v)
                            }
                        }>
                        <div className="mb-3">
                            {/* <AvField
                            name="from_email_link"
                            label="Enter Verification Link"
                            className="form-control"
                            placeholder="Enter the confirmation link"
                            type="textarea"
                            required
                        /> */}
                            <p>An activation link has been sent to your email. Please first open the link received in your email and then click the refresh button below to check your sender ID status.</p>
                        </div>

                        <div className="mt-4" >
                            <div className="col-12 text-start" >
                                <button disabled={loaded ? false : true} className="btn btn-info waves-effect waves-light"
                                    type="submit" >  Refresh to check your sender identify
                                </button>
                            </div>
                        </div>
                    </AvForm>
                )
            }
        </React.Fragment>
    );
};

export default VerificationFromEmail;
