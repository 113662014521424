import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    isAllowed,
    uInfo,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';

export default class Companies extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "company#",
                key: "client_id",
            },
            {
                text: "Name",
                key: "first_name",
                cell: (record, index) => {
                    console.log(record)
                    return (
                        <Fragment>
                            <button
                                key={index}
                                onClick={() => this.props.history.push(
                                    "/company/dialer/" +
                                    bcrypt(record.client_id) +
                                    "/" +
                                    bcrypt(record.coach_id)
                                )
                                }
                                className="waves-effect table-btn"
                            >
                                <i className="fas fa-newspaper pr-5"> </i>
                                {record.first_name}
                            </button>
                        </Fragment>
                    );
                },
            },
            {
                text: "Domain",
                key: "website",
            },
            {
                text: "Email",
                key: "email",
            },
            {
                text: "Phone",
                key: "phone",
            },


            {
                text: "Created Date",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button
                                type="button"
                                color="info"
                                className="btn btn-primary btn-sm mb-1 mr-5"
                                onClick={() =>
                                    this.props.history.push(
                                        "/company/edit/" +
                                        bcrypt(record.client_id) +
                                        "/" +
                                        bcrypt(record.coach_id)
                                    )
                                }

                            >
                                Edit
                            </button>
                            <button
                                className="btn btn-danger btn-sm mb-1"
                                onClick={this.deleteRecord.bind(this, record, index)}
                            >
                                Delete
                            </button>
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
        this.state = {
            records: [],
            roles: [],
            total_pages: 0,
            modal: false,
            record: {},
            filter: {},
            page_name: "Company",
            customDate: true,
            ClientSubcribeModal: false,
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
        };
    }
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/company", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.client_id, coach_id: record.coach_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    componentDidMount() {
        this.getData();
        this.getfilterData();
    }

    getData = (queryString = "", data) => {
        let query = '';
        if (queryString == "") {
            query = "?type=company";
        } else {
            query = queryString + '&type=company'
        }
        fetch(`${ApiUrl}` + "companies" + query, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };

    openClientSubcribeModal = (client, index) => {
        this.setState(
            {
                currentClient: client,
            },
            () =>
                this.setState({
                    ClientSubcribeModal: true,
                })
        );
    };

    ClientSubcribeSubmit = async (event, values) => {
        values.id = this.state.currentClient.id;
        values.coach_id = this.state.currentClient.coach_id;
        fetch(`${ApiUrl}` + "client/subscribe/status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        ClientSubcribeModal: false,
                    });
                    this.getData();
                    this.setState({
                        currentClient: {
                            id: "",
                        },
                    });
                    //   toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getfilterData = () => {
        fetch(`${ApiUrl}` + "get/group/tag", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ coach_id: uInfo().user_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        groups: response.data.groups,
                        tags: response.data.tags,
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                    </title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} Lists</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} Lists</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        {/* <div className="page-title-box text-align-right">
                            {hasRole(uInfo(), ["Import Leads"]) && (
                                <Link to={"import/lead"} className="btn  btn-success">
                                    Import
                                </Link>
                            )}
                        </div> */}
                    </Col>
                    <Col sm={1}>
                        <div className="page-title-box text-align-right">
                            {isAllowed(uInfo(), ["can_create"]) && (
                                <Link
                                    type="button"
                                    color="info"
                                    className="btn btn-info"
                                    to={"/company/add"}
                                >
                                    <i className="mdi mdi-plus"> </i>
                                    Create
                                </Link>
                            )}
                        </div>
                    </Col>
                </Row>

                <ToastContainer />

                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <AvForm
                                    onValidSubmit={this.handleFilterSubmit}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="date_range"
                                                    label="Duration"
                                                    onChange={(e) =>
                                                        e.target.value == "Custom Date"
                                                            ? this.setState({
                                                                customDate: false,
                                                            })
                                                            : this.setState({
                                                                customDate: true,
                                                            })
                                                    }
                                                >
                                                    <option value=""> All Time </option>
                                                    <option value="Current Week"> Current Week </option>
                                                    <option value="Last Week"> Last Week </option>
                                                    <option value="Current Month"> Current Month </option>
                                                    <option value="Last Month"> Last Month </option>
                                                    <option value="Last 3 Months"> Last 3 Months </option>
                                                    <option value="Last 6 Months"> Last 6 Months </option>
                                                    <option value="Current Year"> Current Year </option>
                                                    <option value="Last Year"> Last Year </option>
                                                    <option value="Custom Date"> Custom Range </option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    name="start_date"
                                                    label="Start date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    name="end_date"
                                                    label="End date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <label className=""> {"Action"}</label>
                                                <div className="button-items">

                                                    <Button className="btn-sm" color="primary" type="submit">
                                                        Filter
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.cancelFilter()}
                                                        outline
                                                        color="secondary"
                                                        className="waves-effect btn-sm"
                                                        type="button"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.total_pages}
                                    onChange={this.tableChangeHandler}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* ////////////////// ClientSubcribeModal /////////////////////  */}

                <Modal
                    isOpen={this.state.ClientSubcribeModal}
                    role="dialog"
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                    toggle={() => {
                        this.setState({
                            ClientSubcribeModal: !this.state.ClientSubcribeModal,
                        });
                    }}
                >
                    <div className="modal-content">
                        <ModalHeader
                            toggle={() => {
                                this.setState({
                                    ClientSubcribeModal: !this.state.ClientSubcribeModal,
                                });
                            }}
                        >
                            Subscribe / Unsubscribe Client
                        </ModalHeader>
                        <AvForm
                            onValidSubmit={this.ClientSubcribeSubmit}
                            className="needs-validation"
                        >
                            <ModalBody>
                                <div className="mb-3">
                                    <AvField
                                        className="select form-control"
                                        type="select"
                                        name="call_status"
                                        label="Landline & Mobile"
                                        required
                                        value={this.state.currentClient.call_status}
                                    >
                                        <option value={1}> Subscribe </option>
                                        <option value={0}> Unsubscribe </option>
                                    </AvField>
                                </div>
                                <div className="mb-3">
                                    <AvField
                                        className="select form-control"
                                        type="select"
                                        name="email_status"
                                        label="Email"
                                        required
                                        value={this.state.currentClient.email_status}
                                    >
                                        <option value={1}> Subscribe </option>
                                        <option value={0}> Unsubscribe </option>
                                    </AvField>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => {
                                        this.setState({
                                            ClientSubcribeModal: !this.state.ClientSubcribeModal,
                                        });
                                    }}
                                >
                                    Close
                                </Button>
                                <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                >
                                    Save
                                </button>
                            </ModalFooter>
                        </AvForm>
                    </div>
                </Modal>

                {/* ////////////////// ClientSubcribeModal //////////////////// */}
            </React.Fragment>
        );
    }
}
