import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import ReactDatatable from '@ashvin27/react-datatable';

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet"
import axios from "axios"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { bcrypt, isAllowed, uInfo, uToken } from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
export default class NotificationTemplates extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "SR#",
        key: "id",
        sortable: false
      },
      {
        text: "Title",
        key: "title",
        sortable: true,
      },
      {
        text: "Subject",
        key: "subject",
        sortable: true
      },
      {
        text: "Created At",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ['can_edit']) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-primary btn-sm my-1  mr-5"
                  to={'/default/template/edit/' + bcrypt(record.id)}
                // onClick={() => {
                //   this.setState({
                //     modal: !this.state.modal
                //   })
                // }}
                >
                  <i class="mdi mdi-pencil"></i>
                </Link>
              )}
              {isAllowed(uInfo(), ['can_delete']) && (
                <button
                  className="btn btn-danger mb-1  btn-sm d-none"
                  onClick={this.deleteRecord.bind(this, record, index)}>
                  <i class="mdi mdi-trash-can-outline"></i>
                </button>
              )}

            </Fragment>
          );
        },
      }
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true
      },

    }
    this.state = {
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Notification Templates",
      customDate: true
    }
  }
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + 'delete/notificationtemplate', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({ 'id': record.id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                //  toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error('Error:', error);
            });
        }
      });
  }

  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + 'notificationtemplates' + queryString, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log(response, 'data')
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
          })
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });

  }

  tableChangeHandler = data => {
    let queryString = Object.keys(data).map((key) => {
      if (key === "sort_order" && data[key]) {
        return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      }

    }).join('&');
    this.getData('?' + queryString, this.state.filter);
  }



  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name}  </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name} Lists</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_name} Lists</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              {isAllowed(uInfo(), ['can_create']) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-info"
                  to={'/default/template/add'}
                >
                  <i className="mdi mdi-plus"> </i>
                  Create
                </Link>
              )}

            </div>
          </Col>
        </Row>

        <ToastContainer />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </React.Fragment >
    )
  }
}

