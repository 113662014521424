import React, { useRef, useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import WayBookContext from './WaybookContext';
import { Link, useLocation } from 'react-router-dom';
import {
    Collapse, Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { bcrypt, uInfo, uToken } from '../../useToken';
import { ApiUrl} from '../../config';

import PaymentModel from './PaymentModel';

export default function WaySideBar(props) {

    const params = React.useContext(WayBookContext);
    const [isOpen, setIsOpen] = useState('sub' + params.category + '-' + params.subject);
    const [isDocOpen, setIsDocOpen] = useState('doc' + params.category + '-' + params.subject + '-' + params.documents);
    const [defaultIndex] = useState(params.category);
    const [lists, setLists] = useState(params.lists);
    const { search } = useLocation();
    const url_params = new URLSearchParams(search);
    const is_coach_section = url_params.get('sec') ? 'Yes' : "No";
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [parentCategoryData, setParentCategoryData] = useState({});
    const toggleOpen = (id) => () => setIsOpen((prevOpen) => (prevOpen === id ? null : id));
    const toggleDocOpen = (id) => () => setIsDocOpen((isDocOpen) => (isDocOpen === id ? null : id));
    const [checkboxStates, setCheckboxStates] = useState({});
    useEffect(() => {
        const initialStates = {};
        lists.forEach(item => {
          initialStates[item.cat_id] = item.course_free_paid === 'paid'; 
        });
        setCheckboxStates(initialStates);
      }, [lists]);
    const handleCheckboxChange = (checked, catId) => {
        setCheckboxStates(prevStates => ({
          ...prevStates,
          [catId]: checked, 
        }));
        onChangeCoursePaidHandler(checked, catId);
      };
    const onChangeCoursePaidHandler = async (e, cat_id) => {
        if (cat_id) {
            let bodyParams = {
                cat_id: cat_id,
                course_free_paid: (e == true) ? 'paid' : 'free',
            };
            try {
                fetch(`${ApiUrl}get/waybook/coach/course_free_paid/${cat_id}`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ` + uToken(),
                    },
                    body: JSON.stringify(bodyParams),
                })  
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        //getWayBookLists();
                    }
    
                })
                .catch((error) => {
                });
               
               
            } catch (error) {
                console.error("Error deleting question:", error);
            }
        }
    };
    const handlePriceChange = async (cat_id, value) => {
        if (cat_id) {

            if (value >= 1) {
                let bodyParams = {
                    cat_id: cat_id,
                    course_paid_price: value,
                };
                try {
                    const response = await fetch(`${ApiUrl}get/waybook/coach/course_free_paid_price/${cat_id}`, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify(bodyParams),
                    });
                    const responseData = await response.json();
                    //await getWayBookLists();
                    //console.log(responseData); // Optional: Log response data for debugging
                } catch (error) {
                    console.error("Error deleting question:", error);
                }
            }
        }
    };
    const Subject = ({ cat_id, index, subindex, content, subject, percentage, isOpen, isDocOpen, toggle, toggleDoc, status, image, user_id, training_for, tag_training, master_id, disabled, badge_image, badge_description, coaching_points, course_free_paid, course_paid_price, client_paid,training_certifications }) => {

        return (
            <>

                <div key={cat_id} className="subject" onDragStart={(e) => dragStart(e, subindex)} onDragEnter={(e) => dragEnter(e, subindex)}
                    onDragEnd={(e) => dropTwo(e, index)}
                    draggable={params.can_edit}>

                    <div className={`${isOpen ? 'active' : ''} pb-1 ProgressBarHide 1`}>
                        <div className="step-content p-0">
                            <div className=" d-flex justify-content-between">
                                <div className='d-flex flex-column w-100'>
                                    <div className={`d-flex justify-content-between align-items-center ${isOpen ? 'active' : ''}`}>
                                        <span><Link className={`${disabled ? 'disabled' : ''}`} disabled={disabled} to={`/trainings/${bcrypt(master_id)}/${bcrypt(cat_id)}`}>{subject}</Link></span>
                                        {uInfo().user_id && uInfo().user_id != 2 && (
                                            params.subject && params.subject == subindex && (
                                                <>
                                                    {
                                                        uInfo().type === 'Client' ? (
                                                            course_free_paid === 'paid' ? (
                                                                client_paid === 'paid' ? (
                                                                    <div ClassName="d-flex">
                                                                        <i className="mdi mdi-lock-open-variant-outline me-2"></i>
                                                                        <span className='PercentageShow'> {percentage}%</span>
                                                                    </div>
                                                                ) : (
                                                                    <i className="mdi mdi-lock-outline"></i> // Locked icon
                                                                )
                                                            ) : (
                                                                <span className='PercentageShow'> {percentage}%</span>
                                                            )
                                                        ) : (
                                                            <span className='PercentageShow'> {percentage}%</span>
                                                        )
                                                    }
                                                </>
                                            ))}
                                        {((!params.can_edit && !params.subject) || (!params.can_edit && !isOpen)) && (
                                            <div className='ShowPercentSub'>{percentage}%</div>
                                        )}
                                    </div>

                                    {uInfo().user_id && uInfo().user_id != 2 && (
                                        params.subject && params.subject == subindex && (
                                            <>
                                                <div className="progress w-100">
                                                    <div role="progressbar" class="progress-bar" aria-valuemin={percentage} aria-valuemax="100" aria-valuenow={percentage} style={{ width: `${percentage}%` }}></div>
                                                </div>
                                            </>
                                        ))}
                                </div>
                                {
                                    params.can_edit && (
                                        <div className='subject-action'>
                                            {
                                                status !== 'Publish' && (
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip id="button-tooltip">This module is unpublished</Tooltip>}
                                                    >
                                                        <i className='fas fa-info-circle'></i>
                                                    </OverlayTrigger>
                                                )
                                            }
                                            <Dropdown onSelect={(e) => { params.subjectOrDocumentAction('Subject', cat_id, subject, e, training_for, tag_training, image, user_id, 'left', master_id, badge_image, badge_description, coaching_points,'', training_certifications) }}>
                                                <Dropdown.Toggle variant="link">
                                                    <i className='fas fa-ellipsis-h'></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {status === 'Publish' ? (
                                                        <Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
                                                    ) : (
                                                        <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                    )}
                                                    <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                    <Dropdown.Item eventKey="AddDocument">Add Lesson</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                    <Collapse isOpen={isOpen} className="content-way">
                        {content}
                    </Collapse>
                </div>
            </>
        );
    };


    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        e.preventDefault();


        if (!params.can_edit) {
            e.preventDefault();
        }
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };


    const dropTwo = (e, i) => {

        const copyListItems = [...lists[i].sub_cat];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        let newState = [...lists];
        newState[i].sub_cat = copyListItems;
        setLists(newState);
    };

    const handleBuyNowClick = (e, list) => {
        setParentCategoryData(list)
        setShowPaymentModal(!showPaymentModal);
    };
    
    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0; // Returns true if object is empty
    };

    return (
        <>
            <div className={`subjects px-3 side-change-dropdown CoachTrainSide CourseArea ${uInfo().user_id ===2 && params.can_edit  ? 'AdminSection':""}`}>
                <Accordion defaultActiveKey={defaultIndex && is_coach_section === 'No' ? [defaultIndex] : null} alwaysOpen flush className='WayBookCate main'>

                    {lists.map((item, i) => (
                        <Accordion.Item disabled={item.disabled} key={item.cat_id} className={`${item.disabled ? 'disabled' : ''}  mainCate main ${params.category == item.cat_id ? 'active' : ''}`} eventKey={`${item.cat_id}`}>
                            <div className='d-flex align-items-center justify-content-between'>{params.can_edit && (
                                <div className='category-action PricingFreePaid'>
                                    <div className='d-flex align-items-center'>
                                        {item.status !== 'Publish' && (
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip id="button-tooltip">This course is unpublished</Tooltip>}
                                            >
                                                <i className='fas fa-info-circle'></i>
                                            </OverlayTrigger>
                                        )}

                                        <Dropdown onSelect={(e) => params.subjectOrDocumentAction('Category', item.cat_id, item.title, e, item.training_for, item.tag_training, '', '', 'left', item.master_id, item.badge_image, item.badge_description, item.coaching_points, item.course_description)}>
                                            <Dropdown.Toggle variant="link">
                                                <i className='fas fa-ellipsis-h'></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {item.status === 'Publish' ? (
                                                    <Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
                                                ) : (
                                                    <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                )}
                                                <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                {item.status === 'Publish' && (
                                                    <>
                                                        {item.onboard_training === 1 ? (
                                                            <Dropdown.Item disabled>OnBoard Training <i className='pl-3 fas fa-check'></i></Dropdown.Item>
                                                        ) : (
                                                            <Dropdown.Item eventKey="OnBoarding">Set OnBoard Training</Dropdown.Item>
                                                        )}
                                                    </>
                                                )}
                                                <Dropdown.Item eventKey="AddSubject">Add Module</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            )}
                                <Accordion.Header className={`${params.category == item.cat_id ? 'active' : ''}`}>
                                    <Link disabled={item.disabled} to={`/trainings/${bcrypt(item.cat_id)}`}
                                        className={`${item.disabled ? 'disabled' : ''}`}>
                                        <>
                                            <div className='d-flex WayBookDropDown CourseToggle py-1 justify-content-between pe-0 ps-1'>
                                                <div className="course-title">{item.title}</div>
                                            </div>
                                        </>

                                        {!params.can_edit && item.onboard_training === 1 && (
                                            <i title='Onboard Training' className='fas fa-chalkboard-teacher ml-5'></i>
                                        )}
                                    </Link>
                                </Accordion.Header>
                                {params.can_edit && (
                                    <div className='category-action PricingFreePaid'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            {
                                                params.can_edit && (
                                                    <>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className="form-check form-switch py-0 d-flex px-0" >
                                                                <label className='mb-0 me-2'>Free</label>
                                                                <input type="checkbox" className="form-check-input ms-0" 
                                                                id={`customSwitch-${item.cat_id}`}
                                                                checked={checkboxStates[item.cat_id] || false} 
                                                                onChange={e => handleCheckboxChange(e.target.checked, item.cat_id)}
                                                                />
                                                                <label className='mb-0 ms-2 me-1'>Paid</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }

                                            {item.status !== 'Publish' && (
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="button-tooltip">This course is unpublished</Tooltip>}
                                                >
                                                    <i className='fas fa-info-circle'></i>
                                                </OverlayTrigger>
                                            )}

                                            <Dropdown onSelect={(e) => params.subjectOrDocumentAction('Category', item.cat_id, item.title, e, item.training_for, item.tag_training,
                                                '',
                                                '',
                                                'left',
                                                item.master_id,
                                                item.badge_image,
                                                item.badge_description,
                                                item.coaching_points,
                                                item.course_description)}>
                                                <Dropdown.Toggle variant="link">
                                                    <i className='fas fa-ellipsis-h 2'></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {item.status === 'Publish' ? (
                                                        <Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
                                                    ) : (
                                                        <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                    )}
                                                    <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                    <Dropdown.Item eventKey="AddSubject">Add Module</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                )}
                                {
                                    !params.can_edit &&  item.clientPaid !== 'paid' && (
                                        <>
                                            <div className={`PriceCourse pe-2 inside ${item.course_free_paid}`} title='Course price'>
                                                {
                                                    item.course_free_paid === 'paid'
                                                        ? `$${Number(item.course_paid_price).toLocaleString()}`
                                                        : item.course_free_paid === 'free'
                                                            ? 'Free'
                                                            : ''
                                                }
                                            </div>
                                        </>
                                    )
                                }
                                {!params.can_edit && (
                                    <div className='ShowPercent' title='Course percentage'>{item.percentage}%</div>
                                )}
                            </div>
                            {!params.can_edit && (
                                <>
                                    <div className="progress Module w-100">
                                        <div role="progressbar" class="progress-bar" aria-valuemin={10} aria-valuemax="100" aria-valuenow={10} style={{ width: `${item.percentage}%` }}></div>
                                    </div>
                                </>
                            )}
                            {
                               ['Coach', 'CoachPartner', 'Client'].includes(uInfo().type) && (
                                    <div className='category-action 2'>
                                        <div className='d-flex align-items-center'>                                            <>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className="form-check form-switch py-0 d-flex px-0">
                                                    {
                                                        item.course_free_paid === 'paid'
                                                            ? item.clientPaid !== 'paid'
                                                                ? (
                                                                    <button className='mb-0 ms-2 btn btn-success btn-sm clientpaidbtn' onClick={(e) => handleBuyNowClick(e, item)}>
                                                                        Buy now ${Number(item.course_paid_price).toLocaleString()}
                                                                    </button>
                                                                )
                                                                : (
                                                                    <i className="mdi mdi-lock-open-variant-outline"></i>
                                                                )
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                ['Coach', 'CoachPartner', 'Client'].includes(uInfo().type) && showPaymentModal && !isEmptyObject(parentCategoryData) && (
                                    <PaymentModel
                                        initial={item.course_free_paid === 'paid' && item.clientPaid !== 'paid' ? true : false}
                                        parentCategory={parentCategoryData}

                                    />
                                )
                            }

                            <Accordion.Body className='p-0'>
                            {
                                item.course_free_paid == 'paid' && params.can_edit && (
                                    <>
                                        <div className='CoursePriceSetup d-flex align-items-center justify-content-end mt-2'>
                                            <label className='mb-0 me-2'>Course Price($)</label>
                                            <input type='number' className='form-control' name="course_paid_price" min={1} defaultValue={item.course_paid_price} onChange={(e) => handlePriceChange(item.cat_id, e.target.value)} placeholder="$" />
                                        </div>
                                    </>
                                )
                            }
                                {item.sub_cat.map((sub_item, k) => (
                                    <Subject
                                        key={'sub' + item.cat_id + '-' + sub_item.cat_id}
                                        cat_id={sub_item.cat_id}
                                        index={item.cat_id}
                                        subindex={sub_item.cat_id}
                                        content={
                                            <ListGroup key={'list' + sub_item.cat_id} variant="flush">
                                                {sub_item.sub_cat.map((sub, m) => (
                                                    <ListGroup.Item
                                                        disabled={sub.disabled}
                                                        key={sub.cat_id}
                                                    >
                                                        <div className={`${params.documents == sub.cat_id ? 'active' : ''} d-flex align-items-center justify-content-between px-2`}>
                                                            <div className={`d-flex w-100`}>
                                                                <Link
                                                                    className={`${sub.disabled ? 'disabled' : ''} btn btn-link p-0`}
                                                                    id={sub.cat_id}
                                                                    to={`/${sub.first_step_type == 'quiz' ? 'trainingsreview' : 'trainings'}/${bcrypt(item.cat_id)}/${bcrypt(sub_item.cat_id)}/${bcrypt(sub.cat_id)}${sub.first_step_link ? sub.first_step_link : ''}`}>
                                                                    {isDocOpen === 'doc' + i + '-' + k + '-' + m ? (
                                                                        <i className='fas fas fa-angle-up upp me-1' />
                                                                    ) : (
                                                                        <i className='fas fas fa-angle-down downn me-1' />
                                                                    )}
                                                                </Link>
                                                                <Link
                                                                    className="lessonLevel"
                                                                    id={sub.cat_id}
                                                                    to={`/${sub.first_step_type == 'quiz' ? 'trainingsreview' : 'trainings'}/${bcrypt(item.cat_id)}/${bcrypt(sub_item.cat_id)}/${bcrypt(sub.cat_id)}${sub.first_step_link ? sub.first_step_link : ''}`}>
                                                                    {sub.title}
                                                                </Link>
                                                            </div>
                                                            <div className='d-flex'>
                                                                {!params.can_edit && (
                                                                    <div className='ShowPercentSub'>{sub.percentage}%</div>
                                                                )}
                                                                {params.can_edit ? (
                                                                    <>
                                                                        <div className='document-action d-flex'>
                                                                            {sub.status !== 'Publish' && (
                                                                                <>
                                                                                    <OverlayTrigger
                                                                                        placement="right"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={<Tooltip id="button-tooltip">This lesson is unpublished</Tooltip>}
                                                                                    >
                                                                                        <i className='fas fa-info-circle'></i>
                                                                                    </OverlayTrigger>
                                                                                </>
                                                                            )}
                                                                            <Dropdown onSelect={(eventKey) => {
                                                                                if (eventKey === 'AddStep') {
                                                                                    params.subjectOrDocumentAction('Step', sub.cat_id, sub.title, 'AddStep', '', '', '', '', 'left', sub.cat_id);
                                                                                } else if (eventKey === 'AddStepquiz') {
                                                                                    params.subjectOrDocumentAction('Step', sub.cat_id, sub.title, 'AddStepquiz', '', '', '', '', 'left', sub.cat_id);
                                                                                } else {
                                                                                    params.subjectOrDocumentAction('Document', sub.cat_id, sub.title, eventKey, sub.training_for, sub.tag_training, '', '', 'left', sub.master_id);
                                                                                }
                                                                            }}>
                                                                                <Dropdown.Toggle variant="link">
                                                                                    <i className='fas fa-ellipsis-h'></i>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    {sub.status === 'Publish' ? (
                                                                                        <Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
                                                                                    ) : (
                                                                                        <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                                                    )}
                                                                                    <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                                                    <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                                                    <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                                                    <Dropdown.Item eventKey="AddStep">Add Training</Dropdown.Item>
                                                                                    <Dropdown.Item eventKey="AddStepquiz">Add Quiz</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className='document-action d-flex'>
                                                                        <OverlayTrigger
                                                                            placement="auto"
                                                                            overlay={<Tooltip id="button-tooltip">{sub.percentage}% completed</Tooltip>}
                                                                        >
                                                                            <div className='dummy-box'></div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                )}
                                                                {/* <button
                                                                    disabled={sub.disabled}
                                                                    className={`${sub.disabled ? 'disabled' : ''} btn btn-link p-0`}
                                                                    onClick={toggleDocOpen('doc' + i + '-' + k + '-' + m)}
                                                                >
                                                                    {isDocOpen === 'doc' + i + '-' + k + '-' + m ? (
                                                                        <i className='fas fas fa-angle-up upp' />
                                                                    ) : (
                                                                        <i className='fas fas fa-angle-down downn' />
                                                                    )}
                                                                </button> */}

                                                            </div>
                                                        </div>

                                                        <Collapse isOpen={isDocOpen == 'doc' + item.cat_id + '-' + sub_item.cat_id + '-' + sub.cat_id && is_coach_section === 'No'} className="content-way">
                                                            <ListGroup key={'step_list' + sub.cat_id} className='step-lists content-page-documents-list-item highlight-parent'>
                                                                {sub.sub_steps && sub.sub_steps.map((step, n) => (

                                                                    <ListGroup.Item
                                                                        key={step.step_id}
                                                                        className={`${params.step == step.step_id ? 'active paidicon' : ''} ${step.disabled ? 'disabled' : ''} highlight dssr`}
                                                                    >

                                                                        <div className='d-flex justify-content-between gap-1 AddBorder'>
                                                                            <div className='steps-list d-flex align-items-center test'><i className='fas fa-circle'></i>
                                                                                {step.type === 'quiz' ?
                                                                                    <Link id={step.step_id} className={`${step.disabled ? 'disabled' : ''}`}
                                                                                        to={`/trainingsreview/${bcrypt(item.cat_id)}/${bcrypt(sub_item.cat_id)}/${bcrypt(sub.cat_id)}/${bcrypt(step.step_id)}/?step=${bcrypt(step.step_id)}`}>
                                                                                        {step.title}

                                                                                        {step.status === 'Draft' && (
                                                                                            <div className='text-danger'><small>unpublished</small></div>
                                                                                        )}

                                                                                    </Link> : (
                                                                                        <>
                                                                                            <Link id={step.step_id} className={`${step.disabled ? 'disabled upcoming-step' : 'upcoming-step'}`}
                                                                                                to={`/trainings/${bcrypt(item.cat_id)}/${bcrypt(sub_item.cat_id)}/${bcrypt(sub.cat_id)}/${bcrypt(step.step_id)}/?step=${bcrypt(step.step_id)}`}

                                                                                            >
                                                                                                {step.title}
                                                                                                {step.status === 'Draft' && (
                                                                                                    <div className='text-danger'><small>unpublished</small></div>
                                                                                                )}
                                                                                            </Link>
                                                                                        </>
                                                                                    )}
                                                                            </div>
                                                                            <div className='step-action'>
                                                                                {params.can_edit ? (
                                                                                    <Dropdown onSelect={(e) => params.stepMethodAction('Step', step.step_id, step.title, e, step.training_for, step.tag_training, step.cat_id)}>
                                                                                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                                                            <i className='fas fa-ellipsis-h'></i>
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            {step.status === 'Publish' ? (
                                                                                                <>
                                                                                                    <Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
                                                                                                    <Dropdown.Item eventKey="Retake">Require Retake</Dropdown.Item>
                                                                                                    <Dropdown.Item eventKey="Notification">Training Notification</Dropdown.Item>
                                                                                                </>
                                                                                            ) : (
                                                                                                <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                                                            )}
                                                                                            <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                                                            <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                                                            <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                ) : uInfo().user_id !== 2 && (

                                                                                    uInfo().type === 'Client' ? (
                                                                                        <div className={`mark-option ${step.log_id} ${item.course_free_paid} ${item.clientPaid}`}>
                                                                                            {item.course_free_paid === 'paid' && item.clientPaid !== 'paid' ? (
                                                                                                <i className="mdi mdi-lock-outline"></i>
                                                                                            ) : (
                                                                                                step.log_id ? (
                                                                                                    <OverlayTrigger
                                                                                                        placement="auto"
                                                                                                        overlay={<Tooltip id="button-tooltip">You've completed this step(s)</Tooltip>}
                                                                                                    >
                                                                                                        <i className='fas fa-check-circle'></i>
                                                                                                    </OverlayTrigger>
                                                                                                ) : (
                                                                                                    <OverlayTrigger
                                                                                                        placement="auto"
                                                                                                        overlay={<Tooltip id="button-tooltip">You've not completed this step</Tooltip>}
                                                                                                    >
                                                                                                        <i className='fas fa-circle'></i>
                                                                                                    </OverlayTrigger>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    )
                                                                                        :
                                                                                        (
                                                                                            <div className='mark-option'>
                                                                                                {
                                                                                                    step.log_id ? (
                                                                                                        <OverlayTrigger
                                                                                                            placement="auto"
                                                                                                            overlay={<Tooltip id="button-tooltip">You've completed this step</Tooltip>}
                                                                                                        >
                                                                                                            <i className='fas fa-check-circle'></i>
                                                                                                        </OverlayTrigger>
                                                                                                    ) : (
                                                                                                        <OverlayTrigger
                                                                                                            placement="auto"
                                                                                                            overlay={<Tooltip id="button-tooltip">You've not completed this step</Tooltip>}
                                                                                                        >
                                                                                                            <i className='fas fa-circle'></i>
                                                                                                        </OverlayTrigger>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        )


                                                                                )

                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </ListGroup.Item>
                                                                ))}
                                                            </ListGroup>

                                                        </Collapse>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        }
                                        subject={sub_item.title}
                                        isOpen={isOpen === 'sub' + item.cat_id + '-' + sub_item.cat_id}
                                        isDocOpen={isDocOpen === 'doc' + item.cat_id + '-' + sub_item.cat_id}
                                        toggle={toggleOpen('sub' + item.cat_id + '-' + sub_item.cat_id)}
                                        toggleDoc={toggleDocOpen}
                                        status={sub_item.status}
                                        image={sub_item.image}
                                        user_id={sub_item.user_id}
                                        percentage={sub_item.percentage}
                                        training_for={sub_item.training_for}
                                        tag_training={sub_item.tag_training}
                                        master_id={item.cat_id}
                                        disabled={sub_item.disabled}
                                        badge_image={sub_item.badge_image}
                                        badge_description={sub_item.badge_description}
                                        coaching_points={sub_item.coaching_points}
                                        course_free_paid={item.course_free_paid}
                                        course_paid_price={item.course_paid_price}
                                        client_paid={item.clientPaid}
                                        training_certifications={sub_item.training_certifications}
                                    />
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                </div>
        </>
    );
}