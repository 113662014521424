import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { hasRole, uInfo, uRole } from "../../useToken";

const ProfileSettings = (props) => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    // console.log(window.location,'loc')
    const pathName = window.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [window.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled ResourcesSidebar" id="side-menu">

            <li>
              <Link to="/settings/profile" className="waves-effect">
                {/* <i className="fas fa-user-edit"></i> */}
                <span>{"Profile"}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/profile/password" className="has-arrow waves-effect">
                <i className="fas fa-key"></i>
                <span>{"Change Password"}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/profile/billing" className="has-arrow waves-effect">
                <i className="fas fa-file-invoice"></i>
                <span>{"Billing"}</span>
              </Link>
            </li>
            <li>
              <Link to="/profile/upgrade" className="has-arrow waves-effect">
                <i className="fas fa-arrow-alt-circle-up"></i>
                <span>{"Upgrade"}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/settings/themes" className="dropdown-item">
                {/* <i className="ion ion-md-settings"></i> */}
                {"Theme Setting"}
              </Link>
            </li>

            {hasRole(uInfo(), ["settings_integration"]) && (
              <li>
                <Link to="/settings/integration" className="dropdown-item">
                  {/* <i className="ion ion-md-settings"></i> */}

                  {"Integration"}
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["settings_email"]) && (
              <li>
                <Link to="/settings/email" className="dropdown-item">
                  {/* <i className="ion ion-md-settings"></i> */}
                  {"Email"}
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["settings_email"]) && (
              <li>
                <Link to="/settings/email/list" className="dropdown-item">
                  {/* <i className="ion ion-md-settings"></i> */}
                  {"Email Settings"}
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["settings_payment"]) && (
              <li>
                <Link to="/settings/payment" className="dropdown-item">
                  {/* <i className="ion ion-md-settings"></i> */}
                  {"Payment"}
                </Link>
              </li>
            )}

            <div>
              {hasRole(uInfo(), ["settings_domain"]) && (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <span>Websites</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/settings/domain">Websites</Link>
                    </li>
                    <li>
                      <Link to="/blocked/ip">Blocked IP</Link>
                    </li>
                    <li>
                      <Link to="/cms/privacypolicybuilder">Websites Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/cms/termsofservicebuilder">Websites Terms of Service</Link>
                    </li>
                  </ul>
                </li>
              )}
            </div>


            {hasRole(uInfo(), ["system_forms"]) && (
              <li>
                <Link to="/system/forms" className="dropdown-item">
                  {"System Forms"}
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["standard_profile_request"]) && (
              <li>
                <Link to="/standard-profile/request" className="dropdown-item">
                  {/* <i className="ion ion-md-settings"></i> */}
                  {"Standard Profile Request"}
                </Link>
              </li>
            )}

            <div>
              {hasRole(uInfo(), ["settings_cms"]) && (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <span>ProGlobal CMS</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/settings/proglobal/pages">Pages</Link>
                    </li>
                    <li>
                      <Link to="/settings/advisors/diamond-partners">Advisors & Partners</Link>
                    </li>
                    <li>
                      <Link to="/settings/testimonials">{"Testimonials"}</Link>
                    </li>
                    <li>
                      <Link to="/proglobal/sections">Sections</Link>
                    </li>
                    <li>
                      <Link to="/settings/blogs" >
                        <span>Blogs</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/proglobal/navigations">Navigations</Link>
                    </li>
                    <li>
                      <Link to="/settings/faqs">{"FAQs"}</Link>
                    </li>
                    <li>
                      <Link to="/settings/cms/links">
                        Settings
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </div>

            {/* <li>
              <Link to="/settings/revenueGoals" className="dropdown-item">
              <i className="far fa-money-bill-alt" aria-hidden="true"></i>
                {"Revenue Goals & Products"}
              </Link>
            </li> */}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default ProfileSettings;
