import React from "react"
import { Card, CardBody, Row , Col, Container} from "reactstrap"
import { Link } from "react-router-dom";


const Appointments = props => {
	return (
		<React.Fragment>
			<Container>        
                <Row className="d-flex">
                <div className='d-flex justify-content-between mt-3'>
                    <h4 className="card-title mb-3">Appointments</h4>
                    <Link>See All</Link>
                </div>
                <div className="d-flex flex-nowrap overflow-auto mb-3">
                    {props.appointments.map((report, key) => (
                        <Col xl={3} sm={3} key={key}>
                            <Card className="mini-stat bg-primary">
                                <CardBody className="mini-stat-img Appointments">
                                    <div className="mini-stat-icon CalendarGreen">
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 14C7 13.4477 7.44772 13 8 13C8.55229 13 9 13.4477 9 14C9 14.5523 8.55229 15 8 15C7.44772 15 7 14.5523 7 14Z" fill="#ffffff"></path> <path d="M8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19C8.55229 19 9 18.5523 9 18C9 17.4477 8.55229 17 8 17Z" fill="#ffffff"></path> <path d="M11 14C11 13.4477 11.4477 13 12 13C12.5523 13 13 13.4477 13 14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14Z" fill="#ffffff"></path> <path d="M12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17Z" fill="#ffffff"></path> <path d="M15 14C15 13.4477 15.4477 13 16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15C15.4477 15 15 14.5523 15 14Z" fill="#ffffff"></path> <path d="M16 17C15.4477 17 15 17.4477 15 18C15 18.5523 15.4477 19 16 19C16.5523 19 17 18.5523 17 18C17 17.4477 16.5523 17 16 17Z" fill="#ffffff"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2V3H16V2C16 1.44772 16.4477 1 17 1C17.5523 1 18 1.44772 18 2V3H19C20.6569 3 22 4.34315 22 6V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V6C2 4.34315 3.34315 3 5 3H6V2ZM16 5V6C16 6.55228 16.4477 7 17 7C17.5523 7 18 6.55228 18 6V5H19C19.5523 5 20 5.44772 20 6V9H4V6C4 5.44772 4.44772 5 5 5H6V6C6 6.55228 6.44772 7 7 7C7.55228 7 8 6.55228 8 6V5H16ZM4 11V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V11H4Z" fill="#ffffff"></path> </g></svg>
                                    </div>
                                    <div className="text-white">
                                        <p className="mb-2 text-white mt-4">{report.date}</p>
                                        <h2 className="mb-2 text-white">{report.Desc}</h2>
                                        <p className="mb-2 text-white">{report.time}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                    </div>
                </Row>
			</Container>
			
		</React.Fragment>
	)

}

export default Appointments
