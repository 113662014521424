import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

// Redux
import { withRouter, Link, useSearchParams, useLocation } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action

// import images
import Helmet from "react-helmet"
import { ApiUrl, ProductName, logoLightPng } from '../../config';
import { toast, ToastContainer, Flip } from 'react-toastify';
import useToken, { dcrypt, switchUserList, uInfo } from '../../useToken';
import { useSwitchUser } from '../../useToken';

// A custom hook that builds on useLocation to parse
// the query string for you.


const ClientProfileComplete = props => {
    const [info, setInfo] = useState({});
    const { setToken } = useToken();
    const { setSwitchUser } = useSwitchUser();
    const [loader, setLoader] = useState(false);

    const handleValidSubmit = (event, values) => {
        setLoader(true);
        values.client_info = info;

        fetch(`${ApiUrl}` + 'client/profile/complete', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((res) => {
                if (res.status === true) {
                    setToken(res.data.user);
                    setSwitchUser([]);
                    if (props.location.state) {
                        if (props.location.state.from) {
                            setTimeout(
                                () => props.history.push(`/form/details${props.location.state.from.search}`),
                                4000
                            );
                        }
                        // setLoader(false);
                    } else {
                        setTimeout(
                            () => props.history.push('/client/profile'),
                            4000
                        );
                        // setLoader(false);
                    }
                    //                    toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                    setLoader(false);
                }


            })
            //Then with the error genereted...
            .catch((error) => {
                setLoader(false);
                console.error('Error:', error);
            });
    }

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    useEffect(() => {
        checkAuth();
        getData();
    }, []);

    const checkAuth = () => {
        if (uInfo().user_id) {
            if (uInfo().type == 'Client') {
                props.history.push('/client/profile');

            }
        }
    }


    const getData = () => {

        fetch(`${ApiUrl}` + "get/client/by/contract/meta", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({ client_id: dcrypt(query.get('u')), c_id: dcrypt(query.get('c_id')) },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setInfo(response.data.record);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    console.log(props, 'ClientProfileComplete')
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <h3 className="text-center mt-5 mb-4">
                                        <Link to="/" className="d-block auth-logo">
                                            <img src={logoLightPng} alt="" height="30" className="auth-logo-dark" />
                                        </Link>
                                    </h3>
                                    <AvForm
                                        className="form-horizontal mt-4"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}
                                    >
                                        {props.error && typeof props.error === "string" ? (
                                            <Alert color="danger">{props.error}</Alert>
                                        ) : null}


                                        <Alert color="info">Fill out this form in order to complete your profile.</Alert>

                                        <div className="mb-3">
                                            <AvField
                                                name="email"
                                                label="Enter user email"
                                                value={info.email}
                                                className="form-control"
                                                placeholder="Enter user email"
                                                type="email"
                                                required
                                                readOnly
                                            />
                                        </div>


                                        <div className="mb-3">
                                            <AvField
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                label="Password"
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                label="Confirm Password"
                                                className="form-control"
                                                type="password"
                                                title="Confirm password must be same as password"
                                                name="confirm_password"
                                                validate={{ match: { value: 'password' } }}
                                            />
                                        </div>

                                        <div className="mb-3 row mt-4">
                                            <div className="col-12 text-end">
                                                <button disabled={loader} className="btn btn-primary waves-effect waves-light" type="submit">Log In</button>
                                            </div>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default ClientProfileComplete;

