import React, { useEffect, useState } from "react";
import Helmet from "react-helmet"
import { Link, Redirect, useHistory } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
// reactstrap
import {
  Row,
  Col,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  ModalHeader,
  Progress
} from "reactstrap";
import Dropdown from 'react-bootstrap/Dropdown';

import { ApiUrl, logoLightPng, logoLightSmallPng } from "../../config.js";
import useToken, { bcrypt, logout, switchUserList, uInfo, useSwitchUser, uToken, hasRole } from "../../useToken";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { toast, Flip } from "react-toastify";
import { AvForm } from "availity-reactstrap-validation";
import AvField from "availity-reactstrap-validation/lib/AvField";
import ReactSelect from "react-select";
import countries from "../../common/data/countries";

import { HostUrl, PusherKey } from "../../config";
import Pusher from "pusher-js";
import swal from "sweetalert";
import Loader from "react-loader";
import $ from "jquery"
import Knob from "../../pages/Campaign/knob";
import Fade from 'react-bootstrap/Fade';
import { Collapse as BCollapse } from 'react-bootstrap';
import TraningQuiz from './trainingQuiz';
import Froala from '../Froala';


const coachTraining = (props) => {

    //const [stepActionModal, setStepActionModal] = useState(false);
    const user_types = [
        { value: '1', label: 'Coach' },
        { value: '2', label: 'Diamond Partner' },
        { value: '3', label: 'Client' },
        { value: '4', label: 'Team Admin' },
        { value: '6', label: 'Team Coach' }

    ];
    return (
        <>

            <div className='main-way'>
                <div className='row'>
                    <div className='col-md-12'>
                    <div className="survey-content-question PopUpModal ExtraLarge px-0" role="document">
                        <div className="loadedContent"></div>                            
                            <div className="row">
                                <div className="mx-0 row">
                                    <div className="ps-0 col-xl-6">

                                        <div className="logout-wizard-training d-flex"></div>
                                    </div>
                                    <div className="col-xl-6">
                                        
                                        {/* <div className="text-align-right training-progress PopUpProgress testing">

                                            <div title="100" className="progress">
                                                <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">100%</div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="PointsContent SideBarPage">
                                    <div className="start-Training">
                                        <div className="ms-0 row">
                                            <div className="pl-0 col-lg-3">
                                                {/* <div className="welcome-training code-blue mb-1"><h4 className="card-title">OnBoard Step1</h4></div> */}
                                                <ul className="popupSideBar onborading WayBookBar ">
                                                    <li className="documents">
                                                        <div className="document-popup">
                                                            <div className="collapse show document-step-dropdown collapse">
                                                                <ul className="popupSideBar1 p-0">
                                                                    <li className="documents">
                                                                        <div className="document-popup">
                                                                            <button className="dropdown-item" aria-controls="example-collapse-text" aria-expanded="true"><i className="fas fa-chevron-down"></i><span>OnBoard doc</span>
                                                                            </button>
                                                                            <div className="collapse show document-step-dropdown collapse">
                                                                                <ul className="popupSideBar1 SubNavbar p-0 ps-3">
                                                                                    <li className="">
                                                                                        <button className="dropdown-item completed activeStep"><span>OnBoard Step1</span></button>
                                                                                        <Dropdown>
                                                                                            <Dropdown.Toggle variant="link">
                                                                                                <i className='fas fa-ellipsis-h'></i>
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>
                                                                                                <Dropdown.Item eventKey="Draft">Switch to Draft</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </li>
                                                                                    <li className="">
                                                                                        <button className="dropdown-item completed"><span>OnBoard Step2</span></button>
                                                                                        <Dropdown>
                                                                                            <Dropdown.Toggle variant="link">
                                                                                                <i className='fas fa-ellipsis-h'></i>
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>
                                                                                                <Dropdown.Item eventKey="Draft">Switch to Draft</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </li>
                                                                                    <li className="">
                                                                                        <button className="dropdown-item completed"><span>Onboard Quiz</span></button>
                                                                                        <Dropdown>
                                                                                            <Dropdown.Toggle variant="link">
                                                                                                <i className='fas fa-ellipsis-h'></i>
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>
                                                                                                <Dropdown.Item eventKey="Draft">Switch to Draft</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </li>
                                                                                </ul>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="ps-0 col-lg-9">
                                                <div className="row">
                                                    <div className="col-12 col">
                                                        <div className="TraningRightContent CoachTraning">
                                                        <div className="NoShadow HeightAuto PopUpDataShow ChangeForPopup card ">                                                           
                                                            <div className="mx-0 row">
                                                                <div className="p-1 col-xl-12">
                                                                    <div className='NextBackBtn edit-step-action justify-content-end d-flex border-bottom pb-2'>
                                                                        <button className='BackBtn btn btn-secondary'>Add traing</button>
                                                                    </div>
                                                                    <div className="NoShadow traning VideoHeight card">
                                                                        <div className="card-body p-0">
                                                                            <div className="video-lesson">
                                                                                <div id="isPasted">
                                                                                    <h2>What is Lorem Ipsum?</h2>
                                                                                    <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                                                                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                                                                                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                                                                                        containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                                    </p>
                                                                                </div>
                                                                                <p><br /></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </ >

    )
}
export default coachTraining;