import React, { useEffect, useRef, useState } from "react";
import Loader from "react-loader";
import swal from "sweetalert";
import { ApiUrl, AttachementsUrl } from "../../config";
import { bcrypt, dcrypt, uInfo, uToken } from "../../useToken";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Alert from 'react-bootstrap/Alert';
import WayBookContext from "./WaybookContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs/dist/grapes.min.js";
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css";
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js";

import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/code_beautifier.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/file.min.js";
import "froala-editor/js/plugins/entities.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/save.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins/code_view.css";
import "froala-editor/css/plugins/image_manager.css";
import "froala-editor/css/plugins/image.css";
import "froala-editor/css/plugins/table.css";
import "froala-editor/css/plugins/video.css";
import Froala from "../Froala";
import TraningQuiz from "./trainingQuiz";
import UrlNextPrev from "./UrlNextPrev";

import PaymentModel from "./PaymentModel";
function DocumentContents(props) {
  const params = React.useContext(WayBookContext);
  const [setupreview] = useState(props.setupreview ? true : false);
  const [loaded, setLoaded] = useState(false);
  const [is_publish, setIsPublish] = useState(null);
  const [list, setList] = useState({});
  const [bookmark, setBookmark] = useState(false);
  const [documentList, setDocumentList] = useState({});
  const [is_step_id] = useState(props.id[6] ? props.id[6] : null);

  const { search } = useLocation();
  const url_params = new URLSearchParams(search);
  const is_coach_section = url_params.get("sec") ? "Yes" : "No";
  const [parentCategory, setParentCategory] = useState({});
  const [showDescription, setShowDescription] = useState(false);
  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Create a FileReader instance
    const reader = new FileReader();

    // Define the callback function to execute when the file is read
    reader.onload = (event) => {
      console.log(event.target.result, 'fileContent');

      // Store the file content in localStorage
      localStorage.setItem('transcriptFileContent', event.target.result);
    };

    if (selectedFile) {
      reader.readAsText(selectedFile);
    }
  };
  const handleEyeClick = () => {
    setShowDescription(!showDescription);
  };

  const handleClearClick = () => {
    swal({
      title: 'Are you sure?',
      text: "This will clear the attached transcription data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "clear/training/transcriptionData", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ step_id: list.step_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                swal({
                  title: "Transcript Data cleared successfully", // Displays the response message as the title in the alert
                  icon: "success",         // Shows a success icon
                });

                // After 200ms, the page will reload
                setTimeout(() => {
                  window.location.reload(); // Reloads the current page
                }, 2000);
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };

  useEffect(() => {
    getWayBookStepsLists();
  }, []);
  useEffect(() => { }, [parentCategory]);
  useEffect(() => {
    if (['Coach', 'CoachPartner', 'Client'].includes(uInfo().type)) {
      setParentCategory(handlepaidorfreecatId(props.lists, params.documents));
    }
  }, [props]);

  const handleSubmit = (event, step_content) => {
    const transcriptFileContent = localStorage.getItem('transcriptFileContent') ? localStorage.getItem('transcriptFileContent') : "";
    const formData = new FormData();
    formData.append('step_id', params.step ? params.step : list.step_id);
    formData.append('step_content', step_content);
    formData.append('short_description', transcriptFileContent);
    formData.append('is_publish', is_publish);
    fetch(`${ApiUrl}update/waybook/steps/content/` + params.documents, {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (transcriptFileContent) {
          localStorage.removeItem('transcriptFileContent');
        }
        if (response.status === true) {
          swal({
            title: response.message, // Displays the response message as the title in the alert
            icon: "success",         // Shows a success icon
          });

          // After 200ms, the page will reload
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000);
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const setVideoAttributes = () => {
    const videoElements = document.querySelectorAll(".TraningContent video");
    videoElements.forEach((video) => {
      video.setAttribute("controlsList", "nodownload");
      video.setAttribute("oncontextmenu", "return false");
    });

    const iframe = document.querySelectorAll("iframe");
    iframe.forEach((video) => {
      video.setAttribute("controlsList", "nodownload");
      video.setAttribute("oncontextmenu", "return false");
    });

    const iframeVideo = document.querySelectorAll("video");
    iframeVideo.forEach((videos) => {
      videos.setAttribute("controlsList", "nodownload");
      videos.setAttribute("oncontextmenu", "return false");
    });
  };

  const preventRightClick = (e) => {
    console.log(list.body);
    console.log("prevent");
    e.preventDefault();
  };

  const getWayBookStepsLists = () => {
    fetch(`${ApiUrl}get/waybook/steps/${params.documents}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({
        is_coach_section: is_coach_section,
        step_id: params.step,
        can_edit: params.can_edit ? true : false,
        can_edit_coach: params.can_edit_coach ? true : false,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.status === true) {
          setList(response.data.record);
          if (response.data.record.is_bookmarked) {
            setBookmark(true);
          } else {
            setBookmark(false);
          }
          setDocumentList(response.data.document);
          // setVideoAttributes();
          setTimeout(setVideoAttributes(), 5000);
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
        setLoaded(true);
      })
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };

  const updateTrainingBookmark = (trainingId) => {
    fetch(`${ApiUrl}training/bookmark`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({
        // is_coach_section: is_coach_section,
        step_id: trainingId,
        // can_edit: params.can_edit || params.can_edit_coach ? true : false,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.status === true) {
          if (response.message == "Training added as bookmark successfully") {
            setBookmark(true);
          } else {
            setBookmark(false);
          }
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
        setLoaded(true);
      })
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };

  const handleMarkAsComplete = (documents, stepId) => {
    swal({
      title: "Are you sure",
      text: "you want to mark this training as incomplete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        params.documentLogsReset(documents, stepId);
      }
    });
  };

  const handlepaidorfreecatId = (categories, targetCatId) => {
    for (const category of categories) {
      if (category.cat_id == targetCatId) {
        return category;
      }
      if (category.sub_cat) {
        for (const subCategory of category.sub_cat) {
          if (subCategory.cat_id == targetCatId) {
            return category;
          }
          for (const subCat of subCategory.sub_cat) {
            if (subCat.cat_id == targetCatId) {
              return category;
            }
          }
        }
      }
    }

    return null;
  };

  const isEmptyObject = (obj) => {
    if (obj) {
      return Object.keys(obj).length === 0; // Returns true if object is empty
    }
    return {};
  };

  return (
    <div>
      <Loader loaded={loaded}></Loader>

      <div className="row">
        <div className="col-lg-12 ps-0">
          <div className="way-header-right"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          {setupreview ? (
            <>
              {props.lists && (
                <TraningQuiz
                  title={list && list.title ? list.title : ""}
                  params={params}
                  list={list}
                  lists={props.lists}
                  documentList={documentList}
                  parentCategory={parentCategory}
                  bookmark={bookmark}
                />
              )}
            </>
          ) : (
            <>
              <div
                className={`TraningRightContent p-0 test ${parentCategory &&
                    parentCategory.course_free_paid === "paid" &&
                    parentCategory.clientPaid !== "paid"
                    ? "disabledview"
                    : ""
                  }`}
              >
                <div className="TopSection">
                  <div className="d-flex justify-content-between align-items-center position-relative TopTitle">
                    <h4 className="mb-0 TraningTitles">
                      {documentList.course_name}
                    </h4>

                    <div className="ProgressButtons mb-2">
                      <h4 className="mb-0">{documentList.title}</h4>
                      {!params.can_edit &&
                        uInfo().type !== "WlAdmin" &&
                        !(
                          params.can_edit_coach && is_coach_section === "Yes"
                        ) && (
                          <div className="RightAreaBtn d-flex align-items-center">
                            <div className="progress w-100 mt-1">
                              <div
                                role="progressbar"
                                className="progress-bar"
                                aria-valuemin={documentList.percentage}
                                aria-valuemax="100"
                                aria-valuenow={documentList.percentage}
                                style={{ width: `${documentList.percentage}%` }}
                              />
                            </div>
                            <span className="RightPercent">
                              {documentList.percentage}%
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center position-relative border-bottom p-2 pt-0">
                    <div className="d-flex">
                      <h5 className="my-auto TraningSubTitles">{list && list.title ? list.title : ""}</h5>

                      {uInfo().type !== "WlAdmin" &&
                        is_coach_section === "No" && (
                          // <button
                          //   className="btn btn-md my-auto"
                          //   onClick={() => {
                          //     updateTrainingBookmark(list.step_id);
                          //   }}
                          // >
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="button-tooltip">
                                {bookmark
                                  ? "Remove as bookmark"
                                  : "Add to Bookmark"}
                              </Tooltip>
                            }
                          >
                            {bookmark ? (
                              <i
                                className="fas fa-bookmark text-success ms-2 my-auto"
                                onClick={() => {
                                  updateTrainingBookmark(list.step_id);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            ) : (
                              <i
                                className="far fa-bookmark ms-2 my-auto"
                                onClick={() => {
                                  updateTrainingBookmark(list.step_id);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            )}
                          </OverlayTrigger>
                          // </button>
                        )}
                    </div>

                    <div className="ProgressButtons">
                      <div className="d-flex justify-content-end align-items-center">
                        {props.lists.map((item, i) =>
                          item.sub_cat.map((sub_item, k) =>
                            sub_item.sub_cat.map(
                              (sub, m) =>
                                sub.sub_steps &&
                                sub.sub_steps.map((step, n) => (
                                  <UrlNextPrev
                                    prevlink={`/trainings/${bcrypt(
                                      item.cat_id
                                    )}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                                      sub.cat_id
                                    )}/`}
                                    nextlink={`/trainings/${bcrypt(
                                      item.cat_id
                                    )}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                                      sub.cat_id
                                    )}/`}
                                    link={`/trainings/${bcrypt(
                                      item.cat_id
                                    )}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                                      sub.cat_id
                                    )}/`}
                                    nvalue={n}
                                    docstep={sub.sub_steps}
                                    list={list}
                                    stepCount={sub.sub_steps.length}
                                    is_coach_section={is_coach_section}
                                    can_edit_coach={params.can_edit_coach}
                                  />
                                ))
                            )
                          )
                        )}

                        {(params.can_edit ||
                          (params.can_edit_coach &&
                            documentList.user_id !== 2)) &&
                          list.step_id > 0 && (
                            <>
                              {list.status === "Publish" ? (
                                <div className="edit-step-action">
                                  <button
                                    className="BackBtn btn btn-secondary submit-step-content mx-2 btn-sm"
                                    id="submit-step-content"
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : (
                                <div className="edit-step-action">
                                  <button
                                    className="BackBtn btn btn-secondary submit-step-content mx-2 btn-sm"
                                    id="submit-step-content"
                                  >
                                    Save as Unpublish
                                  </button>
                                </div>
                              )}

                              {list.status === "Publish" ? (
                                <div className="NextBackBtn edit-step-action">
                                  <button
                                    className="BackBtn btn btn-secondary btn-sm"
                                    style={{ marginRight: 7 + "px" }}
                                    onClick={() => {
                                      params.stepMethodAction(
                                        "Step",
                                        list.step_id,
                                        list.title,
                                        "Draft",
                                        list.training_for,
                                        list.tag_training
                                      );
                                      const element = document.getElementById(
                                        "submit-step-content"
                                      );
                                      if (element) {
                                        element.click();
                                      }
                                    }}
                                  >
                                    Unpublish
                                  </button>
                                </div>
                              ) : (
                                <div className="NextBackBtn edit-step-action">
                                  <button
                                    className="BackBtn btn btn-secondary btn-sm"
                                    style={{ "margin-right": "7px" }}
                                    onClick={() => {
                                      params.stepMethodAction(
                                        "Step",
                                        list.step_id,
                                        list.title,
                                        "Publish",
                                        list.training_for,
                                        list.tag_training
                                      );
                                      const element = document.getElementById(
                                        "submit-step-content"
                                      );
                                      if (element) {
                                        element.click();
                                      }
                                    }}
                                  >
                                    Publish & Go Live
                                  </button>
                                </div>
                              )}

                              <div className="NextBackBtn edit-step-action">
                                <button
                                  className="BackBtn btn btn-primary btn-sm"
                                  style={{ "margin-right": "7px" }}
                                  onClick={() =>
                                    params.stepMethodAction(
                                      "Step",
                                      list.step_id,
                                      list.title,
                                      "Delete",
                                      list.training_for,
                                      list.tag_training
                                    )
                                  }
                                >
                                  Delete
                                </button>
                              </div>
                            </>
                          )}
                        <div className="create-step">
                          {(params.can_edit ||
                            (params.can_edit_coach &&
                              documentList.user_id !== 2)) && (
                              <div className="add-new-step">
                                <div className="add-step-btn text-center w-100">
                                  <button
                                    onClick={() =>
                                      params.subjectOrDocumentAction(
                                        "Step",
                                        params.documents,
                                        documentList.title,
                                        "AddStep",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "center"
                                      )
                                    }
                                    className="bwaves-effect waves-light BackBtn btn btn-primary w-100 text-nowrap btn-sm"
                                  >
                                    New Training
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {params.can_edit ||
                  (params.can_edit_coach && documentList.user_id !== 2) ? (
                  <>

                    <div className={list.step_id > 0 ? "" : "d-none"}>
                      {list.step_id > 0 && (
                        <>
                          <Froala
                            inlineEditior={false}
                            btnId={"submit-step-content"}
                            content={list.content}
                            handleSubmit={handleSubmit}
                            use_token={"users"}
                            config={{
                              pluginsEnabled: ["pdf", "image", "link", "video"],
                              toolbarButtons: [
                                "pdf",
                                "bold",
                                "italic",
                                "underline",
                                "insertLink",
                                "insertImage",
                                "insertVideo",
                              ],
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className={list.step_id ? "d-none" : "add-new-step"}>
                      <div className="add-step-btn text-center mt-3 w-100">
                        {/* <button onClick={() => params.subjectOrDocumentAction('Step', params.documents, documentList.title, 'AddStep')} className='bwaves-effect waves-light btn btn-outline-dark'> New Step</button> */}
                      </div>
                    </div>
                    <div className={`form-group mb-3 mt-3 mx-2 ${list.step_id > 0 ? "" : "d-none"}`}>
                      <label>Transcript Data Upload (.txt Only)
                        {list.short_description && (
                          <>
                            <i
                              className={`fas fa-eye mx-1 ${showDescription ? 'text-primary' : 'text-muted'}`}
                              onClick={handleEyeClick}
                              title='View Transcript data'
                              style={{ cursor: "pointer" }}
                            ></i>
                            <i
                              className="fas fa-trash mx-1 text-danger"
                              onClick={handleClearClick}
                              title="Clear Transcript data"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </>
                        )}

                      </label>
                      <input
                        name="file"
                        className="form-control"
                        type="file"
                        accept=".txt"
                        onChange={handleFileChange}
                      />
                    </div>
                    {list.short_description && (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="form-group mb-3 mt-1 mx-1">
                          <div
                            className="TraningContent there"
                            dangerouslySetInnerHTML={{ __html: showDescription ? list.short_description : "" }}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {['Coach', 'CoachPartner', 'Client'].includes(uInfo().type) &&
                      !isEmptyObject(parentCategory) && (
                        <PaymentModel
                          initial={
                            parentCategory.course_free_paid === "paid"
                              ? parentCategory.clientPaid !== "paid"
                                ? true
                                : false
                              : false
                          }
                          parentCategory={parentCategory}
                        />
                      )}

                    <div className="FrolaContent">
                      <div className={`TextEditor fr-view`}>

                        <div
                          className="TraningContent there"
                          dangerouslySetInnerHTML={{ __html: list.body }}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                        {list.short_description && (
                          <div className="row form-group mb-3 mt-5 mx-2">
                            <div className="col-md-12">
                              <label>Transcript Data</label>
                              <div
                                className="TraningContent there"
                                dangerouslySetInnerHTML={{ __html: list.short_description }}
                                onContextMenu={(e) => e.preventDefault()}
                              />
                            </div>
                          </div>
                        )}
                        {!list.step_id && (
                          <Alert variant="info m-5">
                            <strong>Unlock Premium Content!</strong> This training is part of our paid package. To gain full access, please <a href="/trainings">click here</a>.
                          </Alert>
                        )}
                        <div
                          className={`${list.step_id > 0 ? "d-inline-block w-100" : "d-none"
                            }`}
                        >
                          {list.log_id > 0 ? (
                            <div className="not-edit-action">
                              <div className="btn-mark text-center mb-3">
                                <button disabled className="btn btn-success">
                                  Completed
                                </button>
                              </div>
                              <div className="text-center w-100">
                                <button
                                  onClick={() =>
                                    handleMarkAsComplete(
                                      params.documents,
                                      list.step_id
                                    )
                                  }
                                  className="btn btn-danger UndoCompleted"
                                  style={{ color: "#fff" }}
                                >
                                  Undo
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`not-edit-action mt-3 text-center d-flex justify-content-center ${(uInfo().user_id == 2 ||
                                  is_coach_section === "Yes") &&
                                "d-none"
                                }`}
                            >
                              <div className="w-75">
                                <h6>
                                  If you've read and understand everything in
                                  this training you can mark it as completed.
                                  This will help you track your progress on this
                                  lesson.
                                </h6>
                                <div className="btn-mark mt-3 mb-3">
                                  <button
                                    onClick={() =>
                                      params.markAsComplete(
                                        params.documents,
                                        list.step_id
                                      )
                                    }
                                    className="btn btn-primary"
                                  >
                                    Mark as completed
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
}

export default DocumentContents;
