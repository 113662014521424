import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { bcrypt, isAllowed, uInfo, uRole, uToken } from "../../../useToken";
import { ApiUrl, ProductName,ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import ProfileSettings from "../../Authentication/profile-sidebar";
export default class DatatableTables extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Sr#",
                key: "sr",
                sortable: false,
            },
            {
                text: "Title",
                key: "title",
                sortable: false,
            },
            props.match.params.path == 'disclaimer' && 
            {
                text: "Disclaimer For",
                key: "disclaimer_for",
                sortable: false,
                cell: (record, index) => {
                    return (
                      <Fragment>
                        {record.sub_type=='phone' && (
                            <Badge bg="success">{record.disclaimer_for}</Badge>
                        )}
                        {record.sub_type=='email' && (
                            <Badge bg="info">{record.disclaimer_for}</Badge>
                        )}
                        {record.sub_type=='both' && (
                            <Badge bg="secondary">{record.disclaimer_for}</Badge>
                        )}
                      </Fragment>
                    );
                  },
            },
            {
                text: "Status",
                key: "status",
                sortable: false,
                cell: (record, index) => {
                    return (
                      <Fragment>
                         {record.status=='Active' && (
                        <Badge bg="success">{record.status}</Badge>
                         )}
                          {record.status=='Inactive' && (
                        <Badge bg="danger">{record.status}</Badge>
                         )}
                      </Fragment>
                    );
                  },
            },
            {
                text: "Created At",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <Link
                                color="info"
                                className="btn btn-primary btn-sm my-1 mr-5"
                                to={`/cms/${this.state.path}/view/${bcrypt(record.id)}`}
                            >
                                View
                            </Link>
                        </Fragment>
                    );
                },
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: false,
            show_pagination: true,
            sort: { column: "total_lead", order: "desc" },
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.state = {
            propsUpdate: false,
            records: [],
            total_pages: 0,
            record: {},
            path: props.match.params.path,
            page_name: props.match.params.path == 'disclaimer' ? 'Disclaimer' : (props.match.params.path == 'termsofservice' || props.match.params.path == 'termsofservicebuilder') ? 'Terms of Service' : 'Privacy Policy',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.path != this.props.match.params.path) {
            this.setState({
                path: this.props.match.params.path,
                page_name: this.props.match.params.path == 'disclaimer' ? 'Disclaimer' : this.props.match.params.path == 'termsofservice' ? 'Terms of Service' : 'Privacy Policy',
            }, () => this.getData());

        }

    }

    getData = (queryString = "") => {
        fetch(`${ApiUrl}` + "get/contents" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ 'type': this.state.path }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {

                if (data.status === true) {
                    this.setState({
                        total_pages: data.data.total,
                        records: data.data.records,
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleSubmit = async () => {

        fetch(`${ApiUrl}` + "add/table", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
          
                if (data.status === true) {
                    this.getData();
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    this.props.history.goBack();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                    // return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString);
    };

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                    </title>
                </Helmet>
                <Row>
                    {(this.props.match.params.path === 'privacypolicybuilder' || this.props.match.params.path === 'termsofservicebuilder') && (
                        <Col lg="2" className="pl-0"> 
                            <ProfileSettings menuOpen={false} props={this.props} />
                        </Col>
                    )}
                    <Col lg={(this.props.match.params.path === 'privacypolicybuilder' || this.props.match.params.path === 'termsofservicebuilder')? 10:12}>
                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4> {this.state.page_name}</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">
                                            {ProductName}
                                        </li>
                                        <li key={1} className="breadcrumb-item">
                                            <Link to="#"> {this.state.page_name} </Link>
                                        </li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box text-align-right">
                                    <Link
                                        type="button"
                                        color="info"
                                        className="btn btn-info"
                                        to={`/cms/${this.state.path}/add`}

                                    >
                                        <i className="mdi mdi-plus"> </i>
                                        Add {this.state.page_name}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                
                    <ToastContainer />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.records}
                                        columns={this.columns}
                                        dynamic={true}
                                        total_record={this.state.total_pages}
                                        onChange={this.tableChangeHandler}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                </Row>
            </React.Fragment>
        );
    }
}
