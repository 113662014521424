import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
    convertPhoneFormat,
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select"
import countries from "../../common/data/countries";
import { Asia, TimezoneMain, America, Australia, Europe, Indian } from "../../common/data/timezones";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
            page_name: this.props.match.params.id ? "Edit Campaign" : "Create Campaign",
            from: this.props.from ? this.props.from : '',
            record: {
                "from_time": "08:00:00",
                "to_time": "18:00:00",
                "throttle_duration": "15",
                "throttle_value": "5",
                "ignore_followup_tag": "No"
            },
            showAfter: false,
            groups: [],
            tags: [],
            holidays: {},
            managers: [],
            group_id: [],
            tag_id: [],
            emails: [],
            phoneNumbers: [],
            permission: [],
            country_code: [],
            CustomField: [],
            customFieldData: {},
            main: Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone.split('/').shift() : 'America',
            Timezone: TimezoneMain,
            zones: America,
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/Los_Angeles',
            verifiedFromEmail: [],
            verifiedFromEmailId:"",
            verifiedFromName: "",
            default_from_email_id:"",
            
        };
    }

    componentDidMount() {
        //this.state.id != null && this.getData();
        this.getFormData();
        this.getVerifiedFromEmail();

       

       
    }

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/campaign/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        groups: response.data.groups,
                        tags: response.data.tags,
                        holidays: response.data.holidays,
                        emails: response.data.emails,
                        phoneNumbers: response.data.phoneNumbers,
                        permissions: response.data.coachPartners,
                        record: response.data.constraint_settings
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    // getData = () => {
    //     fetch(`${ApiUrl}` + "get/edit/campaign", {
    //         method: "POST",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ` + uToken(),
    //         },

    //         body: JSON.stringify({ id: this.state.id, coach_id: this.state.coach_id },
    //         ),
    //     })
    //         .then((response) => response.json())
    //         .then((response) => {
    //             if (response.status === true) {
    //                 this.setState({
    //                     record: response.data.record,
    //                     selectedTags: response.data.record.tag_ids,
    //                     selectedGroup: response.data.record.group_ids,
    //                     group_id: response.data.record.group_id,
    //                     tag_id: response.data.record.tag_id,
    //                     selectedPermission: response.data.record.permissions,
    //                     permission: response.data.record.permission,
    //                 });
    //             } else {
    //                 toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error:", error);
    //         });
    // };


    handleSubmit = async (event, values) => {
        if (this.state.id) {
            values.id = this.state.record.campaign_id ? this.state.record.campaign_id : -1;
        }

        values.holidays = values.holidays && values.holidays.length > 0 ? values.holidays.toString() : null;
        values.skip_weak_days = values.skip_weak_days && values.skip_weak_days.length > 0 ? values.skip_weak_days.toString() : null;
        
        // values.holidays = values.holidays.toString();
        // values.skip_weak_days = values.skip_weak_days.toString();
       
      

        fetch(`${ApiUrl}` + "add/campaign", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {

                if (data.status === true) {
                    setTimeout(
                        () => this.props.history.push('/campaign/list'),
                        1000
                    );

                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleTag = (selectedTags) => {
        var filter = [];
        if (selectedTags) {
            selectedTags.map(fil => {
                filter.push(fil.group_id);
            })
        }

        this.setState({
            tag_id: filter.toString(),
            selectedTags: selectedTags
        })

    }

    handleGroup = (selectedGroup) => {

        var filter = [];
        if (selectedGroup) {
            selectedGroup.map(fil => {
                filter.push(fil.group_id);
            })
        }

        this.setState({
            group_id: filter.toString(),
            selectedGroup: selectedGroup
        })
    }

    handlePermission = (selectedPermission) => {

        var filter = [];
        if (selectedPermission) {
            selectedPermission.map(fil => {
                filter.push(fil.user_id);
            })
        }

        this.setState({
            permission: filter.toString(),
            selectedPermission: selectedPermission
        })
    }

    changeTimeZone = (zone) => {
        this.setState({
            zone: zone
        })
        // setZone(zone);
        // setRecord(currValue => ({
        //     ...currValue,
        //     time_zone: zone
        // }))
    }

    changeMainZone = (zone) => {
        if (zone == 'Asia') {
            this.setState({
                zones: Asia,
                zone: Asia[0].id
            })
        } else if (zone == 'America') {
            this.setState({
                zones: America,
                zone: America[0].id
            })
        } else if (zone == 'Australia') {
            this.setState({
                zones: Australia,
                zone: Australia[0].id
            })
        } else if (zone == 'Europe') {
            this.setState({
                zones: Europe,
                zone: Europe[0].id
            })
        } else if (zone == 'Indian') {
            this.setState({
                zones: Indian,
                zone: Indian[0].id
            })
        }
        this.setState({
            main: zone,
        })
      

    }
         
   

    getVerifiedFromEmail = () => {
        fetch(`${ApiUrl}` + "verify/email/list", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
                const verifiedFromEmail = response.data.records
                const defaultFromEmail = verifiedFromEmail ? verifiedFromEmail.filter(item => item.is_default === 1).length > 0 ? verifiedFromEmail.filter(item => item.is_default === 1) : [] : [];
               
              this.setState({
                verifiedFromEmail: verifiedFromEmail,
                default_from_email_id: defaultFromEmail[0] && defaultFromEmail[0].setting_id
              });
              
    
            } else {
              toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    
    };
    

    render() {
       
        return (
            <React.Fragment>


                <Helmet>
                    <title> {this.state.page_name} </title>
                </Helmet>
                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#"> {this.state.page_name} </Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />

                <Row>
                    <Col className="col-12">
                        <Card>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                // model={this.state.record}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <ModalBody className="row">
                                    <div className="col-md-6 mb-3">
                                        <AvField
                                            name="title"
                                            value={this.state.record.title}
                                            label="Title"
                                            className="form-control"
                                            required
                                        />
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <label>Main Zone</label>
                                        <div className="CommonSelectTwo ComMain">
                                        <Select
                                            getOptionLabel={(option) => option}
                                            getOptionValue={(option) => option}
                                            options={this.state.Timezone}
                                            value={this.state.Timezone.filter((id) => id == this.state.main)}
                                            isMulti={false}
                                            classNamePrefix="select2-selection"
                                            name="zone"
                                            onChange={(e) => this.changeMainZone(e)}
                                        />
                                        </div>
                                        <AvField required type="hidden" name="main_zone" value={this.state.main} />
                                       
                                    </div>
                             
                                    <div className="col-md-3 mb-3">
                                        <label>Time Zone</label>
                                        <div className="CommonSelectTwo ComMain">
                                        <Select
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.id}
                                            options={this.state.zones}
                                            value={this.state.zones.filter(({ id }) => id === this.state.zone)}
                                            isMulti={false}
                                            classNamePrefix="select2-selection"
                                            name="timezone"
                                            onChange={(e) => this.changeTimeZone(e.id)}
                                        />
                                        </div>
                                        <AvField required type="hidden" name="time_zone" value={this.state.zone} />
                                    </div>



                                    <div className="col-md-6 mb-3">
                                        <label className="control-label">Group</label>
                                        <div className="CommonSelectTwo">
                                            <Select
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.group_id}
                                                isMulti={true}
                                                value={this.state.selectedGroup}
                                                options={this.state.groups}
                                                classNamePrefix="select2-selection"
                                                name="groups_id"
                                                onChange={(e) => {
                                                    this.handleGroup(e)
                                                }}
                                            />
                                            <AvField type="hidden" name="group_id" value={this.state.group_id} />
                                        </div>


                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="control-label">
                                            Tags
                                        </label>
                                        <div className="CommonSelectTwo">
                                            <Select
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.group_id}
                                                isMulti={true}
                                                value={this.state.selectedTags}
                                                options={this.state.tags}
                                                classNamePrefix="select2-selection"
                                                name="country_id"
                                                onChange={(e) => {
                                                    this.handleTag(e)
                                                }}
                                            />
                                            <AvField type="hidden" name="tag_id" value={this.state.tag_id} />
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        {/* <label>From Email</label> */}
                                        {/* <Select
                                            getOptionLabel={(option) => `${option.from_name}  -  ${option.from_email}`}
                                            getOptionValue={(option) => option.id}
                                            isMulti={false}
                                            options={this.state.verifiedFromEmail}
                                            classNamePrefix="select2-selection"
                                            placeholder={"Select From Email"}
                                            autocomplete="off"
                                            name="from_email_id"
                                            value={this.state.defaultFromEmail}
                                            onChange={(e) => {
                                                this.handleFromEmail(e)
                                            }}
                                        />
                                         <AvField type="hidden" name="tag_id" value={this.state.from_email_id} /> */}
                                        <AvField
                                            className="select form-control"
                                            type="select"
                                            name="from_email_id"
                                            label="From Email"
                                            value={this.state.default_from_email_id}
                                        >
                                            <option value=""> Select From Email </option>
                                            {
                                                this.state.verifiedFromEmail.map((row, i) => {
                                                    return (
                                                            <option key={i} value={row.setting_id} > {`${row.from_name}  -  ${row.from_email} -  ${row.type}`}</option>
                                                      

                                                    );
                                                })
                                            }
                                        </AvField>
                                    </div>
                                    <div className="col-md-3 mb-3 d-none">
                                        <AvField
                                            name="from_name"
                                            value={uInfo().from_name}
                                            label="From Name"
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="col-md-3 mb-3 d-none">
                                        <AvField
                                            name="from_email"
                                            value={uInfo().from_email}
                                            label="From Email"
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <AvField
                                            className="select form-control"
                                            type="select"
                                            name="phone_number"
                                            label=" Phone Number (Optional)"
                                            value={this.state.record.phone_number}
                                        >
                                            <option value=""> Select Phone Number </option>
                                            {
                                                this.state.phoneNumbers.map((row, i) => {
                                                    return (

                                                        row.is_locked == 'No' && (
                                                            <option key={i} value={row.phone_number} > {convertPhoneFormat(row.phone_number)}</option>
                                                        )

                                                    );
                                                })
                                            }
                                        </AvField>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <AvField
                                            className="select form-control"
                                            type="select"
                                            name="throttle_duration"
                                            label="Throttle duration"
                                            value={this.state.record.throttle_duration}
                                        >
                                            <option value="15">15 minutes </option>
                                            <option value="30">30 minutes</option>
                                            <option value="60">60 minutes</option>
                                        </AvField>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <AvField
                                            className="select form-control"
                                            type="select"
                                            name="throttle_value"
                                            label="Throttle value"
                                            value={this.state.record.throttle_value}
                                        >
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="150">150</option>
                                            <option value="200">200</option>
                                            <option value="250">250</option>
                                            <option value="300">300</option>
                                            <option value="350">350</option>
                                            <option value="400">400</option>
                                            <option value="450">450</option>
                                            <option value="500">500</option>
                                        </AvField>
                                    </div>
                                    {/* offset-2 */}

                                    <div className="col-md-12 mb-3">

                                        <div className="form-group">
                                            <input className="custom-checkbox" name="afterhour_callflow" onChange={(e) => {
                                                console.log(e.target.checked, 'dddddd')
                                                this.setState({
                                                    showAfter: e.target.checked
                                                })
                                            }} type={'checkbox'} checked={this.state.showAfter ? true : false} />
                                            <label className="vertical-align-middle">Modify Constraint Settings</label>
                                        </div>
                                    </div>



                                    <div className={this.state.showAfter ? 'd-block mb-3' : 'd-none'}>
                                        <p>Current Date/Time: {currentDateTime()}</p>
                                        <div className="row time-constraint">
                                            <div className="col-md-12 mb-3" >
                                                <AvField
                                                    name="block_days"
                                                    value={this.state.record.block_days}
                                                    label="Block Days"
                                                    className="form-control"
                                                    placeholder="For Example: 2022-04-16,2022-04-17"
                                                />

                                                <div className="note">
                                                    Enter comma seperated dates i.e (yyyy-mm-dd,yyyy-mm-dd)
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3" >
                                                <AvField
                                                    name="from_time"
                                                    value={this.state.record.from_time}
                                                    label="From Time"
                                                    className="form-control"
                                                    required
                                                    type="time"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3" >
                                                <AvField
                                                    name="to_time"
                                                    value={this.state.record.to_time}
                                                    label="To Time"
                                                    className="form-control"
                                                    required
                                                    type="time"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3" >
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="skip_weak_days"
                                                    label="Skip Week Days"
                                                    multiple
                                                    value={this.state.record.skip_weak_days}
                                                >
                                                    <option value="Monday">Monday</option>
                                                    <option value="Tuesday">Tuesday</option>
                                                    <option value="Wednesday">Wednesday</option>
                                                    <option value="Thursday">Thursday</option>
                                                    <option value="Friday">Friday</option>
                                                    <option value="Saturday">Saturday</option>
                                                    <option value="Sunday">Sunday</option>
                                                </AvField>
                                            </div>
                                            {this.state?.zone?.includes("America") ? (
                                            <div className="col-md-6 mb-3" >
                                            <AvField
                                                className="select form-control"
                                                type="select"
                                                value={this.state.record.holidays}
                                                name="holidays"
                                                label="USA Holidays"
                                                multiple
                                            >
                                                {
                                                    Object.entries(this.state.holidays).map(([key, value]) => { return <option value={key}>{value}</option> })

                                                }
                                            </AvField>
                                        </div>
                                    ) : ''}
                                           
                                        </div>
                                    </div>


                                    <div className="col-md-6 mb-3">
                                        <label>Ignore Followup Tag <OverlayTrigger
                                            key={`0`}
                                            placement={'auto'}
                                            overlay={
                                                <Tooltip>
                                                    Select the Yes option if you want this message to send regardless of whether or not the lead has responded to this campaign. Leads that respond via any method are automatically removed from all future campaign messaging
                                                </Tooltip>
                                            }
                                        >
                                            <i className="fas fa-info text-dark ml-3"></i>
                                        </OverlayTrigger>
                                        </label>
                                        <AvField
                                            className="select form-control"
                                            type="select"
                                            name="ignore_followup_tag"
                                            value={this.state.record.ignore_followup_tag}
                                        >
                                            <option value="No">No</option>
                                            <option value="Yes">Yes</option>
                                        </AvField>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label className="control-label">Assign to</label>
                                        <div className="CommonSelectTwo">
                                            <Select
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.user_id}
                                                isMulti={true}
                                                value={this.state.selectedPermission}
                                                options={this.state.permissions}
                                                classNamePrefix="select2-selection"
                                                onChange={(e) => {
                                                    this.handlePermission(e)
                                                }}
                                            />
                                            <AvField type="hidden" name="permission" value={this.state.permission} />
                                        </div>


                                    </div>



                                </ModalBody>
                                <ModalFooter>

                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        Cancel
                                    </Button>

                                    <button
                                        className="btn btn-primary waves-effect waves-light"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
