import React, { useState, useCallback,useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory  } from 'react-router-dom';
import { bcrypt, uInfo, uToken,dcrypt } from '../../useToken';



// const MsOfficeStatic = (props) => {

    
//     const history = useHistory();

//     const location = useLocation();
//     const segments = location.pathname.split('/').filter(Boolean); // Split and filter empty strings

//     // console.log(dcrypt(segments[2]));

//     const url = dcrypt(segments[2]);

//     React.useEffect(() => {
//         if (!(window.self !== window.top)) {
//             history.push('/pages-404');
//         }
//       }, []);

//       const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
//   return (
//     <div>
//       <iframe
//       src={viewerUrl}
//       width="100%"
//       height="600px"
//       title="Office Viewer"
//     >
//       This is an embedded <a href={url}>Microsoft Office document</a>.
//     </iframe>
//     </div>
//   );
// };


const MsOfficeStatic = ({ fileUrl }) => {

    const history = useHistory();

    const location = useLocation();
    const segments = location.pathname.split('/').filter(Boolean); // Split and filter empty strings

    // console.log(dcrypt(segments[2]));

    const url = dcrypt(segments[2]);

    React.useEffect(() => {
        if (!(window.self !== window.top)) {
            history.push('/pages-404');
        }
      }, []);

    //   console.log("checking urlk ",url);
    // const viewerUrl = 'http://docs.google.com/gview?url='+url+'&embedded=true';

    // const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`;

    // const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(url)}&embedded=true`;
   
    const viewerUrl = "https://docs.google.com/viewer?url="+url+"&embedded=true";

    return (
      <div>
        <iframe
          src={viewerUrl}
          width="600"
          height="800"
          title="Document Viewer"
        ></iframe>
      </div>
    );
  };
  
  export default MsOfficeStatic;


