import React, { Component } from 'react';
import { Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import user1 from "../../assets/images/users/user-1.jpg";
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";

class Inbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IncomingMessages: [
                {user1, title: "Misty", message: "Message Preview", date:"Oct 12,", time: "13:40 PM" },
            ],
            IncomingMessagesBlack: [
                {user2, title: "Melissa", message: "Message Preview", date:"Oct 12,", time: "13:34 PM" },
            ],
            IncomingMessagesGreen: [
                {user3, title: "Dwayne", message: "Message Preview", date:"Oct 12,", time: "13:17 PM" },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <Card>
                        <CardBody className='p-0 mt-2'>
                            
                            <div className="inbox-wid">
                                {
                                    this.state.IncomingMessages.map((message, key) =>
                                        <Link key={key} to="#" className="text-dark">
                                            <div className="inbox-item">
                                                <div className='d-flex'>
                                                    <div className="inbox-item-img Blue float-start me-3">
                                                    <svg fill="#fff" width="80px" height="80px" viewBox="0 0 24 24" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg"><path d="M21 7h-3c0-1.65-1.35-3-3-3h-12c-1.65 0-3 1.35-3 3v7c0 1.65 1.35 3 3 3v3l3-3c0 1.65 1.35 3 3 3h8l3 3v-3h1c1.65 0 3-1.35 3-3v-7c0-1.65-1.35-3-3-3zm-18 8c-.542 0-1-.458-1-1v-7c0-.542.458-1 1-1h12c.542 0 1 .458 1 1v1h-6.5c-1.379 0-2.5 1.121-2.5 2.5v4.5h-4zm19 2c0 .542-.458 1-1 1h-12c-.542 0-1-.458-1-1v-6.5c0-.827.673-1.5 1.5-1.5h11.5c.542 0 1 .458 1 1v7z"/></svg>
                                                    </div>
                                                    <div>
                                                        <h6 className="inbox-item-author mb-0">{message.title}</h6>
                                                        <p className="inbox-item-text text-muted mb-0">{message.message}</p>
                                                    </div>
                                                </div>
                                                <p className="inbox-item-date text-muted">{message.date} {message.time}</p>
                                            </div>
                                        </Link>
                                        
                                    )

                                }
                                {
                                    this.state.IncomingMessagesBlack.map((message, key) =>
                                        <Link key={key} to="#" className="text-dark">
                                            <div className="inbox-item">
                                                <div className='d-flex'>
                                                    <div className="inbox-item-img Black float-start me-3">
                                                    <svg fill="#fff" width="80px" height="80px" viewBox="0 0 24 24" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg"><path d="M21 7h-3c0-1.65-1.35-3-3-3h-12c-1.65 0-3 1.35-3 3v7c0 1.65 1.35 3 3 3v3l3-3c0 1.65 1.35 3 3 3h8l3 3v-3h1c1.65 0 3-1.35 3-3v-7c0-1.65-1.35-3-3-3zm-18 8c-.542 0-1-.458-1-1v-7c0-.542.458-1 1-1h12c.542 0 1 .458 1 1v1h-6.5c-1.379 0-2.5 1.121-2.5 2.5v4.5h-4zm19 2c0 .542-.458 1-1 1h-12c-.542 0-1-.458-1-1v-6.5c0-.827.673-1.5 1.5-1.5h11.5c.542 0 1 .458 1 1v7z"/></svg>
                                                    </div>
                                                    <div>
                                                        <h6 className="inbox-item-author mb-0">{message.title}</h6>
                                                        <p className="inbox-item-text text-muted mb-0">{message.message}</p>
                                                    </div>
                                                </div>
                                                <p className="inbox-item-date text-muted">{message.date} {message.time}</p>
                                            </div>
                                        </Link>
                                        
                                    )

                                }
                                {
                                    this.state.IncomingMessagesGreen.map((message, key) =>
                                        <Link key={key} to="#" className="text-dark">
                                            <div className="inbox-item">
                                                <div className='d-flex'>
                                                    <div className="inbox-item-img Green float-start me-3">
                                                    <svg fill="#fff" width="80px" height="80px" viewBox="0 0 24 24" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg"><path d="M21 7h-3c0-1.65-1.35-3-3-3h-12c-1.65 0-3 1.35-3 3v7c0 1.65 1.35 3 3 3v3l3-3c0 1.65 1.35 3 3 3h8l3 3v-3h1c1.65 0 3-1.35 3-3v-7c0-1.65-1.35-3-3-3zm-18 8c-.542 0-1-.458-1-1v-7c0-.542.458-1 1-1h12c.542 0 1 .458 1 1v1h-6.5c-1.379 0-2.5 1.121-2.5 2.5v4.5h-4zm19 2c0 .542-.458 1-1 1h-12c-.542 0-1-.458-1-1v-6.5c0-.827.673-1.5 1.5-1.5h11.5c.542 0 1 .458 1 1v7z"/></svg>
                                                    </div>
                                                    <div>
                                                        <h6 className="inbox-item-author mb-0">{message.title}</h6>
                                                        <p className="inbox-item-text text-muted mb-0">{message.message}</p>
                                                    </div>
                                                </div>
                                                <p className="inbox-item-date text-muted">{message.date} {message.time}</p>
                                            </div>
                                        </Link>
                                        
                                    )

                                }
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
}

export default Inbox;