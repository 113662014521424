import React, { Component, Fragment } from "react";
import {
  Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalFooter, Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { bcrypt, isAllowed, uInfo, uToken } from "../../../useToken";
import { ApiUrl, ProductName, WebUrl } from "../../../config";
import { toast, Flip } from "react-toastify";
import Select from "react-select"
import googleCalendar from "../../../assets/images/google-calendar.png";
import SweetAlert from "react-bootstrap-sweetalert"
import outlookCalendar from "../../../assets/images/outlook-calendar.png";
import { Link  , Redirect } from "react-router-dom";
import swal from 'sweetalert';
import ProfileSettings from "../../Authentication/profile-sidebar";

export default class List extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);


    this.columns = [
      {
        text: "From Email",
        key: "from_email",
        sortable: false,
      },
      {
        text: "From Name",
        key: "from_name",
        sortable: false,
      },
      {
        text: "Type",
        key: "type",
        sortable: false,
      },

      {
        key: "action1",
        text: "Default Status",
        cell: (record, index) => {
          return (
            <Fragment>
              {
                record.is_default == '1' ? (
                  <button
                    disabled={true}
                    className="btn btn-success btn-sm mb-1"
                    key={`${record.default_status}-${index}`}
                    onClick={() => {
                      if (record.user_id === uInfo().user_id) {
                        this.setState({ warningAlert: true, record });
                      }
                    }}
                  >
                    Default
                  </button>
                ) : (record.user_id === uInfo().user_id ? (
                  <button
                    key={`${record.is_default}-${index}`}
                    className="btn btn-danger btn-sm mb-1"
                    onClick={() => {
                      this.setState({ warningAlert: true, record });
                    }}
                  >
                    None
                  </button>
                ) : (
                  <button
                    key={`${record.is_default}-${index}`}
                    className="btn btn-info btn-sm mb-1"
                  >
                    Shared
                  </button>
                ))
              }
            </Fragment>

          );
        }
      },

      {
        text: "Status",
        key: "status",
        cell: (record, index) => {
          if (record.status == 'Active') {
            return <p key={index} className="badge bg-success">{record.status}</p>
          } else if (record.status == 'Inactive') {
            return <p key={index} className="badge bg-danger">{record.status}</p>
          } else {
            return <p key={index}>{record.status}</p>
          }
        }
      },
      {
        text: "Created At",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
                {record.user_id === uInfo().user_id && (
                  <button
                    type="button"
                    color="info"
                    className="btn btn-primary btn-sm mr-5"
                    onClick={() => {
                      console.log("Editing record with ID:", record.id); // Log the record ID
                      console.log("Current record data:", record); // Log the entire record data

                      this.getEditData(record.id);
                      this.setState({
                        id: record.id,
                        customstmp: record,
                        modal: true,
                        isEditing: true,
                      });
                    }}
                  >
                    <i className="mdi mdi-pencil"></i>
                  </button>
                )}

              {record.user_id === uInfo().user_id && (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={this.deleteRecord.bind(this, record, index)}
                  >
                    <i className="mdi mdi-delete"></i>
                  </button>
                )}
              {uInfo().type === 'Coach' && (
                <Button
                  type="button"
                  className="btn btn-info btn-sm mx-1"
                  onClick={() => {
                    this.getEditData(record.id);
                    console.log('Record:', record);
                    this.setState({ id: record.id, customstmp: record }, this.toggleModal2);
                  }}
                >
                  <i className="mdi mdi-share-variant"></i>
                </Button>
              )}

            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };
    this.state = {
      tab: "1",
      tab_name: "SendGrid",
      activeTabId: 0,
      id: '',
      isEditing: false,
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      modal2: false,
      record: {},
      filter: {},
      sendgrid: {},
      customstmp: {},
      permissions: [],
      selectedPermission: [],
      permission: '',
      gmail: {},
      server_port: '',
      outlook: {},
      page_name: "Email Settings",
      customDate: true,
    };
  }


  getAssignData = (coachId, id, from_email) => {

    fetch(`${ApiUrl}get/setting/email`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({
        coach_id: coachId, id: id,
        from_email: from_email,

      })
    })
      .then(response => response.json())
      .then((response) => {
        // setAssignRecords(response.records);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/email/setting", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.id }),
          })
            .then((response) => response.json())
            .then((data) => {
              this.getData();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };

  componentDidMount() {
    this.getData();

  }

  updateGmailStatus = () => {
    fetch(`${ApiUrl}` + "update/default/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.record.id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.getData();
          this.setState({
            record: {},
            warningAlert: false
          })
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  getEditData = (id) => {
    fetch(`${ApiUrl}` + "get/edit/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          const selectedPermissions = this.state.permissions.filter(permission =>
            response.data.record.permission.includes(permission.user_id)
          );
          this.setState({
            sendgrid: response.data.record.sendgrid ?? {},
            outlook: response.data.record.outlook ?? {},
            gmail: response.data.record.gmail ?? {},
            customstmp: response.data.record.custom ?? {},
            tab: response.data.record.tab_index,
            tab_name: response.data.record.tab_name,
            permission: response.data.record.permission,
            selectedPermission: selectedPermissions,
          });
        } else {

          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  getData = (queryString = "", data) => {
    let url = `${ApiUrl}` + "get/emailsettings/list";

    fetch(`${url}` + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            permissions: response.data.coachPartners,
            records: response.data.records,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handlePermission = (selectedPermission) => {
    const filter = selectedPermission ? selectedPermission.map(fil => fil.user_id).join(',') : '';
    this.setState({
      permission: filter,
      selectedPermission: selectedPermission,
    });
  }


  handleSubmit = async (event, values) => {
    event.preventDefault();
    console.log('Submitting data:', values);

    if (this.state.id) {
      values.id = this.state.id;
    }

    fetch(`${ApiUrl}` + "create/email/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          swal({
            text: 'Action performed successfully',
            icon: "success",
            buttons: true,
            dangerMode: false,
          }).then(() => {
            this.setState({ modal: false });
            this.getData();
          });
        } else {
          swal({
            text: response.message || 'SMTP information is not valid.',
            icon: "error",
            buttons: true,
            dangerMode: true,
          });
        }
      })
      .catch((error) => {
        swal({
          text: 'SMTP information is not valid.',
          icon: "error",
          buttons: true,
          dangerMode: true,
        });
      });
  };

  toggleModal2 = () => {
    this.setState(prevState => ({
      modal2: !prevState.modal2,

      selectedPermission: []
    }));
  }


  handleShareSubmit = async (event, values) => {
    event.preventDefault();
    values.permission = this.state.permission;

    if (this.state.id) {
      values.id = this.state.id;
    }

    fetch(`${ApiUrl}` + "share/email/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          swal({
            text: 'Action performed successfully',
            icon: "success",
            buttons: true,
            dangerMode: false,
          }).then(() => {
            this.setState({ modal2: false });
            this.getData();
          });
        } else {
          swal({
            text: response.message || 'Share information is not valid.',
            icon: "error",
            buttons: true,
            dangerMode: true,
          });
        }
      })
      .catch((error) => {
        swal({
          text: 'Share information is not valid.',
          icon: "error",
          buttons: true,
          dangerMode: true,
        });
      });
  };

  toggle(index) {
    this.setState({
      tab: index,
    });
  }

  toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  updatesetting = (id) => {
    fetch(`${ApiUrl}` + "update/user/emailsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitGmail = async (event, values) => {
    event.preventDefault();
    if (this.state.id) {
      values.id = this.state.id; 
    }

    try {
      const response = await fetch(`${ApiUrl}edit/gmail`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${uToken()}`,
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();
      if (data.status === true) {
        if (!this.state.id) {
          this.form && this.form.reset(); 
        }
        this.getData();
        this.setState({ modal: false });
        toast.configure();
        toast(data.message, {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: 'toast-custom-cls success'
        });
      } else {
        toast(data.message, {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: 'toast-custom-cls error'
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast("An error occurred while updating Gmail settings.", {
        hideProgressBar: true,
        position: "top-center",
        transition: Flip,
        className: 'toast-custom-cls error'
      });
    }
  };


  handleSubmitOutlook = async (event, values) => {
    event.preventDefault();
    if (this.state.id) {
      values.id = this.state.id; 
    }

    try {
      const response = await fetch(`${ApiUrl}edit/outlook`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${uToken()}`,
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();
      if (data.status === true) {
        if (!this.state.id) {
          this.form && this.form.reset(); 
        }
        this.getData();
        this.setState({ modal: false });
        toast.configure();
        toast(data.message, {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: 'toast-custom-cls success'
        });
      } else {
        toast(data.message, {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: 'toast-custom-cls error'
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast("An error occurred while updating Gmail settings.", {
        hideProgressBar: true,
        position: "top-center",
        transition: Flip,
        className: 'toast-custom-cls error'
      });
    }
  };



  render() {
    return (
      
      <React.Fragment>
        <Redirect to="/settings/profile?tab=4" />
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col lg={2} className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>

          <Col lg={10}>
            {this.state.warningAlert ? (
              <SweetAlert
                title="Are you sure you want to set this record as the default email setting?"
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                warning
                onConfirm={() => this.updateGmailStatus()}
                onCancel={() => this.setState({
                  warningAlert: false,
                  record: {}
                })}
              />
            ) : null}
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">{this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  {isAllowed(uInfo(), ["can_create"]) && (
                    <Button type="button"
                      color="info"
                      onClick={
                        () => this.setState({
                          id: '',
                          customstmp: '',
                          sendgrid: '',
                          outlook: '',
                          gmail: '',
                          isEditing: false,
                          modal2: false,
                          modal: true, row: {

                          }
                        })}>
                      <i className="mdi mdi-plus"></i> Add
                    </Button>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="pt-0">
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandler}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.modal}
              fade={false}
              toggle={() => this.setState({ modal: false })}
              style={{ maxWidth: '700px' }}
            >
              <Row>
                {!this.state.id && (
                  <Col className="">
                    <Nav tabs style={{ padding: '8px' }}>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 1 ? "active" : ""}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          SendGrid
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 2 ? "active" : ""}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Custom SMTP
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 3 ? "active" : ""}
                          onClick={() => {
                            this.toggle("3");
                          }}
                        >
                          Gmail
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 4 ? "active" : ""}
                          onClick={() => {
                            this.toggle("4");
                          }}
                        >
                          Microsoft Outlook
                        </NavLink>
                      </NavItem>

                    </Nav>
                  </Col>
                )}
                <TabContent activeTab={this.state.tab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <AvForm
                            onValidSubmit={this.handleSubmit}
                            ref={(c) => (this.form = c)}
                            className="needs-validation"
                          >
                            <AvField name="type" type="hidden" value={"sendgrid"} />
                            {/* <AvField name="api_key" type="hidden" value={bcrypt(this.state.sendgrid.sendgrid_api_key)} /> */}

                            <ModalBody>
                              <Row>
                                <Col lg={12}>
                                  <h4> SendGrid Settings </h4>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <AvField
                                      name="from_name"
                                      label="From Name"
                                      value={this.state.sendgrid.from_name}
                                      className="form-control"
                                      placeholder="From Name"
                                      required
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <AvField
                                      name="from_email"
                                      type="email"
                                      label="From Email"
                                      value={this.state.sendgrid.from_email}
                                      className="form-control"
                                      placeholder="From Email"
                                      required
                                    />
                                  </div>
                                </Col>
                                {!this.state.isEditing && (
                                  <div className="mb-3">
                                    <AvField
                                      name="sendgrid_api_key"
                                      type="password"
                                      label="SendGrid API Key"
                                      className="form-control"
                                      placeholder="******"
                                      required
                                    />
                                  </div>
                                )}
                                <div className="mb-3">
                                  <AvField
                                    name="to_email"
                                    label="To Email"
                                    type="email"
                                    value={this.state.sendgrid.to_email}
                                    className="form-control"
                                    placeholder="To Email"
                                    required
                                  />
                                </div>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.setState({ modal: false })}
                              >
                                Cancel
                              </Button>
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </AvForm>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId={"2"}>
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <AvForm
                            onValidSubmit={this.handleSubmit}
                            ref={(c) => (this.form = c)}
                            className="needs-validation"
                          >
                            <AvField name="type" type="hidden" value={"custom"} />
                            <ModalBody>
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="server_name"
                                    label="Server Name"
                                    value={this.state.customstmp.server_name}
                                    className="form-control"
                                    placeholder="Server Name"
                                    required
                                  />
                                </div>

                                <div className="col-md-6 mb-3">
                                  <AvField
                                    className="select form-control"
                                    type="select"
                                    name="server_port"
                                    label="Server Port"
                                    required
                                    value={this.state.customstmp.server_port}
                                  >
                                    <option value="">Select Port</option>
                                    <option value="25">25</option>
                                    <option value="65">65</option>
                                    <option value="587">587</option>
                                  </AvField>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="server_protocol"
                                    label="Server Protocol"
                                    value={this.state.customstmp.server_protocol}
                                    className="form-control"
                                    placeholder="Server Protocol"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label> Authentication </label>
                                  <AvRadioGroup
                                    value={
                                      this.state.customstmp.server_authentication
                                    }
                                    inline
                                    name="server_authentication"
                                    required
                                  >
                                    <AvRadio label="Yes" value="yes" />
                                    <AvRadio label="No" value="no" />
                                  </AvRadioGroup>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="user_email"
                                    label="User Email/Name"
                                    value={this.state.customstmp.user_email}
                                    className="form-control"
                                    placeholder="User Email"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="password"
                                    label="Password"
                                    value={this.state.customstmp.password}
                                    className="form-control"
                                    placeholder="Password"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="from_name"
                                    label="From Name"
                                    value={this.state.customstmp.from_name}
                                    className="form-control"
                                    placeholder="From Name"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="from_email"
                                    label="From Email"
                                    value={this.state.customstmp.from_email}
                                    className="form-control"
                                    placeholder="From Email"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="reply_to"
                                    label="Reply To"
                                    value={this.state.customstmp.reply_to}
                                    className="form-control"
                                    placeholder="Reply To"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="test_email"
                                    label="Test To Email"
                                    value={this.state.customstmp.test_email}
                                    className="form-control"
                                    placeholder="Enter test to email to trigger test email"
                                    required
                                  />
                                </div>
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.setState({ modal: false })}
                              >
                                Cancel
                              </Button>
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </AvForm>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={"3"}>
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <AvForm
                            onValidSubmit={this.handleSubmitGmail}
                            ref={(c) => (this.form = c)}
                            className="needs-validation"
                          >
                            <ModalBody>
                              <div className="mb-3">
                                <h4> Gmail Settings </h4>
                              </div>
                              {!this.state.isEditing && (
                                <div>
                                  <span>Authenticate Gmail Account</span>
                                  <a
                                    title="Click here to sync Google Calendar event"
                                    className="btn btn-link btn-sm mb-1"
                                    href={`${WebUrl}/google/email/sync?u_id=${uInfo().user_id}`}
                                  >
                                    <img src={googleCalendar} height={26} alt="Google" />
                                  </a>
                                </div>
                              )}

                              {this.state.isEditing && (
                                <>
                                  <div className="mb-3">
                                    <AvField
                                      name="from_name"
                                      label="From Name"
                                      value={this.state.gmail.from_name}
                                      className="form-control"
                                      placeholder="From Name"
                                      required
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <AvField
                                      name="from_email"
                                      label="From Email"
                                      defaultValue={this.state.gmail.from_email}
                                      className="form-control"
                                      placeholder="From Email"
                                      disabled
                                    />
                                  </div>
                                </>
                              )}

                            </ModalBody>
                            <ModalFooter>
                              {this.state.isEditing && (
                                <>
                                  <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => this.setState({ modal: false })}
                                  >
                                    Cancel
                                  </Button>
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </>
                              )}
                            </ModalFooter>
                          </AvForm>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={"4"}>
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <AvForm
                            onValidSubmit={this.handleSubmitOutlook}
                            ref={(c) => (this.form = c)}
                            className="needs-validation"
                          >

                            <ModalBody>
                              <div className="mb-3">
                                <h4>Outlook Settings </h4>
                                {!this.state.isEditing && (
                                  <div>
                                    <span>Authenticate Outlook Account</span>
                                    <a
                                      title="Click here to sync Outlook event"
                                      className="btn btn-link btn-sm mb-1"
                                      href={`${WebUrl}/outlook/email/sync?u_id=${uInfo().user_id}`}
                                    >
                                      <img src={outlookCalendar} height={26} alt="Outlook" />
                                    </a>
                                  </div>
                                )}

                                {this.state.isEditing && (
                                  <>
                                    <div className="mb-3">
                                      <AvField
                                        name="from_name"
                                        label="From Name"
                                        value={this.state.outlook.from_name}
                                        className="form-control"
                                        placeholder="From Name"
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <AvField
                                        name="from_email"
                                        label="From Email"
                                        value={this.state.outlook.from_email}
                                        className="form-control"
                                        placeholder="From Email"
                                        disabled
                                      />
                                    </div>
                                  </>
                                )}
                              </div>

                            </ModalBody>
                            <ModalFooter>
                              {this.state.isEditing && (
                                <>
                                  <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => this.setState({ modal: false })}
                                  >
                                    Cancel
                                  </Button>
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </>
                              )}
                            </ModalFooter>
                          </AvForm>

                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Row>
            </Modal>
          </Col>

          <Modal isOpen={this.state.modal2} toggle={this.toggleModal2}>
            <AvForm
              onValidSubmit={this.handleShareSubmit}
              ref={(c) => (this.form = c)}
              className="needs-validation"
            >
              <ModalBody>
                <form className="mb-3">
                  <div className="col-md-12 mb-3">
                    <label className="control-label">Assign to</label>
                    <div className="CommonSelectTwo">
                      <Select
                        getOptionLabel={option => `${option.name} - ${option.email}`}
                        getOptionValue={option => option.user_id}
                        isMulti={true}
                        value={this.state.selectedPermission}
                        options={this.state.permissions}
                        classNamePrefix="select2-selection"
                        onChange={(e) => this.handlePermission(e)}
                        formatOptionLabel={(option, { context }) => {
                          if (context === 'value') {
                            return option.name;
                          }
                          return `${option.name} - ${option.email}`;
                        }}
                      />
                      <input type="hidden" name="permission" value={this.state.permission} />
                    </div>
                  </div>
                </form>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={this.toggleModal2}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </ModalBody>
            </AvForm>
          </Modal>

        </Row >
      </React.Fragment >
    );
  }
}
