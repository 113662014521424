import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import ReactDatatable from '@ashvin27/react-datatable';

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet"
import axios from "axios"
import { AvForm, AvField, AvInput, AvFeedback, AvGroup } from "availity-reactstrap-validation"
import { getPages, getPermission, getRoles, isAllowed, uInfo, uRole, uToken } from "../../useToken";
import { ApiUrl, ProductName, ServerIP } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"
import { Link } from "react-router-dom";
import ProfileSettings from "../Authentication/profile-sidebar";
import swal from 'sweetalert';
export default class UserOperation extends Component {


    constructor(props) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        super(props);
        this.state = {
            id: obj.id,
            record: {},
            page_name: "Domain Setup",
            type: 'sub',
            previous_domain: '',
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/domain/setting', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({ id: this.state.id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    if (response.data.record != null) {
                        this.setState({
                            record: response.data.record,
                            type: response.data.record.domain_type,
                            domain: response.data.record.domain.replace('.profitcoach.app', ''),
                            domain_id: response.data.record.id
                        });

                        if (response.data.record.domain_type == 'sub') {
                            this.setState({
                                domain: response.data.record.domain.replace('.profitcoach.app', ''),
                                previous_domain: response.data.record.domain.replace('.profitcoach.app', '')
                            });
                        } else {
                            this.setState({
                                domain: response.data.record.domain,
                                previous_domain: response.data.record.domain.replace('.profitcoach.app', '')
                            });
                        }
                        //console.log(response.data.record.domain, 'domain');
                        if (uInfo().domain_status != response.data.record.user_domain_status) {
                            let newtoken = {
                                domain_status: response.data.record.user_domain_status,
                            };
                            const authUser = {
                                ...JSON.parse(localStorage.getItem("authUser")),
                                ...newtoken,
                            };
                            localStorage.setItem("authUser", JSON.stringify(authUser));
                        }
                    }
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    removeDomain = async () => {
        fetch(`${ApiUrl}` + 'remove/domain/setting', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({
                domain_id: this.state.domain_id
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.getData();
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    handleSubmit = async () => {
        fetch(`${ApiUrl}` + 'update/domain', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({
                type: this.state.type,
                domain: this.state.type == 'sub' ? `${this.state.domain}.profitcoach.app` : this.state.domain,
                domain_id: this.state.domain_id
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.getData();
                    if(this.state.type == 'sub'){
                        swal({
                            text: "Updated successfully.",
                            icon: "success"
                        })
                    }else{
                        swal({
                            text: "Your request for WL configuration final step has been submitted successfully and will be processed shortly.",
                            icon: "success"
                        }) 
                    }
                   
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }




    render() {
        return (
            <React.Fragment>
                <Row className="wldomain_setting">
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="col-lg-12 mb-2">
                                    {
                                        this.state.type == 'sub' ? (
                                            <>
                                                Website Link: <a className="" href={`https://${this.state.domain}.profitcoach.app`} target={'_blank'}>{`https://${this.state.domain}.profitcoach.app`}</a>
                                            </>
                                        ) : (
                                            <>
                                                Website Link: <a className="" href={`https://${this.state.domain}`} target={'_blank'}>{`https://${this.state.domain}`}</a>
                                            </>
                                        )
                                    }
                                </div>
                                    <div className="form-check mb-3 col-md-6">
                                        <input className="form-check-input mb-2" type="radio" name="domain_type" id="domain_type" value="sub"
                                            onChange={() => this.setState({ type: 'sub', domain: this.state.previous_domain })}
                                            checked={this.state.type == 'sub' ? true : false} />



                                        <label className="form-check-label" htmlFor="domain_type">Defaut domain</label>
                                        {
                                            this.state.type == 'sub' && (
                                                <div className="input-group mt-2 mb-3  col-md-6">
                                                    <input
                                                        value={this.state.domain}
                                                        onChange={(e) => this.setState({ domain: e.target.value })}
                                                        placeholder="example"
                                                        className="form-control" name="domain" />
                                                    <div className="input-group-text">profitcoach.app</div>
                                                </div>
                                            )
                                        }
                                    </div>
                           
                                    <div className="form-check mb-3 col-md-12">
                                        <input className="form-check-input mb-2" type="radio" name="domain_type"

                                            checked={this.state.type == 'master' ? true : false}
                                            id="domain_type1" value="master"
                                            onChange={() => this.setState({ type: 'master', domain: '' })} />
                                        <label className="form-check-label" htmlFor="domain_type1">Add your own domain</label>
                                        {
                                            this.state.type == 'master' && (
                                                <div className="row">
                                                    <div className="form-check mb-3 col-md-6">
                                                        <input
                                                            placeholder="www.example.com"
                                                            value={this.state.domain}
                                                            onChange={(e) => this.setState({ domain: e.target.value })}
                                                            className="form-control mb-2" name="domain" />
                                                        <p>Note: Please make sure to have either an A record pointing to <span className="text-danger">{ServerIP}</span></p>
                                                    </div>
                                                
                                                    <div className="form-check mb-3 col-md-6">
                                                            <p>
                                                                <i className="fas fa-question"> </i> Help
                                                            </p>
                                                            <p><span><i class="fa fa-chevron-right"></i> Step 1</span> You must setup an NS "A" record to our server IP {ServerIP}</p>
            
                                                            <p><span><i class="fa fa-chevron-right"></i> Step 2</span>  After completing step 1, please submit to admin for final configuration.</p>
            
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    
                               

                                {
                                    this.state.record.domain_type == 'sub' || this.state.record.status == 'un-verified' ? (
                                        <button className="btn btn-primary waves-effect waves-light mt-3"
                                            type="button" onClick={() => this.handleSubmit()}> Update </button>
                                    ) : (
                                        <button className="btn btn-primary waves-effect waves-light mt-3"
                                            type="button" disabled> Update </button>
                                    )
                                }
                            </CardBody>
                        </Card>
                        {/* <Card>
                            <div className="card-body">
                                <div className="h4 card-title">Step 1</div>
                                <p className="card-title-desc"><b>For Full Domain:</b> Please login to your domain registrar and update following DNS records as</p>
                                <ul>
                                    <li>ns1.vop1.com</li>
                                    <li>ns2.vop1.com</li>
                                </ul>
                                <p className="card-title-desc"><b>For Sub Domain:</b>
                                    If you are using a sub-domain (example: name.mydomain.com) you must setup an NS "A" record to our server IP 72.52.171.190 For example: name.mydomain {`>>`} A  {`>>`} 72.52.171.190
                                </p>
                            </div>
                        </Card>

                        <Card>
                            <div className="card-body">
                                <div className="h4 card-title">Step 2 </div>
                                <p className="card-title-desc">After updating the above DNS records please give the name of the domain you want and click <b>Set Domain</b></p>


                                <Col md={8}>
                                    <AvForm onValidSubmit={this.handleSubmit}
                                        ref={c => (this.form = c)}
                                        className="needs-validation">


                                        <AvGroup className="input-group">
                                            <div className="input-group-text">https:</div>
                                            <AvInput value={this.state.record.domain} name="domain" className="form-control" required />
                                            <AvFeedback>This is an error!</AvFeedback>
                                        </AvGroup>
                                        <button className="btn btn-primary waves-effect waves-light mt-3"
                                            type="submit"> Submit </button>

                                    </AvForm>

                                </Col>
                                <Col md={4}>

                                </Col>
                            </div>
                        </Card> */}
                    </Col>

                </Row>


            </React.Fragment >
        )
    }
}