import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  // ModalTitle,
  Button,
} from "reactstrap";
import Carousel from "react-bootstrap/Carousel";

import Select from "react-select";
import Helmet from "react-helmet";
import "../../assets/css/courses_list.css";
import {
  ApiUrl,
  ProductName,
  AttachementsUrl,
  HostUrl,
  WebUrl,
} from "../../config";
import { uToken, uInfo } from "../../useToken";
import { Link, useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
// import ProfileSettings from "../Authentication/profile-sidebar";
import "../../assets/css/courses_list.css";

export default function UserProgressProfile() {
  const [earnedBadges, setEarnedBadges] = useState([]);

  const [totalEarnedBadges, setTotalEarnedBadges] = useState(0);

  const [inprogressBadges, setInProgressBadges] = useState([]);
  const [totalInprogressBadges, setTotalInProgressBadges] = useState(0);

  const [leftBadges, setLeftBadges] = useState([]);
  const [totalLeftBadges, setTotalLeftBadges] = useState([]);

  const [certificates, setCertificates] = useState([]);
  const [totalCertificates, setTotalCertificates] = useState([]);

  const [pageName, setPageName] = useState("Badges Progress");
  const [shareModal, setShareModal] = useState(false);
  const shareURL = `${HostUrl}` + "/module-badges-web/" + uInfo().user_id;

  const [cSelected, setCSelected] = useState([]);

  const routeHistory = useHistory();

  const is_coach_section = uInfo().type === "Coach" ? "Yes" : "No";
  var user_types;

  if (is_coach_section === "Yes") {
    user_types = [
      { id: "3", title: "Client" },
      { id: "6", title: "Team Coach" },
    ];
  } else {
    user_types = [
      { id: "1", title: "Coach" },
      { id: "2", title: "Diamond Partner" },
      { id: "3", title: "Client" },
      { id: "4", title: "Team Admin" },
      { id: "6", title: "Team Coach" },
    ];
  }

  const getUserBadges = (badgeStatus) => {
    fetch(`${ApiUrl}` + "get/module/badges", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: uInfo().user_id, status: badgeStatus }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          console.log(response.data.records);
          if (badgeStatus === "1") {
            setEarnedBadges(response.data.records);
            setTotalEarnedBadges(response.data.total);
          } else if (badgeStatus === "0") {
            setInProgressBadges(response.data.records);
            setTotalInProgressBadges(response.data.total);
          }
          console.log(response.data.records);
        } else {
          setEarnedBadges([]);
          setInProgressBadges([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  const getUserLeftBadges = () => {
    fetch(`${ApiUrl}` + "get/remaining/badges", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: uInfo().user_id }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          console.log(response.data.records);
          setLeftBadges(response.data.records);
          setTotalLeftBadges(response.data.total);
        } else {
          console.log("in error");
          setLeftBadges([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  const getEarnedCertifications = () => {
    fetch(`${ApiUrl}` + "get/earned/certificates", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      // body: JSON.stringify({ id: uInfo().user_id }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          console.log(response.data.records);
          setCertificates(response.data.records);
          setTotalCertificates(response.data.total);
        } else {
          console.log("in error");
          setTotalCertificates([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  const updateBadgeShownStatus = (badge_id) => {
    fetch(`${ApiUrl}` + "badge/shown/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: badge_id }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          setEarnedBadges((prevBadges) => {
            const updatedBadges = prevBadges.map((badge) => ({
              ...badge,
              shown_on_web: 0,
            }));

            return updatedBadges.map((badge) =>
              badge.id === badge_id ? { ...badge, shown_on_web: 1 } : badge
            );
          });
          console.log("new", earnedBadges);
        } else {
          console.log("in error");
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  const onCheckboxBtnClick = (selected) => {
    if (cSelected.includes(selected)) {
      setCSelected(cSelected.filter((id) => id !== selected));
    } else {
      setCSelected([...cSelected, selected]);
    }

    console.log(cSelected);
  };

  useEffect(() => {
    getUserBadges("0");
    getUserBadges("1");
    getUserLeftBadges();
    getEarnedCertifications();
  }, []);

  const handleDownloadClick = (user_id, badges) => {
    if (badges.length === 0) {
      swal({
        title: "Please select at least one badge to download",
        icon: "warning",
      });
    } else {
      downloadImages(user_id, badges);
    }
  };

  const BadgeSection = ({ title, badges, totalBadges, badgesType }) => {
    let defaultBadge = false;
    if (badgesType === "earned") {
      defaultBadge = badges.some((badge) => badge.shown_on_web === 1);
    }
    return (
      <>
        <Col lg="12" className="mb-1 DashboardCard TraningCoachDash">
          <div className="card badges_outer_div mt-3">
            <div className="card-body ps-2 pb-1 pe-1">
              <div className="DashboardHeadingSection mb-4 modal_badge_title">
                <h4 className="card-title">{`${title} - ${totalBadges}`}</h4>
              </div>
              <Form>
                <Row className="my-3 mx-1">
                  {badgesType === "earned" && cSelected.length > 0 && (
                    <Col sm={12} className="mb-3 mt-0 d-none">
                      <Button
                        outline
                        className="btn btn-secondary btn-sm float-right download_btn"
                        type="button"
                        onClick={() => {
                          downloadImages(earnedBadges[0].user_id, cSelected);
                        }}
                      >
                        <i class="fas fa-download me-1"></i>
                        Download Selected
                      </Button>
                    </Col>
                  )}

                  {badges.length > 0 ? (
                    badges.map((badge, index) => (
                      <Col lg="3" className="mb-3">
                        <Card
                          className={`h-100 d-flex flex-column ${
                            cSelected.includes(badge.id) ? "active-card" : ""
                          }`}
                          onClick={() =>
                            badgesType === "earned" &&
                            onCheckboxBtnClick(badge.id)
                          }
                          style={{
                            cursor:
                              badgesType === "earned" ? "pointer" : "default",
                          }}
                        >
                          {/* {badgesType === "earned" &&
                          defaultBadge &&
                          badge.shown_on_web === 1 ? (
                            <div className="ribbon-1 left">Default</div>
                          ) : (
                            badgesType === "earned" &&
                            !defaultBadge &&
                            index === 0 && (
                              <div className="ribbon-1 left">Default</div>
                            )
                          )} */}

                          {badgesType === "earned" && badge.shown_web && (
                            <div className="ribbon-1 left">Default</div>
                          )}

                          <div className="RecentlyViewed text-center d-flex flex-grow-1 flex-column justify-content-center">
                            <div className="p-2">
                              <div className="SliderContent">
                                <h5 className="ChangeColor fw-bold pb-0">
                                  {badgesType === "earned" &&
                                  badge.certification_id != 0
                                    ? badge.title
                                    : badge.module_title}
                                </h5>
                              </div>

                              {badgesType === "earned" &&
                              badge.certification_id != 0 ? (
                                <img
                                  src={`${AttachementsUrl}user_${badge.wl_admin}/training_badges/${badge.image}`}
                                  alt={badge.title}
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={`${AttachementsUrl}user_${badge.wl_admin}/training/${badge.image}`}
                                  alt={badge.module_title}
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                  }}
                                />
                              )}
                            </div>
                            {badgesType === "earned" &&
                            badge.certification_id != 0 ? (
                              <h5
                                className="title_limit fst-italic fw-normal m-0 text-white"
                                style={{ fontSize: "12px" }}
                              >
                                &nbsp;
                              </h5>
                            ) : (
                              <h5
                                className="title_limit fst-italic fw-normal m-0 "
                                style={{ fontSize: "12px" }}
                              >
                                {badge.course_title}
                              </h5>
                            )}
                          </div>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <h5 className="text-center">
                      No badges found in this section
                    </h5>
                  )}
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </>
    );
  };

  const downloadImages = (userId, moduleIds = []) => {
    const query = moduleIds.length ? `?module_id=${moduleIds.join(",")}` : "";
    window.location.href = `${WebUrl}/download/badges/${userId}${query}`;

    setCSelected([]);
  };

  return (
    <>
      <Row>
        <Col lg="12">
          <Row>
            <Col sm={12}>
              <div className="d-flex  justify-content-end">
                {earnedBadges[0] && (
                  <>
                    <Button
                      outline
                      className="btn btn-info btn-sm me-2"
                      type="button"
                      onClick={() => {
                        handleDownloadClick(earnedBadges[0].user_id, cSelected);
                      }}
                    >
                      <i className="fas fa-download me-1"></i>
                      Download Selected
                    </Button>

                    <Button
                      outline
                      // color="secondary"
                      className="btn btn-success btn-sm"
                      type="button"
                      onClick={() => {
                        downloadImages(earnedBadges[0].user_id);
                      }}
                    >
                      <i class="fas fa-download me-1"></i>
                      Download All
                    </Button>
                  </>
                )}

                <Button
                  outline
                  color="secondary"
                  className="ms-2 waves-effect btn-sm d-none"
                  type="button"
                  onClick={() => {
                    setShareModal(true);
                  }}
                >
                  Share
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="d-none">
            <BadgeSection
              title="Certifications"
              badges={certificates}
              totalBadges={totalCertificates}
              badgesType="earned"
            />
          </Row>

          <Row>
            <BadgeSection
              title="Earned Badges"
              badges={earnedBadges}
              totalBadges={totalEarnedBadges}
              badgesType="earned"
            />
          </Row>

          <Row>
            <BadgeSection
              title="In Progress Badges"
              badges={inprogressBadges}
              totalBadges={totalInprogressBadges}
              badgesType="in_progress"
            />
          </Row>

          <Row>
            <BadgeSection
              title="Remaining Badges"
              badges={leftBadges}
              totalBadges={totalLeftBadges}
              badgesType="remaining"
            />
          </Row>
        </Col>
      </Row>
      <Modal
        isOpen={shareModal}
        role="dialog"
        // centered={true}
        className="exampleModal modal-md UpdateInformationModal"
        tabIndex="-1"
        toggle={() => {
          setShareModal(false);
        }}
      >
        {/* <ModalHeader>Badges Progress Share</ModalHeader> */}
        <ModalHeader className="text-transform-capitalize p-2 UpdateInformationUser">
          <div>Badges Progress Share</div>
          <button
            onClick={() => {
              setShareModal(false);
            }}
            className="btn btn-link pull-right BtnCenterClose me-end"
          >
            <i className="fas fa-times"></i>
          </button>
        </ModalHeader>

        <ModalBody>
          <div className="url">
            <div className="d-flex ">
              <label className="my-auto fw-bold">Share url: </label>
              <a
                href={shareURL}
                target="_blank"
                className="text-primary ms-2 my-auto fst-italic pr-5 mr-5"
              >
                {shareURL}
              </a>

              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="overlay-example">Copy url</Tooltip>}
              >
                <button
                  className="btn btn-link btn-md"
                  onClick={() => {
                    // navigator.clipboard.writeText(
                    //   `${shareURL}`
                    // );

                    if (navigator.clipboard && navigator.clipboard.writeText) {
                      navigator.clipboard
                        .writeText(shareURL)
                        .then(() => {})
                        .catch((err) => {
                          console.error("failed to copy: ", err);
                        });
                    } else {
                      const textArea = document.createElement("textarea");
                      textArea.value = shareURL;
                      document.body.appendChild(textArea);
                      textArea.select();
                      try {
                        document.execCommand("copy");
                      } catch (err) {
                        console.error("unable to copy", err);
                      }
                      document.body.removeChild(textArea);
                    }
                  }}
                >
                  <i className="fas fa-copy"></i>
                </button>
              </OverlayTrigger>
            </div>
          </div>

          <div className="url d-flex mt-3">
            <label className="my-auto fw-bold">Iframe Embed Url </label>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="overlay-example">Copy</Tooltip>}
            >
              <button
                className="btn btn-link btn-md"
                onClick={() => {
                  // navigator.clipboard.writeText(
                  //   `<iframe allowtransparency="true" src="${shareURL}" style="height: 360px; width: 100%; border: none;"></iframe>`
                  // );

                  if (navigator.clipboard && navigator.clipboard.writeText) {
                    navigator.clipboard
                      .writeText(
                        `<iframe allowtransparency="true" src="${shareURL}" style="height: 360px; width: 100%; border: none;"></iframe>`
                      )
                      .then(() => {})
                      .catch((err) => {
                        console.error("failed to copy: ", err);
                      });
                  } else {
                    const textArea = document.createElement("textarea");
                    textArea.value = `<iframe allowtransparency="true" src="${shareURL}" style="height: 360px; width: 100%; border: none;"></iframe>`;
                    document.body.appendChild(textArea);
                    textArea.select();
                    try {
                      document.execCommand("copy");
                    } catch (err) {
                      console.error("unable to copy", err);
                    }
                    document.body.removeChild(textArea);
                  }
                }}
              >
                <i className="fas fa-copy"></i>
              </button>
            </OverlayTrigger>
          </div>

          <textarea
            rows={"5"}
            className="form-control mt-2 fst-italic fw-light"
            defaultValue={`<iframe allowtransparency="true" src="${shareURL}" style="height: 360px; width: 100%; border: none;"></iframe>`}
          ></textarea>
        </ModalBody>
        {/* <ModalFooter>
          <Button
            color="info"
            onClick={() => {
              setShareModal(false);
            }}
          >
            Close
          </Button>
        </ModalFooter> */}
      </Modal>
    </>
  );
}
