import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"
import { withRouter, Link, useSearchParams, useLocation } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { ApiUrl, ProductName, logoLightPng } from '../../config';
import { toast, ToastContainer, Flip } from 'react-toastify';
import useToken, { dcrypt, bcrypt, switchUserList,useSwitchUser } from '../../useToken';
import Loader from "react-loader";


const StaffLogin = props => {
    const [searchParams, setSearchParams] = ([]);
    const { setToken } = useToken();
    const [loaded, setLoaded] = useState(true);
    const { setSwitchUser } = useSwitchUser();
    const handleValidSubmit = (event, values) => {

        fetch(`${ApiUrl}` + 'staff/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status === true) {
                    if (res.data.multiple == 0) {
                    localStorage.setItem("theme", res.data.theme);
                      setToken(res.data.user);
                      setSwitchUser([]);
                      if (res.data.user.type == 'Client') {
                        window.location.href = '/client/profile';
                      } else {
                        window.location.href = '/dashboard';
                      }
                    } else {
                      props.history.push(`/user/toggle?id=${bcrypt(res.data.staff_user_id)}`, { users: res.data.users });
                    }
                  } else {
                    if (res.data) {
                      if (res.data.code == 302) {
                        props.history.push(`/send/account/notification/${bcrypt(res.data.user_id)}/${bcrypt(res.data.admin_id)}/${bcrypt(res.data.msg)}`)
                      } else if (res.data.code == 303) {
                        props.history.push(`/renew/subscription/${bcrypt(res.data.user_id)}/${bcrypt(res.data.admin_id)}/${bcrypt(res.data.msg)}`)
                      } else {
                        setLoaded(true);
                        toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                      }
                    } else {
                      setLoaded(true);
                      toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                  }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    useEffect(() => {
        localStorage.removeItem("switchUser");
        localStorage.removeItem("authUser");
    }, []);

    let query = useQuery();
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <h3 className="text-center mt-5 mb-4">
                                        <Link to="/" className="d-block auth-logo">
                                            <img src={logoLightPng} alt="" height="30" className="auth-logo-dark" />
                                        </Link>
                                    </h3>
                                    <AvForm
                                        className="form-horizontal mt-4"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}
                                    >
                                        {props.error && typeof props.error === "string" ? (
                                            <Alert color="danger">{props.error}</Alert>
                                        ) : null}

                                        <div className="mb-3">
                                            <AvField
                                                name="email"
                                                label="Enter user email"
                                                value={query.get("user_email")}
                                                className="form-control"
                                                placeholder="Enter user email"
                                                type="email"
                                                readOnly={query.get("user_email") ? true : false}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="staff_email"
                                                label="Enter your Team admin login email"
                                                value=""
                                                className="form-control"
                                                placeholder="Enter your Team admin login email"
                                                type="email"
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="password"
                                                label="Password"
                                                value=""
                                                type="password"
                                                required
                                                placeholder="Enter your Team admin login password"
                                            />
                                        </div>

                                        <div className="mb-3 row mt-4">
                                            <div className="col-12 text-end">
                                                <button className="btn btn-primary waves-effect waves-light" type="submit">Log In</button>
                                            </div>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default StaffLogin;

