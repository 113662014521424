
import React, { useEffect, useState } from 'react'
import { Row, Col } from "reactstrap"
import { ApiUrl } from "../../config"
import { uToken } from "../../useToken"
import { toast, Flip } from "react-toastify"
import { Link } from "react-router-dom"
import swal from 'sweetalert';

const TrainingNotifications = () => {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(() => {
    getData();
    setIsLoading(true);

  }, []);


  const getData = () => {
    fetch(`${ApiUrl}` + "get/training/notification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data.records);
          setIsLoading(true);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleClick = (itemId) => {
    fetch(`${ApiUrl}status/training/notification/${itemId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ status: 0 }), 
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          // If status update is successful, fetch updated notification data
          getData();
          window.location.reload();
          
          
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls error',
          });
          // Reset loading state on error
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false); // Reset loading state if an error occurs
      });
  };
  
  
  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <div className="card-body">
            <div className="h4 card-title mb-3">New Training Notifications </div>
            {isLoading && records.map((item, index) => (
            <div className='alert alert-info alert fade show' role="alert">
            <button type="button" class="btn-close float-end" data-bs-dismiss="alert" aria-label="Close" 
            onClick= {()=>handleClick(item.id)}
            ></button>
             <p>
              {/*
             <strong>Training # {item?.step_id} :  </strong>
             &nbsp;  A new Training &nbsp; <a href={`${item.link}`} key={index} className='text-primary'><b>{item.title}</b>  </a> 
                  &nbsp; has been added  on  {item?.created_at} with  {item?.subject_title}
                */}
             A new Training &nbsp; <a href={`${item.link}`} key={index} className='text-primary'><b>{item.title}</b>  </a> 
                  &nbsp; has been added  on  {item?.created_at}
             </p>
            </div>
            ))}
          
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TrainingNotifications