import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Progress
} from "reactstrap";
import moment from 'moment';
import Helmet from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import Phone from "../../InitiateCall/Phone";
import {
  ApiUrl,
  AttachementsUrl,
  HostUrl,
} from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { bcrypt, CommasStringToNumber, convertPhoneFormat, dcrypt, isObjectEmpty, uInfo, uToken } from "../../../useToken";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Badge from "react-bootstrap/Badge";
import "audio-react-recorder/dist/index.css";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import {
  AvForm,
  AvField,
  AvGroup,
  AvFeedback,
  AvInput,
} from "availity-reactstrap-validation";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import Select from "react-select";
import { Accordion, Dropdown, Tab, Tabs, Tooltip } from "react-bootstrap";
import Avatar from "react-avatar";

import CreateUpdate from "../../Coaches/Clients/ClientCreateUpdate.js";
import CreateTask from "../../Tasks/create.js";
import ClientContract from "../../Contract/clientContract.js";

import CreateAssessment from "../../Assessment/create.js";
import EditAssessment from "../../Assessment/update.js";
import { useHistory } from "react-router-dom";
import Loader from "react-loader";
import swal from 'sweetalert';
import {
  Asia,
  America,
  Australia,
  Europe,
  Indian,
} from "../../../common/data/timezones";
import { currencies } from "../../../common/Common-Currency.js";
import Cog from "../../../assets/images/profile-icons/gear-icon.png";
import Login from "../../../assets/images/profile-icons/userorange.png";
import Info from "../../../assets/images/profile-icons/info.png";
import Assesments from "../../../assets/images/profile-icons/usergrren.png";
import PfIcon from "../../../assets/images/favicon.png";
import UpdateCredential from "../../Coaches/Clients/UpdateCredential";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const CoachDetails = (props) => {
  const [page_name] = useState("Coach Dialer");
  const [client_id] = useState(dcrypt(props.match.params.id));
  const [user_id] = useState(dcrypt(props.match.params.coach_id));
  const [coach_id] = useState(dcrypt(props.match.params.wl_admin_id));
  const [info, setInfo] = useState({});
  const [assignCallStatus, setAssignCallStatus] = useState({});
  const [tab, setTab] = useState("3");
  const [btab, setBTab] = useState("3");
  const [groupModal, setGroupModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const [index, setIndex] = useState("");
  const [tags, setTags] = useState([]);
  const [alltags, setAllTags] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tag, setTag] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [style, setStyle] = useState({ display: "none" });
  const [page2Class, setpage2Class] = useState("");
  const [page3Class, setpage3Class] = useState("");
  const [page4Class, setpage4Class] = useState("");
  const [page5Class, setpage5Class] = useState("");
  const [page6Class, setpage6Class] = useState("");
  const [page7Class, setpage7Class] = useState("");
  const [page8Class, setpage8Class] = useState("");
  const [page9Class, setpage9Class] = useState("");
  const [page10Class, setpage10Class] = useState("");
  const [page11Class, setpage11Class] = useState("");
  const [callToken, setCallToken] = useState(null);
  const [searchClient, setSearchClient] = useState("");
  const [existing, setExisting] = useState([]);
  const [clients, setClients] = useState([]);
  const [DynamicModal, setDynamicModal] = useState(false);
  const [EmailModal, setEmailModal] = useState(false);
  const [DynamicModalName, setDynamicModalName] = useState("");
  const [activity, setActivity] = useState([]);
  const [script, setScript] = useState("");
  const [templates, setSmsTemplates] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailSetting, setEmailSetting] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [smsBody, setSmsBody] = useState("");
  const [token, setToken] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState("");
  const [sentEmailTemplateModal, setSentEmailTemplateModal] = useState(false);
  const [emailData, setEmailData] = useState("");
  const [taskCompleteModal, setTaskCompleteModal] = useState(false);
  const [taskID, setTaskID] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [pipeline, setPipeline] = useState({});
  const [pipelineStatus, setPipelineStatus] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const [pipelineModal, setPipelineModal] = useState(false);
  const [pipeline_status_month, setPipelineStatusMonth] = useState("");
  const [coachPartners, setCoachPartners] = useState([]);
  const [UpPermission, setUpPermission] = useState({
    permission: 0,
    remaining_coach: 0,
    packages: []
  });
  const [invoices, setInvoices] = useState([]);
  const [defaultCalendar, setDefaultCalendar] = useState(0);
  const identity = "proglobal";
  const childRef = useRef();
  const [phoneno, setphoneno] = useState(null);
  const [attachmentFile, setattachmentFile] = useState("");
  const [assessment_id, setAssessmentId] = useState("");
  const [training, setTraining] = useState({
    userTraining: [],
    allTraining: [],
    userResource: [],
    allResource: [],
    subCategories: []
  });
  const [loader, setLoader] = useState(false);
  const [smsloader, setSmsLoader] = useState(true);
  const [pipeline_list, setPipelineList] = useState([]);
  const [current_pipeline_id, setCurrentPipelineId] = useState([]);
  const [pipeline_stats, setPipelineStats] = useState([]);
  const [timeZone_data, setnewzone] = useState(Asia);
  const [timezone, setTimeZone] = useState("");
  const [maintimezone, setMainTimeZone] = useState("");
  const [reminderModal, setReminderModal] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [template_id, setTemplateId] = useState(0);
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [goalsModal, setGoalsModal] = useState(false);
  const [goalsArray, setGoalsArray] = useState(false);
  const [todoLists, setTodoLists] = useState([]);
  const [coachStatsMonthly, setCoachStatsMonthly] = useState({
    revenue_goals: {},
  });
  const [coachStatsYearly, setCoachStatsYearly] = useState({
    revenue_goals: {},
  });
  const [todoModal, setTodoModal] = useState(false);
  const [sendNotes, setSendNote] = useState(false);
  const [noteType, setNoteType] = useState('manual');
  const [inbox_activity, setInboxActivity] = useState({
    report_results: [],
    sms_count: 0,
    email_count: 0,
    call_count: 0,
    voice_count: 0,
    voice_message_un_listen: 0
  });
  const [task_record, setTaskRecord] = useState({
    task_log: [],
    today: 0,
    total: 0,
    important: 0,
    scheduled: 0
  });
  const [inboxDay, setInboxDay] = useState("today");
  const [notes, setNotes] = useState([]);

  const [recordState, setRecordState] = useState(null);
  const [selected_file, SetSelectedFile] = useState("");
  const [selected_mms_custom, SetSelectedMmsCustom] = useState("");
  const [selected_mms_default, SetSelectedMmsDefault] = useState("");
  const [file_type_validate, SetFileTypeValidate] = useState("");
  const [file_type, setFileType] = useState("template-section");
  const [audioData, setAudioData] = useState("");
  const [clearFile, setClearFile] = useState(true);

  const [verifiedFromEmail, setVerfiedFromEmail] = useState([]);
  const [verifiedFromEmailId, setVerfiedFromEmailId] = useState("");
  const [verifiedFromName, setVerfiedFromName] = useState("");
  const [defaultFromEmail, SetDefaultFromEmail] = useState("");

  let this_form = useRef(null);
  const paramsters = {
    token: callToken,
    from_number: uInfo().outbound_phone,
    phoneno: phoneno,
    record: "false",
    client_id: info.client_id,
    coach_id: coach_id,
  };
  let history = useHistory();

  const childClientRef = useRef();
  useEffect(() => {
    let t1 = new moment();
    let month_year = t1.format("YYYY-MM");
    getData();
    // getActivity("3");
    getCompanyClients();
    // getInboxActivity();
    // getTag();
    getActivity("all");
    getTodoList();
    getMyCoachLog();
    getPipeline();
    getClientPipeline();
    getVerifiedFromEmail();
  }, []);

  const getVerifiedFromEmail = () => {
    fetch(`${ApiUrl}` + "verify/email/list", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setVerfiedFromEmail(response.data.records);
          var verify_rec = response.data.records && response.data.records.filter(item => item.is_default === 1);
          console.log(verify_rec, 'verify_rec')
          SetDefaultFromEmail(verify_rec);
          setVerfiedFromEmailId(verify_rec && verify_rec[0].setting_id);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  };
  
  const changeHandler = (e) => {
    if (e.target.files[0].size < 5036070 && e.target.files[0].type.match('image.*')) {
      let selected_file = [];
      var reader = new FileReader();
      reader.onload = () => {
        selected_file.push(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      SetSelectedMmsCustom(selected_file);
    } else {
      toast('Invalid file', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

    }
  };

  const handleClearFile = () => {
    setClearFile(false);
    setTimeout(() => {
      setClearFile(true);
    }, 100);
  };
  const NumberWithCommas = (x) => {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const handleTodoSubmit = async (event, values) => {
    values.client_id = info.client_id;
    fetch(`${ApiUrl}` + "add/todo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setTodoModal(false);
          getTodoList();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const handleGoalsSubmit = async (event, values) => {

    fetch(`${ApiUrl}` + "edit/user/goals/" + user_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setGoalsModal(false);
          getMyCoachLog();
          getPipelineStats(pipeline_status_month, current_pipeline_id);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const getPipeline = () => {

    fetch(`${ApiUrl}` + "get/master/pipeline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPipelineList(response.data.records);
          setCurrentPipelineId(response.data.current_pipeline.pipeline_id);
          let t1 = new moment();
          let month_year = t1.format("YYYY-MM");
          getPipelineStats(month_year, response.data.current_pipeline.pipeline_id);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getPipelineStats = (year_month, pipeline_id) => {
    setPipelineStatusMonth(year_month);
    fetch(`${ApiUrl}` + "dashboard/dealstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'year_month': year_month, 'pipeline_id': pipeline_id, 'user_id': user_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPipelineStats(response.data.record);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getTodoList = () => {
    fetch(`${ApiUrl}` + "get/todo/" + client_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setTodoLists(response.data.todoTists);
        } else {

        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const todoRemove = (id) => {
    // swal({
    //   title: "Are you sure?",
    //   text: "You want to remove this action",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // })
    //   .then((confirmation) => {
    //     if (confirmation) {
    fetch(`${ApiUrl}` + "todo/delete/" + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          getTodoList();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {

        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
    //   }
    // });
  };

  const todoComplete = (id) => {
    // swal({
    //   title: "Are you sure?",
    //   text: "You want to mark complete",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // })
    //   .then((confirmation) => {
    //     if (confirmation) {
    fetch(`${ApiUrl}` + "todo/complete/" + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          getTodoList();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
    //   }
    // });
  };

  const getMyCoachLog = () => {
    //console.log(user_id, 'user_id')
    fetch(`${ApiUrl}` + "get/mycoach/log", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'client_id': client_id, 'user_id': user_id, 'coach_id': coach_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data.records.current_month_calculations.revenue_goals.outbound_call,'setCampaignStats')
          setCoachStatsMonthly(response.data.records.current_month_calculations);
          setCoachStatsYearly(response.data.records.current_year_calculations);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const download = (e) => {
    fetch(
      "https://cors-anywhere.herokuapp.com/" +
      AttachementsUrl +
      "user_" +
      coach_id +
      "/attachment/" +
      selected_mms_default,
      {
        method: "GET",
        headers: {},
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInboxActivity = (type = 'all', day = inboxDay) => {
    fetch(`${ApiUrl}` + "get/business/inbox", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ day: day, 'type': type, 'client_id': client_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setInboxActivity(response.data.records);
        } else {

        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getInvoiceCurrency = (code) => {
    let currencysymbol = currencies[code];
    if (currencysymbol) {
      return currencysymbol.symbol;
    }
    return '$';
  }
  const getTaskStatistics = (task_interval = 'today', client_id = 0) => {
    fetch(`${ApiUrl}` + "dashboard/task/statistics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_interval: task_interval, client_id: client_id == 0 ? info.client_id : client_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data.record, 'recccc')
          setTaskRecord(response.data.record);
        } else {

        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const maintimezones = [
    { id: "Asia", label: "Asia" },
    { id: "America", label: "America" },
    { id: "Australia", label: "Australia" },
    { id: "Europe", label: "Europe" },
    { id: "Indian", label: "Indian" },
  ];

  function settimezone(e) {
    setMainTimeZone(e);
    if (e.label == "Australia") {
      setnewzone(Australia);
    } else if (e.label == "America") {
      setnewzone(America);
    } else if (e.label == "Asia") {
      setnewzone(Asia);
    } else if (e.label == "Europe") {
      setnewzone(Europe);
    } else if (e.label == "Indian") {
      setnewzone(Indian);
    }
    setTimeZone({});
    // console.log(timeZone_data,'Zone Change');
  }


  const handleAttachementRequestSubmit = async (event, values) => {
    values.client_id = info.client_id;
    values.email = info.email;
    fetch(`${ApiUrl}` + "send/attachment/request", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        this_form && this_form.reset();
        if (data.status === true) {
          setSubject("");
          setSmsBody("");
          setToken('');
          //getActivity(tab);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleAtSubmit = async (event, values) => {
    // values.client_id = info.client_id;
    // values.attachment_file = attachmentFile;
    //setLoader(false);
    const formData = new FormData();
    formData.append("client_id", info.client_id);
    formData.append("attachment_file", attachmentFile);
    formData.append("file_title", values.file_title);
    formData.append("type", uInfo().type);
    fetch(`${ApiUrl}` + "client/attachment", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "Add new attach");
        if (data.status === true) {
          this_form && this_form.reset();
          //getActivity(tab);
          getCompanyClients();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        //setLoader(true);

      })

      .catch((error) => {
        //setLoader(true);

        console.error("Error:", error);
      });
  };

  const getCompanyClients = () => {
    fetch(`${ApiUrl}` + "get/client/information/" + client_id + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ deal_type: 'training' })
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setClients(response.data.records);
          setpage3Class("slide-out2");
          setAttachment(response.data.attachments);
          setCoachPartners(response.data.coachPartners);
          setUpPermission(response.data.coachPermission);
          setTraining(response.data.training);

          var tokens_group = [
            {
              label: "Lead Default Field Tokens",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Lead Custom Field Tokens",
              options: response.data.tokens.custom_fields,
            },
            {
              label: "User Tokens",
              options: response.data.tokens.users_token,
            },
          ];
          setTokens(tokens_group);
          setInvoices(response.data.invoices);
        } else {

        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getClientPipeline = () => {
    fetch(`${ApiUrl}` + "get/client/pipeline/" + client_id + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ deal_type: 'training' }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setPipelineStatus(response.data.allPipelines);
          // if (response.data.allPipelines.length > 0) {
          //   setPipeline(response.data.allPipelines[0]);
          // }
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleExistingClient = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let companyIds = [];
    for (let [key, value] of formData.entries()) {
      companyIds.push(value);
    }

    if (companyIds.length > 0) {
      fetch(`${ApiUrl}` + "update/company/client", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({
          clients: info.client_id,
          comapny_id: companyIds[0],
        }),
      })
        .then((response) => response.json())

        .then((response) => {

          if (response.status === true) {
            setpage3Class("slide-out2");
            setSearchClient("");
            setExisting([]);
            getCompanyClients();

          } else {

          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const getData = () => {
    fetch(`${ApiUrl}` + "lead/details/" + client_id + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          var client_info = response.data.records;
          setInfo(client_info);
          var phone_number = client_info.phone
            ? client_info.country_code + client_info.phone
            : client_info.country_code + client_info.mobile;
          setphoneno(phone_number);
          setGroups(response.data.groups_data);
          setTags(response.data.tag_data);
          setAssignCallStatus(response.data.call_status);
          getTaskStatistics("today", client_info.client_id);

        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getReminderList = (meeting_id) => {
    //setLoader(false);
    fetch(`${ApiUrl}` + "get/meeting/reminder/" + meeting_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setReminders(data.data.records);
          setReminderModal(true);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        //setLoader(true);
      })
      .catch((error) => {
        //setLoader(true);
        console.error("Error:", error);
      });
  }
  const changeMeetingStatus = (meeting_id, status) => {
    swal({
      title: "Are you sure?",
      text: "You want to mark this " + status,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "update/status/appointment", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ 'meeting_id': meeting_id, 'status': status }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === true) {
                //getActivity('12');
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  }




  const removeGroupLead = () => {
    fetch(
      `${ApiUrl}` + "remove/group/lead/" + client_id + "/" + index + "/" + coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setGroupModal(false);
          getData();
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeTagLead = () => {
    fetch(
      `${ApiUrl}` + "remove/tag/lead/" + client_id + "/" + index + "/" + coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setTagModal(false);
          getData();

        } else {

        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getTag = () => {
    fetch(`${ApiUrl}` + "get/group/tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ manager_id: coach_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setAllTags(response.data.tags);
        } else {

        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeGroupModal = (i) => {
    setGroupModal(!groupModal);
    setIndex(i);
  };
  const removeTagModal = (i) => {
    setTagModal(!tagModal);
    setIndex(i);
  };
  const generateClientLogin = () => {
    //setLoader(false);
    fetch(`${ApiUrl}` + "generate/client/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ client_id: info.client_id, coach_id: info.coach_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          getData();
        } else {

        }
        //setLoader(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const sendLoginDetails = async (event, values) => {
    //setLoader(false);
    values.client_id = info.client_id;
    values.coach_id = info.coach_id;
    fetch(`${ApiUrl}` + "send/client/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {

        } else {

        }
        //setLoader(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  useEffect(() => {
    if (searchClient != "") {
      fetch(`${ApiUrl}` + "get/companies", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ filter_value: searchClient }),
      })
        .then((response) => response.json())

        .then((response) => {

          if (response.status === true) {
            setExisting(response.data.records);
            // this.setState({
            //   records: response.data.records,
            // });
          } else {

          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setExisting([]);
    }
  }, [searchClient]);


  const handleSubmitSmsSend = async (event, values) => {
    values.client_id = info.client_id;
    values.type = 'sms';
    values.body = smsBody;

    //setLoader(false);
    setSmsLoader(false);

    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          //getActivity("8");
          setDynamicModal(false);
          setDynamicModalName("sms");
          setSmsBody("");
          SetSelectedMmsCustom("");
          SetSelectedMmsDefault("");
          setToken("");
          setSubject("");
          setHtml("");
          setTemplateId(0);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        //setLoader(true);
        setSmsLoader(true);
      })

      .catch((error) => {
        //setLoader(true);
        setSmsLoader(true);

        console.error("Error:", error);
      });
  }

  const handleSubmit = async (event, values) => {
    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();
    values.body = smsBody;
    values.selected_mms_default = selected_mms_default;
    values.selected_mms_custom = selected_mms_custom;
    //setLoader(false);

    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (tab == "5") {
            //getActivity("5");
          } else if (tab == "12") {
            //getActivity("12");
          } else {
            //getActivity("3");
          }
          setDynamicModal(false);

          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
          setTemplateId(0);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

        //setLoader(true);

      })

      .catch((error) => {
        //setLoader(true);
        console.error("Error:", error);
      });
  };

  const handleDealSubmit = async (pipeline_id, status_id, client_id, final_pipeline) => {
    fetch(`${ApiUrl}` + "assinged_pipeline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        'pipeline_id': pipeline_id,
        'status_id': status_id,
        'client_id': client_id,
        'final_pipeline': final_pipeline
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          getClientPipeline();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleEmailSubmit = async (event, values) => {
    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();
    values.setting_id = verifiedFromEmailId;
    if (html != "") {
      let body = document.getElementById("custom-template").innerHTML;
      values.body = body;
    }else{
      values.body = smsBody;
    }

    fetch(`${ApiUrl}` + "company/email/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          //getActivity(tab);
          setEmailModal(false);
          setDynamicModalName("");
          setHtml('');
          setSmsBody("");
          SetSelectedMmsCustom("");
          SetSelectedMmsDefault("");
          setToken("");
          setSubject("");


          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCallSid = (call_sid) => {

  }

  const getActivity = (interval) => {
    //setLoader(false);
    fetch(`${ApiUrl}` + "get/all/activity/" + client_id + "/" + interval, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setActivity(response.data.records);

        } else {
          //setLoader(true);


        }
      })

      .catch((error) => {
        //setLoader(true);

        console.error("Error:", error);
      });
  };

  function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const getEmailTemplate = () => {
    fetch(`${ApiUrl}` + "get/email/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);
          var tokens_group = [
            {
              label: "Lead Default Field Tokens",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Lead Custom Field Tokens",
              options: response.data.tokens.custom_fields,
            },
            {
              label: "User Tokens",
              options: response.data.tokens.users_token,
            },
          ];

          setTokens(tokens_group);
          setEmailSetting(response.data.phoneNumbers);
        } else {

        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getSmsTemplate = () => {
    fetch(`${ApiUrl}` + "get/sms/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          var templates = groupArrayOfObjects(response.data.templates, "type");
          var templates_group = [
            { label: "SMS", options: templates.SMS },
            { label: "MMS", options: templates.MMS },
          ];
          setSmsTemplates(templates_group);

          var tokens_group = [
            {
              label: "Lead Default Field Tokens",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Lead Custom Field Tokens",
              options: response.data.tokens.custom_fields,
            },
            {
              label: "User Tokens",
              options: response.data.tokens.users_token,
            },
          ];

          setTokens(tokens_group);
          setPhoneNumbers(response.data.phoneNumbers);
        } else {

        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getRVMTemplate = () => {
    fetch(`${ApiUrl}` + "get/rvm/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);

          setPhoneNumbers(response.data.phoneNumbers);
        } else {

        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getEmailTemplateHtml = (id) => {
    fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setHtml(response.theme);
        // localStorage.setItem("theme", response.theme);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const cancelTask = () => {
    setpage4Class("slide-out2");
    //getActivity("6");
  };
  const cancelAssessment = () => {
    setpage9Class("slide-out2");
    setpage10Class("slide-out2");
    //getActivity("13");
  };
  const completeTask = () => {
    fetch(`${ApiUrl}` + "update/task/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_id: taskID, client_id: info.client_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //getActivity("6");
          setTaskCompleteModal(false);
        } else {

        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOutCall = () => {
    fetch(`${ApiUrl}` + "voice/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ identity: encodeURIComponent(identity) }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCallToken(response.data.token);
          setClicked(true);
        } else {

        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeAttachement = async (id, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          const formData = new FormData();
          formData.append("id", id);
          formData.append("index", index);
          formData.append("client_id", info.client_id);
          fetch(`${ApiUrl}` + "remove/attachments", {
            method: "POST",
            headers: {
              Authorization: `Bearer ` + uToken(),
            },
            body: formData,
          })
            .then((response) => response.json())

            .then((data) => {
              if (data.status === true) {
                //getActivity(tab);
                getCompanyClients();
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })

            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };


  const handleTrainingChange = (selectedTags) => {
    let tags = [];
    selectedTags.map(tag => {
      tags.push(tag.cat_id);
    });
    setTraining(prevTraining => ({
      ...prevTraining,
      userTraining: tags
    }));

    fetch(`${ApiUrl}` + "trainings/subcategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'cat_id': tags }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setTraining(prevTraining => ({
            ...prevTraining,
            subCategories: data.data.records
          }));

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });


  }
  const handleResourceChange = (selectedTags) => {
    let tags = [];
    selectedTags.map(tag => {
      tags.push(tag.cat_id);
    });
    setTraining(prevTraining => ({
      ...prevTraining,
      userResource: tags
    }));
  }

  const getNotes = () => {
    fetch(`${ApiUrl}` + "get/activity/" + info.client_id + "/5", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setNotes(response.data.records)

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };




  const assignTraining = async (event, values) => {

    if (training.userTraining.length > 0) {
      let cat_id = training.userTraining.toString();
      let resource_id = training.userResource.toString();



      fetch(`${ApiUrl}` + "assign/training/user/" + info.linked_user_id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ 'cat_id': cat_id, 'resource_id': resource_id, 'user_id': info.linked_user_id, 'client_id': info.client_id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === true) {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
            setpage11Class("slide-out2");
            //getActivity("3");
          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }
  const start = () => {
    console.log(RecordState.START, 'DEBUGGINGG');
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };
  //audioData contains blob and blobUrl
  const onStop = (audioData) => {
    setAudioData(audioData);
    let selected_file = [];
    console.log("audioData", audioData);
    var reader = new FileReader();
    reader.readAsDataURL(audioData.blob);
    reader.onloadend = function () {
      selected_file.push(reader.result);
    }


    SetSelectedFile(selected_file);
    SetFileTypeValidate("wav");
  };
  const onChangefileTypeAction = (value) => {
    var x = document.getElementById("record-section");
    var y = document.getElementById("template-section");
    if (value == "record-section") {
      setFileType("record-section");
      x.style.display = "block";
      y.style.display = "none";
    } else {
      setFileType("template-section");
      x.style.display = "none";
      y.style.display = "block";
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title> {page_name} </title>
      </Helmet>
      <ToastContainer />
      <Row className="CompanyDetail ClientDialer ClientProfileDetails NewCoachDialer">
        <Col md={3}>
          <div className="NoShadow custom-scroll LeftSideUser">
            <div className="user-profile-pic-area">
              <CardHeader>
                <div className="top-heading WithDrop">
                  <Link className="text-primary" to={"/users/coach"}>
                    <i className="fas fa-angle-left pr-5"> </i>Coaches
                  </Link>
                  <p className="text-white">{info.first_name} {info.last_name}</p>
                </div>
              </CardHeader>
              <Card className="UserProfileBG">
                <CardBody className="px-0">
                  <div className="company p-3">
                    <button
                      className="btn btn-link btn-md pull-right"
                      onClick={() => {
                        setpage2Class("slide-in");
                        childRef.current.getAlert();
                      }}
                    >
                      <i className="fas fa-pencil-alt"> </i>
                    </button>

                    <div className="company-info">
                      {
                        info.coach_login == 'yes' ? (
                          <div className="OnlineOffline">
                            <span className="OnlineIcon"></span>
                            Online
                          </div>
                        ) : (
                          <div className="OnlineOffline">
                            <span className="OfflineIcon OnlineIcon"></span>
                            Offline
                          </div>
                        )
                      }
                      <div className="logo">
                        <Avatar
                          name={`${info.first_name} ${info.last_name}`}
                          size="76"
                          round={true}
                        />
                      </div>
                      <div className="info">
                        <h3></h3>
                        {info.website != "" && info.website != null && (
                          <div className="url">
                            <a
                              href={`${info.website}`}
                              target="_blank"
                              className="pr-5"
                            >
                              {info.website}

                              <i className="fas fa-external-link-alt"> </i>
                            </a>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="overlay-example">
                                  Copy domain to clipboard
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-link btn-md"
                                onClick={() => {
                                  navigator.clipboard.writeText(`${info.website}`);
                                }}
                              >
                                <i className="fas fa-copy"> </i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        )}

                        {info.phone != "" && info.mobile != "" && info.phone != null && info.mobile != null && (
                          <>
                            <Link to={"#"}>

                              {convertPhoneFormat(info.phone ? info.phone_extension + info.phone
                                : info.country_code + info.mobile)}
                            </Link>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="overlay-example">
                                  Copy phone number to clipboard assad
                                </Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(`${convertPhoneFormat(info.phone ? info.phone_extension + info.phone
                                    : info.country_code + info.mobile)}`);
                                }}
                                className="btn btn-link btn-md"
                              >
                                <i className="fas fa-copy"> </i>
                              </button>
                            </OverlayTrigger>
                          </>
                        )}
                        <div className="compant-action-btn">
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="overlay-example"> Create a note </Tooltip>
                            }
                          >
                            <button
                              onClick={() => {
                                getNotes();
                                setDynamicModal(true);
                                setDynamicModalName("Note");
                              }}
                              className="btn btn-primary btn-md"
                            >
                              <i className="fas fa-edit"> </i>
                            </button>
                          </OverlayTrigger>
                          {(info.mobile != '' || info.phone != '') && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example"> Send Sms </Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("sms");
                                  getSmsTemplate();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-comment"> </i>
                              </button>
                            </OverlayTrigger>
                          )}
                          {info.email != "" && info.email != null && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example">Create an email</Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setEmailModal(true);
                                  setDynamicModalName("email");
                                  getEmailTemplate();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-envelope"> </i>
                              </button>
                            </OverlayTrigger>
                          )}
                          {(info.mobile != '' || info.phone != '') && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example">Make a phone call</Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("call");
                                  handleOutCall();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-phone-alt"> </i>
                              </button>
                            </OverlayTrigger>
                          )}
                          {/* <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip id="overlay-example"> Task </Tooltip>}
                          >
                            <button
                              onClick={() => {
                                setpage4Class("slide-in");
                              }}
                              className="btn btn-primary btn-md"
                            >
                              <i className="fas fa-tasks"> </i>
                            </button>
                          </OverlayTrigger> */}
                          {(info.mobile != '' || info.phone != '') && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example"> Send RVM </Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("rvm");
                                  getRVMTemplate();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-microphone"> </i>
                              </button>
                            </OverlayTrigger>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="user-profile-pic-area">

              <div className="action-coach-trianing text-center d-none 4">

                {
                  info.already_coach == 'No' && (
                    <>
                      {(UpPermission.permission == 1 && UpPermission.remaining_coach >= 1) && (
                        info.email != '' && (info.phone != '' || info.mobile != '') && info.linked_user_id != 0 ?
                          <button
                            onClick={() => props.history.push(`/invoice/add/${bcrypt(info.client_id)}/coach`)}
                            className="btn btn-primary btn-sm mt-2 mb-2"
                          >
                            Convert To Coach
                          </button>
                          :
                          <button
                            onClick={() => swal({
                              title: "Convert to coach",
                              text: `To convert into a coach, a client (phone or mobile), email, and {client login} are compulsory.`,
                              icon: "warning",
                              dangerMode: true,
                            })}
                            className="btn btn-primary btn-sm mt-2 mb-2"
                          >
                            Convert To Coach
                          </button>

                      )}
                    </>
                  )
                }

              </div>

              <div className="AboutCard 5">
                <div className="details">
                  <Accordion className="border-0">

                    {
                      info.linked_user_id == "0" ? (
                        <button
                          className="btn btn-sm btn-primary m-2"
                          onClick={() => {
                            generateClientLogin();
                          }}
                        >Generate Client Account <i className="fas fa-sign-in-alt" aria-hidden="true"></i>
                        </button>
                      ) : (
                        <Accordion.Item eventKey="0">
                          <Accordion.Header> Login Credentials</Accordion.Header>
                          <Accordion.Body className="pt-3">
                            <div className="position-relative">
                              <AvForm
                                onValidSubmit={sendLoginDetails}

                                className="needs-validation"
                              >
                                <div className="info border-bottom pb-2">
                                  <div className="info info pt-2 pb-2 border-bottom">
                                    <AvField
                                      className="form-control"
                                      type="textarea"
                                      rows={4}
                                      name="login_details"
                                      value={`Please find below the PROFITCOACH login details. \nLogin URL: ${HostUrl} \nLogin Email: ${info.link_user_email} \nLogin Password: ${info.link_user_password}`}
                                    />
                                  </div>
                                  <div className="info info pt-2 pb-2 border-bottom">
                                    <button
                                      className="btn btn-link btn-md"
                                      type="submit"
                                    >
                                      <i className="fa fa-paper-plane"></i> Send
                                    </button>
                                    <button
                                      className="btn btn-link btn-md"
                                      type="button"
                                      onClick={() => {
                                        navigator.clipboard.writeText(` Login URL: ${HostUrl} \nLogin Email: ${info.link_user_email}  \nLogin Password: ${info.link_user_password}`);
                                      }}
                                    >
                                      <i className="fas fa-copy"></i> Copy
                                    </button>
                                  </div>
                                </div>
                              </AvForm>
                              <UpdateCredential email={info.link_user_email} user_id={info.linked_user_id} refreshScreen={getData} />
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                    <Accordion.Item eventKey="1">
                      <Accordion.Header> About This Coach</Accordion.Header>
                      <Accordion.Body className="pt-3">
                        <div className="info info pb-2 border-bottom">
                          <span> ID </span> <p> {info.client_id} </p>
                        </div>
                        <div className="info info pt-2 pb-2 border-bottom">
                          <span> Name </span> <p> {info.first_name} {info.last_name} </p>
                        </div>
                        <div className="info info  pt-2 pb-2 border-bottom">
                          <span> Domain </span> <p> {info.website} </p>
                        </div>
                        <div className="info info  pt-2 pb-2 border-bottom">
                          <span> Email </span> <p> {info.email} </p>
                        </div>
                        <div className="info info  pt-2 pb-2 border-bottom">
                          <span> Phone </span> <p> {convertPhoneFormat(info.phone
                            ? info.phone_extension + info.phone
                            : info.country_code + info.mobile)} </p>
                        </div>
                        <div className="info info  pt-2 pb-2 border-bottom">
                          <span> Address </span> <p> {info.address} </p>
                        </div>
                        <div className="info info  pt-2 pb-2 border-bottom">
                          <span> City </span> <p> {info.city} </p>
                        </div>
                        <div className="info info  pt-2">
                          <span>State </span> <p> {info.state} </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={tagModal}
            fade={false}
            toggle={() => removeTagModal()}
          >
            <ModalBody>
              <div className="warning-box text-center">
                <span className="warning">
                  <i className="mdi mdi-alert-circle"> </i>
                </span>
                <h3> Are you sure ? </h3>
                <p> You want to remove this Tag ? </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="info"
                onClick={() => removeTagLead()}
              >
                Yes, Remove it
              </Button>
              <Button
                color="danger"
                onClick={() => removeTagModal()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <div>
            <div className={`page2 ${page2Class} custom-scroll`}>
              <div>
                <div className="modal-head top-heading">
                  <h2> Edit Detail </h2>
                  <button
                    onClick={() => {
                      setpage2Class("slide-out");
                      // getActivity("3");
                    }}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>

                <CreateUpdate
                  ref={childRef}
                  getData={getData}
                  id={info.client_id}
                  coach_id={info.coach_id}
                  from="companyDetails"
                />
              </div>
            </div>
          </div>

        </Col>
        <Col md={7} className="px-1 pr-3">
          <Row>
            <Col lg={3} className="px-1">
              <div className="CoachRevnewCount p-2">
                <div className="RevenueCoach">Total YTD Revenue<br></br>&nbsp;</div>
                <div className="CoachRevCount">${coachStatsYearly.coaching_revenue}</div>
              </div>
            </Col>
            <Col lg={3} className="px-1">
              <div className="CoachRevnewCount p-2">
                <div className="RevenueCoach">Monthly Recurring Revenue</div>
                <div className="CoachRevCount">${coachStatsMonthly.coaching_revenue}</div>
              </div>
            </Col>
            <Col lg={2} className="px-1"><div className="CoachRevnewCount p-2">
              <div className="RevenueCoach">Total Client Count</div>
              <div className="CoachRevCount">{coachStatsMonthly.total_clients}</div>
            </div></Col>
            <Col lg={2} className="px-1"><div className={`CoachRevnewCount p-2 code-${coachStatsMonthly.outbound_goals_thumb}`}>
              <div className="RevenueCoach">Campaign Activity Rating</div>
              <div className="CoachRevCount"><i className={`ion ion-md-thumbs-${coachStatsMonthly.outbound_goals_thumb_direction}`}></i></div>
            </div>
            </Col>
            <Col lg={2} className="px-1"><div className={`CoachRevnewCount p-2 code-${coachStatsMonthly.revenue_goals_thumb}`}>
              <div className="RevenueCoach">Financial Success Rating</div>
              <div className="CoachRevCount"><i className={`ion ion-md-thumbs-${coachStatsMonthly.revenue_goals_thumb_direction}`}></i></div>
            </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={4} className="px-1">
              <Card className="mt-3 boxescard CampaignStats">
                <CardBody>
                  <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Campaign Stats</h5>
                  </section>
                  <button
                    onClick={() => {
                      setGoalsModal(true);
                      setGoalsArray([
                        ["outbound_call", "Phone Calls Goal", coachStatsMonthly.revenue_goals.outbound_call],
                        ["outbound_email", "Email Goal", coachStatsMonthly.revenue_goals.outbound_email],
                        ["outbound_sms", "SMS Goal", coachStatsMonthly.revenue_goals.outbound_sms],
                        ["calendar_events", "Calendar Events Goal", coachStatsMonthly.revenue_goals.calendar_events],
                      ]);
                    }}
                    title="Edit Goals"
                    className="btn btn-link border-0 float-end pb-0"><i className="fas fa-edit"></i></button>
                  <div className="NewDailerCampaign" style={{ width: "100%" }}>
                    <div className="ProgressDashboard OutboundGoals">
                      <div className="BarProgress">
                        <i className="ion ion-md-call"></i><div className="ProgressLabel"><label>Total Calls</label><Progress color="primary" title={"Outbound call this month: " + coachStatsMonthly.out_call} value={coachStatsMonthly.out_call_percentage}>
                          {coachStatsMonthly.out_call} <div className="TotalProgress">{NumberWithCommas(coachStatsMonthly.revenue_goals.outbound_call)}</div>
                        </Progress></div>
                      </div>
                    </div>
                    <div className="ProgressDashboard OutboundGoals mt-2">
                      <div className="BarProgress">
                        <i className="ion ion-md-mail"></i><div className="ProgressLabel"><label>Total Emails</label><Progress color="primary" title={"Outbound email this month: " + coachStatsMonthly.out_email} value={coachStatsMonthly.out_email_percentage}>
                          {coachStatsMonthly.out_email} <div className="TotalProgress">{NumberWithCommas(coachStatsMonthly.revenue_goals.outbound_email)}</div>
                        </Progress></div>
                      </div>
                    </div>
                    <div className="ProgressDashboard OutboundGoals mt-2">
                      <div className="BarProgress">
                        <i className="mdi mdi-comment"></i><div className="ProgressLabel"><label>Total SMS</label><Progress color="primary" title={"Outbound SMS this month: " + coachStatsMonthly.out_sms} value={coachStatsMonthly.out_sms_percentage}>
                          {coachStatsMonthly.out_sms} <div className="TotalProgress">{NumberWithCommas(coachStatsMonthly.revenue_goals.outbound_sms)}</div>
                        </Progress></div>
                      </div>
                    </div>
                    <div className="ProgressDashboard OutboundGoals mt-2">
                      <div className="BarProgress">
                        <i className="mdi mdi-calendar-month-outline"></i><div className="ProgressLabel"><label>Total Events</label><Progress color="primary" title={"Calendar Events this month: " + coachStatsMonthly.calendar_events} value={coachStatsMonthly.calendar_events_percentage}>
                          {coachStatsMonthly.calendar_events} <div className="TotalProgress">{NumberWithCommas(coachStatsMonthly.revenue_goals.calendar_events)}</div>
                        </Progress></div>
                      </div>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
            <Col lg={4} className="px-1">
              <Card className="mt-3 boxescard">
                <CardBody>
                  <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Onboarding / Training</h5>
                  </section>
                  <div className="Breakdown PathwayProgression">
                    <div className="ProfileBreakDown">
                      {
                        pipelineStatus.map((pstatus, i) => {
                          if (pstatus.is_complete == 1) {
                            return (
                              <div className="ROiPercents mb-2">
                                <div className="revenue pull-left"><p>{pstatus.name}</p></div>
                                <div className="SectionHeader RoiPage ">
                                  <div className="Percents">
                                    <h4 className="GreenBG text-center FullBG" style={{ background: '#f4bc33', width: `100%` }}>
                                      {pstatus.final_pipeline == 1 ? `${pstatus.current_status}` : 'Complete'}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )
                          } else {
                            return (
                              <div className="ROiPercents mb-2">
                                <div className="revenue pull-left"><p>{pstatus.name}</p></div>
                                <div className="SectionHeader RoiPage ">
                                  <div className="Percents">
                                    <h4 className="GrayBG justify-content-center" style={{ background: '#041934', width: `100%` }}>
                                      <span className="pull-left">Next</span>
                                    </h4>
                                    <h4 className="GreenBG text-end" style={{ background: '#008037', width: `0%` }}>
                                      000
                                    </h4>
                                  </div>

                                </div>
                              </div>
                            )
                          }

                        })
                      }

                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} className="px-1">
              <Card className="mt-3  boxescard ListAction">
                <CardBody>
                  <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">ACTION LIST
                    </h5>
                  </section>
                  <div className="text-right mb-2">
                    <button onClick={() => setTodoModal(true)} className="btn btn-link" ><i className="fas fa-plus"></i></button>
                  </div>

                  <ul className="list-group todo-list">
                    {
                      todoLists.map((list, i) => {
                        return (
                          <li className="mb-2" key={i}>
                            <div className="d-flex justify-content-between todotask">
                              <div className="list-todo">
                                {
                                  list.status == 1 ?
                                    <button disabled className="btn btn-outline-success boder-green rounded-100"><i className="fas fa-check"></i></button>
                                    :
                                    <button title="Mark as complete" onClick={() => todoComplete(list.id)} className="bwaves-effect waves-light btn btn-outline-dark rounded-100"></button>
                                }

                                <p className="notes">{list.description}</p>
                              </div>

                              {/* {
                                list.status == 1 && ( */}
                              <button title="Remove action list" onClick={() => todoRemove(list.id)} className="btn btn-link Remove-Action"><i className="fas fa-trash-alt"></i></button>
                              {/* )
                              } */}
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col lg={4} className="px-1">
              <Card className="VariousCalls mb-2 pb-3 mt-3  boxescard ActivityRecent">
                <CardBody>
                  <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Recent Activity</h5></section>
                  <div className="d-flex justify-content-between">
                    <div className="MonthlyDropDown mb-2">
                    </div>
                    <div className="MonthlyDropDown mb-2">
                      <select onChange={(e) => { getActivity('weekly') }} className="form-control">
                        <option value={'all'}>All</option>
                        <option value={'weekly'}>This Week</option>
                        <option value={'monthly'}>Monthly</option>
                      </select>
                    </div>
                  </div>
                  <ul className="list-group todo-list mt-2">
                    {/* <li className="mb-2">
                      <div className="d-flex justify-content-between todotask">
                        <div className="list-todo activityLists">
                          <div className="ActivityIcon"><img src={Login}></img></div>
                          <p className="notes">Hello thois is test</p>
                          <div className="ActivityDate pull-right">9/24/2022</div>
                        </div>
                      </div>
                    </li>
                    <li className="mb-2">
                      <div className="d-flex justify-content-between todotask">
                        <div className="list-todo activityLists">
                          <div className="ActivityIcon"><img src={Info}></img></div>
                          <p className="notes">Hello thois is test</p>
                          <div className="ActivityDate pull-right">9/24/2022</div>
                        </div>
                      </div>
                    </li> */}
                    {activity.map((row, i) => {
                      return (
                        <li className="mb-2">
                          <div className="d-flex justify-content-between todotask">
                            <div className="list-todo activityLists d-flex">
                              <div className="ActivityIcon"><img src={Assesments}></img></div>
                              <div>
                                <p className="notes">{row.activity}</p>
                                <div className="ActivityDate pull-right">{row.created_at}</div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}

                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} className="px-1 Statuses clientProfileDetail FinacialSuccess">
              <Card className="VariousCalls TotalRecord MyScheduleMain mt-3 boxescard calanderbox ">
                <CardBody className="RecentRecord MyTasks">
                  <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Financial Success</h5>
                  </section>
                  <button
                    onClick={() => {
                      setGoalsModal(true);
                      setGoalsArray([
                        ["assessment_completed", "Assessments Completed Goal", coachStatsMonthly.revenue_goals.assessment_completed],
                        ["monthly_revenue", "Monthly Contracted Revenue Goal", coachStatsMonthly.revenue_goals.monthly_revenue],
                        ["total_clients", "Total Clients", coachStatsMonthly.revenue_goals.total_clients],
                        ["lmc_monthly_revenue", "Monthly Academy Revenue", coachStatsMonthly.revenue_goals.lmc_monthly_revenue],
                        ["lmc_total_subscriptions", "Total Academy Subscribers", coachStatsMonthly.revenue_goals.lmc_total_subscriptions],
                      ]);
                    }}
                    title="Edit Goals"
                    className="btn btn-link border-0 float-end pb-0"><i className="fas fa-edit"></i></button>
                  <div className="FinacialLists" style={{ width: "100%" }}>
                    <div className="ProgressDashboard OutboundGoals">
                      <div className="BarProgress">
                        <div className="FinacialIcon"><img src={PfIcon}></img></div>
                        <div className="ProgressLabel"><label>Assessments Completed</label><Progress title={"Assessments Completed: " + coachStatsMonthly.assessment_completed} color="primary" value={coachStatsMonthly.assessment_completed_percentage}>
                          {coachStatsMonthly.assessment_completed} <div className="TotalProgress"> {NumberWithCommas(coachStatsMonthly.revenue_goals.assessment_completed)}</div>
                        </Progress></div>
                      </div>
                    </div>
                    <div className="ProgressDashboard OutboundGoals mt-2">
                      <div className="BarProgress">
                        <i className="far fa-list-alt"></i><div className="ProgressLabel"><label>MONTHLY Contracted Revenue</label><Progress title={"Monthly Contracted Revenue: $" + coachStatsMonthly.coaching_revenue} color="primary" value={coachStatsMonthly.coaching_revenue_percentage}>
                          ${coachStatsMonthly.coaching_revenue} <div className="TotalProgress">${NumberWithCommas(coachStatsMonthly.revenue_goals.monthly_revenue)}</div>
                        </Progress>
                        </div>
                      </div>
                    </div>
                    <div className="ProgressDashboard OutboundGoals mt-2">
                      <div className="BarProgress">
                        <div className="FinacialIcon"><img src={Assesments}></img></div><div className="ProgressLabel"><label>Total Clients</label><Progress color="primary" title={"Total Clients: " + coachStatsMonthly.total_clients} value={coachStatsMonthly.total_clients_percentage}>
                          {coachStatsMonthly.total_clients} <div className="TotalProgress">{NumberWithCommas(coachStatsMonthly.revenue_goals.total_clients)}</div>
                        </Progress>
                        </div>
                      </div>
                    </div>
                    <div className="ProgressDashboard OutboundGoals mt-2">
                      <div className="BarProgress">
                        <i className="mdi mdi-currency-usd"></i><div className="ProgressLabel"><label>Monthly Academy Revenue</label><Progress title={"Monthly Academy Revenue: $" + coachStatsMonthly.academy_revenue} color="primary" value={coachStatsMonthly.academy_revenue_percentage}>
                          ${coachStatsMonthly.academy_revenue} <div className="TotalProgress">${NumberWithCommas(coachStatsMonthly.revenue_goals.lmc_monthly_revenue)}</div>
                        </Progress>
                        </div>
                      </div>
                    </div>
                    <div className="ProgressDashboard OutboundGoals mt-2">
                      <div className="BarProgress">
                        <i className="ion ion-md-thumbs-up"></i><div className="ProgressLabel"><label>Total Academy Subscribers</label><Progress title={"Total Academy Subscribers: " + coachStatsMonthly.academy_subscribers} color="primary" value={coachStatsMonthly.academy_subscribers_percentage}>
                          {coachStatsMonthly.academy_subscribers} <div className="TotalProgress">{NumberWithCommas(coachStatsMonthly.revenue_goals.lmc_total_subscriptions)}</div>
                        </Progress>
                        </div>
                      </div>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
            <Col lg={4} className="px-1">
              <Card className=" boxescard mt-3 calanderbox PipelineStats">
                <CardBody>
                  <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Pipeline Stats</h5>
                  </section>
                  <div className="StatusHeading">
                    <div className="MonthlyDropDown mb-2 float-start" style={{ width: "inherit" }}>
                      <select value={current_pipeline_id} onChange={(e) => { setCurrentPipelineId(e.target.value); getPipelineStats(pipeline_status_month, e.target.value) }} className="form-control">
                        {pipeline_list.length > 0 &&
                          pipeline_list.map((row, i) => {
                            return (
                              <option key={i} value={row.pipeline_id}>{row.label}</option>
                            )
                          }
                          )
                        }

                      </select>

                    </div>
                    <h4 className="card-title">
                      {/* <input value={pipeline_status_month} onChange={(e) => getPipelineStats(e.target.value, current_pipeline_id)} className="form-control" type={'month'} name="deal_month" /> */}
                      <button
                        onClick={() => {
                          setGoalsModal(true);
                          setGoalsArray([
                            ["pipeline_goal", "Pipeline Goal", coachStatsMonthly.revenue_goals.pipeline_goal],
                          ]);
                        }}
                        title="Edit Goals"
                        className="btn btn-link border-0 pb-0"><i className="fas fa-edit"></i>
                      </button>
                    </h4>

                  </div>

                  <div className="ProfilePipelineStats">
                    <div className="Campaigns px-3">
                      {pipeline_stats.length > 0 ? (
                        pipeline_stats.map((row, i) => {
                          return (
                            <div className="RevenueGoals mb-2" key={"deal" + i}>
                              <div className="ProgressDashboardCoaching">
                                <div className="">
                                  <label>{row.title}</label>
                                  <Progress color={`primary ${row.random_keys}`} value={row.stats_count_percentage} title={"Total Count: " + row.stats_count}>
                                    {row.stats_count} <div className="TotalProgress"> {row.goal}
                                    </div>
                                  </Progress>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="RevenueGoals no-record p-1" key={"deal1"}>
                          <div className="ProgressDashboardCoaching">
                            <div className="text-center">
                              <p>No record found</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <Card className="NoShadow custom-scroll FixedSidebar CoachDailer">
            <CardBody className="px-0">
              <div className="col-md-12 text-center third-column-meetings d-none">
                {
                  info.coach_login == 'yes' && info.linked_user_id != 0 ? (
                    <button
                      onClick={() => props.history.push(`/chat/${info.linked_user_id}/client`)}
                      // onClick={() => {
                      //   generateMetting();
                      // }}
                      className="btn btn-sm btn-primary pull-right m-2"
                    >
                      Start Meeting
                    </button>
                  ) : (
                    defaultCalendar != 0 && (
                      <button
                        onClick={() => {
                          setDynamicModal(true);
                          setDynamicModalName("meeting");
                        }}
                        className="btn btn-sm btn-primary pull-right m-2"
                      >
                        Schedule Meeting
                      </button>
                    )
                  )

                }


              </div>
              <div className="details ProflieActions meeting-schdule">
                <Accordion defaultActiveKey="10" className="border-0">
                  <Accordion.Item eventKey="2" className="">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header> TRAINING </Accordion.Header>
                      {
                        pipelineStatus.length > 0 ? (
                          <button
                            className="btn btn-link"
                            onClick={() => {
                              setpage5Class("slide-in");
                            }}
                          >
                            <img src={Cog}></img>
                          </button>
                        ) :
                          (
                            <button
                              className="btn btn-link"
                              onClick={() => swal({
                                title: "Assign Training",
                                text: `You need to add/assign training`,
                                icon: "warning",
                                dangerMode: true,
                              })}
                            >
                              <img src={Cog}></img>
                            </button>
                          )

                      }
                    </div>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="d-none">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header>Attachments</Accordion.Header>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage8Class("slide-in");
                        }}
                      >
                        <img src={Cog}></img>
                      </button>
                    </div>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3" className="d-none">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header> Products </Accordion.Header>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage6Class("slide-in");
                        }}
                      >
                        <img src={Cog}></img>
                      </button>
                    </div>
                  </Accordion.Item>


                  <Accordion.Item eventKey="3" className="d-none">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header> Training </Accordion.Header>
                      {(info.email != '' && (info.phone != '' || info.mobile != '') && info.linked_user_id != 0) ? (
                        <button
                          className="btn btn-link"
                          onClick={() => {
                            setpage11Class("slide-in");
                          }}
                        >
                          <img src={Cog}></img>
                        </button>

                      ) : (
                        <button
                          onClick={() => swal({
                            title: "Assign Training",
                            text: `For assign training, a client (phone or mobile), email, and {client login} are compulsory.`,
                            icon: "warning",
                            dangerMode: true,
                          })}
                          className="btn btn-link"
                        >
                          <img src={Cog}></img>
                        </button>
                      )}
                    </div>
                  </Accordion.Item>


                  {/* <Accordion.Item eventKey="4">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header> Contracts </Accordion.Header>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage7Class("slide-in");
                        }}
                      >
                        <i className="fas fa-plus"> </i> Add/View
                      </button>
                    </div>
                  </Accordion.Item> */}


                </Accordion>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <div className="dialer">
        {/* <Loader loaded={loader}></Loader> */}
      </div>
      <div className={`page3 ${page3Class} custom-scroll`}>
        <div>
          <div className="modal-head top-heading">
            <h2> Add company to this client</h2>
            <button
              onClick={() => {
                setpage3Class("slide-out2");
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <Tabs defaultActiveKey="existing" className="mb-3">
            <Tab eventKey="existing" title="Association existing">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="col-md-8 offset-2">
                        <div className="form-group relative">
                          <input
                            value={
                              searchClient != "" && searchClient != null
                                ? searchClient
                                : ""
                            }
                            onChange={(e) => setSearchClient(e.target.value)}
                            placeholder="Search clients"
                            name="searh"
                            type={"text"}
                            className={"form-control"}
                          />
                          {searchClient == "" ? (
                            <button className="btn btn-sm searchBtn">
                              <span className="fas fa-search"> </span>
                            </button>
                          ) : (
                            <button
                              onClick={() => setSearchClient(null)}
                              className="btn btn-sm searchBtn"
                            >
                              <span className="fas fa-times"> </span>
                            </button>
                          )}
                        </div>
                        <p>
                          {existing.length}
                          results
                        </p>
                        {existing.length < 1 && searchClient != "" && (
                          <p className="text-danger">
                            No company match the current search.
                          </p>
                        )}
                        {existing.length > 0 && (
                          <form method="POST" onSubmit={handleExistingClient}>
                            <fieldset className="form-group">
                              {/* onChange={this.handleChange.bind(this)} */}
                              <div className="form-control border-0 p-0 h-auto is-untouched is-pristine av-valid">
                                {existing.map((row, i) => {
                                  return (
                                    <div
                                      className="form-check pt-3"
                                      key={"search" + i}
                                    >
                                      <input
                                        name={`company_id[]`}
                                        type="radio"
                                        className=" form-check-input"
                                        value={`${row.client_id}`}
                                      />
                                      <label className="form-check-label">
                                        {`${row.first_name} ${row.last_name} (${row.email})`}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </fieldset>
                            <button
                              className="btn btn-lg btn-primary my-3"
                              type="submit"
                            >
                              Submit
                            </button>
                          </form>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="new" title="Association new">
              {/* <CompanyCreateUpdate
                ref={childClientRef}
                getData={getCompanyClients}
                compannyId={info.client_id}
                from="clientDetails"
              /> */}
            </Tab>
          </Tabs>
        </div>
      </div>

      <Modal
        isOpen={todoModal}
        fade={true}
        size="md"
        toggle={() => {
          setTodoModal(false);
        }}
      >
        <div className="note">
          <div className="modal-head top-heading">
            <h2> Add list </h2>
            <button
              onClick={() => {
                setTodoModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <ModalBody>
            <AvForm
              onValidSubmit={handleTodoSubmit}
              // model={this.state.record}
              className="needs-validation"
            >
              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="textarea"
                  name="description"
                  label=""
                  placeholder="Add a note"
                  required
                  rows={5}
                />
              </div>
              <ModalFooter>
                <Button color="info" type="submit">
                  Save
                </Button>
                <Button color="danger" onClick={() => setTodoModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={goalsModal}
        fade={true}
        size="md"
        toggle={() => {
          setGoalsModal(false);
        }}
      >
        <AvForm
          onValidSubmit={handleGoalsSubmit}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> Set Goals </h2>
              <button
                type="button"
                onClick={() => {
                  setGoalsModal(false);
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3 row">
                {goalsArray.length > 0 && (
                  goalsArray.map((row, i) => {
                    return (
                      <div className="col-md-12 mb-3">
                        <AvField
                          name={row[0]}
                          label={row[1]}
                          className="form-control"
                          required
                          type="text"
                          value={row[2]}
                        />
                      </div>
                    )
                  })
                )
                }
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="info" type="submit">
                Save
              </Button>
              <Button type="button" color="danger" onClick={() => setGoalsModal(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>

      <Modal
        isOpen={DynamicModal}
        fade={true}
        size="md"
        toggle={() => {
          setDynamicModal(false);
          setSmsBody("");
          SetSelectedMmsCustom("");
          SetSelectedMmsDefault("");
          setToken("");
          setSubject("");
          setHtml("");
          setTemplateId(0);
        }}
      >
        <AvForm
          onValidSubmit={handleSubmit}
          className="needs-validation"
        >
          {DynamicModalName == "Note" ? (
            <div className="note LeadDialer">
              <div className="modal-head top-heading">
                <h2> Add/View Note </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSendNote(false);
                    setSmsBody("");
                    SetSelectedMmsCustom("");
                    SetSelectedMmsDefault("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  className="btn btn-link text-white"
                  type="button"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="d-flex justify-content-between">
                  <div className="MonthlyDropDown mb-2" style={{ width: 'auto' }}>
                    <select onChange={(e) => { setNotes([]); setNoteType(e.target.value); getNotes({ 'note_type': e.target.value }, 'notes') }} className="form-control">
                      <option value={'manual'}>Manual Notes</option>
                      <option value={'system'}>System Notes</option>
                      <option value="all">All Notes</option>
                    </select>
                  </div>
                  {
                    noteType == 'manual' && (
                      <div className="text-right mb-2">
                        <button onClick={() => setSendNote(true)} type="button" className="btn code-green" ><i className="fas fa-plus"></i></button>
                      </div>
                    )
                  }
                </div>
                {
                  sendNotes && (

                    <div className="mb-3">
                      <div className="mb-2">
                        <AvField
                          className="form-control"
                          type="textarea"
                          name="activity"
                          label=""
                          placeholder="Add a note"
                          required
                        />
                      </div>
                      <div className="btns">
                        <Button className="mr-3" color="info" type="submit">
                          Add Note
                        </Button>
                        <Button color="danger" onClick={() => { setSendNote(false) }}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )
                }
                {notes.length > 0 ? (
                  notes.map((row, i) => {
                    return (
                      <div className="CompanyDetail ">
                        <div key={"note-" + i} className="note activity-info">
                          <div className="activity-body">
                            <div className="activity-bubble note">
                              <div className="activity-content">
                                <div className="activity_time">
                                  <div className="ActivityTitle" style={{ width: '65%' }}>
                                    <span> {row.type} </span>
                                  </div>
                                  {row.created_at}
                                </div>
                                <p> {row.activity} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12 col-lg-12 col-xl-12 text-center">
                    No Record Found
                  </div>
                )}

                {/* <div>
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="activity"
                    label=""
                    placeholder="Add a note"
                    required
                  />
                </div> */}
              </ModalBody>
              {/* <ModalFooter>
                <Button color="info" type="submit">
                  Add Note
                </Button>
                <Button color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter> */}
            </div>
          )  : DynamicModalName == "sms" ? (
            <div className="sms">
              <div className="modal-head top-heading">
                <h2> Send SMS</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    SetSelectedMmsCustom("");
                    SetSelectedMmsDefault("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                    setTemplateId(0);
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3">
                  <div className="MainTZone SendSMS One">
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isMulti={false}
                      options={templates}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      placeholder={"Select sms template"}
                      onChange={(e) => {
                        setSmsBody(e.body);
                        SetSelectedMmsDefault(e.media_file);
                        SetSelectedMmsCustom("");
                        setTemplateId(e.id);
                      }}
                    />
                  </div>
                  <AvField type="hidden" name="template_id" value={template_id} />
                  <AvField type="hidden" name="tos" value={info.mobile} />
                  <AvField type="hidden" name="activity" value={"sms send"} />
                </div>
                <div className="mb-3">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option key={"phone-" + i} value={row.phone_number}>
                          {convertPhoneFormat(row.phone_number)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
                <div className="row mb-3">
                  <div className="col-md-8">
                    <div className="MainTZone SendSMS Two">
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.key}
                        isMulti={false}
                        options={tokens}
                        classNamePrefix="select2-selection"
                        name="country_id"
                        placeholder={"Select token"}
                        onChange={(e) => {
                          setToken(`{${e.key}}`);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={() => setSmsBody(`${smsBody} ${token}`)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Token
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="body"
                    required
                    value={smsBody}
                    placeholder={"Enter body"}
                    onChange={(e) => setSmsBody(e.target.value)}
                  />
                </div>
                {
                  clearFile && (
                    <>
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-10">
                            <AvInput
                              name="media_file"
                              label="Media File"
                              className="form-control"
                              type="file"
                              accept="image/*"
                              onChange={(e) => changeHandler(e)}
                            />
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-info" type="button" onClick={() => handleClearFile()}>Clear</button>
                          </div>
                        </div>
                        <p className="text-info">
                          Note: Maximum file size 5 MB - gif, jpeg, png content files fully supported, content types outside of these are not fully supported.
                        </p>
                      </div>
                    </>
                  )
                }


                {selected_mms_default && (
                  <div className="mb-3">
                    <a
                      href={
                        AttachementsUrl +
                        "/user_" +
                        coach_id +
                        "/attachment/" +
                        selected_mms_default
                      }
                      download
                      onClick={(e) => download(e)}
                      target="blank"
                    >
                      <i className="fa fa-download" />
                      download
                    </a>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Send
                </Button>
                <Button color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "rvm" ? (
            <div className="rvm">
              <div className="modal-head top-heading">
                <h2> Send RVM </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>

                <div className="mb-3">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option key={"num-" + i} value={row.phone_number}>
                          {convertPhoneFormat(row.phone_number)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
                <div className="mb-3" >
                  <AvRadioGroup
                    inline name="file_type"
                    value={file_type}
                    onChange={
                      (e) =>
                        onChangefileTypeAction(e.target.value)
                    } >
                    <AvRadio label="Select Template"
                      value="template-section" />
                    <AvRadio label="Record"
                      value="record-section" />
                  </AvRadioGroup>
                </div>
                <div id="record-section" style={{ display: "none" }}>
                  <div className="row">
                    <div className="col-12" >
                      <AudioReactRecorder style={{ height: 100 }} state={recordState}
                        onStop={onStop}
                        backgroundColor="rgb(255,255,255)"
                      />
                      <audio id="audio"
                        controls src={
                          audioData ? audioData.url : null
                        }>
                      </audio>
                    </div >
                    <div className="col-12 mt-2" >
                      <Button type="button" className="m-2"
                        color="secondary"
                        onClick={start} >
                        Start </Button>
                      <Button type="button"
                        color="secondary"
                        onClick={stop} >
                        Stop </Button>
                    </div >
                  </div>
                </div>

                <div className="mb-3" id="template-section" style={{ display: "block" }}>
                  <div className="MainTZone SendRVM One">
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      // value={options.filter(({ id }) => id ===country_code)}
                      isMulti={false}
                      options={templates}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      placeholder={"Select rvm template"}
                      onChange={(e) => {
                        setSmsBody(e.id);
                      }}
                    />
                  </div>
                  <AvField
                    type="hidden"
                    name="tos"
                    value={info.mobile ? info.mobile : info.phone}
                  />
                  <AvField type="hidden" name="activity" value={"RVM: send"} />
                  <AvField type="hidden" name="template_id" value={smsBody} />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Send
                </Button>
                <Button color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "call" ? (
            <div className="call">
              <div className="modal-head top-heading">
                <h2> Initiate Outbound Call </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  className="btn btn-link text-white"
                  type="button"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3">
                  {callToken ? (
                    <Phone paramsters={paramsters} getCallSid={getCallSid}> </Phone>
                  ) : (
                    <p> Loading... </p>
                  )}
                </div>
              </ModalBody>
            </div>
          ) : DynamicModalName == "meeting" ? (
            <div className="meeting">
              <div className="modal-head top-heading">
                <h2> Schedule a meeting </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3 row">
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="server_date"
                      label="Select Date"
                      className="form-control"
                      required
                      type="date"
                    />

                    <AvInput
                      name="body"
                      value={`A meeting schedule  request sent  to ({client_name}) {link}`}
                      type="hidden"
                    />

                    <AvInput
                      name="body"
                      value={`A meeting schedule  request sent  to ({client_name}) {link}`}
                      type="hidden"
                    />
                    <AvInput
                      name="calendar_id"
                      value={defaultCalendar}
                      type="hidden"
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="server_time"
                      label="Select Time"
                      className="form-control"
                      required
                      type="time"
                    />
                  </div>

                  <Col md={6}>
                    <AvGroup>
                      <label> MainTime Zone </label>
                      <div className="MainTZone MainTime One">
                        <Select
                          value={maintimezone}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.id}
                          isMulti={false}
                          options={maintimezones}
                          classNamePrefix="select2-selection"
                          className={`${maintimezone != ""
                            ? "is-touched is-dirty av-valid"
                            : "is-touched is-pristine av-invalid is-invalid"
                            }`}
                          name="time_zone"
                          onChange={(e) => {
                            settimezone(e);
                          }}
                        />
                      </div>
                      <AvInput
                        type="hidden"
                        required
                        name="time_zone_1"
                        value={maintimezone.id}
                      />
                      <AvFeedback> This is required * </AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <label> Time Zone </label>
                      <div className="MainTZone MainTime Two">
                        <Select
                          value={timezone}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.id}
                          isMulti={false}
                          options={timeZone_data}
                          classNamePrefix="select2-selection"
                          className={`${timezone != ""
                            ? "is-touched is-dirty av-valid"
                            : "is-touched is-pristine av-invalid is-invalid"
                            }`}
                          name="time_zone1"
                          onChange={(e) => {
                            setTimeZone(e);
                          }}
                        />
                      </div>
                      <AvInput
                        type="hidden"
                        required
                        name="time_zone"
                        value={timezone.id}
                      />
                      <AvFeedback> This is required * </AvFeedback>
                    </AvGroup>
                  </Col>

                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Schedule
                </Button>
                <Button color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : (
            <div className=""> </div>
          )}
        </AvForm>
      </Modal>
      <Modal
        isOpen={EmailModal}
        fade={true}
        size="lg"
        toggle={() => {
          setEmailModal(false);
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");

        }}
      >
        <AvForm
          onValidSubmit={handleEmailSubmit}
          className="needs-validation"
          autoComplete="off"
        >
          <div className="email">
            <div className="modal-head top-heading">
              <h2> Send Email</h2>
              <button
                onClick={() => {
                  setEmailModal(false);
                  setSmsBody("");
                  setToken("");
                  setSubject("");
                  setHtml("");
                  setVerfiedFromEmailId("");

                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3">
                <div className="ClientDetails Two">
                  <Select
                    getOptionLabel={(option) => `${option.from_name} - ${option.from_email} - ${option.type}`}
                    getOptionValue={(option) => option.setting_id}
                    isMulti={false}
                    options={verifiedFromEmail}
                    classNamePrefix="select2-selection"
                    placeholder={"Select From Email"}
                    autocomplete="off"
                    value={defaultFromEmail}
                    name="froms"
                    onChange={(option) => {
                      setVerfiedFromEmailId(option.setting_id);
                      SetDefaultFromEmail(option);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="ClientDetails One">
                  <Select
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    // value={options.filter(({ id }) => id ===country_code)}
                    isMulti={false}
                    options={templates}
                    classNamePrefix="select2-selection"
                    autocomplete="off"
                    placeholder={"Select email template"}
                    name="country_id"
                    onChange={(e) => {
                      setSubject(e.title);
                      getEmailTemplateHtml(e.id);
                    }}
                  />
                </div>
                <AvField type="hidden" name="tos" value={info.email} />
                <AvField type="hidden" name="activity" value={"Email: sent"} />
              </div>
              <div className="mb-3 d-none">
                {/* <AvField
                  className="form-control"
                  type="text"
                  name="froms"
                  value={uInfo().from_email}
                  required
                  placeholder="Enter from email"
                /> */}
              </div>
              <div className="row mb-3">
                <div className="col-md-12 mb-3">
                  <div className="MainTZone ClientDetails Two 7">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      // value={options.filter(({ id }) => id ===country_code)}
                      isMulti={false}
                      options={tokens}
                      classNamePrefix="select2-selection"

                      placeholder={"Select Token"}
                      autocomplete="off"
                      name="country_id"
                      onChange={(e) => {
                        setToken(`{${e.key}}`);
                      }}
                    />
                  </div>
                  {/* <AvField type="hidden" name="country_code" value={token} /> */}
                </div>


                {html == "" && (
                  <div className="col-md-2 col-2">
                    <button
                      onClick={() => setSmsBody(`${smsBody} ${token}`)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Body
                    </button>
                  </div>
                )}
                <div className="col-md-4 col-6">
                  <button
                    onClick={() => setSubject(`${subject} ${token}`)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Insert Subject
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <AvField
                  className="form-control"
                  autocomplete="off"
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              {html == "" ? (
                <div className="mb-3">
                  {/* <AvField
                    className="form-control"
                    autocomplete="off"
                    type="textarea"
                    name="body"
                    placeholder="Enter bodyy"
                    required
                    value={smsBody}
                    onChange={(e) => setSmsBody(e.target.value)}
                  /> */}
                  <SunEditor 
                      name="body"
                      setOptions={
                      {
                        height: 300,
                        width: '100%',
                        resizeEnable: 'false',
                        buttonList: [
                          ["undo", "redo", "font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "removeFormat",
                          ],
                          [
                            "fontColor",
                            "hiliteColor",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "table",
                          ],
                          [
                            "link",
                            "image",
                            "video",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                            "preview",
                          ],

                        ],
                        proportion: 'Constrain proportions assasa',
                      }
                    }
                    setContents={smsBody}
                    value={smsBody}
                    onChange={(e) => {setSmsBody(e);}}
                  />
                </div>
              ) : (
                <>
                  <div
                    id="custom-template"
                    className="height-500 custom-scroll"
                    dangerouslySetInnerHTML={{ __html: html }}
                  ></div>
                  {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="info" type="submit">
                Send
              </Button>
              <Button color="danger" onClick={() => {
                setEmailModal(false);
                setSmsBody("");
                setToken("");
                setSubject("");
                setHtml("");
                setVerfiedFromEmail("");
              }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
      <Modal
        isOpen={sentEmailTemplateModal}
        fade={true}
        size="lg"
        toggle={() => {
          setSentEmailTemplateModal(false);
        }}
      >
        <div className="email">
          <div className="modal-head top-heading">
            <h2> Email </h2>
            <button
              onClick={() => {
                setSentEmailTemplateModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <ModalBody>
            <h3><span>Subject:</span> {emailData.subject}</h3>
            <div
              className="height-500 custom-scroll"
              dangerouslySetInnerHTML={{ __html: emailData.body }}
            />
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={taskCompleteModal}
        fade={false}
        toggle={() => setTaskCompleteModal(false)}
      >
        <ModalBody>
          <div className="warning-box text-center">
            <span className="warning">
              <i className="mdi mdi-alert-circle"> </i>
            </span>
            <h3> Are you sure ? </h3> <p> You want to mark this complete ? </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => completeTask()}>
            Yes, Change it
          </Button>
          <Button color="danger" onClick={() => setTaskCompleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>


      <Modal
        isOpen={reminderModal}
        fade={true}
        size="lg"
        toggle={() => {
          setReminderModal(false);
        }}
      >
        <div className="email">
          <div className="modal-head top-heading">
            <h2> Appointment Reminder List </h2>
            <button
              onClick={() => {
                setReminderModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <ModalBody>
            <table className="table table-striped  table-bordered">
              <thead>
                <tr>
                  <th>Appointment ID</th>
                  <th>Status</th>
                  <th>Reminder For</th>
                  <th>Reminder Time</th>
                </tr>
              </thead>
              <tbody>
                {
                  reminders.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.reminder_id}</td>
                        <td>{row.status}</td>
                        <td>{row.reminder_for}</td>
                        <td>{row.reminder_datetime}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </ModalBody>
        </div>
      </Modal>

      {page4Class == "slide-in" && (
        <div className={`page3 ${page4Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Add Task </h2>
              <button
                onClick={() => {
                  setpage4Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <CreateTask
              cancelSlider={cancelTask}
              compannyId={info.client_id}
              from="companyDetails"
            />
          </div>
        </div>
      )}
      {page5Class == "slide-in" && (
        <div className={`page3 ${page5Class} custom-scroll deal-assign w-25`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Training Status </h2>
              <button
                onClick={() => {
                  setpage5Class("slide-out2");
                  setPipelineModal(false);
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <Card>
              <CardBody>

                <div className="pipeline">
                  {pipelineStatus.map((pstatus, i) => {
                    return (
                      <div className="DealCards mb-2 border-1" key={i}>
                        <div key={i} className={`form-check form-check-right d-flex justify-content-between align-items-center ${pstatus.is_complete == 1 ? 'complete' : 'pending'}`}>
                          <label
                            className="form-check-label"
                            htmlFor="customCheck2"
                          >
                            {pstatus.name} {pstatus.final_pipeline == 1 && (`- ${pstatus.current_status}`)}
                          </label>
                          {
                            pstatus.final_pipeline == 0 && (
                              <input
                                key={i}
                                type="checkbox"
                                title="Mark as complete"
                                className="form-check-input"
                                disabled={pstatus.is_complete == 1 ? true : false}
                                defaultChecked={pstatus.is_complete == 1 ? true : false}
                                onChange={(e) => e.target.checked && (handleDealSubmit(pstatus.pipeline_id, 0, info.client_id, pstatus.final_pipeline))}
                              />
                            )
                          }

                        </div>
                        <ul className="mb-0 py-2">
                          {pstatus.pipeline_status.map((row, i) => {
                            return (
                              <li
                                disabled={pstatus.is_complete == 1 && pstatus.final_pipeline == 0 ? true : false}
                                onClick={() => {
                                  handleDealSubmit(row.pipeline_id, row.status_id, info.client_id, pstatus.final_pipeline);
                                }}
                                key={"p_status-" + i}
                              >
                                <div className=""
                                  style={{
                                    backgroundColor: pstatus.is_complete == 1 ? 'transparent' : row.color_code,
                                    padding: '3px',
                                    display: 'inline-block',
                                    width: '90%'
                                  }}>
                                  <p className={pstatus.is_complete != 1 && (row.color_code == '#fff' ? 'text-dark' : 'text-white')}>{row.title}</p>
                                </div>
                                {/* {row.is_complete == 1 && (<i className="fas fa-check code-green"></i>)} */}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      {page6Class == "slide-in" && (
        <div className={`page3 ${page6Class} custom-scroll invoice-details`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Products </h2>
              <button
                onClick={() => {
                  setpage6Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-md-12">
                    <button type="button" className="btn btn-link">
                      <i className="fas fa-file"> </i> View Products
                    </button>
                    <Link
                      to={"/invoice/add/" + bcrypt(info.client_id)}
                      className="btn btn-link pull-right"
                    >
                      <i className="fas fa-plus"> </i> Assign Product
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td> Invoice# </td> <td> Start Date </td>
                        <td> Expiry Date </td> <td> Type(Recurring#) </td>
                        <td> Total Price </td> <td> Status </td>
                        <td> Action </td>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices.map((row, i) => {
                        return (
                          <tr key={"invoice-" + i}>
                            <td>
                              #IN - {String(row.invoice_id).padStart(5, "0")}
                            </td>
                            <td> {row.start_date} </td>
                            <td> {row.expire_date} </td>
                            <td> {row.type} </td>
                            <td> {getInvoiceCurrency(row.currency_code)}{row.total_price} </td>
                            <td> {row.payment_status} </td>
                            <td>
                              <Link
                                to={
                                  "/invoice/preview/" + bcrypt(row.invoice_id)
                                }
                                className="btn btn-link"
                              >
                                <i className="fas fa-eye"> </i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      {page7Class == "slide-in" && (
        <div className={`page3 ${page7Class} custom-scroll contract-details`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Contracts </h2>
              <button
                onClick={() => {
                  setpage7Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>

            <ClientContract
              cancelSlider={() => setpage7Class("slide-out2")}
              clientId={info.client_id}
              from="clientDetails"
            />
          </div>
        </div>
      )}

      {page8Class == "slide-in" && (
        <div
          style={{ width: "auto", minWidth: "50%" }}
          className={`page3 ${page8Class} custom-scroll attachment-details`}
        >
          <div>
            <div className="modal-head top-heading">
              <h2>Attachment </h2>
              <button
                onClick={() => {
                  setpage8Class("slide-out2");
                  setSubject("");
                  setSmsBody("");
                  setToken('');
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <Tabs defaultActiveKey="existing" className="mb-3">
              <Tab eventKey="existing" title="Existing Attachment">
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <td>Title </td>
                                <td>Created At</td>
                                <td>Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {attachment &&
                                attachment.id &&
                                JSON.parse(attachment.attachments).map(
                                  (row, k) => {
                                    return (
                                      <tr key={k}>
                                        <td>{row.file_title}</td>
                                        <td>{row.created_at}</td>
                                        <td>
                                          <a
                                            target="_blank"
                                            href={`${AttachementsUrl}${row.file}`}
                                          >
                                            <i className="fas fa-download"></i>
                                          </a>
                                          <button
                                            className="btn btn-link"
                                            title="remove"
                                            onClick={() =>
                                              removeAttachement(
                                                attachment.id,
                                                k
                                              )
                                            }
                                          >
                                            <i className="fas fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="new" title="Add New">
                <ModalBody>
                  <Col className="col-12">
                    {
                      loader && (
                        <AvForm
                          onValidSubmit={handleAtSubmit}
                          ref={(c) => (this_form = c)}
                          className="needs-validation"
                          id="selection-attachementaa"
                        >
                          <div className="mb-3">
                            <AvField
                              className="form-control"
                              type="text"
                              name="file_title"
                              label="Title"
                              placeholder="Enter attachment title"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvInput
                              name="attachment_file"
                              label="Image"
                              className="form-control"
                              multiple={false}
                              type="file"
                              accept=".gif, .jpg, .png, .doc"
                              onChange={(e) => {
                                setattachmentFile(e.target.files[0]);
                              }}
                            />
                            <p className="text-danger"><b>Note:</b>The attachment file must be a file of type: jpeg, png, jpg, pdf, docx, doc</p>
                          </div>
                          <div className="button-items">
                            <Button
                              className="btn-ld"
                              color="primary"
                              type="submit"
                            >
                              Add
                            </Button>
                          </div>
                        </AvForm>
                      )
                    }
                  </Col>
                </ModalBody>
              </Tab>
              <Tab eventKey="request" title="Send Request 12">
                <ModalBody>
                  <Col className="col-12">
                    <AvForm
                      onValidSubmit={handleAttachementRequestSubmit}
                      ref={(c) => (this_form = c)}
                      className="needs-validation"
                    >
                      <div className="col-md-12 mb-3">
                        <div className="MainTZone MainTime Three">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            // value={options.filter(({ id }) => id === this.state.country_code)}
                            isMulti={false}
                            options={tokens}
                            classNamePrefix="select2-selection"
                            placeholder="Select Token"
                            name="country_id"
                            onChange={(e) => {
                              setToken(`{${e.key}}`);
                            }}
                          />
                        </div>
                        <div className="row mb-3 mt-3">
                          <div className="col-md-4 col-6">
                            <button
                              onClick={() =>
                                setSmsBody(`${smsBody} ${token}`)
                              }
                              type="button"
                              className="btn btn-primary"
                            >
                              Insert Body
                            </button>
                          </div>
                          <div className="col-md-4 col-6">
                            <button
                              onClick={() =>
                                setSubject(`${subject} ${token}`)
                              }
                              type="button"
                              className="btn btn-primary"
                            >
                              Insert Subject
                            </button>
                          </div>
                        </div>

                        <div className="mb-3">
                          <AvField
                            className="form-control"
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            required
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            className="form-control"
                            type="textarea"
                            name="body"
                            placeholder="Enter body"
                            required
                            value={smsBody}
                            onChange={(e) => setSmsBody(e.target.value)}
                          />
                        </div>

                        {/* <AvField type="hidden" name="country_code" value={token} /> */}
                      </div>

                      <div className="button-items">
                        <Button
                          className="btn-ld"
                          color="primary"
                          type="submit"
                        >
                          Send a request
                        </Button>
                      </div>
                    </AvForm>
                  </Col>
                </ModalBody>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}

      {page8Class == "slide-in" && (
        <div
          style={{ width: "auto", minWidth: "50%" }}
          className={`page3 ${page8Class} custom-scroll attachment-details`}
        >
          <div>
            <div className="modal-head top-heading">
              <h2>Attachment</h2>
              <button
                onClick={() => {
                  setpage8Class("slide-out2");
                  setSubject("");
                  setSmsBody("");
                  setToken('');
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <Tabs defaultActiveKey="existing" className="mb-3">
              <Tab eventKey="existing" title="Existing Attachment">
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <td>Title </td>
                                <td>Created At</td>
                                <td>Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {attachment &&
                                attachment.id &&
                                JSON.parse(attachment.attachments).map(
                                  (row, k) => {
                                    return (
                                      <tr key={k}>
                                        <td>{row.file_title}</td>
                                        <td>{row.created_at}</td>
                                        <td>
                                          <a
                                            target="_blank"
                                            href={`${AttachementsUrl}${row.file}`}
                                          >
                                            <i className="fas fa-download"></i>
                                          </a>
                                          <button
                                            className="btn btn-link"
                                            title="remove"
                                            onClick={() =>
                                              removeAttachement(
                                                attachment.id,
                                                k
                                              )
                                            }
                                          >
                                            <i className="fas fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="new" title="Add New">
                <ModalBody>
                  <Col className="col-12">
                    {
                      loader && (
                        <AvForm
                          onValidSubmit={handleAtSubmit}
                          ref={(c) => (this_form = c)}
                          className="needs-validation"
                          id="selection-attachement"
                        >
                          <div className="mb-3">
                            <AvField
                              className="form-control"
                              type="text"
                              name="file_title"
                              label="Title"
                              placeholder="Enter attachment title"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvInput
                              name="attachment_file"
                              label="Image"
                              className="form-control"
                              multiple={false}
                              type="file"
                              accept=".gif, .jpg, .png, .doc"
                              required
                              onChange={(e) => {
                                setattachmentFile(e.target.files[0]);
                              }}
                            />
                            <p className="text-danger"><b>Note:</b>The attachment file must be a file of type: jpeg, png, jpg, pdf, docx</p>
                          </div>
                          <div className="button-items">
                            <Button
                              className="btn-ld"
                              color="primary"
                              type="submit"
                            >
                              Add
                            </Button>
                          </div>
                        </AvForm>
                      )
                    }
                  </Col>
                </ModalBody>
              </Tab>
              <Tab eventKey="request" title="Send Request">
                <ModalBody>
                  <Col className="col-12">
                    <AvForm
                      onValidSubmit={handleAttachementRequestSubmit}
                      ref={(c) => (this_form = c)}
                      className="needs-validation"
                    >
                      <div className="col-md-12 mb-3">
                        <div className="MainTZone">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            // value={options.filter(({ id }) => id === this.state.country_code)}
                            isMulti={false}
                            options={tokens}
                            classNamePrefix="select2-selection"
                            placeholder="Select Token"
                            name="country_id"
                            onChange={(e) => {
                              setToken(`{${e.key}}`);
                            }}
                          />
                        </div>

                        <div className="row mb-3 mt-3">
                          <div className="col-md-6 col-12">
                            <button
                              onClick={() =>
                                setSmsBody(`${smsBody} ${token}`)
                              }
                              type="button"
                              className="btn btn-primary"
                            >
                              Insert Body
                            </button>

                            <button
                              onClick={() =>
                                setSubject(`${subject} ${token}`)
                              }
                              type="button"
                              className="btn btn-primary m-2 mt-0 mb-0"
                            >
                              Insert Subject
                            </button>
                          </div>
                        </div>

                        <div className="mb-3">
                          <AvField
                            className="form-control"
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            required
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            className="form-control"
                            type="textarea"
                            name="body"
                            placeholder="Enter body"
                            required
                            value={smsBody}
                            onChange={(e) => setSmsBody(e.target.value)}
                          />
                        </div>

                        {/* <AvField type="hidden" name="country_code" value={token} /> */}
                      </div>

                      <div className="button-items">
                        <Button
                          className="btn-ld"
                          color="primary"
                          type="submit"
                        >
                          Send a request
                        </Button>
                      </div>
                    </AvForm>
                  </Col>
                </ModalBody>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
      {page9Class == "slide-in" && (
        <div className={`page3 ${page9Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2> New Assessment </h2>
              <button
                onClick={() => {
                  setpage9Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <CreateAssessment
              cancelSlider={cancelAssessment}
              client_id={info.client_id}
              from="companyDetails"
            />
          </div>
        </div>
      )}
      {page10Class == "slide-in" && (
        <div className={`page3 ${page10Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Edit Assessment </h2>
              <button
                onClick={() => {
                  setpage10Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <EditAssessment
              cancelSlider={cancelAssessment}
              client_id={info.client_id}
              assessment_id={assessment_id}
            />
          </div>
        </div>
      )}

      {page11Class == "slide-in" && (
        <div className={`page3 ${page11Class} custom-scroll contract-details`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Trainings </h2>
              <button
                onClick={() => {
                  setpage11Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>

            <AvForm
              onValidSubmit={assignTraining}
              // model={this.state.record}
              className="needs-validation"
            >
              <Card>
                <CardBody>
                  {
                    !isObjectEmpty(info) && (
                      <>
                        <div className="mb-3 mt-1">
                          <label>Assign Training</label>
                          <div className="MainTZone Trainings One">
                            <Select
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.cat_id}
                              value={training.allTraining.filter(obj => training.userTraining.includes(obj.cat_id))}
                              options={training.allTraining}
                              isMulti={true}
                              classNamePrefix="select2-selection"
                              name="tags_id"
                              onChange={handleTrainingChange}
                            />
                          </div>
                          <AvField
                            type="hidden"
                            name="cat_ids"
                            value={training.userTraining.toString()}
                            required
                          />
                        </div>
                        <div className="Training">

                          <div className="row">
                            {
                              training.subCategories.map((row, i) => {
                                return <div className="col-md-6 mb-4">
                                  <div className={`${training.userTraining.includes(row.cat_id) ? 'active img-page' : 'img-page'}`}>
                                    <button type="button" className="btn btn-link" onClick={() => {

                                      if (training.userTraining.includes(row.cat_id)) {
                                        setTraining(prevTraining => ({
                                          ...prevTraining,
                                          userTraining: training.userTraining.filter(item => item !== row.cat_id)
                                        }));
                                      } else {
                                        setTraining(prevTraining => ({
                                          ...prevTraining,
                                          userTraining: [...training.userTraining, row.cat_id]
                                        }));
                                      }
                                    }}>
                                      <h3 className="title-page">{row.title}</h3>
                                      <img src={`${AttachementsUrl}/user_2/training/${row.image}`} width={'100%'} />
                                    </button>
                                  </div>
                                </div>;
                              })
                            }
                          </div>
                        </div>

                        {/* <div className="mb-3 mt-1">
                          <label>Assign Resource</label>
                          <Select
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.cat_id}
                            value={training.allResource.filter(obj => training.userResource.includes(obj.cat_id))}
                            options={training.allResource}
                            isMulti={true}
                            classNamePrefix="select2-selection"
                            name="tags_id"
                            onChange={handleResourceChange}
                          />

                          <AvField
                            type="hidden"
                            name="cat_ids"
                            value={training.userResource.toString()}
                          // required
                          />
                        </div> */}
                        <div className="row">
                          <div className="col-md-6 offset-md-3 mt-3">
                            <button
                              type="submit"
                              className="btn btn-info width-100-percent"
                            >
                              Assign
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  }

                </CardBody>
              </Card>

            </AvForm>
          </div>
        </div>
      )}
      <Modal
        isOpen={pipelineModal}
        fade={true}
        size="md"
        className="hello"
        toggle={() => {
          setPipelineModal(false);
          setSelectedPipeline({});
        }}
      >
        <AvForm
          onValidSubmit={handleDealSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          <div className="sms">
            <div className="modal-head top-heading">
              <h2> Opportunity </h2>
              <button
                onClick={() => {
                  setPipelineModal(false);
                  setSelectedPipeline({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3">
                <AvField
                  className="select form-control"
                  type="select"
                  name="pipeline_id"
                  label="Pipeline"
                  required
                  disabled
                  defaultValue={pipeline.pipeline_id}
                >
                  <option value={pipeline.pipeline_id}>{pipeline.name}</option>
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  className="select form-control"
                  type="select"
                  name="status_id"
                  label="Deal Status"
                  required
                  disabled
                  defaultValue={selectedPipeline.status_id}
                >
                  <option value={selectedPipeline.status_id}>
                    {selectedPipeline.title}
                  </option>
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  className="select form-control"
                  type="select"
                  name="assigned_to"
                  label="Assign Member"
                  required
                >
                  <option value={""}> Select Member </option>
                  {coachPartners.map((row, i) => {
                    return (
                      <option key={"member-" + i} value={row.user_id}>
                        {row.name}
                      </option>
                    );
                  })}
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  type="hidden"
                  name="client_id"
                  value={info.client_id}
                />
              </div>
              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="textarea"
                  name="comments"
                  required
                  placeholder={"Enter comments"}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="info" type="submit">
                Assign
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  setPipelineModal(false);
                  setSelectedPipeline({});
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};
export default CoachDetails;

