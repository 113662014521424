import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import TrainingSidebar from "./training-sidebar";
import Loader from "react-loader";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import swal from 'sweetalert';

export default class TrainingContentAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      page_name: "Add Category Content",
      roles: [],
      packages: [],
      resellers: [],
      image: "",
      categories: [],
      category_name: "",
      category_sections: [],
      cat_id: "",
      section_id: "",
      selected_file: "",
      file_type: '',
      loader: true,
      subSection: [
        []
      ],
    };
  }

  componentDidMount() {
    this.getData();
  }
  changeHandler = (e) => {
    const file = e.target.files[0];
    if (e.target.files[0].size < 50360746) {
      if (e.target.files[0].type.match('video.*')) {
        console.log(e.target.files, 'e.target.files[0].type')
        let selected_file = [];
        var reader = new FileReader();
        reader.onload = () => {
          selected_file.push(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        this.setState({
          selected_file: selected_file,
          file_type: file.type,
        });
      } else {
        this.setState({
          selected_file: "",
          file_type: "",
        });
        toast('Olny video allowed', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

      }
    } else {
      this.setState({
        selected_file: "",
        file_type: "",
      });
      toast('For larger file contact to support team', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    }
  };

  setsection = (e) => {
    this.setState({
      section_id: e,
    });
  };
  showsections = () => {
    var x = document.getElementById("section_field");
    x.style.display = "block";
  };
  getcategorysections = (e, index) => {
    this.setState({
      cat_id: e,
    });

    fetch(`${ApiUrl}` + "get/category/sections", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: e }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          // this.setState({
          //   category_sections: response.data.records,
          // });

          // index = index + 1;

          // console.log(this.state.subSection[index], 'this.state.subSection[index]')

          if (this.state.subSection[index]) {

            this.setState(prevState => {
              const subSection = [...prevState.subSection];
              subSection[index] = [...subSection[index], ...response.data.records];
              return { subSection };

            });

            if (response.data.records.length < 1) {
              let newstate = this.state.subSection.splice(index, this.state.subSection.length - index);

              // this.setState({ subSection: newstate }) //another array

              this.setState({
                section_id: {}
              })
            }
          } else {

            if (response.data.records.length > 0) {
              this.setState({ subSection: [...this.state.subSection, response.data.records] }) //another array
            }
          }


          // console.log(this.state.subSection, 'subSectionssubSectionssubSectionssubSections')

        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/trainingcategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            categories: response.data.records,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    if (this.state.id) {
      submitData.append('id', this.state.id ? this.state.id : -1);
    }

    for (let [key, value] of formData.entries()) {
      if(key.match('section_id') && (value == '' || value == null)){
       swal("Warning!", "Can not add content in main category. please select remaining section", "warning");
       return true;
      }
      submitData.append(key, value);
    }
    // submitData.append('cat_id', this.state.cat_id);


    this.setState({ loader: false });
    // values.section_id = this.state.section_id;
    // values.selected_file = this.state.selected_file;
    // values.cat_id = this.state.cat_id;
    // values.file_type = this.state.file_type;
    fetch(`${ApiUrl}` + "add/training/content", {
      method: "POST",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {


          if (this.state.id == null) {
            this.form && this.form.reset();
          }

          // this.getData();
          this.props.history.goBack();
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

        this.setState({ loader: true });

      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });

        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>

          <Col lg="2" className="pl-0">
            <TrainingSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />
            <Loader loaded={this.state.loader}></Loader>
            <Row>
              <Col className="col-12">
                <Card>
                  <form onSubmit={this.handleSubmit} method="post"
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                    <ModalBody>
                      <div className="mb-3">
                        <label>Training Category </label>
                        <Select
                          getOptionLabel={(option) => option.title}
                          getOptionValue={(option) => option.id}
                          options={this.state.categories}
                          isMulti={false}
                          classNamePrefix="select2-selection"
                          name="cat_id"
                          isClearable
                          onChange={(e) => {
                            this.getcategorysections(e ? e.id : "", 0);
                          }}
                          label="Title"
                        />
                        {/* <AvField
                          required
                          type="hidden"
                          name="cat_id"
                          value={this.state.cat_id}
                        /> */}
                      </div>
                      {
                        this.state.subSection.map((row, i) => {
                          return (
                            <div id="section_field">
                              <div className="mb-3">
                                <label>Section </label>
                                <Select
                                  getOptionLabel={(option) => option.title}
                                  getOptionValue={(option) => option.id}
                                  options={row}
                                  isMulti={false}
                                  classNamePrefix="select2-selection"
                                  name={`section_id[${i}]`}
                                  isClearable
                                  onChange={(e) => {
                                    this.getcategorysections(e ? e.id : "", i + 1);
                                    this.setsection(e ? e.id : "");
                                  }}
                                />
                                {/* <AvField
                                  type="hidden"
                                  name="section_id"
                                  value={this.state.section_id}
                                /> */}
                              </div>
                            </div>
                          )
                        })
                      }

                      <div className="form-group mb-3">
                        <input
                          name="title"
                          label="Title"
                          className="form-control"
                          placeholder="Enter Title"
                          required
                        />
                      </div>

                      <div className="form-group mb-3">
                        <textarea
                          name="short_description"
                          label="Description"
                          className="form-control"
                          placeholder="Enter description"
                          rows={4}
                        />
                      </div>

                      <div className="mb-3" >
                        <SunEditor name="content"
                          setOptions={
                            {
                              height: 600,
                              width:'100%',
                              resizeEnable:'false',
                              // height: 400,
                              buttonList: [
                                ["undo", "redo", "font", "fontSize", "formatBlock"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                  "removeFormat",
                                ],
                                [
                                  "fontColor",
                                  "hiliteColor",
                                  "outdent",
                                  "indent",
                                  "align",
                                  "horizontalRule",
                                  "list",
                                  "table",
                                ],
                                [
                                  "link",
                                  "image",
                                  "video",
                                  "fullScreen",
                                  "showBlocks",
                                  "codeView",
                                  "preview",
                                ],

                              ],
                              proportion: 'Constrain proportions assasa',
                            }
                          }
                          defaultValue={this.state.record.body}
                          onChange={this.handleChange}
                          appendContents={this.state.appendContents}
                        />

                        {/* <AvField name="body"
                          value={this.state.editor}
                          type="hidden"
                          required /> */}
                      </div>

                      {/* <div className="mb-3">
                        <AvField
                          name="media_file"
                          value={this.state.record.media_file}
                          label="Media File"
                          className="form-control"
                          type="file"
                          accept="video/*"
                          onChange={(e) => this.changeHandler(e)}
                        />
                        <p className="text-danger">Note: videos file are allowed</p>


                      </div> */}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => this.props.history.goBack()}
                        disabled={this.state.loader ? false : true}
                      >
                        Cancel
                      </Button>
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                        disabled={this.state.loader ? false : true}
                      >
                        Submit
                      </button>
                    </ModalFooter>
                  </form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
