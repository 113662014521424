import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    Alert
} from "reactstrap";


import { ApiUrl, logoLightPng, ProductName } from "../../config.js";
import { bcrypt, isValidJSONString, uInfo, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Helmet } from "react-helmet";
import { AvField, AvForm } from "availity-reactstrap-validation";
import swal from 'sweetalert';

//i18n

const VerificationApprovalRequest = (props) => {
    const [vData, setVData] = useState({});
    const [loaded, setLoaded] = useState(true);
    const [msg, setMsg] = useState('');
    const [showCode, setShowCode] = useState(false);
    const [type] = useState('approval');
    const [code_key] = useState('approval');

    const resendCode = (event, values) => {
        setLoaded(false);
        values.type = type;
        values.code_key = code_key;
        values.approval = 'true';
        fetch(`${ApiUrl}` + 'verify/wizard/code', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((res) => {
                if (res.status === true) {
                    setMsg(res.message);
                    setShowCode(true);
                    // setLoaded(true);

                } else {
                    swal({
                        title: "Sorry...",
                        text: res.message,
                        button: "Ok",
                        icon: "warning",
                    })
                    // setLoaded(true);
                }

            })
            //Then with the error genereted...
            .catch((error) => {
                setLoaded(true);
                console.error('Error:', error);
            });
    }
    return (
        <React.Fragment>
            {
                msg && msg != '' && (
                    <Alert color="info">{msg}</Alert>

                )
            }
            <AvForm className="form-horizontal mt-4"
                onValidSubmit={
                    (e, v) => {
                        resendCode(e, v)
                    }
                }>
                <div className="mt-4" >
                    <div className="col-12 text-end" >
                        <p className="text-left">Send Approval Request to Admin</p>
                        <button disabled={loaded ? false : true} className="btn btn-primary waves-effect waves-light"
                            type="submit" > Send Approval Request
                        </button>
                    </div>
                </div>
            </AvForm>
        </React.Fragment>
    );
};

export default VerificationApprovalRequest;
