import React, { useState } from 'react';
import { Card, CardBody, Row , Col, Container} from "reactstrap";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false); // Close dropdown after selection
  };

  const options = ['Oldest', 'Newest'];

  return (
    <div className="dropdown">
      <button onClick={toggleDropdown} className="dropdown-button">
        {selectedOption}
      </button>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option) => (
            <li key={option} onClick={() => handleOptionClick(option)} className="dropdown-item">
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const TopBar = props => {
	return (
		<React.Fragment>
			<Container>
        <Row>
          <Col md="12">
            <div className="AppTopBar pt-2">
                <div className="AppTopBar__title">
                  <h4 className="AppTopBar__title__text text-center">Home</h4>
                  <div className="DropDownBtnMenu"><i className="mdi mdi-dots-vertical"></i><Dropdown /></div>
                </div>
            </div>
          </Col>
        </Row>			
			</Container>
        <div className="border-bottom pb-2">
        <Container>
          <Row>
          <Col md="12">
          <div className="AppTopBar__search">
          <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#98989d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            <input type="Search" className="form-control" placeholder="Search"></input> 
            </div>
          </Col>
          </Row>
        </Container>
        </div> 
			
		</React.Fragment>
	)

}

export default TopBar
