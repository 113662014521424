import React from "react";

import { uInfo } from "../../useToken";

import CoachTrainingPath from "./CoachTrainingPath";
import AdminTrainingPath from "./AdminTrainingPath";

export default function TrainingPaths() {
  const userType = uInfo().type;

  return (
    <>
      {userType === "WlAdmin" ? <AdminTrainingPath /> : <CoachTrainingPath />}
    </>
  );
}
