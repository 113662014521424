import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import {
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class UserOperation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id ? atob(this.props.match.params.id) : null,
      record: {},
      page_name: this.props.match.params.id
        ? "Edit WL Admin"
        : "Create WL Admin",
      roles: [],
      packages: [],
    };
    
  }

  componentDidMount() {
    console.log(this.props, "a");
    this.getData();
    this.getPackages();
  }

  getPackages = (role) => {
    fetch(`${ApiUrl}` + "packages/list/WlAdmin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log(response, "packagedata");
        if (response.status === true) {
          this.setState({
            packages: response.data.records,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/reseller", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log(response, "data");
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            roles: response.data.roles,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }

    values.domain = values.domain + ".profitcoach.app";
    fetch(`${ApiUrl}` + "edit/reseller", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name}</title>{" "}
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>{" "}
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {" "}
                  {ProductName}{" "}
                </li>{" "}
                <li key={1} className="breadcrumb-item">
                  {" "}
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>{" "}
            </div>{" "}
          </Col>{" "}
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back{" "}
              </Button>
            </div>{" "}
          </Col>{" "}
        </Row>

        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Name"
                      value={this.state.record.name}
                      className="form-control"
                      placeholder="Enter Name"
                      required
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      value={this.state.record.email}
                      className="form-control"
                      placeholder="Enter Email Address"
                      required
                    />
                  </div>{" "}
                  {this.state.id == undefined && (
                    <>
                      <Label>Add subdomain</Label>
                      <AvGroup className="input-group mb-3">
                        <AvInput
                          name="domain"
                          className="form-control"
                          required
                        />
                        <div className="input-group-text">profitcoach.app</div>
                        <AvFeedback>This is an error!</AvFeedback>
                      </AvGroup>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value={this.state.record.password}
                          className="form-control"
                          placeholder="Enter Password"
                          minLength={8}
                          errorMessage="Password must be at least 8 characters"
                          required
                          type="password"
                        />
                      </div>{" "}
                      <div className="mb-3">
                        <AvField
                          minLength={8}
                          errorMessage="Confirm password must be at least 8 characters and same as password"
                          name="confirm_password"
                          label="Confirm Password"
                          type="password"
                          value={this.state.record.password}
                          className="form-control"
                          placeholder="Enter Confirm Password"
                          validate={{ match: { value: "password" } }}
                          required
                        />
                      </div>{" "}
                    </>
                  )}
                  {/*   <div className="mb-3">
                      <AvField
                        className="select form-control"
                        type="select" name="role_id" label="Role" onChange={(e) => this.getPackages(e.target.value)} required
                        value={this.state.record.role_id}>
                        <option value={''}>Select Role</option>
                        {
                          this.state.roles.map(role => {
                            return (
                              <option key={role.id} value={role.id}>{role.name}</option>
                            )
                          })
                        }
                      </AvField>

                    </div> */}
                  <AvField
                    name="package_id"
                    type="hidden"
                    value={this.state.record.package_id}
                  />
                  {this.state.page_name == "Create WL Admin" && (
                    <div className="mb-3">
                      <AvField
                        className="select form-control"
                        type="select"
                        name="package_id"
                        label="Package"
                        value={this.state.record.package_id}
                        required
                      >
                        <option value={""}> Select Package </option>{" "}
                        {this.state.packages.map((row) => {
                          return (
                            <option key={row.package_id} value={row.package_id}>
                              {" "}
                              {`${row.name} | ${row.package_interval}`}{" "}
                            </option>
                          );
                        })}{" "}
                      </AvField>
                    </div>
                  )}
                  <div className="mb-3">
                    <AvField
                      className="select form-control"
                      type="select"
                      id="s"
                      name="status"
                      label="Status"
                      required
                      value={this.state.record.status}
                    >
                      <option value={""}> Select Status </option>{" "}
                      <option value={"Active"}> Active </option>{" "}
                      <option value={"Inactive"}> Inactive </option>{" "}
                    </AvField>
                  </div>
                </ModalBody>{" "}
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel{" "}
                  </Button>{" "}
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    {" "}
                    Submit{" "}
                  </button>{" "}
                </ModalFooter>{" "}
              </AvForm>{" "}
            </Card>{" "}
          </Col>{" "}
        </Row>

        {/* /////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
