import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  inputRemoveSpecialCharacter,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import swal from 'sweetalert';
export default class UserOperation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.match.params.type,
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {
        package_interval: "Monthly",
        name: "",
        price: 0,
        trial_package: "No",
        voip_credits: 0,
        clients: 0,
        description: "",
        s_member_limit: 0,
        t_member_limit: 0,
        status: "Active",
        showTrialAction: "No"
      },
      page_name: "Create " + this.props.match.params.type + " Package",

      roles: [],
      inputList: [],
    };
    this.role = { id: this.props.role.id };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    // this.onChangeTrialAction("No");
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/package", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            inputList: JSON.parse(response.data.record.description),
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    let check = false;
    var filteredArray = this.state.inputList.filter(function (itm) {
      if (itm.description_meta == '') {
        swal({
          title: "Error",
          text: "Description: cannot be blank",
          icon: "warning",
        });

        check = true;
      }

    });

    values.description = this.state.inputList;

    if (this.state.type) {
      values.type = this.state.type ? this.state.type : -1;

    }
    if (this.state.type == 'AccountCredit') {
      values.package_interval = 'OneTime';
    }
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    if (!check) {
      fetch(`${ApiUrl}` + "add/package", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
          if (data.status === true) {
            if (this.state.id == null) {
              this.form && this.form.reset();
              this.setState({
                inputList: [],
              });
            }
            this.props.history.push("/packages/list/" + this.state.type);
            // this.getData();
            //               toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });      

          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];

    list[index][name] = value;
    this.setState({ inputList: list });
  };

  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({ inputList: list });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    this.setState({
      inputList: [
        ...this.state.inputList,
        { key: "description_meta", description_meta: "" },
      ],
    });
  };

  addLimit = (key, name, value) => {
    if (value > 0) {
      const target = this.state.inputList.find(
        (element) => element.key === key
      );

      if (target) {
        target.description_meta = value + " - " + name;
      } else {
        let itemObj = {
          key: key,
          description_meta: value + " - " + name,
        };
        this.state.inputList.push(itemObj);
      }
    } else {
      this.setState({
        inputList: this.state.inputList.filter((item) => item.key != key),
      });
    }

    this.forceUpdate();

    //   const retail = this.state.inputList.filter(iterator => iterator.key == key);
    //   console.log(retail, 's')
    //   if (retail.length < 1) {
    //     this.setState({ inputList: [...this.state.inputList, { key: key, name: value + ' - ' + name }] });
    //   } else {
    //     this.setState({ inputList: [...retail, { key: key, name: value + ' - ' + name }] });
    //   }
    // }
  };
  handleKeyPress = (event) => {
    // alert(event.key);
    if (event.key === '+' || event.key === '-' || event.key === 'e') {
      console.log(event.key);
    }
  };
  onChangeTrialAction = (value) => {

    this.setState({
      showTrialAction: value
    })
    //console.log(value);
    // var x = document.getElementById("trial_fields");
    // if (value == "Yes") {
    //   x.style.display = "block";
    // } else {
    //   x.style.display = "none";
    // }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>

        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card className="package-add">
              <AvForm
                onValidSubmit={this.handleSubmit}
                model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      value={this.state.record.name}
                      label="Name"
                      className="form-control"
                      required
                    />
                  </div>
                  {this.state.type !== "AccountCredit" && (
                    <div className="mb-3">
                      <AvField
                        className="select form-control"
                        type="select"
                        value={this.state.record.package_interval}
                        name="package_interval"
                        label="Interval"
                      >
                        <option value={"Monthly"}> Monthly </option>
                        <option value={"Yearly"}> Yearly </option>
                      </AvField>
                    </div>
                  )}




                  <div className="mb-3">
                    <label> Price </label>
                    <div className="group-addnon">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="validationTooltipUsernamePrepend"
                          >
                            $
                          </span>

                        </div>
                        <AvField
                          name="price"
                          id="price"
                          type="number"
                          value={this.state.record.price}
                          validate={{
                            required: { value: true, errorMessage: 'This field is invalid' },
                            pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                          }} />

                      </div>
                    </div>


                    {/* <label> Price </label> */}
                    {/* <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          $
                        </span>
                      </div>
                      <AvInput
                        onChange={(e) => inputRemoveSpecialCharacter(e.target.value)}
                        name="price"
                        id="price"
                        type="number"
                        min="0"
                        value={this.state.record.price}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter a name",
                          },
                          pattern: {
                            //value: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                            value: "([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                            errorMessage:
                              "Your name must be composed only with letter and numbers",
                          },
                        }}
                        required
                      />
                    </div> */}
                  </div>
                  {this.role.id !== 1 &&
                    this.state.type !== "CoachPartner" &&
                    this.state.type !== "AccountCredit" && (
                      <div className="mb-3">
                        <label>Trial </label>
                        <AvRadioGroup
                          value={this.state.record.trial_package}
                          inline
                          name="trial_package"
                          onChange={(e) =>
                            this.onChangeTrialAction(e.target.value)
                          }
                        >
                          <AvRadio label="No" value="No" />
                          <AvRadio label="Yes" value="Yes" />
                        </AvRadioGroup>
                      </div>
                    )}

                  {this.state.type == "AccountCredit" && (
                    <div className="mb-3 d-none">
                      <label> Credit Amount </label>
                      <div className="group-addnon">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="validationTooltipUsernamePrepend"
                            >
                              (¢)
                            </span>

                          </div>
                          <AvField
                            name="credit_amount"
                            className="form-control"
                            type="number"
                            placeholder="Enter Credit Amount in Cents"
                            value={this.state.record.credit_amount}
                            validate={{
                              // required: { value: true, errorMessage: 'This field is invalid' },
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                            }}

                          />
                        </div>
                      </div>
                    </div>
                  )}


                  {
                    this.state.showTrialAction == 'Yes' && (
                      <div id="trial_fields">
                        <div className="mb-3">
                          <AvField
                            name="trial_duration"
                            label="Trial Duration"
                            className="form-control"
                            type="number"
                            placeholder="Enter trial duration in days"
                            value={this.state.record.trial_duration}
                            required
                            validate={{
                              // required: { value: true, errorMessage: 'This field is invalid' },
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                            }}
                          // onChange={(e) =>
                          //   this.addLimit(
                          //     "trial_duration",
                          //     "trial_duration",
                          //     e.target.value
                          //   )
                          // }
                          />
                        </div>

                        <div className="mb-3">
                          <label> Trial Fee </label>

                          <div className="group-addnon">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="validationTooltipUsernamePrepend"
                                >
                                  $
                                </span>

                              </div>
                              <AvField
                                name="trial_fee"
                                id="trial_fee"
                                type="number"
                                value={this.state.record.trial_fee}
                                required
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }} />

                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }


                  {this.role.id !== 1 && this.state.type !== "CoachPartner" && this.state.type !== "AccountCredit" && (
                    <div className="mb-3">
                      <AvField
                        name="monthly_credits"
                        label="Monthly Credits"
                        className="form-control"
                        type="number"
                        value={this.state.record.monthly_credits}
                        validate={{
                          required: { value: true, errorMessage: 'This field is invalid' },
                          pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                        }}
                        onChange={(e) =>
                          this.addLimit(
                            "monthly_credits",
                            "monthly_credits",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )}
                  {this.role.id !== 1 &&
                    this.state.type !== "CoachPartner" &&
                    this.state.type !== "AccountCredit" && (
                      <div className="mb-3">
                        <AvField
                          name="clients_limit"
                          label="Clients Limit"
                          className="form-control"
                          type="number"
                          value={this.state.record.clients_limit}
                          validate={{
                            required: { value: true, errorMessage: 'This field is invalid' },
                            pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                          }}
                          onChange={(e) =>
                            this.addLimit(
                              "clients_limit",
                              "clients_limit",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    )}
                  {this.role.id !== 1 && this.state.type !== "AccountCredit" && (
                    <div className="mb-3">
                      <AvField
                        name="coach_partner_limit"
                        label="Coach Partner Limit"
                        className="form-control"
                        type="number"
                        value={this.state.record.coach_partner_limit}
                        validate={{
                          required: { value: true, errorMessage: 'This field is invalid' },
                          pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                        }}
                        onChange={(e) =>
                          this.addLimit(
                            "coach_partner_limit",
                            "coach_partner_limit",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )}
                  {this.role.id !== 1 &&
                    this.state.type !== "CoachPartner" &&
                    this.state.type !== "AccountCredit" && (
                      <div className="mb-3">
                        <label>Features Available </label>
                        <AvCheckboxGroup
                          inline
                          name="features_available"
                          label=""
                          value={this.state.record.features_available}
                        >
                          <AvCheckbox
                            customInput
                            label=" Calculator"
                            value="Calculator"
                          />
                          <AvCheckbox customInput label="Sales" value="Sales" />
                          <AvCheckbox
                            customInput
                            label=" Marketing"
                            value="Marketing"
                          />
                        </AvCheckboxGroup>
                      </div>
                    )}
                  {this.role.id !== 1 && (
                    <div className="mb-3">
                      <label> Description </label>
                      <Button
                        type="button"
                        className="btn btn-sm info ml-5"
                        onClick={this.handleAddClick}
                      >
                        <i class="mdi mdi-plus"></i>
                      </Button>
                      {this.state.inputList.map((x, i) => {
                        return (
                          <div key={i} className="mb-3 box DesperationBox ">
                            {x.key == "voip_credits" ? (
                              <AvInput
                                name="description_meta[]"
                                id={i}
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "clients" ? (
                              <AvInput
                                name="description_meta[]"
                                id={i}
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "monthly_credits" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "clients_limit" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "coach_partner_limit" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "s_member_limit" ? (
                              <AvInput
                                name="description_meta[]"
                                id={i}
                                value={x.description_meta}
                                readOnly
                                className="form-control"
                              />
                            ) : x.key == "t_member_limit" ? (
                              <AvInput
                                name="description_meta[]"
                                id={i}
                                value={x.description_meta}
                                readOnly
                                className="form-control"
                              />
                            ) : (
                              <div className="input-group">
                                <AvInput
                                  name="description_meta"
                                  id={i}
                                  value={x.description_meta}
                                  label="Add Description"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                  className="form-control"

                                />
                                <div className="input-group-prepend">
                                  {this.state.inputList.length && (
                                    <button
                                      className="btn btn-md btn-danger ml-5"
                                      type="button"
                                      onClick={() => this.handleRemoveClick(i)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {this.state.package_type !== "CoachPartner" &&
                    this.state.package_type !== "AccountCredit" && (
                      <div className="mb-3">
                        <label>Type </label>
                        <AvRadioGroup
                          // value={this.state.record.package_type}
                          inline
                          name="package_type"
                          defaultValue={'default'}
                        >
                          <AvRadio label="Default" value="default" />
                          <AvRadio label="Custom" value="custom" />
                        </AvRadioGroup>
                      </div>
                    )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>

        {/* /////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
