import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  inputRemoveSpecialCharacter,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import swal from 'sweetalert';
export default class ProposalsAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.match.params.type,
      package_id: 4,
      selectedProduct: [],
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {
        product_id: null,
        name: "",
        proposal_status: "Active"
      },
      page_name: "Create new proposal",
      productList: {
        package: [],
        coaching: [],
        elearning: [],
      },
      product_type: '',
      roles: [],
      inputList: [],
    };
    this.role = { id: this.props.role.id };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    this.getProductList();
  }
  getProductList = () => {
    fetch(`${ApiUrl}` + "get/proposal/productList", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      }
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        //console.log(response.data.records,'respnsss')
        if (response.status === true) {
          this.setState({
            productList: response.data.records
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/proposal", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response.data.record, 'respnsss')
          this.setState({
            record: response.data.record,
            product_type: response.data.record.type
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getSelectedProduct = () => {
    fetch(`${ApiUrl}` + "get/proposal/productList", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      }
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        //console.log(response.data.records,'respnsss')
        if (response.status === true) {
          this.setState({
            productList: response.data.records
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmit = async (event, values) => {
    let check = false;
    if (this.state.id) {
      values.id = this.state.record.proposal_id ? this.state.record.proposal_id : -1;
    }
    if (this.state.record.product_id) {
      values.product_id = this.state.record.product_id;
    }
    if (!check) {
      fetch(`${ApiUrl}` + "add/proposal", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
          if (data.status === true) {
            if (this.state.id == null) {
              this.form && this.form.reset();
              this.setState({
                inputList: [],
              });
            }
            this.props.history.push("/packages/proposal/list");
            // this.getData();
            //               toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });   

          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })

        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };


  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>

        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card className="package-add">
              <AvForm
                onValidSubmit={this.handleSubmit}
                model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody className="pb-0">
                  <div className="mb-3">
                    <AvField
                      name="name"
                      value={this.state.record.name}
                      label="Name"
                      className="form-control"
                      required
                    />
                  </div>
                  {
                    this.state.record.type == 'Coaching' && ['default', 'clone'].includes(this.state.record.created_type) ?
                      <></>
                      : (
                        <>
                          <div className="mb-3">
                            <label> Select Product </label>
                            <select className="form-control" name="product_id"
                              onChange={(e) => {
                                let product_id = e.target.value;
                                let row = [];
                                if (product_id != '') {
                                  var prod = product_id.split("_");

                                  if (prod[0] == 'Package') {
                                    this.setState({
                                      product_type: 'Package'
                                    })
                                    row = this.state.productList.package.filter(item => item.product_id == prod[1]);
                                  }
                                  if (prod[0] == 'Coaching') {
                                    this.setState({
                                      product_type: 'Coaching'
                                    })
                                    row = this.state.productList.coaching.filter(item => item.product_id == prod[1]);
                                  }

                                  if (prod[0] == 'ELearning') {
                                    this.setState({
                                      product_type: 'ELearning'
                                    })
                                    row = this.state.productList.elearning.filter(item => item.product_id == prod[1]);
                                  }

                                  if (prod[0] == 'Roadmap') {
                                    this.setState({
                                      product_type: 'Roadmap'
                                    })
                                    row = [{
                                      price: 0,
                                      setup_price: 0,
                                      setup_fee_installment: 0
                                    }]
                                  }
                                }
                                this.setState(prevState => {
                                  let record = Object.assign({}, prevState.record);
                                  record.price = row[0].price;
                                  record.setup_price = row[0].setup_price;
                                  record.setup_fee_installment = row[0].setup_fee_installment ?? 0;
                                  record.product_id = product_id;
                                  return { record };
                                });
                              }}
                              value={this.state.record.product_id}
                            >
                              <option> Select Product</option>
                              <option value={'Roadmap_0'}
                                className="form-check pt-3">
                                Roadmap
                              </option>
                              <optgroup label="Signup Packages">
                                {
                                  this.state.productList && this.state.productList.package.map((row, i) => {
                                    return (
                                      <option value={'Package_' + row.product_id}
                                        className="form-check pt-3" key={i}>
                                        {`${row.name} | ($${row.price})`}
                                      </option>
                                    );
                                  })
                                }
                              </optgroup>
                              <optgroup label="Coaching Product">
                                {
                                  this.state.productList && this.state.productList.coaching.map((row, i) => {

                                    return (
                                      <option value={'Coaching_' + row.product_id}
                                        className="form-check pt-3" key={i}>
                                        {`${row.name} | ($${row.price})`}
                                      </option>
                                    );
                                  })
                                }
                              </optgroup>
                              <optgroup label="E-Learning Product">
                                {
                                  this.state.productList && this.state.productList.elearning.map((row, i) => {

                                    return (
                                      <option value={'ELearning_' + row.product_id}
                                        className="form-check pt-3" key={i}>
                                        {`${row.name} | ($${row.price})`}
                                      </option>
                                    );
                                  })
                                }
                              </optgroup>
                            </select>

                          </div>

                          {
                            this.state.product_type != 'Roadmap' && (
                              <>
                                <div className="mb-3">
                                  <label> Price </label>
                                  <div className="group-addnon">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="validationTooltipUsernamePrepend"
                                        >
                                          $
                                        </span>
                                      </div>
                                      <AvField
                                        name="price"
                                        id="price"
                                        type="number"
                                        value={this.state.record.price}
                                        validate={{
                                          required: { value: true, errorMessage: 'This field is invalid' },
                                          pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                        }} />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className={`${this.state.product_type == 'Package' ? 'col-md-6' : 'd-none'}`}>
                                    <AvField
                                      className="select form-control"
                                      type="select"
                                      value={this.state.record.setup_fee_installment}
                                      name="setup_fee_installment"
                                      label="Setup Fee Monthly Installment"
                                    >
                                      <option value={"0"}> 0 </option>
                                      <option value={"1"}> 1 </option>
                                      <option value={"2"}> 2 </option>
                                      <option value={"3"}> 3 </option>
                                      <option value={"4"}> 4 </option>
                                      <option value={"5"}> 5 </option>
                                      <option value={"6"}> 6 </option>
                                      <option value={"7"}> 7 </option>
                                      <option value={"8"}> 8 </option>
                                      <option value={"9"}> 9 </option>
                                      <option value={"10"}> 10 </option>
                                    </AvField>
                                  </div>

                                  <div className={`${this.state.product_type == 'Package' ? 'col-md-6' : 'col-md-12'}`}>
                                    <div className="mb-3">
                                      <label>Setup Fee </label>
                                      <div className="group-addnon">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text"
                                              id="validationTooltipUsernamePrepend"
                                            >
                                              $
                                            </span>

                                          </div>
                                          <AvField
                                            name="setup_price"
                                            id="setup_price"
                                            type="number"
                                            value={this.state.record.setup_price}
                                            validate={{
                                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }

                        </>
                      )
                  }

                  <div className="mb-3">
                    <AvField
                      className="select form-control"
                      type="select"
                      value={this.state.record.proposal_status}
                      name="proposal_status"
                      label="Proposal Status"
                    >
                      <option value={"Active"}> Active </option>
                      <option value={"Inactive"}> Inactive </option>
                    </AvField>
                  </div>
                </ModalBody>
                <ModalFooter className="border-0 pt-0">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light ms-2"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
