import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup } from "availity-reactstrap-validation";
import {
    bcrypt,
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"

import { currencies } from '../../../common/Common-Currency';
import AsyncSelect from 'react-select/async';
export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            currency_arr: [],
            module_arr: [],
            module_set_arr: {},
            currency_code: "USD",
            clients: [],
            client_id: this.props.client_id ? this.props.client_id : 0,
            permissions: [],
            permission: [],
            canEditCocah: true,
            list_tags: [],
            selectedTags: []
        };
    }
    // selectCurrency(e){
    //     const name = e.target.name;
    //     const val = e.target.value;
    //     this.setState({
    //         currency: prevState => ({ ...prevState, [name]:val })
    //     });
    //     console.log(currencies, 'currency');
    // };


    componentDidMount() {
        this.getFormData();
        this.getCurrency();
        this.getWayBookLists();
        this.getTagLists();
    }
    getCurrency = () => {
        var currency_arr = [];
        Object.keys(currencies).map(item => {
            currency_arr.push({ 'label': currencies[item].symbol + '- (' + currencies[item].name_plural + ') (' + currencies[item].code + ')', 'value': item });
        })
        this.setState({ currency_arr: currency_arr });
    }

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/assessment/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        clients: response.data.clients,
                        permissions: response.data.coachPartners,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    handleSubmit = async (event, values) => {
        if (this.state.client_id != '') {
            values.client_id = this.state.client_id;
        }

        fetch(`${ApiUrl}` + "add/trainingproducts", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }

                    // if (this.props.from != true) {
                    //     this.props.props.history.push(`/assessment/${bcrypt(data.data.assessment_id)}/summary`)
                    // }

                    //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    this.props.cancelSlider();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleCurrency = (selectedCurrency) => {
        console.log(selectedCurrency);
        this.setState({
            currency_code: selectedCurrency.value
        })
    }

    getWayBookLists = async () => {
        fetch(`${ApiUrl}` + "get/trainingproduct/category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ can_edit: this.state.canEditCocah, is_coach_section: 'Yes' }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    console.log(response.data.record)


                    this.setState({
                        module_arr: response.data.record
                    })

                } else {

                }


            })
            .catch((error) => {


                console.error("Error:", error);
            });
    };
    getTagLists = async () => {
        fetch(`${ApiUrl}` + "get/user/trainingtags", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ user_id: uInfo().user_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({ list_tags: response.data.records });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleChangeModule = (selectedOptions) => {
        this.setState({ module_set_arr: selectedOptions.map((option) => option.value) });
    }
    handleChangeTags = (selectedOptions) => {
        this.setState({ selectedTags: selectedOptions.map((option) => option.id) });

    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <ToastContainer />
                    <Col className="col-12">
                        <AvForm
                            onValidSubmit={this.handleSubmit}
                            ref={(c) => (this.form = c)}
                            className="needs-validation"
                        >
                            <ModalBody className="row">
                                <div className="col-md-12 mb-3">
                                    <AvField
                                        name="name"
                                        value={this.state.record.name}
                                        label="Product Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <AvField
                                        name="price"
                                        value={this.state.record.price}
                                        label="Price"
                                        type="number"
                                        className="form-control"
                                        required
                                    />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="control-label">Currency</label>
                                    <div className="MainTZone zIndexThree">
                                        <Select
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                            isMulti={false}
                                            options={this.state.currency_arr}
                                            value={this.state.currency_arr.filter(({ value }) => value === this.state.currency_code)}
                                            onChange={(e) => {
                                                this.handleCurrency(e)
                                            }}
                                            classNamePrefix="select form-control"
                                            className={"is-touched is-dirty av-valid"}
                                        />
                                    </div>
                                    <AvField type="hidden" name="assessment_currency" value={this.state.currency_code} />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className="control-label">Training Course(s)</label>
                                    <div className="MainTZone zIndexFour">
                                        <Select
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                            isMulti={true}
                                            options={this.state.module_arr}
                                            // value={this.state.module_arr.filter(({ value }) => value === this.state.step_id)}
                                            onChange={this.handleChangeModule}
                                            classNamePrefix="select form-control"
                                            className={"is-touched is-dirty av-valid"}
                                        />
                                    </div>
                                    <AvField type="hidden" name="module[]" value={this.state.module_set_arr} />
                                </div>
                                {/* <div className="col-md-12 mb-3">
                                    <label className="control-label">Training tags</label>
                                    <div className="MainTZone zIndexFive">
                                        <Select getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isMulti={true}
                                            options={this.state.list_tags || []} // Simplified condition
                                            onChange={this.handleChangeTags}

                                            classNamePrefix="select2-selection position-relative"
                                            name="tags[]"
                                            isDisabled={!this.state.list_tags || this.state.list_tags.length === 0}
                                            noOptionsMessage={() => null}
                                        />
                                    </div>
                                    <AvField type="hidden" name="tags[]" value={this.state.selectedTags} />
                                </div> */}
                                {/* <div className="col-md-12 mb-3">
                                    <AvField
                                        className="select form-control"
                                        type="select"
                                        name="module"
                                        label="Training Module"
                                        required
                                        value="6"
                                    >
                                    
                                        <option value="6">Pathway to Profit</option>
                                    </AvField>
                                </div> */}



                            </ModalBody>
                            <ModalFooter>
                                <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </ModalFooter>
                        </AvForm>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
