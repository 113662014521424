import React, { Component } from "react";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import swal from "sweetalert";
import { ApiUrl } from "../../config";
import { bcrypt, uToken } from "../../useToken";

class EmailToolbar extends Component {
  state = {
    dropdownOpen: false,
    isSearch: false,
    searchTerm: "",
  };

  toggleDropdown = () => {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
  };

  searchClient = async (searchTerm) => {
    this.setState({ searchTerm });

    try {
      const response = await fetch(`${ApiUrl}aio/report/email/search?search=${searchTerm}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${uToken()}`,
        },
      });
      const data = await response.json();

      if (data.status) {
        this.setState({ existing: data.data.records, isClient: true });
      } else {
        this.setState({ existing: [], isClient: false });
      }
    } catch (error) {
      console.error("Error:", error);
      this.setState({ existing: [], isClient: false });
    }

    this.props.onSearchChange(searchTerm);
  };

  render() {
    const {
      currentPage,
      totalEmails,
      pageSize,
      onPageChange,
      onPageSizeChange,
      hidePagination,
      hideSearch,
      hideDeleteButton,
    } = this.props;

    const { isSearch, searchTerm, dropdownOpen } = this.state;

    return (
      <div className="btn-toolbar d-flex align-items-center DeleteBtn" role="toolbar">
        <div style={{ marginLeft: "8px" }} className="d-flex flex-grow-1 align-items-center">
          {!hideSearch && (
            <div className="search-container position-relative flex-grow-1" style={{ position: 'relative' }}>
              <span
                className="search-icon"
                style={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#aaa',
                  pointerEvents: 'none',
                }}
              >
                <i className="fas fa-search" />
              </span>
              <input
                type="text"
                className="form-control search-input"
                style={{ paddingLeft: '30px' }}
                placeholder="Search mail..."
                value={searchTerm}
                onChange={(e) => this.searchClient(e.target.value)}
                onFocus={() => this.setState({ isSearch: true })}
                onBlur={() => setTimeout(() => this.setState({ isSearch: false }), 200)}
              />
            </div>
          )}
        </div>

        {!hidePagination && (
          <div className="pagination-controls d-flex align-items-center ms-3">
            <span
              className="text-muted"
              style={{
                padding: "6px 12px",
                borderRadius: "20px",
                backgroundColor: "#f1f3f4",
                color: "#5f6368",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                margin: "0 8px",
              }}
            >
              {`${(currentPage - 1) * pageSize + 1} - ${Math.min(currentPage * pageSize, totalEmails)} of ${totalEmails}`}
            </span>

            <Button
              color="primary"
              className="waves-light waves-effect me-2"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="mdi mdi-chevron-left" />
            </Button>

            <Button
              color="primary"
              className="waves-light waves-effect"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalEmails / pageSize)}
            >
              <i className="mdi mdi-chevron-right" />
            </Button>

            <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown} className="ms-2">
              <DropdownToggle
                className="btn btn-primary waves-light waves-effect dropdown-toggle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "4px 8px",
                  fontSize: "14px",
                  borderRadius: "4px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span>{pageSize}</span>
                <i className="mdi mdi-chevron-down" style={{ marginLeft: "8px", fontSize: "16px" }} />
              </DropdownToggle>

              <DropdownMenu
                className="dropdown-menu-end"
                style={{
                  borderRadius: "8px",
                  minWidth: "100px",
                }}
              >
                {[10, 20, 50, 100].map((size) => (
                  <DropdownItem
                    key={size}
                    onClick={() => onPageSizeChange(size)}
                    style={{
                      padding: "8px 16px",
                      borderRadius: "4px",
                    }}
                    className="d-flex align-items-center"
                  >
                    {size}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>
    );
  }
}

export default EmailToolbar;