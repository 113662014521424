import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select";
import countries from "../../../../common/data/countries";

export default class AssesmentTrainingTakeQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: dcrypt(this.props.match.params.id),
      customField: [],
      customFieldData: {},
      page_name: 'Take Quiz'
    };
  }

  componentDidMount() {
    this.getQuizQuestion();
  }



  getQuizQuestion = () => {
    fetch(`${ApiUrl}` + "training/quiz/question/" + this.state.id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response.data.records, 'response.data.records')
          this.setState({
            customField: response.data.records,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  handleSubmit = async (event, values) => {
    values.custom_fields = this.state.customFieldData;
    values.cat_id = this.state.id;

    fetch(`${ApiUrl}` + "edit/client", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        // console.log(data, 'Editing');
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          if (this.state.from == "companyDetails") {
            this.props.getData();
          } else {
            setTimeout(() => this.props.history.push("/client/list"), 1000);
          }
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  handleChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFieldData: {
        ...prevState.customFieldData,
        [name]: value,
      },
    }));
  }

  CreateCustomFieldUI() {
    return this.state.customField.map((el, i) => {
      return (
        <div className="col-md-6 mb-3" key={el.question}>
          <label>{`${el.question}`}</label>
          <fieldset className="form-group">
            <input className="form-control" type={'text'} value={el.quiz_id} name="quiz_id[]" />
            <div className="form-control border-0 p-0 h-auto ">
              {el.options.map((row, i) => {
                return (
                  <div key={i} className="form-check form-check-inline">
                    <input
                      checked={
                        this.state.customFieldData['quiz_id_' + el.quiz_id] == row.option
                          ? "checked"
                          : ""
                      }
                      onChange={this.handleChange.bind(this)}
                      name={'quiz_id_' + el.quiz_id}
                      type="radio"
                      className="form-check-input"
                      value={row.option}
                    />
                    <label className="form-check-label">{`${row.option}`}</label>
                  </div>
                );
              })}
            </div>
          </fieldset>
        </div>
      );
    });
  }

  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />

        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                // model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody className="row">
                  {console.log(this.state.customField, 'this.state.customField')}
                  {this.state.customField.length > 0 &&
                    this.CreateCustomFieldUI()}
                </ModalBody>
                <ModalFooter>
                  {this.state.from != "companyDetails" && (
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancel
                    </Button>
                  )}

                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
        {/* /////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
