import React, { useState, useCallback, useEffect, useRef } from 'react';
import { toast, ToastContainer, Flip } from "react-toastify";
import { ApiUrl } from '../../../config';
import { uInfo, uToken, truncateString, dcrypt } from '../../../useToken';
import $ from "jquery";
import Modal from 'react-bootstrap/Modal'
import SweetAlert from "react-bootstrap-sweetalert"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Button, ButtonToolbar, Overlay, Tooltip } from 'react-bootstrap';
import Select from "react-select";
const arrowsPlus = require('../../../assets/images/arrows_plus.png');


const PipelineStatusFlow = (props) => {

    const [record, setRecord] = useState([]);
    const [show, setShow] = useState(false);
    const [getTypeContent, setTypeContent] = useState("");
    const [alert, setAlert] = useState(false);
    const [msg, setMsg] = useState("");
    const [menu, setMenu] = useState("");

    const [childid, setChildid] = useState(0);
    const [nextId, setNextId] = useState(0);
    const [nodeId, setNodeId] = useState(0);
    const [isOk, setIsOk] = useState('no');
    const [g_cond, setGCond] = useState('');
    const [statusId] = useState(props.match.params ? props.match.params.status_id : 0);
    const [metaData, setMetaData] = useState({
        pipeline_status_data: {},
        opt_: [{ options: [] }, { options: [] }]
    });

    const [editModal, setEditModal] = useState(false);

    const [formFieldData, setFormFieldData] = useState({
        email_subject: '',
        email_body: '',
        sms_body: '',
        groups_id: [],
        email_token: '',
        from_email_id:'',
        sms_token: '',
        Copy: false,
        Move: false,
        Remove: false,
        AddTags: false,
        RemoveTags: false,
        AddNotes: false,
        UnsubscribePhone: false,
        UnsubscribeEmail: false,
        wait_type: "minute",

    });
    const [seletedTemplate, setSeletedTemplate] = useState('');

    const [html, setHtml] = useState("");
    const [path, setPath] = useState("");

    const [showP, setShowP] = useState({});
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event, key) => {
        setShowP({ ...showP, [key]: true });
        // setShowP(false);
        // setShowP(true);
        setTarget(event.target);
    };

    const form = useRef(null);

    const overlayShow = useRef(null);

    useEffect(() => {
        getSeries();
        getMetaData();

        var outerContent = $('.house-tree');
        var innerContent = $('.tree-point');
        outerContent.scrollLeft((innerContent.width() - outerContent.width()) / 2);
    }, []);


    const getMetaData = () => {
        fetch(`${ApiUrl}` + "get/pipeline/flowMetaData", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ 'status_id': dcrypt(statusId) })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setMetaData(response.data && response.data)
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const renderHtml = (data) => {

        if (data.records.length > 0) {

            let obj = Object.values(data.bTree);
            // console.log(obj);
            return obj.map && obj.map(($treeArray, $parentNode) => {
                //let idx = $treeArray.indexOf(); 
                //console.log($treeArray, 'is here');
                let $nodeId = $treeArray['0'];
                let $childid = $treeArray['1'];
                //let $parentNode = $treeArray['2'];
                //console.log($parentNode, 'parentNode');
                let $nxtId = 0;
                if ($childid != 0) {
                    $nxtId = $nodeId;
                }

                if ($parentNode == 0) {
                    return (
                        <li className="plus_outer startNow" key={$parentNode}>
                            <ul>
                                <li>
                                    {addChildHtml($nodeId, $childid)}
                                </li>

                            </ul>
                            {/* <ul>
                                <li className='child a'>
                                    <a className="plus" onClick={(e) => chooseAction($childid, $nodeId, e)}>
                                         <img src={arrowsPlus} />
                                    </a>
                                </li>
                            </ul> */}
                        </li>
                    )
                }
            });
        } else {
            return <li className="plus_outer startNow">
                <a className="plus" onClick={(e) => chooseAction(0, 0, e)}>
                    <img src={arrowsPlus} />
                </a>
            </li>;
        }


    }


    function notIn($type) {
        let $actionArr = ['end', 'goto', 'start'];
        if ($actionArr.includes($type) === false) {
            return true;
        } else {
            return false;
        }
    }

    const addChildHtml = ($nodeId, $childid) => {


        let $dataArray = record.dataArray;

        let $childData = $dataArray[$nodeId];
        let $series = JSON.parse($childData.seriesData);
        let $returnHtml = '<p>A</p>';

        if ($childData.length == 0) {
            return $returnHtml;
        }


        $series.groups_id = $dataArray[$nodeId].group_ids;
        $series.tags_id = $dataArray[$nodeId].tags_id;
        $series.p_series_id = $dataArray[$nodeId].series_id;
        $series.html = $dataArray[$nodeId].html;

        // if (getProcessed.includes($nodeId)) {
        //     return $returnHtml;
        // }

        // setProcessed(oldArray => [...oldArray, $nodeId]);


        let $childNotes = $childData.notes != '' ? JSON.parse($childData.notes) : 0;



        return (
            <>
                <a href="javascript:;" className="delete"
                    onClick={() => deleteNode($nodeId, $childid, $childData.previous_id, $childData.type)}
                    title="Delete"><i className="fa fa-times" aria-hidden="true"></i>
                </a>

                <div style={{ position: 'relative' }}>
                    <div className='button-notes-view'>
                        <button variant='none' className='notes-list' onClick={(e) => handleClick(e, `key_${$nodeId}`)}><i className="fa fa-file" aria-hidden="true"></i></button>

                        <Overlay
                            show={showP[`key_${$nodeId}`]}
                            target={target}
                            placement="left"
                            rootClose={true}
                            onHide={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })}
                        >
                            <Popover id="popover-contained">
                                <Popover.Header as="h3">Notes </Popover.Header>
                                <Popover.Body style={{ padding: "0.5rem 0.75rem 0" }}>
                                    <div className="add_note_drapdown_body">
                                        <div className={`col-md-12 show-notes nkt-${$nodeId}`}>
                                            {
                                                $childNotes.length > 0 && (
                                                    $childNotes.map(($note, i) => {
                                                        return <div className={`notes notee-${i}`}>
                                                            <div className="notes-text">{$note}</div>
                                                            <span className="notes-delete fas fa-trash cursor-pointer" title='Delete a note' href="javascript:;" onClick={() => deleteNote($nodeId, i)}></span>
                                                        </div>
                                                    })
                                                )
                                            }


                                        </div>
                                        <textarea className={`form-control text-note-${$nodeId}`} placeholder="Type note here ..."></textarea>
                                        <div className="add_note_drapdown_button">
                                            <button onClick={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })} className="btn btn-info mr-5">Cancel</button>
                                            <button onClick={(e) => addNote($nodeId, e)} className="btn btn-primary">Add</button>
                                        </div>
                                    </div>
                                </Popover.Body>
                            </Popover>
                        </Overlay>
                    </div>
                </div>
                {
                    $childData.type == 'sms' ?
                        <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'sms', 'sms')}>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        FollowUpId # {$series.p_series_id}
                                        <br />
                                        Remind On: {$series.reminds}
                                        <br />
                                        Trigger On: {$series.trigger_interval}
                                    </Tooltip>
                                }
                            >
                                <div className="sb_button_inner btn-primary sb_editable"
                                    childid={`${$nodeId}`}>
                                    <i className="fa fa-mobile" aria-hidden="true"></i> Send SMS ( {truncateString($series.sms_body, 30)} )
                                </div>
                            </OverlayTrigger>
                        </div>
                        :
                        $childData.type == 'voicemail' ?
                            <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'voicemail', 'voicemail')}>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            FollowUpId # {$series.p_series_id}
                                            <br />
                                            Remind On: {$series.reminds}
                                            <br />
                                            Trigger On: {$series.trigger_interval}
                                        </Tooltip>
                                    }
                                >
                                    <div className="sb_button_inner btn-primary sb_editable"
                                        childid={`${$nodeId}`}>
                                        <i className="fa fa-phone" aria-hidden="true"></i> RvM
                                    </div>
                                </OverlayTrigger>
                            </div>
                            :
                            $childData.type == 'email' ?
                                <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'email', 'email')} >
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                FollowUpId # {$series.p_series_id}
                                                <br />
                                                Remind On: {$series.reminds}
                                                <br />
                                                Trigger On: {$series.trigger_interval}
                                            </Tooltip>
                                        }
                                    >
                                        <div className="sb_button_inner btn-primary sb_editable"
                                            childid={`${$nodeId}`}>
                                            <i className="fa fa-envelope" aria-hidden="true"></i> Email
                                        </div>
                                    </OverlayTrigger>
                                </div>

                                :
                                $childData.type == 'contact_action' ?
                                    <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'contact_action', 'contact_action')} >
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id="button-tooltip">
                                                    FollowUpId # {$series.p_series_id}
                                                </Tooltip>
                                            }
                                        >
                                            <div className="sb_button_inner btn-primary sb_editable"
                                                childid={`${$nodeId}`}>
                                                <i className="fas fa-user" aria-hidden="true"></i> Contact Action
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    : $childData.type == 'wait' ?
                                        <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'wait', 'wait')} >
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id="button-tooltip">
                                                        FollowUpId # {$series.p_series_id}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="sb_button_inner btn-primary sb_editable"
                                                    childid={`${$nodeId}`}>
                                                    <i className="fas fa-clock" aria-hidden="true"></i> Wait
                                                </div>
                                            </OverlayTrigger>
                                        </div>

                                        : $childData.type == 'salespipeline' ?
                                            <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'salespipeline', 'salespipeline')} >
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                            FollowUpId # {$series.p_series_id}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="sb_button_inner btn-primary sb_editable"
                                                        childid={`${$nodeId}`}>
                                                        <i className="far fa-user" aria-hidden="true"></i> Sale/Pipeline
                                                    </div>
                                                </OverlayTrigger>
                                            </div>


                                            : $childData.type == 'condition' ?

                                                <>

                                                </>
                                                // <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                                                //     <div className="sb_button_inner btn-primary sb_editable"
                                                //         childid={`${$nodeId}`}>
                                                //         <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.title})
                                                //     </div>
                                                // </div>

                                                : ""

                }



                {
                    $childData.type == 'condition' ? (
                        conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                    ) : conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                }


            </>
        );



    }


    function addNote($nodeId, obj) {
        var note = $.trim($('.text-note-' + $nodeId).val());
        if (note != '') {
            fetch(`${ApiUrl}` + "add/note/series/" + $nodeId, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ note: note })
            })
                .then((response) => response.json())
                //Then with the data from the response in JSON...
                .then((response) => {
                    if (response.status === true) {
                        $('.text-note-' + $nodeId).val(' ');
                        getSeries();
                    } else {


                        toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }

    function conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series) {
        if ($childData.type == 'condition') {
            let $subChild = $dataArray[$childid] ? $dataArray[$childid] : {};
            let $preChild = [];
            if ($childData.pre_next_id != 0 && $childData.pre_next_id != $childid) {
                $preChild = $dataArray[$childData.pre_next_id];
            }

            let $trueChildId = 0;
            let $falseChildId = 0;

            if ($subChild.condition_status == 'true' && $subChild.series_id == $childid) {
                $trueChildId = $childid;
            }
            if ($subChild.condition_status == 'true' && $subChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childid) {
                $trueChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childid) {
                $falseChildId = $childid;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childid) {
                $falseChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }

            return (
                <>
                    <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                        <div className="sb_button_inner btn-primary sb_editable"
                            childid={`${$nodeId}`}>
                            <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.title})
                        </div>
                    </div>

                    <ul className='tree-condition'>
                        <li>
                            <ul className='tree-condition-ul'>
                                <li className='tree-condition-li-yes'>
                                    <ul>
                                        <li className="plus_outer yes-outer">
                                            <a className="sb_button_inner condition_yes " childid="end">Yes</a>
                                            <br />
                                            <a className="plus" onClick={(e) => chooseAction($trueChildId, $nodeId, e, 'yes')}>  <img src={arrowsPlus} /></a>
                                            {/* $returnHtml .= $this->getChild($nodeId, $trueChildId); */}
                                            {getChild($nodeId, $trueChildId)}
                                        </li>
                                    </ul>
                                </li>
                                <li className='tree-condition-li-no'>
                                    <ul>
                                        <li className="plus_outer">
                                            <a className="sb_button_inner condition_no" id={`yes_${$nodeId}`} childid="end">No</a>
                                            <br />
                                            <a className="plus" onClick={(e) => chooseAction($falseChildId, $nodeId, e, 'no')}>  <img src={arrowsPlus} /></a>
                                            {getChild($nodeId, $falseChildId)}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            )


        }

        if (notIn($childData.type)) {
            return <ul>
                <li className="plus_outer">
                    <a className="plus" onClick={(e) => chooseAction($childid, $nodeId, e)}>
                        <img src={arrowsPlus} /></a>
                    {getChild($nodeId, $childid)}
                </li>
            </ul>
        }
    }

    function getChild($parentNode, $nodeId) {
        let $dataArray = record.dataArray;

        if ($nodeId != 0) {
            let $childData = $dataArray[$nodeId];
            let $childid = $childData.next_id;
            return (
                <ul>
                    <li>
                        {addChildHtml($nodeId, $childid)}
                    </li>
                </ul>
            )
        }
    }


    const dynamicSetting = (modal, data, type, contetType) => {
        if (data.html) {
            setHtml(data.html);
        } else {
            setHtml('');
        }

        data.email_token = '';
        data.sms_token = '';
        setShow(modal);
        setFormFieldData(data);
        setMenu(type);
        setTypeContent(contetType);
        setEditModal(true);
    }

    const dynamicModal = (type) => {
        setMenu(type);
        setTypeContent(type);
    }

    const modalHide = () => {
        setShow(false);
        setFormFieldData({
            email_subject: '',
            email_body: '',
            sms_body: '',
            groups_id: [],
            email_token: '',
            sms_token: '',
            Copy: false,
            Move: false,
            Remove: false,
            AddTags: false,
            RemoveTags: false,
            AddNotes: false,
            UnsubscribePhone: false,
            UnsubscribeEmail: false,
            wait_type: "minute",
        });
        setMenu('');
        setTypeContent('');
        setEditModal(false);
        setSeletedTemplate('');
        setHtml('');
        setPath('');
    }

    const deleteNode = ($nodeId, $childid, $previous_id, $type) => {
        fetch(`${ApiUrl}` + "delete/pipeline/status/series/" + dcrypt(statusId), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ nodeId: $nodeId, childId: $childid, previousId: $previous_id, type: $type })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const deleteNote = ($nodeId, $noteIndex) => {
        fetch(`${ApiUrl}` + "delete/note/series/" + $nodeId, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ index: $noteIndex })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }




    const getSeries = () => {
        fetch(`${ApiUrl}` + "pipeline/status/series/" + dcrypt(statusId), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    //console.log(response.data, 'getSeries');
                    setRecord(response.data);
                    // renderHtml(response.data.records);
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    const chooseAction = (nId, cId, obj, cond = '') => {
        $('.btn-group-vertical .btn.green').removeClass('active');
        $('.action-description').html('<h4 style="text-align:center;">Select Action From Menu</h4>');
        // getTypeContent('type');
        $('#action-detail').removeClass('col-md-12');
        $('#action-detail').addClass('col-md-9');
        $('#action-nav').show();
        // $('.modal-title').html('Add New Action');
        setShow(true);
        setChildid(0);
        setNextId(nId);
        setNodeId(cId);
        setIsOk('no');
        setGCond(cond);

    }


    const saveAction = (type) => {

        if (type == 'sms') {
            if ($('input[name=sms_body]').val() == '') {
                setMsg('Please enter sms body!');
                setAlert(true);
                return false;
            }
        }

        if (type == 'voicemail') {
            if ($('select[name=rvm_template]').val() == '') {
                setMsg('Please enter voicemail!');
                setAlert(true);
                return false;
            }
        }


        if (type == 'wait') {

            if ($('input[name=wait_duration]').val() == '' || $('input[name=wait_duration]').val() == '0') {
                setMsg('Please enter wait duration greater than 0!');
                setAlert(true);
                return false;
            }

            if ($('select[name=wait_type]').val() == '') {
                setMsg('Please select wait type!');
                setAlert(true);
                return false;
            }
        }

        if (type == 'salespipeline') {

            if ($('input[name=status_id]').val() == '') {
                setMsg('Please select status!');
                setAlert(true);
                return false;
            }

            if ($('select[name=assigned_to]').val() == '') {
                setMsg('Please select member!');
                setAlert(true);
                return false;
            }

            if ($('textarea[name=comments]').val() == '') {
                setMsg('Please enter comments!');
                setAlert(true);
                return false;
            }
        }

        if (type == 'email') {

            if ($('input[name=email_subject]').val() == '') {
                setMsg('Please enter email subject!');
                setAlert(true);
                return false;
            }

            if ($('textarea[name=email_body]').val() == '') {
                setMsg('Please enter email body!');
                setAlert(true);
                return false;
            }
        }

        if (type == 'contact_action') {


            var copy_groups_id = $('input[name="copy_groups_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!copy_groups_id) {
                setMsg('Please select a group for copy!');
                setAlert(true);
                return false;
            }

            var move_groups_id = $('input[name="move_groups_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!move_groups_id) {
                setMsg('Please select a group for move!');
                setAlert(true);
                return false;
            }

            var remove_groups_id = $('input[name="remove_groups_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!remove_groups_id) {
                setMsg('Please select a group for remove!');
                setAlert(true);
                return false;
            }

            var add_tags_id = $('input[name="add_tags_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!add_tags_id) {
                setMsg('Please select a tag for add!');
                setAlert(true);
                return false;
            }

            var remove_tags_id = $('input[name="remove_tags_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!remove_tags_id) {
                setMsg('Please select a tag for remove!');
                setAlert(true);
                return false;
            }


            if ($('textarea[name=notes]').val() == '') {
                setMsg('Please enter note!');
                setAlert(true);
                return false;
            }




        }



        let data = {
            statusId: dcrypt(statusId),
            childId: childid,
            nextId: nextId,
            nodeId: nodeId,
            cond: g_cond,
            isOk: isOk,
            type: type,

        }
        console.log(data, 'data333');

        fetch(`${ApiUrl}` + "save/pipeline/status/action?" + $('.sms-form').serialize(), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                    modalHide();
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormFieldData({ ...formFieldData, [name]: value });
        if (name == 'sms_template') {
            setFormFieldData({ ...formFieldData, ['sms_body']: value });
        }
        if (name == 'email_template' && value != '') {
            setFormFieldData({ ...formFieldData, ['email_subject']: e.target.selectedOptions[0].getAttribute("data-title") });
            getEmailTemplateHtml(value);
        }

        if (name == 'email_template' && value == '') {
            setFormFieldData({ ...formFieldData, ['email_subject']: '' });
            setHtml('');
            setPath('');
        }

        if (name == 'from_email_id' && value != ''){
            setFormFieldData({...formFieldData, ['from_email_id']:value});
        }

        if (name === 'sms_template' || name === 'email_template' || name === 'rvm_template') {
            setSeletedTemplate(e.target.selectedOptions[0].getAttribute("data-id"));

        }
    }


    const getEmailTemplateHtml = (id) => {
        fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                setHtml(response.theme);
                setPath(response.path);
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <React.Fragment>
            <div className='mb-4'>

                <div className='row'>
                    <div className='col-sm-5' sm={5}>
                        <div className="page-title-box">
                            <h4>
                                {metaData.pipeline_status_data.title}
                            </h4>
                        </div>
                    </div>
                    <div className='col-sm-3' sm={3}></div>
                    <div className='col-sm-4'>
                        <div className="page-title-box text-align-right">
                            <button className='btn btn-secondary'
                                onClick={() => props.history.push("/pipeline/list?p=" + metaData.master_pipeline_id)}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>


                {alert ? (
                    <SweetAlert
                        title={msg}
                        warning
                        onConfirm={() => setAlert(false)}
                    />
                ) : null}


                <div className='card card-body house-tree custom-scroll' >


                    <div className='event-tree'>
                        <h5>Start Adding Automation! </h5>
                    </div>
                    <ul className="tree-point pb-4">
                        {record.html == '' ? renderHtml(record) : ""}
                    </ul>

                </div>

                <Modal
                    show={show}
                    onHide={() => modalHide()}
                    dialogClassName="modal-90w"
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {editModal ? 'Edit Action' : 'Add Action'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='row'>
                        <div className={`${editModal ? 'd-none' : 'col-md-3 col-sm-3 col-xs-12'}`} id="action-nav">
                            <div className={`btn-group-vertical left_vertical_tabs  ${editModal ? 'd-none' : ''}`}>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'email' ? 'active' : ''}`} onClick={() => dynamicModal('email')} data-toggle="dropdown">
                                        <i className="fas fa-envelope"></i> Send Email
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'sms' ? 'active' : ''}`} onClick={() => dynamicModal('sms')} data-toggle="dropdown">
                                        <i className="fas fa-mobile"></i> Send SMS
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'voicemail' ? 'active' : ''}`} onClick={() => dynamicModal('voicemail')} data-toggle="dropdown">
                                        <i className="fas fa-phone"></i> Voicemail
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'contact_action' ? 'active' : ''}`} onClick={() => dynamicModal('contact_action')} data-toggle="dropdown">
                                        <i className="fas fa-user"></i> Contact Action
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'wait' ? 'active' : ''}`} onClick={() => dynamicModal('wait')} data-toggle="dropdown">
                                        <i className="fas fa-clock"></i> Wait
                                    </button>
                                </div>

                                <div className="btn-group d-none">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'salespipeline' ? 'active' : ''}`} onClick={() => dynamicModal('salespipeline')} data-toggle="dropdown">
                                        <i className="far fa-user"></i> Sale/Pipeline
                                    </button>
                                </div>


                            </div>
                        </div>
                        <div className={`${editModal ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-9 col-sm-9 col-xs-12'}`}>
                            {getTypeContent == 'sms' || getTypeContent == 'voicemail' || getTypeContent == 'email' || getTypeContent == 'contact_action' || getTypeContent == 'wait' || getTypeContent == 'salespipeline' ? (
                                <form ref={form} className="sms-form" method="post">
                                    {/* <div className="form-group mb-3">
                                        <label>Tags (Optional)</label>
                                        <Select
                                            getOptionLabel={option => option.title}
                                            getOptionValue={option => option.group_id}
                                            isMulti={true}
                                            value={formFieldData.tags_id}
                                            options={metaData.tags}
                                            classNamePrefix="select2-selection"
                                            name="tags_id[]"
                                            onChange={(selectedTags) => setFormFieldData({ ...formFieldData, ['tags_id']: selectedTags })}
                                        />

                                    </div> */}


                                    <input readOnly type={'hidden'} name="template_id" value={seletedTemplate} />


                                    {
                                        getTypeContent == 'sms' ?
                                            <div className="sms-content">
                                                <div className="form-group mb-3">
                                                    <label>SMS Template</label>
                                                    <div className="input-group">
                                                        <select defaultValue={formFieldData.sms_template} onChange={(e) => handleChange(e)} name="sms_template" className='form-control' >
                                                            <option value={''}>Select</option>
                                                            {
                                                                metaData.smsTemplates.map((sms, s) => {
                                                                    return <option data-id={sms.id} key={s} value={sms.body}>{sms.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label> SMS Token</label>
                                                    <div className="input-group">
                                                        <select onChange={(e) => handleChange(e)} name="sms_token" className='form-control' >
                                                            <option value={''}>Select Token</option>
                                                            <optgroup label="Lead Default Field Tokens" >
                                                                {
                                                                    metaData.tokens.default_fields.map((df, d) => {
                                                                        return <option key={'default' + d} value={`{${df.key}}`}>{df.label}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                            <optgroup label="Lead Custom Field Tokens" >
                                                                {
                                                                    metaData.tokens.custom_fields.map((cf, c) => {
                                                                        return <option key={'custom' + c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                            <optgroup label="User Tokens" >
                                                                {
                                                                    metaData.tokens.users_token.map((cf, c) => {
                                                                        return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                        </select>

                                                        <button onClick={() => setFormFieldData({ ...formFieldData, ['sms_body']: formFieldData.sms_body + '  ' + formFieldData.sms_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label>SMS Body</label>
                                                    <div className="input-group">
                                                        <textarea placeholder='Add message body' rows="4" cols="50" value={formFieldData.sms_body} onChange={(e) => handleChange(e)} name="sms_body" className='form-control' />
                                                    </div>
                                                </div>
                                                <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                <div className="form-group">
                                                    <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('sms')}>Save</a>
                                                </div>
                                            </div>
                                            :
                                            getTypeContent == 'voicemail' ?
                                                <div className="voicemail-content">
                                                    <div className="form-group mb-3">
                                                        <label>RVM Template</label>
                                                        <div className="input-group">
                                                            <select defaultValue={formFieldData.rvm_template} onChange={(e) => handleChange(e)} name="rvm_template" className='form-control' >
                                                                <option value={''}>Select</option>
                                                                {
                                                                    metaData.voiceTemplates.map((rvm, r) => {
                                                                        return <option data-id={rvm.id} key={r} value={rvm.body}>{rvm.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />

                                                    <div className="form-group">
                                                        <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('voicemail')}>Save</a>
                                                    </div>
                                                </div>
                                                : getTypeContent == 'email' ?
                                                    <div className="email-content">
                                                        <div className="form-group mb-3">
                                                            <label>Email Template</label>
                                                            <div className="input-group">
                                                                <select defaultValue={formFieldData.email_template} onChange={(e) => handleChange(e)} name="email_template" className='form-control' >
                                                                    <option value={''}>Select</option>
                                                                    {
                                                                        metaData.emailTemplates.map((email, e) => {
                                                                            return <option data-title={email.title} data-id={email.id} key={e} value={email.id}>{email.title}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label> Email Token</label>
                                                            <div className="input-group">
                                                                <select onChange={(e) => handleChange(e)} name="email_token" className='form-control' >
                                                                    <option value={''}>Select Token</option>
                                                                    <optgroup label="Default Fields" >
                                                                        {
                                                                            metaData.tokens.default_fields.map((df, d) => {
                                                                                return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                    <optgroup label="Custom Fields" >
                                                                        {
                                                                            metaData.tokens.custom_fields.map((cf, c) => {
                                                                                return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                </select>

                                                                <button
                                                                    onClick={() => setFormFieldData({ ...formFieldData, ['email_subject']: formFieldData.email_subject + '  ' + formFieldData.email_token })}
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                >
                                                                    Insert Subject
                                                                </button>
                                                                {html == "" && (
                                                                    <button onClick={() => setFormFieldData({ ...formFieldData, ['email_body']: formFieldData.email_body + '  ' + formFieldData.email_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                         <div className="form-group mb-3">
                                                        <label>Email From</label>
                                                        <div className="input-group">
                                                            <select defaultValue={formFieldData.from_email_id} onChange={(e) => handleChange(e)} name="from_email_id" className='form-control' >
                                                                <option value={''}>Select</option>
                                                                {
                                                                    metaData.FromEmail.map((email, e) => {
                                                                        return <option data-title={email.from_email} data-id={email.setting_id} key={e} value={email.setting_id}>{email.from_name} - {email.from_email} - {email.type}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                       </div>

                                                        <div className="form-group mb-3">
                                                            <label>Email Subject</label>
                                                            <div className="input-group">
                                                                <input value={formFieldData.email_subject} onChange={(e) => handleChange(e)} className='form-control' name="email_subject" />
                                                            </div>
                                                        </div>

                                                        {html == "" ? (
                                                            <div className="form-group mb-3">
                                                                <label>Email Body</label>
                                                                <div className="input-group">
                                                                    <textarea value={formFieldData.email_body} onChange={(e) => handleChange(e)} name="email_body" className='form-control' />
                                                                </div>
                                                            </div>
                                                        ) : (<>
                                                            <div
                                                                id="custom-template"
                                                                className="height-500 custom-scroll"
                                                                dangerouslySetInnerHTML={{ __html: html }}
                                                            />
                                                            <input value={path} name="email_body" className='d-none' />
                                                        </>
                                                        )}
                                                        {/* <textarea readOnly value={JSON.stringify(html)} name="email_body" className='form-control d-none' /> */}
                                                        <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                        <div className="form-group">
                                                            <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('email')}>Save</a>
                                                        </div>
                                                    </div>
                                                    : getTypeContent == 'contact_action' ?
                                                        <div className="contact_action-content">
                                                            <div className="form-group">
                                                                <label className="control-label"> Available Actions: </label>
                                                                <div className="action-controls">
                                                                    <div className='row'>
                                                                        <div className='col-md-4'>
                                                                            <label className="checkbox-inline mr-10">
                                                                                <input type="checkbox" defaultChecked={formFieldData.Copy} onChange={(e) => setFormFieldData({ ...formFieldData, ['Copy']: e.target.checked })} name="Copy" />
                                                                                <span> Copy</span>
                                                                            </label>
                                                                        </div>

                                                                        <div className='col-md-4'>
                                                                            <label className="checkbox-inline mr-10">
                                                                                <input type="checkbox" defaultChecked={formFieldData.Move} onChange={(e) => setFormFieldData({ ...formFieldData, ['Move']: e.target.checked })} name="Move" />
                                                                                <span> Move</span>
                                                                            </label>
                                                                        </div>

                                                                        <div className='col-md-4'>
                                                                            <label className="checkbox-inline">
                                                                                <input type="checkbox" defaultChecked={formFieldData.Remove} onChange={(e) => setFormFieldData({ ...formFieldData, ['Remove']: e.target.checked })} name="Remove" />
                                                                                <span> Remove</span>
                                                                            </label>
                                                                        </div>

                                                                        <div className='col-md-4'>
                                                                            <label className="checkbox-inline">
                                                                                <input type="checkbox" defaultChecked={formFieldData.AddTags} onChange={(e) => setFormFieldData({ ...formFieldData, ['AddTags']: e.target.checked })} name="AddTags" />
                                                                                <span> Add Tags</span>
                                                                            </label>
                                                                        </div>

                                                                        <div className='col-md-4'>
                                                                            <label className="checkbox-inline">
                                                                                <input type="checkbox" defaultChecked={formFieldData.RemoveTags} onChange={(e) => setFormFieldData({ ...formFieldData, ['RemoveTags']: e.target.checked })} name="RemoveTags" />
                                                                                <span> Remove Tags</span>
                                                                            </label>
                                                                        </div>

                                                                        <div className='col-md-4'>
                                                                            <label className="checkbox-inline">
                                                                                <input type="checkbox" defaultChecked={formFieldData.AddNotes} onChange={(e) => setFormFieldData({ ...formFieldData, ['AddNotes']: e.target.checked })} name="AddNotes" />
                                                                                <span>Add Notes</span>
                                                                            </label>
                                                                        </div>


                                                                        <div className='col-md-4'>
                                                                            <label className="checkbox-inline">
                                                                                <input type="checkbox" defaultChecked={formFieldData.UnsubscribePhone} onChange={(e) => setFormFieldData({ ...formFieldData, ['UnsubscribePhone']: e.target.checked })} name="UnsubscribePhone" />
                                                                                <span>Unsubscribe Phone</span>
                                                                            </label>
                                                                        </div>

                                                                        <div className='col-md-4'>
                                                                            <label className="checkbox-inline">
                                                                                <input type="checkbox" defaultChecked={formFieldData.UnsubscribeEmail} onChange={(e) => setFormFieldData({ ...formFieldData, ['UnsubscribeEmail']: e.target.checked })} name="UnsubscribeEmail" />
                                                                                <span>Unsubscribe Email</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {formFieldData.Copy && (
                                                                <div className="form-group mb-3">
                                                                    <label>Copy Contact To:</label>
                                                                    <Select
                                                                        getOptionLabel={option => option.title}
                                                                        getOptionValue={option => option.group_id}
                                                                        isMulti={true}
                                                                        value={formFieldData.copy_groups_id}
                                                                        options={metaData.groups}
                                                                        classNamePrefix="select2-selection"
                                                                        name="copy_groups_id[]"
                                                                        onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['copy_groups_id']: selectedGroup })}
                                                                    />
                                                                </div>
                                                            )}

                                                            {formFieldData.Move && (
                                                                <div className="form-group mb-3">
                                                                    <label>Move Contact To:</label>
                                                                    <Select
                                                                        getOptionLabel={option => option.title}
                                                                        getOptionValue={option => option.group_id}
                                                                        isMulti={false}
                                                                        value={formFieldData.move_groups_id}
                                                                        options={metaData.groups}
                                                                        classNamePrefix="select2-selection"
                                                                        name="move_groups_id[]"
                                                                        onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['move_groups_id']: selectedGroup })}
                                                                    />
                                                                </div>
                                                            )}

                                                            {formFieldData.Remove && (
                                                                <div className="form-group mb-3">
                                                                    <label>Remove Contact From Group:</label>
                                                                    <Select
                                                                        getOptionLabel={option => option.title}
                                                                        getOptionValue={option => option.group_id}
                                                                        isMulti={true}
                                                                        value={formFieldData.remove_groups_id}
                                                                        options={metaData.groups}
                                                                        classNamePrefix="select2-selection"
                                                                        name="remove_groups_id[]"
                                                                        onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['remove_groups_id']: selectedGroup })}
                                                                    />
                                                                </div>
                                                            )}

                                                            {formFieldData.AddTags && (
                                                                <div className="form-group mb-3">
                                                                    <label>Add Tag:</label>
                                                                    <Select
                                                                        getOptionLabel={option => option.title}
                                                                        getOptionValue={option => option.group_id}
                                                                        isMulti={true}
                                                                        value={formFieldData.add_tags_id}
                                                                        options={metaData.tags}
                                                                        classNamePrefix="select2-selection"
                                                                        name="add_tags_id[]"
                                                                        onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['add_tags_id']: selectedGroup })}
                                                                    />
                                                                </div>
                                                            )}

                                                            {formFieldData.RemoveTags && (
                                                                <div className="form-group mb-3">
                                                                    <label>Remove Tag:</label>
                                                                    <Select
                                                                        getOptionLabel={option => option.title}
                                                                        getOptionValue={option => option.group_id}
                                                                        isMulti={true}
                                                                        value={formFieldData.remove_tags_id}
                                                                        options={metaData.tags}
                                                                        classNamePrefix="select2-selection"
                                                                        name="remove_tags_id[]"
                                                                        onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['remove_tags_id']: selectedGroup })}
                                                                    />
                                                                </div>
                                                            )}

                                                            {formFieldData.AddNotes && (
                                                                <div className="form-group mb-3">
                                                                    <label>Add Notes:</label>
                                                                    <textarea value={formFieldData.notes} onChange={(e) => handleChange(e)} name="notes" className='form-control' />

                                                                </div>
                                                            )}



                                                            <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />

                                                            <div className="form-group">
                                                                <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('contact_action')}>Save</a>
                                                            </div>
                                                        </div>
                                                        : getTypeContent == 'wait' ?
                                                            <div className="wait-content">
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <div className="form-group mb-3">
                                                                            <label>Wait For</label>
                                                                            <div className="input-group">
                                                                                <input type={'number'} min={1} defaultValue={formFieldData.wait_duration} onChange={(e) => handleChange(e)} name="wait_duration" className='form-control' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className="form-group mb-3">
                                                                            <label className='visibilty-hidden'>Wait For</label>
                                                                            <div className="input-group">
                                                                                <select defaultValue={formFieldData.wait_type} onChange={(e) => handleChange(e)} name="wait_type" className='form-control' >
                                                                                    <option value="minute">Minute(s)</option>
                                                                                    <option value="hour">Hour(s)</option>
                                                                                    <option value="day">Day(s)</option>

                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />

                                                                <div className="form-group">
                                                                    <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('wait')}>Save</a>
                                                                </div>

                                                            </div>
                                                            : getTypeContent == 'salespipeline' ?
                                                                <div className="salespipeline-content">

                                                                    <div className="form-group mb-3">
                                                                        <label>Pipeline Status:</label>
                                                                        <Select
                                                                            getOptionLabel={option => option.pipeline_title}
                                                                            getOptionValue={option => option.status_id}
                                                                            isMulti={false}
                                                                            value={metaData.pipeline_status.filter(({ status_id }) => status_id == formFieldData.status_id)}
                                                                            options={metaData.pipeline_status}
                                                                            classNamePrefix="select2-selection"
                                                                            name="status_id"
                                                                            onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['status_id']: selectedGroup.status_id })}
                                                                        />
                                                                    </div>

                                                                    <div className="form-group mb-3 d-none">
                                                                        <label>Assign Coach Partner</label>
                                                                        <div className="input-group">
                                                                            <select defaultValue={formFieldData.assigned_to} onChange={(e) => handleChange(e)} name="assigned_to" className='form-control' >
                                                                                <option value={''}>Select</option>
                                                                                {
                                                                                    metaData.coachPartners.map((row, e) => {
                                                                                        return <option data-title={row.name} data-id={row.user_id} key={e} value={row.user_id}>{row.name} ({row.email})</option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group mb-3">
                                                                        <label>Add Comments:</label>
                                                                        <textarea value={formFieldData.comments} onChange={(e) => handleChange(e)} name="comments" className='form-control' />

                                                                    </div>
                                                                    <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />

                                                                    <div className="form-group">
                                                                        <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('salespipeline')}>Save</a>
                                                                    </div>

                                                                </div>
                                                                : <></>
                                    }
                                </form>
                            ) : <></>}
                        </div>
                    </Modal.Body>
                </Modal >
            </div>
        </React.Fragment >
    );
};

export default PipelineStatusFlow;
