import React, { useState, useCallback, useEffect, useRef } from 'react';
import { toast, ToastContainer, Flip } from "react-toastify";
import { ApiUrl } from '../../../config';
import { uInfo, uToken, truncateString, dcrypt } from '../../../useToken';
import $ from "jquery";
import Modal from 'react-bootstrap/Modal'
import SweetAlert from "react-bootstrap-sweetalert"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Button, ButtonToolbar, Overlay, Tooltip } from 'react-bootstrap';
import Select from "react-select";
import Froala from "../../Froala";
import Loader from "react-loader";
const DripsFlow = (props) => {

    const [record, setRecord] = useState([]);
    const [show, setShow] = useState(false);
    const [getTypeContent, setTypeContent] = useState("");
    const [alert, setAlert] = useState(false);
    const [msg, setMsg] = useState("");
    const [menu, setMenu] = useState("");
    const [childid, setChildid] = useState(0);
    const [nextId, setNextId] = useState(0);
    const [nodeId, setNodeId] = useState(0);
    const [isOk, setIsOk] = useState('no');
    const [g_cond, setGCond] = useState('');
    const [campaignId] = useState(props.match.params ? props.match.params.id : 0);
    const [group_id] = useState(props.match.params ? props.match.params.group_id : 0);
    const [automation_id] = useState(props.match.params ? props.match.params.flow_id : 0);

    const [extTime, setExtTime] = useState(false);
    const [seletedTemplate, setSeletedTemplate] = useState('');
    const [loading, setLoading] = useState(true);

    const [metaData, setMetaData] = useState({
        campaign: {},
        opt_: [{ options: [] }, { options: [] }]
    });

    const [editModal, setEditModal] = useState(false);

    const [formFieldData, setFormFieldData] = useState({
        email_subject: '',
        email_body: '',
        sms_body: '',
        groups_id: [],
        email_token: '',
        sms_token: '',
        notify_email_subject: '',
        notify_email_body: '',
        notify_sms_body: '',
        notify_email: 'off',
        notify_sms: 'off',
        notify_email_token: '',
        notify_sms_token: '',
        Copy: false,
        Move: false,
        Remove: false,
        AddTags: false,
        RemoveTags: false,
        AddNotes: false,
        UnsubscribePhone: false,
        UnsubscribeEmail: false,
        wait_type: "minute",
        from_email_id: "",
    });
    const [html, setHtml] = useState("");
    const [path, setPath] = useState("");

    const [showP, setShowP] = useState({});
    const [target, setTarget] = useState(null);
    const [emalil_template_selected, setEmailTemaplateSelected] = useState('');

    const ref = useRef(null);

    const handleClick = (event, key) => {
        setShowP({ ...showP, [key]: true });
        // setShowP(false);
        // setShowP(true);
        setTarget(event.target);
    };

    const form = useRef(null);

    const overlayShow = useRef(null);

    useEffect(() => {
        getSeries();
        getMetaData();

        var outerContent = $('.house-tree');
        var innerContent = $('.tree-point');
        outerContent.scrollLeft((innerContent.width() - outerContent.width()) / 2);
    }, []);


    const getMetaData = () => {
        fetch(`${ApiUrl}` + "get/drips/meta/data", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ 'campaign_id': dcrypt(campaignId), 'group_id': dcrypt(group_id),'automation_id': dcrypt(automation_id) })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setMetaData(response.data)
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const renderHtml = (data) => {

        if (data.records.length > 0) {

            let obj = Object.values(data.bTree);
            // console.log(obj);
            return obj.map(($treeArray, $parentNode) => {
                //let idx = $treeArray.indexOf(); 
                //console.log($treeArray, 'is here');
                let $nodeId = $treeArray['0'];
                let $childid = $treeArray['1'];
                //let $parentNode = $treeArray['2'];
                //console.log($parentNode, 'parentNode');
                let $nxtId = 0;
                if ($childid != 0) {
                    $nxtId = $nodeId;
                }

                if ($parentNode == 0) {
                    return (
                        <li className="plus_outer startNow" key={$parentNode}>
                            <ul>
                                <li>
                                    {addChildHtml($nodeId, $childid)}
                                </li>

                            </ul>
                            {/* <ul>
                                <li className='child a'>
                                    <a className="plus" onClick={(e) => chooseAction($childid, $nodeId, e)}>
                                        <img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" />
                                    </a>
                                </li>
                            </ul> */}
                        </li>
                    )
                }
            });
        } else {
            return <li className="plus_outer startNow">
                <a className="plus" onClick={(e) => chooseAction(0, 0, e)}>
                    <img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" />
                </a>
            </li>;
        }


    }


    function notIn($type) {
        let $actionArr = ['end', 'goto', 'start'];
        if ($actionArr.includes($type) === false) {
            return true;
        } else {
            return false;
        }
    }

    const addChildHtml = ($nodeId, $childid) => {


        let $dataArray = record.dataArray;

        let $childData = $dataArray[$nodeId];
        let $series = JSON.parse($childData.seriesData);
        let $returnHtml = '<p>A</p>';

        if ($childData.length == 0) {
            return $returnHtml;
        }
        $series.p_series_id = $dataArray[$nodeId].series_id;
        $series.html = $dataArray[$nodeId].html;


        // if (getProcessed.includes($nodeId)) {
        //     return $returnHtml;
        // }

        // setProcessed(oldArray => [...oldArray, $nodeId]);


        let $childNotes = $childData.notes != '' ? JSON.parse($childData.notes) : 0;



        return (
            <>
                <a href="#" className="delete"
                    onClick={() => deleteNode($nodeId, $childid, $childData.previous_id, $childData.type)}
                    title="Delete"><i className="fa fa-times" aria-hidden="true"></i>
                </a>

                <div style={{ position: 'relative' }}>
                    <div className='button-notes-view'>
                        <button variant='none' className='notes-list' onClick={(e) => handleClick(e, `key_${$nodeId}`)}><i className="fa fa-file" aria-hidden="true"></i></button>

                        <Overlay
                            show={showP[`key_${$nodeId}`]}
                            target={target}
                            placement="left"
                            rootClose={true}
                            onHide={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })}
                        >
                            <Popover id="popover-contained">
                                <Popover.Header as="h3">Notes </Popover.Header>
                                <Popover.Body style={{ padding: "0.5rem 0.75rem 0" }}>
                                    <div className="add_note_drapdown_body">
                                        <div className={`col-md-12 show-notes nkt-${$nodeId}`}>
                                            {
                                                $childNotes.length > 0 && (
                                                    $childNotes.map(($note, i) => {
                                                        return <div className={`notes notee-${i}`}>
                                                            <div className="notes-text">{$note}</div>
                                                            <span className="notes-delete fas fa-trash cursor-pointer" title='Delete a note' href="#" onClick={() => deleteNote($nodeId, i)}></span>
                                                        </div>
                                                    })
                                                )
                                            }


                                        </div>
                                        <textarea className={`form-control text-note-${$nodeId}`} placeholder="Type note here ..."></textarea>
                                        <div className="add_note_drapdown_button">
                                            <button onClick={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })} className="btn btn-info mr-5">Cancel</button>
                                            <button onClick={(e) => addNote($nodeId, e)} className="btn btn-primary">Add</button>
                                        </div>
                                    </div>
                                </Popover.Body>
                            </Popover>
                        </Overlay>
                    </div>
                </div>
                {
                    $childData.type == 'sms' ?
                        <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'sms', 'sms')}>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        FollowUpId # {$series.p_series_id}
                                    </Tooltip>
                                }
                            >
                                <div className="sb_button_inner btn-primary sb_editable"
                                    childid={`${$nodeId}`}>
                                    <i className="fa fa-mobile" aria-hidden="true"></i> Send SMS ( {truncateString($series.sms_body, 30)} )
                                </div>
                            </OverlayTrigger>
                        </div>
                        :
                        $childData.type == 'voicemail' ?
                            <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'voicemail', 'voicemail')}>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            FollowUpId # {$series.p_series_id}
                                        </Tooltip>
                                    }
                                >
                                    <div className="sb_button_inner btn-primary sb_editable"
                                        childid={`${$nodeId}`}>
                                        <i className="fa fa-phone" aria-hidden="true"></i> RvM
                                    </div>
                                </OverlayTrigger>
                            </div>
                            :
                            $childData.type == 'email' ?
                                <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'email', 'email')} >
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                FollowUpId # {$series.p_series_id}
                                            </Tooltip>
                                        }
                                    >
                                        <div className="sb_button_inner btn-primary sb_editable"
                                            childid={`${$nodeId}`}>
                                            <i className="fa fa-envelope" aria-hidden="true"></i> Email
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                :
                                $childData.type == 'no_response' ?
                                    <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'no_response', 'no_response')} >
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id="button-tooltip">
                                                    FollowUpId # {$series.p_series_id}
                                                </Tooltip>
                                            }
                                        >
                                            <div className="sb_button_inner btn-primary sb_editable"
                                                childid={`${$nodeId}`}>
                                                <i className="fa fa-ban" aria-hidden="true"></i>  No Response Action
                                            </div>
                                        </OverlayTrigger>
                                </div>

                                : $childData.type == 'condition' ?

                                    <>

                                    </>
                                    // <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                                    //     <div className="sb_button_inner btn-primary sb_editable"
                                    //         childid={`${$nodeId}`}>
                                    //         <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.title})
                                    //     </div>
                                    // </div>

                                    : "h"

                }



                {
                    $childData.type == 'condition' ? (
                        conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                    ) : conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                }


            </>
        );



    }


    function addNote($nodeId, obj) {
        var note = $.trim($('.text-note-' + $nodeId).val());
        if (note != '') {
            fetch(`${ApiUrl}` + "add/note/series/" + $nodeId, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ note: note })
            })
                .then((response) => response.json())
                //Then with the data from the response in JSON...
                .then((response) => {
                    if (response.status === true) {
                        $('.text-note-' + $nodeId).val(' ');
                        getSeries();
                    } else {
                         toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }

    function conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series) {
        if ($childData.type == 'condition') {
            let $subChild = $dataArray[$childid] ? $dataArray[$childid] : {};
            let $preChild = [];
            if ($childData.pre_next_id != 0 && $childData.pre_next_id != $childid) {
                $preChild = $dataArray[$childData.pre_next_id];
            }

            let $trueChildId = 0;
            let $falseChildId = 0;

            if ($subChild.condition_status == 'true' && $subChild.series_id == $childid) {
                $trueChildId = $childid;
            }
            if ($subChild.condition_status == 'true' && $subChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childid) {
                $trueChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childid) {
                $falseChildId = $childid;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childid) {
                $falseChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }

            return (
                <>
                    <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                        <div className="sb_button_inner btn-primary sb_editable"
                            childid={`${$nodeId}`}>
                            <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.title})
                        </div>
                    </div>

                    <ul className='tree-condition'>
                        <li>
                            <ul className='tree-condition-ul'>
                                <li className='tree-condition-li-yes'>
                                    <ul>
                                        <li className="plus_outer yes-outer">
                                            <a className="sb_button_inner condition_yes " childid="end">Yes</a>
                                            <br />
                                            <a className="plus" onClick={(e) => chooseAction($trueChildId, $nodeId, e, 'yes')}><img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" /></a>
                                            {/* $returnHtml .= $this->getChild($nodeId, $trueChildId); */}
                                            {getChild($nodeId, $trueChildId)}
                                        </li>
                                    </ul>
                                </li>
                                <li className='tree-condition-li-no'>
                                    <ul>
                                        <li className="plus_outer">
                                            <a className="sb_button_inner condition_no" id={`yes_${$nodeId}`} childid="end">No</a>
                                            <br />
                                            <a className="plus" onClick={(e) => chooseAction($falseChildId, $nodeId, e, 'no')}><img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" /></a>
                                            {getChild($nodeId, $falseChildId)}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            )


        }

        if (notIn($childData.type) && $childData.type !='no_response') {
            return <ul>
                <li className="plus_outer">
                    <a className="plus" onClick={(e) => chooseAction($childid, $nodeId, e)}>
                        <img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" /></a>
                    {getChild($nodeId, $childid)}
                </li>
            </ul>
        }
    }

    function getChild($parentNode, $nodeId) {
        let $dataArray = record.dataArray;

        if ($nodeId != 0) {
            let $childData = $dataArray[$nodeId];
            let $childid = $childData.next_id;
            return (
                <ul>
                    <li>
                        {addChildHtml($nodeId, $childid)}
                    </li>
                </ul>
            )
        }
    }


    const dynamicSetting = (modal, data, type, contetType) => {
        if (data.html) {
            setHtml(data.html);
        } else {
            setHtml('');
        }
        setShow(modal);
        data.email_token = '';
        data.sms_token = '';
        setFormFieldData(data);
        if(data.wait_for_zero_interval != undefined && data.wait_for_zero_interval !=""){
            setExtTime(true);
        }
        setMenu(type);
        setTypeContent(contetType);
        setEditModal(true);
    }

    const dynamicModal = (type) => {
        setMenu(type);
        setTypeContent(type);
    }

    const modalHide = () => {
        setShow(false);
        setFormFieldData({
            email_subject: '',
            email_body: '',
            sms_body: '',
            groups_id: [],
            email_token: '',
            sms_token: ''
        });
        setMenu('');
        setTypeContent('');
        setEditModal(false);
        setSeletedTemplate('');
        setHtml('');
        setPath('');
    }

    const deleteNode = ($nodeId, $childid, $previous_id, $type) => {
        fetch(`${ApiUrl}` + "delete/drips/series/" + dcrypt(campaignId) + '/' +dcrypt(automation_id), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ nodeId: $nodeId, childId: $childid, previousId: $previous_id, type: $type })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const deleteNote = ($nodeId, $noteIndex) => {
        fetch(`${ApiUrl}` + "delete/note/series/" + $nodeId, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ index: $noteIndex })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }




    const getSeries = () => {
        fetch(`${ApiUrl}` + "drips/series/" + dcrypt(campaignId) + '/' + dcrypt(group_id) + '/' +dcrypt(automation_id), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    //console.log(response.data, 'getSeries');
                    setRecord(response.data);
                    // renderHtml(response.data.records);
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    const chooseAction = (nId, cId, obj, cond = '') => {
        $('.btn-group-vertical .btn.green').removeClass('active');
        $('.action-description').html('<h4 style="text-align:center;">Select Action From Menu</h4>');
        // getTypeContent('type');
        $('#action-detail').removeClass('col-md-12');
        $('#action-detail').addClass('col-md-9');
        $('#action-nav').show();
        // $('.modal-title').html('Add New Action');
        setShow(true);
        setChildid(0);
        setNextId(nId);
        setNodeId(cId);
        setIsOk('no');
        setGCond(cond);

    }


    const saveAction = (type , email_body = '') => {
        if (type == 'sms' || type == 'email' || type == 'voicemail') {
            if ($('input[name=interval_of_day]').val() == '') {
                setMsg('Please enter interval of day!');
                setAlert(true);
                return false;
            }

            if ($('input[name=interval_of_day]').val() >= 1) {
                if ($('input[name=execution_time]').val() == '') {
                    setMsg('Please enter execution time!');
                    setAlert(true);
                    return false;
                }
            }

            if (extTime) {
                if ($('input[name=wait_for_zero_interval]').val() == '') {
                    setMsg('Please enter wait interval!');
                    setAlert(true);
                    return false;
                }

                if ($('select[name=zero_interval_wait_type]').val() == '') {
                    setMsg('Please enter interval type!');
                    setAlert(true);
                    return false;
                }
            }

            if (type == 'sms') {
                if ($('textarea[name=sms_body]').val() == '') {
                    setMsg('Please enter sms body!');
                    setAlert(true);
                    return false;
                }
            }

            if (type == 'voicemail') {
                if ($('select[name=rvm_template]').val() == '') {
                    setMsg('Please enter voicemail!');
                    setAlert(true);
                    return false;
                }
            }

            if (type == 'email') {
            
                if ($('input[name=email_subject]').val() == '') {
                    setMsg('Please enter email subject!');
                    setAlert(true);
                    return false;
                }
                if (($('textarea[name=email_body]').val() == ''  || email_body == '') && (emalil_template_selected ==  undefined || emalil_template_selected == '' || emalil_template_selected == 0)) {
                    setMsg('Please enter email body!');
                    setAlert(true);
                    return false;
                }
            }
        }
        if (type == 'no_response') {

            if ($("input[name='notify_email']:checked").val() == 'on') {

                if ($('input[name=notification_email]').val() == '') {
                    setMsg('Please enter notification email!');
                    setAlert(true);
                    return false;
                }

                if ($('input[name=notify_email_subject]').val() == '') {
                    setMsg('Please enter notification email subject!');
                    setAlert(true);
                    return false;
                }

                if ($('textarea[name=notify_email_body]').val() == '') {
                    setMsg('Please enter notification email body!');
                    setAlert(true);
                    return false;
                }

            }
           
            if ($("input[name='notify_sms']:checked").val() == 'on') {

                if ($('input[name=notification_number]').val() == '') {
                    setMsg('Please enter notification number!');
                    setAlert(true);
                    return false;
                }



                if ($('textarea[name=notify_sms_body]').val() == '') {
                    setMsg('Please enter notification sms body!');
                    setAlert(true);
                    return false;
                }

            }
            var copy_groups_id = $('input[name="copy_groups_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!copy_groups_id) {
                setMsg('Please select a group for copy!');
                setAlert(true);
                return false;
            }

            var move_groups_id = $('input[name="move_groups_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!move_groups_id) {
                setMsg('Please select a group for move!');
                setAlert(true);
                return false;
            }

            var remove_groups_id = $('input[name="remove_groups_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!remove_groups_id) {
                setMsg('Please select a group for remove!');
                setAlert(true);
                return false;
            }

            var add_tags_id = $('input[name="add_tags_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!add_tags_id) {
                setMsg('Please select a tag for add!');
                setAlert(true);
                return false;
            }

            var remove_tags_id = $('input[name="remove_tags_id[]"]').filter(function () {
                return $.trim($(this).val()).length == 0
            }).length == 0;


            if (!remove_tags_id) {
                setMsg('Please select a tag for remove!');
                setAlert(true);
                return false;
            }


            if ($('textarea[name=notes]').val() == '') {
                setMsg('Please enter note!');
                setAlert(true);
                return false;
            }

            if ($("input[name='notify_email']:checked").val() === undefined && $("input[name='notify_sms']:checked").val() === undefined
            && $("input[name='Copy']:checked").val() === undefined  && $("input[name='Move']:checked").val() === undefined 
            && $("input[name='Remove']:checked").val() === undefined  && $("input[name='AddTags']:checked").val() === undefined 
            && $("input[name='RemoveTags']:checked").val() === undefined  && $("input[name='AddNotes']:checked").val() === undefined 
            && $("input[name='UnsubscribePhone']:checked").val() === undefined  && $("input[name='UnsubscribeEmail']:checked").val() === undefined 

            ) {
                setMsg('Please select minimum one notify type!');
                setAlert(true);
                return false;
            }
        }
       
        let data = {
            campaignId: dcrypt(campaignId), 
            automation_id: dcrypt(automation_id),
            groupId: dcrypt(group_id),
            childId: childid,
            nextId: nextId,
            nodeId: nodeId,
            cond: g_cond,
            isOk: isOk,
            type: type,
            email_body: email_body,
        }
        setLoading(false);
        let form_name = type =='no_response'? 'no_response-form': 'sms-email-rvm-form';
        fetch(`${ApiUrl}` + "save/drips/action?" + $('.' + form_name).serialize(), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((response) => {
            setLoading(true);
            if (response.status === true) {
                getSeries();
                modalHide();
            } else {
                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
        })
        .catch((error) => {
            setLoading(true);
            console.error("Error:", error);
        });
        
    } 
    const handleEmailBody = async (event, values) => {
        // setLoading(false);
        saveAction('email',values)
       
      }

    const handleChange = (e) => {
        e.preventDefault();
        //console.log(formFieldData,'formFieldData')
        const { name, value } = e.target;

        if (name == 'interval_of_day') {
            setFormFieldData({ ...formFieldData, [name]: value.replace(/\D/g, '') });
        } else if (name == 'wait_for_zero_interval') {
            setFormFieldData({ ...formFieldData, [name]: value.replace(/\D/g, '') });
        } else {
            setFormFieldData({ ...formFieldData, [name]: value });
        }

        if (name == 'sms_template') {
            setFormFieldData({ ...formFieldData, ['sms_body']: value });
        }
        if (name == 'email_template' && value != '') {
            setFormFieldData({ ...formFieldData, ['email_subject']: e.target.selectedOptions[0].getAttribute("data-title") });
            getEmailTemplateHtml(value);
        }

        if (name == 'from_email_id' && value != ''){
            setFormFieldData({...formFieldData, ['from_email_id']: value});
        }


        if (name == 'email_template' && value == '') {
            setFormFieldData({ ...formFieldData, ['email_subject']: '' });
            setHtml('');
            setPath('');
        }

        if (name === 'sms_template' || name === 'email_template' || name === 'rvm_template') {
            setSeletedTemplate(e.target.selectedOptions[0].getAttribute("data-id"));

        }


    }


    const getEmailTemplateHtml = (id) => {
        fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                setHtml(response.theme);
                setPath(response.path);
                setEmailTemaplateSelected(id);
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };





    return (
        <React.Fragment>
              <Loader loaded={loading}></Loader>
              <ToastContainer />
            <div className='mb-4'>

                <div className='row'>
                    <div className='col-sm-5' sm={5}>
                        <div className="page-title-box">
                            <h4>
                                {metaData.campaign.title}
                            </h4>
                        </div>
                    </div>
                    <div className='col-sm-3' sm={3}></div>
                    <div className='col-sm-4'>
                        <div className="page-title-box text-align-right">
                            <button className='btn btn-secondary'
                                onClick={() => props.history.goBack()}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>


                {alert ? (
                    <SweetAlert
                        title={msg}
                        warning
                        onConfirm={() => setAlert(false)}
                    />
                ) : null}


                <div className='card card-body house-tree custom-scroll' >


                    <div className='event-tree'>
                        <h5>Start Adding Drips!</h5>
                    </div>
                    <ul className="tree-point pb-4">
                        {record.html == '' ? renderHtml(record) : ""}
                    </ul>

                </div>

                <Modal
                    show={show}
                    onHide={() => modalHide()}
                    dialogClassName="modal-90w"
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {editModal ? 'Edit Action' : 'Add Action'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='row'>
                        <div className={`${editModal ? 'd-none' : 'col-md-3 col-sm-3 col-xs-12'}`} id="action-nav">
                            <div className={`btn-group-vertical left_vertical_tabs  ${editModal ? 'd-none' : ''}`}>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'email' ? 'active' : ''}`} onClick={() => dynamicModal('email')} data-toggle="dropdown">
                                        <i className="fas fa-envelope"></i> Send Email
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'sms' ? 'active' : ''}`} onClick={() => dynamicModal('sms')} data-toggle="dropdown">
                                        <i className="fas fa-mobile"></i> Send SMS
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'voicemail' ? 'active' : ''}`} onClick={() => dynamicModal('voicemail')} data-toggle="dropdown">
                                        <i className="fas fa-phone"></i> Voicemail
                                    </button>
                                </div>
                                    <div className="btn-group">
                                        <button type="button" className={`btn green dropdown-toggle ${menu == 'no_response' ? 'active' : ''}`} onClick={() => dynamicModal('no_response')} data-toggle="dropdown">
                                            <i className="fas fa-ban"></i>  No Response Action									
                                        </button>
                                    </div>
                            </div>
                        </div>
                        <div className={`${editModal ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-9 col-sm-9 col-xs-12'}`}>
                            {getTypeContent == 'sms' || getTypeContent == 'voicemail' || getTypeContent == 'email' ? (
                                <form ref={form} className="sms-email-rvm-form" method="post">
                                    <div className='row'>

                                        <div className='col-md-6'>

                                            <div className="form-group mb-3">
                                                <label className='text-transform-capitalize'>{getTypeContent} Interval:</label>
                                                <div className="input-group">
                                                    <input value={formFieldData.interval_of_day} className="form-control"
                                                        onChange={(e) => handleChange(e)}
                                                        readOnly={formFieldData.p_series_id  ? true : false}
                                                        name="interval_of_day"
                                                        placeholder="Enter number of day(s)(e.g. 0 or 1 etc )" maxLength={'3'} type="text" />
                                                </div>
                                                <small className="text-muted note">In days after which {getTypeContent} will trigger at, whenever a new contact added</small>
                                            </div>
                                        </div>
                                        <input readOnly type={'hidden'} name="template_id" value={seletedTemplate} />


                                        {
                                            formFieldData.interval_of_day >= 1 ? (

                                                <div className='col-md-6'>

                                                    <div className="form-group mb-3">
                                                        <label>Trigger on(Time):</label>
                                                        <Select
                                                            getOptionLabel={option => option}
                                                            getOptionValue={option => option}
                                                            value={metaData.time_duration.filter(
                                                                (x) => x === formFieldData.execution_time
                                                            )}
                                                            // value={metaData.time_duration.formFieldData.execution_time}
                                                            options={metaData.time_duration}
                                                            classNamePrefix="select2-selection"
                                                            name="execution_time"
                                                            onChange={(selectedTags) => setFormFieldData({ ...formFieldData, ['execution_time']: selectedTags })}
                                                        />

                                                    </div>
                                                </div>
                                            ) : formFieldData.interval_of_day == 0 ? (
                                                <>
                                                    <div className='col-md-6'>



                                                        <div className='row'>
                                                            <div className='col-md-2'>
                                                                {formFieldData.interval_of_day == 0 && formFieldData.interval_of_day != '' && (
                                                                    <div className='toogle-button'>
                                                                        <label style={{ width: '100%' }}>&nbsp;</label>
                                                                        <button type='button' className='btn btn-success btn-sm' onClick={() => setExtTime(!extTime)}><i className='fas fa-plus' /></button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {
                                                                extTime && (
                                                                    <>
                                                                        <div className="col-md-4 paddng_left">
                                                                            <label>Wait For: </label>
                                                                            <input maxLength={'3'} value={formFieldData.wait_for_zero_interval}
                                                                                onChange={(e) => handleChange(e)}
                                                                                type="text" name="wait_for_zero_interval" className="form-control" />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className='visibilty-hidden'>Wait For: </label>
                                                                            <select defaultValue={formFieldData.zero_interval_wait_type} onChange={(e) => handleChange(e)} className="form-control" name="zero_interval_wait_type">
                                                                                <option value="">Select Type</option>
                                                                                <option value="minutes">Minute(s)</option>
                                                                                <option value="hours">Hour(s)</option>
                                                                            </select>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>

                                                    </div>

                                                </>
                                            ) : (
                                                <>
                                                </>
                                            )
                                        }
                                    </div>

                                    {
                                        getTypeContent == 'sms' ?
                                            <div className="sms-content">
                                                <div className="form-group mb-3">
                                                    <label>SMS Template</label>
                                                    <div className="input-group">
                                                        <select defaultValue={formFieldData.sms_template} onChange={(e) => handleChange(e)} name="sms_template" className='form-control' >
                                                            <option value={''}>Select</option>
                                                            {
                                                                metaData.smsTemplates.map((sms, s) => {
                                                                    return <option data-id={sms.id} key={'sms' + s} value={sms.body}>{sms.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label> SMS Token</label>
                                                    <div className="input-group">
                                                        <select onChange={(e) => handleChange(e)} name="sms_token" className='form-control' >
                                                            <option value={''}>Select Token</option>
                                                            <optgroup label="Lead Default Field Tokens" >
                                                                {
                                                                    metaData.tokens.default_fields.map((df, d) => {
                                                                        return <option key={'default' + d} value={`{${df.key}}`}>{df.label}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                            <optgroup label="Lead Custom Field Tokens" >
                                                                {
                                                                    metaData.tokens.custom_fields.map((cf, c) => {
                                                                        return <option key={'custom' + c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                            <optgroup label="User Tokens" >
                                                                {
                                                                    metaData.tokens.users_token.map((cf, c) => {
                                                                        return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                        </select>

                                                        <button onClick={() => setFormFieldData({ ...formFieldData, ['sms_body']: formFieldData.sms_body + '  ' + formFieldData.sms_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label>SMS Body</label>
                                                    <div className="input-group">
                                                    <textarea placeholder='Add message body'  rows="7" cols="50" value={formFieldData.sms_body} onChange={(e) => handleChange(e)} name="sms_body" className='form-control' />
                                                    </div>
                                                </div>
                                                <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                <div className="form-group">
                                                    <a href="#" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('sms')}>Save</a>
                                                </div>
                                            </div>
                                            :
                                            getTypeContent == 'voicemail' ?
                                                <div className="voicemail-content">
                                                    <div className="form-group mb-3">
                                                        <label>RVM Template</label>
                                                        <div className="input-group">
                                                            <select defaultValue={formFieldData.rvm_template} onChange={(e) => handleChange(e)} name="rvm_template" className='form-control' >
                                                                <option value={''}>Select</option>
                                                                {
                                                                    metaData.voiceTemplates.map((rvm, r) => {
                                                                        return <option data-id={rvm.id} key={'rvm' + r} value={rvm.body}>{rvm.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />

                                                    <div className="form-group">
                                                        <a href="#" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('voicemail')}>Save</a>
                                                    </div>
                                                </div>
                                                : getTypeContent == 'email' ?
                                                    <div className="email-content">
                                                        <div className="form-group mb-3">
                                                            <label>Email Template</label>
                                                            <div className="input-group">
                                                                <select defaultValue={formFieldData.email_template} onChange={(e) => handleChange(e)} name="email_template" className='form-control' >
                                                                    <option value={''}>Select</option>
                                                                    {
                                                                        metaData.emailTemplates.map((email, e) => {
                                                                            return <option data-title={email.title} key={'email' + e} value={email.id}>{email.title}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label> Email Token</label>
                                                            <div className="input-group">
                                                                <select onChange={(e) => handleChange(e)} name="email_token" className='form-control' >
                                                                    <option value={''}>Select Token</option>
                                                                    <optgroup label="Lead Default Field Tokens" >
                                                                        {
                                                                            metaData.tokens.default_fields.map((df, d) => {
                                                                                return <option key={'default' + d} value={`{${df.key}}`}>{df.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                    <optgroup label="Lead Custom Field Tokens" >
                                                                        {
                                                                            metaData.tokens.custom_fields.map((cf, c) => {
                                                                                return <option key={'custom' + c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                    <optgroup label="User Tokens" >
                                                                        {
                                                                            metaData.tokens.users_token.map((cf, c) => {
                                                                                return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                </select>

                                                                <button
                                                                    onClick={() => setFormFieldData({ ...formFieldData, ['email_subject']: formFieldData.email_subject + '  ' + formFieldData.email_token })}
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                >
                                                                    Insert Subject
                                                                </button>
                                                                {/* {html == "" && (
                                                                    <button onClick={() => setFormFieldData({ ...formFieldData, ['email_body']: formFieldData.email_body + '  ' + formFieldData.email_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                                                )} */}
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3 d-none">
                                                        <label>Email From</label>
                                                        <div className="input-group">
                                                            <select defaultValue={formFieldData.from_email_id} onChange={(e) => handleChange(e)} name="from_email_id" className='form-control' >
                                                                <option value={''}>Select</option>
                                                                {
                                                                    metaData.FromEmail.map((email, e) => {
                                                                        return <option data-title={email.from_email} data-id={email.id} key={e} value={email.id}> {email.from_name} - {email. from_email}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                       </div>

                                                        <div className="form-group mb-3">
                                                            <label>Email Subject</label>
                                                            <div className="input-group">
                                                                <input value={formFieldData.email_subject} onChange={(e) => handleChange(e)} className='form-control' name="email_subject" />
                                                            </div>
                                                        </div>

                                                        {html == "" ? (
                                                            <div className="form-group mb-3">
                                                                <label>Email Body</label>
                                                                <div className="input-group">
                                                                    {/* <textarea rows={7} value={formFieldData.email_body} onChange={(e) => handleChange(e)} name="email_body" className='form-control' /> */}
                                                                    <Froala btnId={'add-drip-email'}  content={formFieldData.email_body}  handleSubmit={handleEmailBody}  use_token={'users'} />
                                                                </div>
                                                            </div>
                                                        ) : (<>
                                                            <div
                                                                id="custom-template"
                                                                className="height-500 custom-scroll"
                                                                dangerouslySetInnerHTML={{ __html: html }}
                                                            />
                                                            <input value={path} name="email_body" className='d-none' />
                                                        </>
                                                        )}
                                                        {/* <textarea readOnly value={JSON.stringify(html)} name="email_body" className='form-control d-none' /> */}
                                                        <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                        <div className="form-group">
                                                        {html == "" ? (
                                                            <button type='button' id='add-drip-email' className="btn btn-primary pull-right save-btn mt-2" >Save</button>
                                                        ) : (<>
                                                              <a href="#" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('email')}>Save</a>
                                                        </>
                                                        )}
                                                           
                                                        </div>
                                                    </div>
                                                    : <></>
                                    }
                                </form>
                            ) : <></>}
                            {getTypeContent == 'no_response' ? (
                                <div className="notify-content">
                                <form ref={form} className="no_response-form" method="post">
                                
                                    <div className='row'>
                                        <small className="text-muted note">Update Lead's who have not responded after final message is sent and the following delay has passed</small>
                                        <small className="text-danger">Final Message Interval should be greater than 0 (Non Instant Messages)</small>
                                        <div className='col-md-6 mt-3'>
                                            <div className="form-group mb-3">
                                                <label className='text-transform-capitalize'>{getTypeContent} Interval:</label>
                                                <div className="input-group">
                                                    <input value={formFieldData.interval_of_day} className="form-control"
                                                        onChange={(e) => handleChange(e)}
                                                        readOnly={formFieldData.p_series_id  ? true : false}
                                                        name="interval_of_day"
                                                        placeholder="Enter number of day(s)(e.g. 0 or 1 etc )" maxLength={'3'} type="text" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <input readOnly type={'hidden'} name="template_id" value={seletedTemplate} />
                                    {
                                        formFieldData.interval_of_day >= 1 ? (

                                            <div className='col-md-6 mt-3'>

                                                <div className="form-group mb-3">
                                                    <label>Trigger on(Time):</label>
                                                    <Select
                                                        getOptionLabel={option => option}
                                                        getOptionValue={option => option}
                                                        value={metaData.time_duration.filter(
                                                            (x) => x === formFieldData.execution_time
                                                        )}
                                                        // value={metaData.time_duration.formFieldData.execution_time}
                                                        options={metaData.time_duration}
                                                        classNamePrefix="select2-selection"
                                                        name="execution_time"
                                                        onChange={(selectedTags) => setFormFieldData({ ...formFieldData, ['execution_time']: selectedTags })}
                                                    />

                                                </div>
                                            </div>
                                        ) : formFieldData.interval_of_day == 0 ? (
                                            <>
                                                <div className='col-md-6 mt-3'>
                                                    <div className='row'>
                                                        <div className='col-md-2'>
                                                            {formFieldData.interval_of_day == 0 && formFieldData.interval_of_day != '' && (
                                                                <div className='toogle-button'>
                                                                    <label style={{ width: '100%' }}>&nbsp;</label>
                                                                    <button type='button' className='btn btn-success btn-sm' onClick={() => setExtTime(!extTime)}><i className='fas fa-plus' /></button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {
                                                            (extTime) && (
                                                                <>
                                                                    <div className="col-md-4 paddng_left">
                                                                        <label>Wait For: </label>
                                                                        <input maxLength={'3'} value={formFieldData.wait_for_zero_interval}
                                                                            onChange={(e) => handleChange(e)}
                                                                            type="text" name="wait_for_zero_interval" className="form-control" />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className='visibilty-hidden'>Wait For: </label>
                                                                        <select defaultValue={formFieldData.zero_interval_wait_type} onChange={(e) => handleChange(e)} className="form-control" name="zero_interval_wait_type">
                                                                            <option value="">Select Type</option>
                                                                            <option value="minutes">Minute(s)</option>
                                                                            <option value="hours">Hour(s)</option>
                                                                        </select>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>

                                                </div>

                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )
                                    }
                                    </div>

                                    <div className="form-group">
                                        <label className="control-label"> Notifications: </label>
                                        <div className="input-group">
                                            <label className="checkbox-inline mr-10">
                                                <input type="checkbox" defaultChecked={formFieldData.notify_email == 'on' ? true : false} onChange={(e) => setFormFieldData({ ...formFieldData, ['notify_email']: e.target.checked ? 'on' : 'off' })} name="notify_email" />
                                
                                                <span> Email (Team)</span>
                                            </label>
                                            <label className="checkbox-inline">
                                                <input type="checkbox" defaultChecked={formFieldData.notify_sms == 'on' ? true : false} onChange={(e) => setFormFieldData({ ...formFieldData, ['notify_sms']: e.target.checked ? 'on' : 'off' })} name="notify_sms" />
                                
                                                <span> SMS (Team)</span>
                                            </label>
                                        </div>
                                    </div>
                                
                                    {formFieldData.notify_email == 'on' && (
                                        <div className="notify-section p-3 mb-3">
                                            <div className="form-group mb-3">
                                                <label>Notification Email</label>
                                                <input className="form-control" value={formFieldData.notification_email} onChange={(e) => handleChange(e)} name="notification_email" placeholder="Add one/more notification email(s) separated by comma" type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label> Email Token</label>
                                                <div className="input-group">
                                                    <select onChange={(e) => handleChange(e)} name="notify_email_token" className='form-control' >
                                                        <option value={''}>Select Token</option>
                                                        <optgroup label="Lead Default Field Token" >
                                                            {
                                                                metaData.tokens.default_fields.map((df, d) => {
                                                                    return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                })
                                                            }
                                                        </optgroup>
                                                        <optgroup label="Lead Custom Field Tokens" >
                                                            {
                                                                metaData.tokens.custom_fields.map((cf, c) => {
                                                                    return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                })
                                                            }
                                                        </optgroup>
                                                        <optgroup label="User Tokens" >
                                                            {
                                                                metaData.tokens.users_token.map((cf, c) => {
                                                                    return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                })
                                                            }
                                                        </optgroup>     
                                                    </select>
                                
                                                    <button
                                                        onClick={() => setFormFieldData({ ...formFieldData, ['notify_email_subject']: formFieldData.notify_email_subject + '  ' + formFieldData.notify_email_token })}
                                                        type="button"
                                                        className="btn btn-primary"
                                                    >
                                                        Insert Subject
                                                    </button>
                                
                                                    <button onClick={() => setFormFieldData({ ...formFieldData, ['notify_email_body']: formFieldData.notify_email_body + '  ' + formFieldData.notify_email_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                
                                                </div>
                                            </div>
                                
                                            <div className="form-group mb-3">
                                                <label>Email Subject</label>
                                                <div className="input-group">
                                                    <input value={formFieldData.notify_email_subject} onChange={(e) => handleChange(e)} className='form-control' name="notify_email_subject" />
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label>Email Body</label>
                                                <div className="input-group">
                                                    <textarea value={formFieldData.notify_email_body} onChange={(e) => handleChange(e)} name="notify_email_body" className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                
                                    {formFieldData.notify_sms == 'on' && (
                                        <div className="notify-section p-3">
                                            <div className="form-group mb-3">
                                                <label>Notification Number</label>
                                                <input className="form-control" value={formFieldData.notification_number} onChange={(e) => handleChange(e)} name="notification_number" placeholder="Add one/more notification phone(s) with country code separated by comma" type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label> Sms Token</label>
                                                <div className="input-group">
                                                    <select onChange={(e) => handleChange(e)} name="notify_sms_token" className='form-control' >
                                                        <option value={''}>Select Token</option>
                                                        <optgroup label="Lead Default Field Token" >
                                                            {
                                                                metaData.tokens.default_fields.map((df, d) => {
                                                                    return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                })
                                                            }
                                                        </optgroup>
                                                        <optgroup label="Lead Custom Field Token" >
                                                            {
                                                                metaData.tokens.custom_fields.map((cf, c) => {
                                                                    return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                })
                                                            }
                                                        </optgroup>
                                                        <optgroup label="User Tokens" >
                                                            {
                                                                metaData.tokens.users_token.map((cf, c) => {
                                                                    return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                })
                                                            }
                                                        </optgroup>
                                                    </select>
                                
                                
                                                    <button onClick={() => setFormFieldData({ ...formFieldData, ['notify_sms_body']: formFieldData.notify_sms_body + '  ' + formFieldData.notify_sms_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                
                                                </div>
                                            </div>
                                
                                            <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                
                                            <div className="form-group mb-3">
                                                <label>SMS Text</label>
                                                <div className="input-group">
                                                    <textarea value={formFieldData.notify_sms_body} onChange={(e) => handleChange(e)} name="notify_sms_body" className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="contact_action-content">
                                        <div className="form-group">
                                            <label className="control-label"> Available Actions: </label>
                                            <div className="action-controls">
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label className="checkbox-inline mr-10">
                                                            <input type="checkbox" defaultChecked={formFieldData.Copy} onChange={(e) => setFormFieldData({ ...formFieldData, ['Copy']: e.target.checked })} name="Copy" />
                                                            <span> Copy</span>
                                                        </label>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className="checkbox-inline mr-10">
                                                            <input type="checkbox" defaultChecked={formFieldData.Move} onChange={(e) => setFormFieldData({ ...formFieldData, ['Move']: e.target.checked })} name="Move" />
                                                            <span> Move</span>
                                                        </label>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className="checkbox-inline">
                                                            <input type="checkbox" defaultChecked={formFieldData.Remove} onChange={(e) => setFormFieldData({ ...formFieldData, ['Remove']: e.target.checked })} name="Remove" />
                                                            <span> Remove</span>
                                                        </label>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className="checkbox-inline">
                                                            <input type="checkbox" defaultChecked={formFieldData.AddTags} onChange={(e) => setFormFieldData({ ...formFieldData, ['AddTags']: e.target.checked })} name="AddTags" />
                                                            <span> Add Tags</span>
                                                        </label>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className="checkbox-inline">
                                                            <input type="checkbox" defaultChecked={formFieldData.RemoveTags} onChange={(e) => setFormFieldData({ ...formFieldData, ['RemoveTags']: e.target.checked })} name="RemoveTags" />
                                                            <span> Remove Tags</span>
                                                        </label>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className="checkbox-inline">
                                                            <input type="checkbox" defaultChecked={formFieldData.AddNotes} onChange={(e) => setFormFieldData({ ...formFieldData, ['AddNotes']: e.target.checked })} name="AddNotes" />
                                                            <span>Add Notes</span>
                                                        </label>
                                                    </div>


                                                    <div className='col-md-4'>
                                                        <label className="checkbox-inline">
                                                            <input type="checkbox" defaultChecked={formFieldData.UnsubscribePhone} onChange={(e) => setFormFieldData({ ...formFieldData, ['UnsubscribePhone']: e.target.checked })} name="UnsubscribePhone" />
                                                            <span>Unsubscribe Phone</span>
                                                        </label>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label className="checkbox-inline">
                                                            <input type="checkbox" defaultChecked={formFieldData.UnsubscribeEmail} onChange={(e) => setFormFieldData({ ...formFieldData, ['UnsubscribeEmail']: e.target.checked })} name="UnsubscribeEmail" />
                                                            <span>Unsubscribe Email</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {formFieldData.Copy && (
                                            <div className="form-group mb-3">
                                                <label>Copy Contact To:</label>
                                                <Select
                                                    getOptionLabel={option => option.title}
                                                    getOptionValue={option => option.group_id}
                                                    isMulti={true}
                                                    value={formFieldData.copy_groups_id}
                                                    options={metaData.groups}
                                                    classNamePrefix="select2-selection"
                                                    name="copy_groups_id[]"
                                                    onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['copy_groups_id']: selectedGroup })}
                                                />
                                            </div>
                                        )}

                                        {formFieldData.Move && (
                                            <div className="form-group mb-3">
                                                <label>Move Contact To:</label>
                                                <Select
                                                    getOptionLabel={option => option.title}
                                                    getOptionValue={option => option.group_id}
                                                    isMulti={false}
                                                    value={formFieldData.move_groups_id}
                                                    options={metaData.groups}
                                                    classNamePrefix="select2-selection"
                                                    name="move_groups_id[]"
                                                    onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['move_groups_id']: selectedGroup })}
                                                />
                                            </div>
                                        )}

                                        {formFieldData.Remove && (
                                            <div className="form-group mb-3">
                                                <label>Remove Contact From Group:</label>
                                                <Select
                                                    getOptionLabel={option => option.title}
                                                    getOptionValue={option => option.group_id}
                                                    isMulti={true}
                                                    value={formFieldData.remove_groups_id}
                                                    options={metaData.groups}
                                                    classNamePrefix="select2-selection"
                                                    name="remove_groups_id[]"
                                                    onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['remove_groups_id']: selectedGroup })}
                                                />
                                            </div>
                                        )}

                                        {formFieldData.AddTags && (
                                            <div className="form-group mb-3">
                                                <label>Add Tag:</label>
                                                <Select
                                                    getOptionLabel={option => option.title}
                                                    getOptionValue={option => option.group_id}
                                                    isMulti={true}
                                                    value={formFieldData.add_tags_id}
                                                    options={metaData.tags}
                                                    classNamePrefix="select2-selection"
                                                    name="add_tags_id[]"
                                                    onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['add_tags_id']: selectedGroup })}
                                                />
                                            </div>
                                        )}

                                        {formFieldData.RemoveTags && (
                                            <div className="form-group mb-3">
                                                <label>Remove Tag:</label>
                                                <Select
                                                    getOptionLabel={option => option.title}
                                                    getOptionValue={option => option.group_id}
                                                    isMulti={true}
                                                    value={formFieldData.remove_tags_id}
                                                    options={metaData.tags}
                                                    classNamePrefix="select2-selection"
                                                    name="remove_tags_id[]"
                                                    onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['remove_tags_id']: selectedGroup })}
                                                />
                                            </div>
                                        )}

                                        {formFieldData.AddNotes && (
                                            <div className="form-group mb-3">
                                                <label>Add Notes:</label>
                                                <textarea value={formFieldData.notes} onChange={(e) => handleChange(e)} name="notes" className='form-control' />

                                            </div>
                                        )}
                                    </div>
                                    <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                    <div className="form-group">
                                        <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('no_response')}>Save</a>
                                    </div>
                                </form>
                                </div>
                            ) : <></>}
                        </div>
                    </Modal.Body>
                </Modal >
            </div>
        </React.Fragment >
    );
};

export default DripsFlow;
