import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import ResourcesSidebar from "./resources-sidebar";
export default class TrainingCategoryCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cat_id: this.props.match.params.cat_id
        ? dcrypt(this.props.match.params.cat_id)
        : null,
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {},
      page_name: this.props.match.params.id
        ? "Edit Resource Section "
        : "Create Resource Section",
      roles: [],
      packages: [],
      resellers: [],
      image: "",
      categories: [],
      category_name: "",
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.getData();
    }
    if(this.props.match.params.cat_id){
      this.gettrainingcategory();
    }
   
  }
  changeHandler = (e) => {
    let image = [];
    var reader = new FileReader();
    reader.onload = () => {
      image.push(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState({
      image: image,
    });
  };
  gettrainingcategory = () => {
    fetch(`${ApiUrl}` + "get/edit/training/Category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.cat_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            category_name: response.data.record.title,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/training/Category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            cat_id:response.data.record.master_id
          });
          this.gettrainingcategory();
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.id;
    }

    values.cat_id = this.state.cat_id;
    console.log(values, "Debug values");
    fetch(`${ApiUrl}` + "edit/resource/categorysection", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          this.props.history.goBack();
          // this.getData();
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          <Col lg="2" className="pl-0">
          <ResourcesSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
          <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody>
                  <h6>Product Name: {this.state.category_name} </h6>
                  <div className="mb-3">
                    <AvField
                      name="title"
                      label="Title"
                      value={this.state.record.title}
                      className="form-control"
                      placeholder="Enter Title"
                      required
                    />
                  </div>

                  {this.state.id && (
                    <div className="mb-3">
                      <AvField
                        className="select form-control"
                        type="select"
                        id="s"
                        name="status"
                        label="Status"
                        required
                        value={this.state.record.status}
                      >
                        <option value={""}> Select Status </option>
                        <option value={"Active"}> Active </option>
                        <option value={"Inactive"}> Inactive </option>
                      </AvField>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
          </Col>
        </Row>
      
      </React.Fragment>
    );
  }
}
