import React, { Component, Fragment } from "react";
import Loader from "react-loader";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Table,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Dropdownaction from "./Dropdown";
import Tooltip from "@material-ui/core/Tooltip";

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import {
  ApiUrl,
  ProductName,
  ToastAutoClose,
  AttachementsUrl,
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ProfileSettings from "./profile-sidebar";
import swal from 'sweetalert';

export default class Integrations extends Component {
  constructor(props) {
    super(props);


    this.approvedcolumns = [
      {
        text: "SR#",
        key: "sr",
      },
      {
        text: "Title",
        key: "title",
      },
      {
        text: "Ip Address",
        key: "ip_address",
      },
      {
        text: "Type",
        key: "p_type",
      },
      {
        text: "Dated",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <Link
                key={index}
                to={"/acceptedproposal/view/" + bcrypt(record.id)}
                className="waves-effect btn btn-light"
              >
                <i className="fas fa-eye pr-5"> </i>
                View
              </Link>
            </Fragment>
          );
        },
      },
    ];

    this.state = {
      record: [],
      page_name: "Integrations",
      modal: false,
      loaded: true,
      updatemodal: false,
    };
  }


  componentDidMount() {
    this.getData();
  }

  changeStatus = async (id, index, status) => {
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "change/integration/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id, index: index, status: status }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          this.getData();
          // swal({
          //   text: "Updated successfully",
          //   icon: "success",
          // })

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  getData = () => {
    fetch(`${ApiUrl}` + "get/coach/integration", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {

        if (response.status === true) {
          this.setState({
            record: response.data.record,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        <Loader loaded={this.state.loaded} className="spinner"></Loader>
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Row>
                  <Col>
                    <div className="card mb-5 mb-lg-0 my-2">
                      <div className="card-body">

                        <Row>
                          {this.state.record.map((row, i) => {
                            return (
                              <Col md={3} key={i}>
                                <div className="card mb-5 mb-lg-0 my-2">
                                  <div className="card-body package">
                                    <h5 className="card-title text-muted text-uppercase text-center">
                                      <i className={`${row.icon}`}></i>
                                    </h5>
                                    <h6 className="card-price text-center">
                                      {row.title}

                                    </h6>
                                    <div class="d-grid">
                                      <Link
                                        key={i}
                                        to={"/settings/integrations/facebook"}
                                        className="btn btn-default waves-effect waves-light"
                                      > Manage Configration
                                      </Link>
                                    </div>
                                    <hr />
                                    <div className="d-grid">
                                      <button
                                        onClick={() =>
                                          this.changeStatus(i, row.index, row.status)
                                        }
                                        className={`btn btn-primary text-uppercase code-${row.status == 'Connected' ? 'green' : 'red'} border-0`}
                                      >
                                        {row.status}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.modal}
          role="dialog"
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            this.setState({
              modal: !this.state.modal,
            });
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                this.setState({
                  modal: !this.state.modal,
                });
              }}
              className="text-transform-capitalize"
            >
              Add Payment Method
            </ModalHeader>
            <AvForm
              onValidSubmit={this.addcarddetails}
              className="needs-validation"
            >
              <ModalBody>
                <Row className="mt-3">
                  <Col className="col-6">
                    <label className="control-label">Card Number</label>
                    <AvField
                      name="cardnumber"
                      className="form-control"
                      type="number"
                      Placeholder="Enter Card Number"
                      value=""
                      required
                    />
                  </Col>
                  <Col className="col-6">
                    <label className="control-label"> CVC</label>
                    <AvField
                      name="cvc"
                      className="form-control"
                      type="number"
                      Placeholder="CVC"
                      value=""
                      required
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="col-6">
                    <AvField
                      name="expirationdateMM"
                      label="MM"
                      className="form-control"
                      type="number"
                      Placeholder="MM"
                      value=""
                      required
                    />
                  </Col>
                  <Col className="col-6">
                    <AvField
                      name="expirationdateYYYY"
                      label="YYYY"
                      className="form-control"
                      type="number"
                      Placeholder="YYYY"
                      value=""
                      required
                    />
                  </Col>
                </Row>
              </ModalBody>
              <Loader loaded={this.state.loaded} className="spinner">
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        modal: false,
                      });
                    }}
                  >
                    Close
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                </ModalFooter>
              </Loader>
            </AvForm>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
