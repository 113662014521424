import React, { useContext, useRef, useState, useEffect } from 'react';
import WaySideBarCoach from './WaySideBarCoach';
import WaySideBarCoachCourse from './WaySideBarCoachCourse';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { uInfo, dcrypt, bcrypt } from '../../useToken';
import WayBookContext from './WaybookContext';


export default function WayCoachTraining(props) {
	const params = React.useContext(WayBookContext);
	const [canEditCocah, setCanEditCocah] = useState(uInfo().type === 'Coach' ? JSON.parse(localStorage.getItem("can_edit_coach")) : false);
	const [action, setAction] = useState(0);
	//const [coursePaid, setCoursePaid] = useState(uInfo().type === 'Coach' ? JSON.parse(localStorage.getItem("course_free_paid")) : false);
	const history = useHistory();

	const { search } = useLocation();
	const urlParams = new URLSearchParams(search);
	const [module, setModule] = useState(urlParams.get('module') ? urlParams.get('module') : "");

	const checkSecParam = () => {
		return urlParams.has('sec');
	};
	useEffect(() => {
		console.log(canEditCocah)
		if (module) {
			params.setAction(module);
		}

	}, []);
	const handleSelect = (e) => {
		if (e == 2 || e == 3) {
			params.getTagLists('subject', 0);
		}
		if (!checkSecParam()) {
			history.push(`/training/${bcrypt(0)}/${bcrypt(0)}?sec=Y29hY2g=&module=${e}`);
		}
		params.setAction(e);
	};
	return (
		<WayBookContext.Provider value={params}>
			<div className={`MyCoaching ${canEditCocah ? '' : 'mytraning'}`}>
				<div className='d-flex  w-100 WayBookDropDown py-2 justify-content-between mt-2 pe-2 ps-1'>

					{
						canEditCocah && uInfo().coach_can_sell_trainings === "active_paid" ? (
							<>
								<Link to={'/trainings'} className="p-1 OpenTraining" > My Training</Link>

							</>
						) : (
							<>
								{
									uInfo().type == 'Client' ?
										<>
											<Link to={'/trainings'} className="p-1" >My Training </Link>

											<Link to={'/client/profile'} className="p-1" > Back</Link>
										</>
										:
										<Link to={'/trainings'} className="p-1" > My Training </Link>
								}
							</>
						)
					}
					<div className='d-flex justify-content-between align-items-center'>

						<div className="form-check form-switch py-0 d-flex px-0" >
							<label className='mb-0 me-2'>Edit</label>
							<input
								type="checkbox"
								className="form-check-input ms-0"
								id="customSwitch2"
								checked={JSON.parse(canEditCocah)}
								onChange={e => {
									setCanEditCocah(e.target.checked);
									localStorage.setItem("can_edit_coach", e.target.checked);
									window.location.reload();
								}}
							/>
						</div>

						{canEditCocah && (

							<Dropdown className='align-self-center' onSelect={handleSelect}>
								<Dropdown.Toggle title='Add New' variant="link" id="dropdown-basic">
									<i className='fas fa-plus'></i>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item eventKey="1">Course</Dropdown.Item>
									<Dropdown.Item eventKey="2">Module</Dropdown.Item>
									<Dropdown.Item eventKey="3">Lesson</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						)}
					</div>
				</div>
				<div className='way-sidebarCoach px-2'>
					<WaySideBarCoachCourse />
					<WaySideBarCoach />
				</div>
			</div>
		</WayBookContext.Provider>
	);
}
