
export function getCoachPartnerPages() {
    return [
        {
            "key": "clients", "label": "Clients",
            "sub_tabs": [
                { "key": "inbox", "label": "Inbox" },
                { "key": "leads", "label": "Leads" },
                { "key": "clients", "label": "Clients" },
                { "key": "my_coaches", "label": "My Coaches" },
                { "key": "groups_and_tags", "label": "Groups & Tags" },
                { "key": "custom_fields", "label": "Custom Fields" },
            ]
        },
        {
            "key": "sales", "label": "Sales",
            "sub_tabs": [
                { "key": "pipeline", "label": "Pipeline" },
                { "key": "onboard_training", "label": "OnBoard Training" },
                { "key": "contracts", "label": "Contracts" },
                { "key": "invoices", "label": "Invoices" },
            ]
        },
        {
            "key": "campaign", "label": "Campaign",
            "sub_tabs": [
                { "key": "campaign_list", "label": "Campaign List" },
                { "key": "call_status", "label": "Call Status" },
                { "key": "caller_group", "label": "Caller Group" },
                { "key": "template_library", "label": "Template Library" },
                { "key": "unsubscribe_keywords", "label": "Unsubscribe Keywords" },
            ]
        },
        {
            "key": "coaching", "label": "Coaching",
            "sub_tabs": [
                { "key": "assessment_list", "label": "Assessments" },
                { "key": "trainings", "label": "Trainings" },
                { "key": "training_tags", "label": "Training Tags" },
                { "key": "resources", "label": "Resources" },
            ]
        },
        {
            "key": "reports", "label": "Reports",
            "sub_tabs": [
                { "key": "reporting", "label": "Reporting" },
                { "key": "credit_log", "label": "Credit Log" },
            ]
        },
        {
            "key": "settings", "label": "Settings",
            "sub_tabs": [
                // { "key": "profile", "label": "Profile" },
                // { "key": "coach_partner", "label": "Coach Partner" },
                // { "key": "permission_library", "label": "Permission Library" },
                // { "key": "credits", "label": "Credits" },
                { "key": "calendar", "label": "Calendar" },
                { "key": "theme_settings", "label": "Theme Settings" },
                { "key": "settings_email", "label": "Email Setting" },
                // { "key": "phone_numbers", "label": "Phone Numbers" },
                // { "key": "billing", "label": "Billing" },
                // { "key": "transaction_log", "label": "Transaction Log" },
                // { "key": "payment", "label": "Payment" },
                { "key": "api", "label": "API" },
                // { "key": "website_builder", "label": "Website Builder" },
            ]
        },
    ];
}

export function getCoachPages() {
    return [
        {
            "key": "clients", "label": "Clients",
            "sub_tabs": [
                { "key": "inbox", "label": "Inbox" },
                { "key": "leads", "label": "Leads" },
                { "key": "clients", "label": "Clients" },
                { "key": "my_coaches", "label": "My Coaches" },
                { "key": "groups_and_tags", "label": "Groups & Tags" },
                { "key": "custom_fields", "label": "Custom Fields" },
            ]
        },
        {
            "key": "sales", "label": "Sales",
            "sub_tabs": [
                { "key": "pipeline", "label": "Pipeline" },
                { "key": "onboard_training", "label": "OnBoard Training" },
                { "key": "contracts", "label": "Contracts" },
                { "key": "invoices", "label": "Invoices" },
            ]
        },
        {
            "key": "campaign", "label": "Campaign",
            "sub_tabs": [
                { "key": "campaign_list", "label": "Campaign List" },
                { "key": "call_status", "label": "Call Status" },
                { "key": "caller_group", "label": "Caller Group" },
                { "key": "template_library", "label": "Template Library" },
                { "key": "unsubscribe_keywords", "label": "Unsubscribe Keywords" },
            ]
        },
        {
            "key": "coaching", "label": "Coaching",
            "sub_tabs": [
                { "key": "assessment_list", "label": "Assessments" },
                { "key": "trainings", "label": "Trainings" },
                { "key": "training_tags", "label": "Training Tags" },
                { "key": "resources", "label": "Resources" },
            ]
        },
        {
            "key": "reports", "label": "Reports",
            "sub_tabs": [
                { "key": "reporting", "label": "Reporting" },
                { "key": "credit_log", "label": "Credit Log" },
            ]
        },
        {
            "key": "settings", "label": "Settings",
            "sub_tabs": [
                // { "key": "profile", "label": "Profile" },
                { "key": "global_constraint_setting", "label": "Global Constraint Setting" },
                { "key": "coach_partner", "label": "Coach Partner" },
                { "key": "permission_library", "label": "Permission Library" },
                { "key": "credits", "label": "Credits" },
                { "key": "calendar", "label": "Calendar" },
                { "key": "notifications", "label": "Notifications" },
                { "key": "theme_settings", "label": "Theme Settings" },
                { "key": "phone_numbers", "label": "Phone Numbers" },
                { "key": "billing", "label": "Billing" },
                { "key": "transaction_log", "label": "Transaction Log" },
                { "key": "payment", "label": "Payment" },
                { "key": "api", "label": "API" },
                { "key": "website_builder", "label": "Website Builder" },
            ]
        },
    ];
}

export function getStaffPages() {
    return [
        {
            "key": "master_users", "label": "Users", "sub_tabs":
                [
                    { "key": "coaches", "label": "Coaches" },
                    { "key": "staff", "label": "Staff" },
                    { "key": "permission_library", "label": "Permission Library" },
                    { "key": "clone_coach_settings", "label": "Clone Coach Settings" },
                ]
        },
        {
            "key": "plans", "label": "Plans", "sub_tabs":
                [
                    { "key": "coach_packages", "label": "Coach Packages" },
                    { "key": "credit_packages", "label": "Credit Packages" },
                    { "key": "proposal_list", "label": "Proposals" },
                    { "key": "revenue_goals", "label": "Revenue Goals" },
                    { "key": "revenue_products", "label": "Revenue Products" },
                ]
        },
        {
            "key": "credits", "label": "Credits", "sub_tabs":
                [
                    { "key": "free_credit", "label": "Free Credit" },
                    { "key": "consume_criteria", "label": "Credit Consumption Criteria" },
                ]
        },
        {
            "key": "coaching", "label": "Coaching", "sub_tabs":
                [
                    { "key": "training", "label": "Training" },
                    { "key": "training_tags", "label": "Training Tags" },
                    { "key": "resources", "label": "Resources" },
                    { "key": "tracker_steps", "label": "Tracker Steps" },
                    { "key": "assessment_questions", "label": "Assessment Questions" },
                ]
        },
        {
            "key": "reports", "label": "Reports", "sub_tabs":
                [
                    { "key": "activity_log", "label": "Activity Log" },
                    { "key": "twillio_usagelog", "label": "Twilio Usage Log" },
                    { "key": "credit_consumption_log", "label": "Credit Consumption Log" },
                    { "key": "coach_login_history", "label": "Coach Login History" },
                    { "key": "coach_subscription_log", "label": "Coach Subscription Log" },
                    { "key": "coach_nonsubscription_log", "label": "Coach Nonsubscription Log" },
                    { "key": "invoices_lists", "label": "Invoice Log" },


                ]
        },
        {
            "key": "cms", "label": "CMS", "sub_tabs":
                [
                    { "key": "disclaimer", "label": "Disclaimer" },
                    { "key": "terms_of_service", "label": "Terms of Service" },
                    { "key": "privacy_policy", "label": "Privacy Policy" },
                    { "key": "default_template", "label": "Default Notification Template" },
                    { "key": "landing_page", "label": "Landing Page" },


                ]
        },
        {
            "key": "staff_setting", "label": "Setting", "sub_tabs":
                [
                    { "key": "settings_integration", "label": "Integration" },
                    { "key": "settings_email", "label": "Email" },
                    { "key": "settings_payment", "label": "Payment" },
                    { "key": "settings_domain", "label": "Domain" },
                    { "key": "system_forms", "label": "System Forms" },
                    { "key": "standard_profile_request", "label": "Standard Profile Request" },
                    { "key": "settings_cms_blogs", "label": "Blogs" },
                    { "key": "settings_cms_blogs", "label": "Testimonial" },
                    { "key": "settings_cms_blogs", "label": "FAQs" },

                ]
        },
    ];
}
