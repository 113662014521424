import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import Loader from "react-loader";
import { ApiUrl } from "../../config";
import { bcrypt, dcrypt, uToken } from "../../useToken";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AvField, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { Asia, TimezoneMain, America, Australia, Europe, Indian } from "../../common/data/timezones";
import Select from "react-select";
import { toast, ToastContainer, Flip } from "react-toastify";
import $ from "jquery";
import { Link } from "react-router-dom";
const Implementation = (props) => {

    //console.log('implementation  props ', props)
    const [loaded, setLoaded] = useState(false);
    const [hideUnhide, setHideUnhide] = useState(false);
    const [main, setMain] = useState('Asia');
    const [Timezone] = useState(TimezoneMain);
    const [zones, setZones] = useState(Asia);
    const [zone, setZone] = useState('Asia/Aden');
    const [records, setRecords] = useState({
        date: '',
        noweeks: '',
        dates: [],
        records: []
    });
    const [html, setHtml] = useState([]);


    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const [record, setRecord] = useState({
        'meeting_sequence': 'once_a_week',
        'meeting_day': 'Monday',
        'meeting_time': '',
        'main_zone': 'America',
        'time_zone': 'America/New_York',
        'reminder_1': '',
        'reminder_2': '',
        'reminder_3': ''

    });

    const [page2Class, setpage2Class] = useState("");


    useEffect(() => {
        setZone('America/New_York')
        getImplementation();
        getReminder();
    }, []);


    const changeTimeZone = (zone) => {
        setZone(zone);
        setRecord(currValue => ({
            ...currValue,
            time_zone: zone
        }))
    }

    const changeMainZone = (zone) => {
        if (zone == 'Asia') {
            setZones(Asia);
            setZone(Asia[0].id)
        } else if (zone == 'America') {
            setZones(America);
            setZone(America[0].id)
        } else if (zone == 'Australia') {
            setZones(Australia);
            setZone(Australia[0].id)
        } else if (zone == 'Europe') {
            setZone(Europe[0].id)
            setZones(Europe);
        } else if (zone == 'Indian') {
            setZone(Indian[0].id)
            setZones(Indian);
        }
        setMain(zone);
        setRecord(currValue => ({
            ...currValue,
            main_zone: zone
        }))
    }


    const getReminder = () => {
        fetch(`${ApiUrl}` + "get/assessments/reminder", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                id: props.assessment,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                    changeMainZone(response.data.record.main_zone);
                    setZone(response.data.record.time_zone);
                    setpage2Class("slide-out2");
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const getImplementation = () => {
        fetch(`${ApiUrl}` + "assessments/implementation/flow", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                id: props.assessment,
                slug: props.slug,
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setRecords(response.data);
                    setLoaded(true);
                } else {
                    setLoaded(true);
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
                setLoaded(true);
            });
    };

    const handleAtSubmit = async (event, values) => {
        values.id = props.assessment;

        fetch(`${ApiUrl}` + "assessments/reminder", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    setpage2Class("slide-out2");
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    getReminder();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }
    const customClass = ['add_quarterly_reviews', 'initial_planning_meeting'];


    let co = 0;
    let types = 1;

    let buffer = [];

    function export3Word(filename = '') {
        setHtml([])
        fetch(`${ApiUrl}` + "assessments/implementation/flow/dowload", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                id: props.assessment,
                slug: props.slug,
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                let array = response.data.array;
                setHtml(array);

                export2Word();
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }



    function export2Word(filename = '') {

        var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' 
        xmlns: w = 'urn:schemas-microsoft-com:office:word'
        xmlns = 'http://www.w3.org/TR/REC-html40' >
        <head>
             <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
            <meta charset='utf-8'>
                <style>
                    
                table {
                    border-width: 0px;
                    border-spacing: 0px;
                    border-style: solid;
                    border-color: #fff;
                    border-collapse: collapse;
                    background-color: white;
                    font-family: 'Poppins', sans-serif;
                    }
                    table th {
                        border-width: 1px;
                        padding: 0px;
                        border-style: solid;
                        border-color: #ddd;
                        background-color: white;
                        -moz-border-radius: ;
                    }
                    table td {
                        border-width: 0px;
                        padding: 0px;
                        border-style: solid;
                        border-color: #ddd;
                        background-color: white;
                        -moz-border-radius: ;
                        height:25px;
                        text-transform: capitalize;
                    }
                </style>
                </head>
                <body> 
                <h2 style="font-size: 30px;text-align: center;font-weight: normal;font-family: 'Poppins', sans-serif;">IMPLEMENTATION CALENDAR</h2>
                <h4 style="font-size: 25px;text-align: center;font-weight: normal;color:#29bbe3;font-family: 'Poppins', sans-serif;">Prepared for ${props.name}</h4>
                <h6 style="font-size: 16px;font-weight: normal;font-family: 'Poppins', sans-serif;">Implementation starts: ${records.date}</h6>
                `;
        var postHtml = "</body></html > ";
        var html = preHtml + document.getElementById('dynamicData').innerHTML + postHtml;

        var link, url, css;


        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });
        url = URL.createObjectURL(blob);
        link = document.createElement('A');
        link.href = url;
        link.download = 'Document';  // default name without extension 
        document.body.appendChild(link);
        if (navigator.msSaveOrOpenBlob)
            navigator.msSaveOrOpenBlob(blob, 'Document.docx'); // IE10-11
        else link.click();  // other browsers
        document.body.removeChild(link);

        setHtml([]);

    };


    function chunkArrayInGroups(arr, size) {
        var myArray = [];
        for (var i = 0; i < arr.length; i += size) {
            myArray.push(arr.slice(i, i + size));
        }
        return myArray;
    }

    function titleCase(str) {
        var splitStr = str.toLowerCase().split('_');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }


    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = (e) => {
        if (!props.canEdit) {
            return true;
        }
        const copyListItems = [...records.records];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;

        setRecords((prevState) => ({
            ...prevState,
            records: copyListItems,
        }));


        // this.setState({
        //     records: copyListItems
        // });
        updateSequence(copyListItems);
    };

    const updateSequence = (items_arr) => {


        fetch(`${ApiUrl}` + "update/implementation/order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ items_arr: items_arr }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    props.getAssessment();
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const impWeekUpdation = (event) => {
        if (!props.canEdit) {
            return true;
        }
        event.preventDefault();
        fetch(`${ApiUrl}` + "update/implementation/week", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ imp_id: event.target.name, imp_weeks: event.target.value }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    getImplementation();
                    // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    const handleChange = (status, imp_id, type_key) => {

        fetch(`${ApiUrl}` + "assessment/module/hideunhide", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ imp_id: imp_id, assessment_id: props.assessment, type_key: type_key, status: status }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    console.log(imp_id, 'heree')
                    window.location.reload(false);
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }
    const renderTd = () => {
        let count = -1;
        let continues = 0;
        let percentage = 0;
        for (const i of [1, 2, 3]) {

        }
        return records.records.map((row, r) => {

            count = records.records[r - 1] ? (count = count + (row.status == 1 ? records.records[r - 1].imp_weeks : 0)) : (count = 0);
            if (r == 0 && row.status == 0) {
                count = -4;
            }

            return (
                <tr key={'mair-' + r + '-' + count}
                    onDragStart={(e) => dragStart(e, r)}
                    onDragEnter={(e) => dragEnter(e, r)}
                    onDragEnd={drop}
                    draggable>
                    <td className="implementation-td"> <div style={{ textAlign: 'left', paddingLeft: '10px' }} className={`text-transform-capitalize ${customClass.includes(row.type_key) ? 'meeting' : ''}`}>
                        {!customClass.includes(row.type_key) ? (
                            <>
                                <Link to={`/assessment/${bcrypt(row.assessment_id)}/${row.type_key}`}>{row.cat_name}</Link>
                            </>
                        ) : row.cat_name}
                        <span className="pull-right mr-5" title={`Input percentage : ${row.input_percentage}% `}>{row.input_percentage}%</span>
                    </div>
                    </td>
                    <td style={{ width: '30px' }}>
                        <input disabled={props.canEdit ? false : true} onChange={(e) => handleChange(e.target.checked, row.imp_id, row.type_key)} type={'checkbox'} name="imp_id" value={row.imp_id} defaultChecked={row.status} />
                    </td>
                    <td className="ImplimAssment">
                        <select disabled={props.canEdit ? false : true} className="form-control" name={`${row.imp_id}`} value={row.imp_weeks} onChange={(e) => impWeekUpdation(e)}>
                            {/* <option value={0}>0</option> */}
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                        </select>
                    </td>
                    {
                        row.status == 1 ? (
                            [...Array(Number(records.noweeks))].map((x, i) => {
                                if (continues > 0) {
                                    continues = continues - 1;
                                    return;
                                }

                                if (count == i) {
                                    continues = row.imp_weeks - 1;

                                    percentage = row.imp_weeks > 0 ? (row.imp_weeks / 4) * 100 : 0;
                                    return (
                                        <td colSpan={row.imp_weeks} key={'child' + i}>
                                            <div className={`imp-time rounded-3 ${customClass.includes(row.type_key) ? 'meeting' : ''}`} style={{ width: `${row.imp_weeks < 5 && records.records.length < 2 && percentage}%` }}>{row.imp_weeks}</div>
                                        </td>
                                    );
                                } else {
                                    return <td key={'child' + i}></td>;
                                }
                            })
                        ) : (
                            <td></td>
                        )
                    }
                </tr>
            )
        })
    }
    return (
        <React.Fragment>
            <section className="breakthrough d-flex space-between sub-nav-bg d-none">
                <h5 className="mb-0 text-transform-capitalize">
                    {props.module && props.module.replaceAll("_", " ")}
                </h5>
                <div className="">
                    <button className="btn btn-sm mr-5 btn-primary" onClick={() => export3Word()}><i className="fa fa-file-word"></i> Dowload</button>
                    <button className="btn btn-success btn-sm" onClick={() => {
                        setpage2Class("slide-in");
                    }}><i className="fa fa-cogs"></i> Settings</button>
                </div>
            </section>
            <div id="dynamicData" className="WordSection1">
                {html.map((row, ri) => {
                    co = 0;
                    return (
                        <div key={ri} className="table-responsive" style={{ padding: '0' }}>
                            <h5 style={{ fontSize: '16px', fontFamily: 'Poppins, sans-serif', fontWeight: 'normal' }}> Quarter {ri + 1}</h5>
                            <table className="table table-striped" cellPadding={0} cellSpacing={0}>
                                <thead>
                                    <tr>

                                        <th width="210" style={{ textAlign: 'center', border: '1px solid #ddd', fontSize: '13px', textTransform: 'capitalize' }}>IMPLEMENTATION LINKS</th>
                                        <th style={{ textAlign: 'center', border: '1px solid #ddd', width: '50px', fontSize: '13px', textTransform: 'capitalize' }}>TIME<br /> <small>(WEEKS)</small></th>
                                        {
                                            Array.from({ length: 12 }, (_, i) => {
                                                return <th style={{ textAlign: 'center', border: '1px solid #ddd', width: '50px', fontSize: '13px', textTransform: 'capitalize' }} key={'head--' + i + 1}>{i + 1}</th>
                                            })
                                        }
                                    </tr>
                                </thead>

                                <tbody>
                                    {


                                        row.map((tbody, b) => {
                                            co = html[ri][b - 1] ? (co = co + html[ri][b - 1].imp_weeks) : (co = 0);
                                            return (
                                                <tr key={b}>
                                                    <td style={{ border: '1px solid #ddd', textAlign: 'center', width: '210px', fontSize: '13px', textTransform: 'capitalize' }}>{titleCase(tbody.type_key)}</td>
                                                    <td style={{ border: '1px solid #ddd', textAlign: 'center', width: '50px', fontSize: '13px', textTransform: 'capitalize' }}>{tbody.imp_weeks}</td>
                                                    {
                                                        Array.from({ length: 12 }, (_, c) => {
                                                            return (
                                                                co == c ? (
                                                                    <td style={{ background: '#3a3b3d', textAlign: 'center', width: '50px', fontSize: '13px', textTransform: 'capitalize' }} colSpan={tbody.imp_weeks} key={'child' + b}>
                                                                        {/* {c = c + tbody.imp_weeks} */}
                                                                        <div className={`imp-time ${customClass.includes(tbody.type_key) ? 'meeting' : ''}`}>{tbody.imp_weeks}</div>
                                                                    </td>
                                                                ) : (
                                                                    <td ></td>
                                                                )
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>

                            </table>
                            <br />
                            <br />
                        </div>
                    )
                })}
            </div>






            {page2Class == "slide-in" && (
                <div className={`page3 ${page2Class} custom-scroll`}>
                    <div>
                        <div className="modal-head top-heading">
                            <h2>MEETING REMINDERS</h2>
                            <button
                                onClick={() => {
                                    setpage2Class("slide-out2");
                                }}
                                className="btn btn-link text-white"
                            >
                                <i className="fas fa-times"> </i>
                            </button>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-body">
                                    <AvForm
                                        onValidSubmit={handleAtSubmit}
                                        className="needs-validation"
                                    >
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="mb-3">
                                                    <AvField
                                                        name="meeting_sequence"
                                                        label="Meeting sequence"
                                                        className="form-control"
                                                        type="select"
                                                        value={record.meeting_sequence}
                                                    >
                                                        <option value={'once_a_week'}>Once a week</option>
                                                        {/* <option value={'Twice a week'}>Twice a week</option> */}
                                                        <option value={'once_every_2_weeks'}>Once every 2 weeks</option>
                                                        <option value={'once_every_3_weeks'}>Once every 3 weeks</option>
                                                        <option value={'once_a_month'}>Once a month</option>
                                                    </AvField>
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        name="meeting_day"
                                                        label="Meeting day"
                                                        className="form-control"
                                                        type="select"
                                                        value={record.meeting_day}
                                                    >
                                                        <option value={'Sunday'}>Sunday</option>
                                                        <option value={'Monday'}>Monday</option>
                                                        <option value={'Tuesday'}>Tuesday</option>
                                                        <option value={'Wednesday'}>Wednesday</option>
                                                        <option value={'Thursday'}>Thursday</option>
                                                        <option value={'Friday'}>Friday</option>
                                                        <option value={'Saturday'}>Saturday</option>
                                                    </AvField>
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        name="meeting_time"
                                                        label="Meeting time"
                                                        className="form-control"
                                                        value={record.meeting_time}
                                                        type="time"
                                                        required
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label>Main Zone</label>
                                                    <div className="MainTZone MainZone">
                                                        <Select
                                                            getOptionLabel={(option) => option}
                                                            getOptionValue={(option) => option}
                                                            options={Timezone}
                                                            value={Timezone.filter((id) => id == main)}
                                                            isMulti={false}
                                                            classNamePrefix="select2-selection"
                                                            name="zone"
                                                            onChange={(e) => changeMainZone(e)}
                                                        />
                                                        <AvField required type="hidden" name="main_zone" value={record.main_zone} />
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label>Time Zone</label>
                                                    <div className="MainTZone">
                                                        <Select
                                                            getOptionLabel={(option) => option.id}
                                                            getOptionValue={(option) => option.id}
                                                            options={zones}
                                                            value={zones.filter(({ id }) => id === zone)}
                                                            isMulti={false}
                                                            classNamePrefix="select2-selection"
                                                            name="timezone"
                                                            onChange={(e) => changeTimeZone(e.id)}
                                                        />
                                                        <AvField required type="hidden" name="time_zone" value={record.time_zone} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-6 mb-3 align-self-center">
                                                <section className="main-section-3 ">
                                                    <section className="main-sub-section-3">
                                                        <section className="sub-header">3</section>
                                                        <section className="sub-header">Days</section>
                                                        <section className="sub-header">Before</section>
                                                    </section>
                                                    <section className="middle-section">Send Meeting Reminder to Client.</section>
                                                    <section className="last-section">
                                                        <AvInput type="checkbox" value={record.reminder_1} name="reminder_1" trueValue="three-days" falseValue="" />
                                                    </section>
                                                </section>
                                                <section className="main-section-3">
                                                    <section className="main-sub-section-3">
                                                        <section className="sub-header">1</section>
                                                        <section className="sub-header">Days</section>
                                                        <section className="sub-header">Before</section>
                                                    </section>
                                                    <section className="middle-section">Send Meeting Reminder to Client.</section>
                                                    <section className="last-section">
                                                        <AvInput type="checkbox" value={record.reminder_2} name="reminder_2" trueValue="one-day" falseValue="" />
                                                    </section>
                                                </section>
                                                <section className="main-section-3">
                                                    <section className="main-sub-section-3">
                                                        <section className="sub-header">1</section>
                                                        <section className="sub-header">Hour</section>
                                                        <section className="sub-header">Before</section>
                                                    </section>
                                                    <section className="middle-section">Send Meeting Reminder to Client.</section>
                                                    <section className="last-section">
                                                        <AvInput type="checkbox" value={record.reminder_3} name="reminder_3" trueValue="one-hour" falseValue="" />
                                                    </section>
                                                </section>
                                            </div>
                                        </div>
                                        <div className="button-items pull-right">
                                            <Button className="btn-ld" color="primary" type="submit">
                                                Save
                                            </Button>
                                        </div>
                                    </AvForm>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )
            }


            <Loader loaded={loaded}></Loader>


            <div className="table-responsive WordSection1 survey card card-body pt-0" style={{ minHeight: '350px' }}>
                <h6 className="h-6 d-none">Implementation starts: {records.date}</h6>
                {loaded && (

                    <table className="table" >
                        <thead className="sub-nav-bg">
                            <tr >
                                <th className="implementation-th" style={{ textAlign: 'left' }}>Pathway to Profit</th>
                                <th style={{ textAlign: 'left' }}><small>Hide/Unhide</small></th>
                                <th style={{ textAlign: 'left' }}><small>Weeks</small></th>
                                {

                                    records.dates.map((row, i) => {
                                        return <OverlayTrigger
                                            key={`auto-${i}`}
                                            placement={'auto'}
                                            overlay={
                                                <Tooltip>
                                                    {row.month_year}
                                                </Tooltip>
                                            }
                                        >
                                            <th colSpan={row.week}> {row.month} </th>
                                        </OverlayTrigger>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody id="MainHTML" >
                            {renderTd()}


                        </tbody>
                    </table>
                )}
            </div>

        </React.Fragment >
    );
};

export default Implementation;
