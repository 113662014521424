import React from "react"
import { Card, CardBody, Row , Col, Container} from "reactstrap"


const Activity = props => {
	return (
		<React.Fragment>
			<Container>        
                <Row className="d-flex">
                <div className='d-flex justify-content-between'>
                    <h4 className="card-title mb-3">Activity</h4>
                </div>
                <div className="d-flex flex-nowrap overflow-auto mb-3">
                    {props.reports.map((report, key) => (
                        <Col xl={3} sm={3} key={key}>
                            <Card className="mini-stat bg-primary">
                                <CardBody className="card-body mini-stat-img">
                                    <div className="mini-stat-icon">
                                    <svg className="UserMagnet" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="9" r="3" stroke="#000" stroke-width="1.5"></circle> <circle cx="12" cy="12" r="10" stroke="#000" stroke-width="1.5"></circle> <path opacity="0.5" d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                                    <svg className="MagnetIcon" fill="#000000" width="64px" height="64px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M204.10547,53.05469A83.43674,83.43674,0,0,0,144.57227,28.001C144.46582,28,144.35938,28,144.252,28A83.43215,83.43215,0,0,0,84.8457,52.62012L18.74219,119.53223a12.0281,12.0281,0,0,0,.05273,16.91894l28.65332,28.65332a11.914,11.914,0,0,0,8.48438,3.51563h.07129a11.91554,11.91554,0,0,0,8.51367-3.61426l65.61523-67.16309a20.09518,20.09518,0,0,1,27.99219-.25683,19.55435,19.55435,0,0,1,5.95117,14.03906,20.87149,20.87149,0,0,1-6.01269,14.85645l-67.0166,64.99218a11.9984,11.9984,0,0,0-.13086,17.09864l28.65429,28.65429a12.03749,12.03749,0,0,0,16.88672.082l32.93262-32.2951.001-.00079.00048-.0008,33.55518-32.90545C235.84766,139.20605,236.36719,85.7998,204.10547,53.05469ZM58.79492,159.416a3.97331,3.97331,0,0,1-2.83691,1.2041h-.02442a3.9766,3.9766,0,0,1-2.8291-1.17188L24.45117,130.79492a4.00968,4.00968,0,0,1-.01758-5.63965L53.97412,95.25262l34.146,34.146Zm72.06055,72.18164a4.01382,4.01382,0,0,1-5.62891-.02735L96.57227,202.916a4.00027,4.00027,0,0,1,.04492-5.7002l30.11474-29.20563,34.146,34.14624Zm35.73437-35.04218-34.11523-34.11481,31.20215-30.26a28.9462,28.9462,0,0,0,8.39844-20.63183,27.49364,27.49364,0,0,0-8.40137-19.72656,28.1387,28.1387,0,0,0-39.21289.3789c-.01172.01074-.02246.02149-.03321.0332l-30.71728,31.442L59.59619,89.562,90.51953,58.25977A75.47294,75.47294,0,0,1,144.25293,36c.0957,0,.19434,0,.29.001a75.486,75.486,0,0,1,53.86328,22.66894c29.18945,29.625,28.68848,77.97559-1.08887,107.75293Z"></path> </g></svg>
                                    
                                    </div>
                                    <div className="text-white">
                                        <p className="mb-2 text-white">{report.Desc}</p>
                                        <h2 className="mb-4 text-white">{report.total}</h2>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                    </div>
                </Row>
			</Container>
			
		</React.Fragment>
	)

}

export default Activity
