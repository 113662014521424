import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  inputRemoveSpecialCharacter,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

export default class ProposalsAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.match.params.type,
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {
        name: "",
        product_interval: "Monthly",
        payment_type: "one_time",
        product_status: "Active",
        type:'coaching'
      },
      page_name: "Create new Revenue Product",

      roles: [],
      inputList: [],
    };
    this.role = { id: this.props.role.id };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
  }
  
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/revenueProduct", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response.data.record, 'recordsrecords');
          this.setState({
            record: response.data.record,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    let check = false;
    values.payment_type = this.state.record.payment_type;
    values.product_interval = this.state.record.product_interval;
    if (this.state.id) {
      values.id = this.state.record.product_id ? this.state.record.product_id : -1;
    }
    if (!check) {
      fetch(`${ApiUrl}` + "add/revenueProduct", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
          if (data.status === true) {
            if (this.state.id == null) {
              this.form && this.form.reset();
              this.setState({
                inputList: [],
              });
            }
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });            this.props.history.push("/packages/revenueProducts");
           
          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
 
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };


  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>

        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card className="package-add">
              <AvForm
                onValidSubmit={this.handleSubmit}
                model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody className="pb-0">
                <Row>
                  <Col sm={6}>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      value={this.state.record.name}
                      label="Name"
                      className="form-control"
                      required
                    />
                  </div>
                  </Col>
                  <Col sm={6}>
                  <div className="mb-3">
                      <AvField
                         className="select form-control"
                        type="select"
                        value={this.state.record.type}
                        name="type"
                        label="Product Type"
                      >
                        <option value={"coaching"}> Coaching</option>
                        <option value={"e-learning"}> E-Learning </option>
                      </AvField>
                  </div>
                </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                  <div className="mb-3">
                    <label> Price </label>
                    <div className="group-addnon">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="validationTooltipUsernamePrepend"
                          >
                            $
                          </span>
                        </div>
                        <AvField
                          name="price"
                          id="price"
                          type="number"
                          value={this.state.record.price}
                          validate={{
                            required: { value: true, errorMessage: 'This field is invalid' },
                            pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                          }} />
                      </div>
                    </div>
                  </div>
                  </Col>
                  <Col sm={3}>
                  <div className="mb-3">
                    <label> Setup Fee </label>
                    <div className="group-addnon">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="validationTooltipUsernamePrepend"
                          >
                            $
                          </span>
                        </div>
                        <AvField
                          name="setup_fee"
                          id="setup_fee"
                          type="number"
                          value={this.state.record.setup_fee}
                          validate={{
                            required: { value: true, errorMessage: 'This field is invalid' },
                            pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                          }} />
                      </div>
                    </div>
                  </div>
                  </Col>
                
                  <Col sm={3}>
                    <div className="mb-3 pt-2">
                        <label> Payment Type </label>
                        <div className="group-addnon">
                          <div className="form-check form-check-inline">
                            <input
                              checked={this.state.record.payment_type == 'one_time' ? "checked" : ""}
                              name={'payment_type'}
                              type="radio"
                              className="form-check-input"
                              value={'one_time'}
                              onChange={() => this.setState(prevState => {
                                let record = Object.assign({}, prevState.record);
                                record.payment_type = `one_time`;
                                return { record };
                              })}
                            />
                            <label className="form-check-label">{`One Time`}</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              checked={this.state.record.payment_type == 'recurring' ? "checked" : ""}
                              name={'payment_type'}
                              type="radio"
                              className="form-check-input"
                              value={'recurring'}
                              onChange={() => this.setState(prevState => {
                                let record = Object.assign({}, prevState.record);
                                record.payment_type = `recurring`;
                                return { record };
                              })}
                            />
                            <label className="form-check-label">{`Recurring`}</label>
                          </div>
                          </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                      {
                        this.state.record.payment_type == 'recurring' && (
                          <div className="mb-3 pt-2">
                            <label> Payment Interval </label>
                            <div className="group-addnon">
                            {
                              [{ label: 'Monthly', value: 'Monthly' },{ label: 'Quarterly', value: 'Quarterly' },{ label: 'Yearly', value: 'Yearly' }].map((row, i) => {
                                  return (
                                      <div key={i} className="form-check form-check-inline">
                                          <input checked={this.state.record.product_interval == row.value ? 'checked' : ''}
                                              onChange={(e) => {
                                                  this.setState(prevState => {
                                                      let record = Object.assign({}, prevState.record);
                                                      record.product_interval = e.target.value;
                                                      return { record };
                                                  })
                                              }} name={'product_interval'} type="radio"
                                              className="form-check-input" value={row.value} />
                                          <label className="form-check-label">{`${row.label}`}</label>
                                      </div>
                                  );
                              })
                            }
                            </div>
                          </div>
                        )
                      }
                        
                  </Col>
                </Row>
                  <div className="mb-3">
                    <AvField
                      name="description"
                      value={this.state.record.description}
                      label="Description"
                      type="textarea"
                      required
                      className="form-control"
                      
                    />
                    
                  </div>
                  <div className="mb-3">
                      <AvField
                        className="select form-control"
                        type="select"
                        value={this.state.record.product_status}
                        name="product_status"
                        label="status"
                      >
                        <option value={"Active"}> Active </option>
                        <option value={"Inactive"}> Inactive </option>
                      </AvField>
                    </div>
                </ModalBody>
                <ModalFooter className="py-0 border-0">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light ms-2"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
