import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    ModalHeader
} from "reactstrap";

import ThrusthubHeader from "./header.js";
import { Stepper, Step } from "react-form-stepper";
import { Accordion, ModalTitle, Tab, Tabs } from "react-bootstrap";
import { ApiUrl } from "../../config.js";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import $ from "jquery";
import SweetAlert from 'react-bootstrap-sweetalert';
import { uToken, uInfo } from "../../useToken.js";
import { toast, ToastContainer, Flip } from "react-toastify";
import Loader from "react-loader";
//i18n
const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TrustHub = (props) => {
    const queryParms = useQuery();
    const [step, setStep] = useState(queryParms.get('step') == 'two' ? 1 : queryParms.get('step') == 'three' ? 2 : 0);
    const [volume, setVolume] = useState(queryParms.get('volume') ? queryParms.get('volume') : 'low');
    const [lowVolumeProfile, SetLowVolumeProfile] = useState('No');
    const [highVolumeProfile, SethighVolumeProfile] = useState('No');
    const [data, setData] = useState([]);
    const [profile, setProfile] = useState({
        s_profile_data: [],
        secondary_profile_data: {},
    });
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const [haveSecondryProfile, sethaveSecondryProfile] = useState('Yes');
    const [secondaryBrandSid, setSecondaryBrandSid] = useState("");
    const [countryCode, setCountryCode] = useState('+1');
    const [countryCode2, setCountryCode2] = useState('+1');

    const [hasAuthorized, setHasAuthorized] = useState(false);
    const [companyType, setCompanyType] = useState('private');
    const [stockExchange, setStockExchange] = useState('');





    const [physicalAddress, setPhysicalAddress] = useState({
        address_friendly_name: '',
        s2id_country: 'US',
        city: '',
        state: '',
        postal_code: '',
        address_id: ''
    });

    const [numbers, setNumbers] = useState({
        registered: [],
        notRegistered: []
    });








    useEffect(() => {
        getProfileData();
        getData();
        // if (step == 3) {
        getTwillioNumbers()
        //}

    }, []);

    const getProfileData = () => {
        fetch(`${ApiUrl}` + "check/trusthub/profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setProfile(response.data.records);
                    setHasAuthorized(response.data.records.secondary_profile_data.check_auth_new);
                    if ((response.data.records.account_status == 'twilio-rejected' || response.data.records.account_status == 'in-review' || response.data.records.account_status == 'pending-review') && response.data.records.brand_status != 'APPROVED') {

                        queryParms.get('is_error') != "Yes" && setStep(1);

                        if (queryParms.get('volume') == '' || queryParms.get('volume') == null) {
                            setTimeout(
                                () => props.history.push('/trusthub?step=two&volume=' + volume),
                                1000
                            );
                        }


                    }
                    if (response.data.records.account_status == 'twilio-approved' && response.data.records.brand_status == 'APPROVED' && response.data.records.campaign_status == 'VERIFIED') {
                        setStep(3);
                    }
                    if (response.data.records.account_status == 'twilio-approved' && response.data.records.brand_status == 'APPROVED' && response.data.records.campaign_status != 'VERIFIED') {
                        setStep(1);
                    }
                    if (response.data.records.account_status == 'twilio-approved' && response.data.records.brand_status != 'APPROVED') {
                        setStep(1);
                    }
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getTwillioNumbers = () => {

        setLoaded(false);
        fetch(`${ApiUrl}` + "trusthub/twillio/numbers", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                setLoaded(true);
                if (response.status === true) {
                    setNumbers(response.data.records);
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const upgradeViaSide = () => {
        setLoaded(false);
        fetch(`${ApiUrl}` + "upgrade/profileVia/sid", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ brand_id: secondaryBrandSid }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                setLoaded(true);
                if (response.status === true) {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getData = () => {
        fetch(`${ApiUrl}` + "get/country", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setData(response.data);
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    const handleSubmitRegisterUSA2PBrand = async (event, values) => {
        setLoaded(false);
        fetch(`${ApiUrl}` + "register/a2p/brand", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                setLoaded(true);
                if (data.status === true) {
                    var i = 0;
                    var timer = setInterval(function () {
                        $('#custom').text('');
                        $('#custom').text(Number(5) - Number(i++));
                        if (i === 5) {
                            clearInterval(timer);
                            // setStep(1);
                            setAlert2(false);
                            getProfileData();
                            //props.history.push('trusthub?step=three')
                            window.location.href = "/trusthub"

                            // window.location.href = "/trusthub?step=two&volume=" + volume ? volume : "low"
                        }
                    }, 1000);
                    setAlert2(true);

                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const handleSubmit = async (event, values) => {
        if (volume) {
            values.request_type = volume;
        }
        setLoaded(false);
        fetch(`${ApiUrl}` + "trusthub/upgrade/profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    setTimeout(
                        () => props.history.push('/trusthub?step=' + step + '&volume=' + volume),
                        4000
                    );
                    var i = 0;
                    var timer = setInterval(function () {
                        $('#custom').text('');
                        $('#custom').text(Number(15) - Number(i++));
                        if (i === 15) {
                            clearInterval(timer);
                            setAlert2(false);
                            getProfileData();
                        }
                    }, 1000);
                    setAlert2(true);
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                setLoaded(true);
            })
            .catch((error) => {
                toast(error, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                console.error("Error:", error);
            });
    }


    const handleAssignPhoneSubmit = async (event, values) => {
        setLoaded(false);
        fetch(`${ApiUrl}` + "trusthub/assign/phone", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                setLoaded(true);
                if (data.status === true) {
                    getTwillioNumbers();
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const handleUseCaseSubmit = async (event, values) => {
        setLoaded(false);
        fetch(`${ApiUrl}` + "trusthub/starter/stepTwo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                setLoaded(true);
                if (response.status === true) {
                    var i = 0;
                    var timer = setInterval(function () {
                        $('#custom').text('');
                        $('#custom').text(Number(5) - Number(i++));
                        if (i === 5) {
                            clearInterval(timer);
                            setStep(3);
                            setAlert2(false);
                            window.location.href = "/trusthub";
                            // window.location.href = "/trusthub?step=three";
                        }
                    }, 1000);

                    setAlert2(true);
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });

    }



    const sync_already_created_account = async (event, values) => {
        setLoaded(false);
        values.request_type = 1;
        values.coach_id = uInfo().user_id;

        fetch(`${ApiUrl}` + "sync/trusthub/profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                setLoaded(true);
                if (data.status === true) {
                    getProfileData();
                    getTwillioNumbers();
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    return (
        <React.Fragment>
            <div className="trusthub" >
                <ToastContainer />
                <Loader loaded={loaded}></Loader>
                <Container fluid>
                    <Row>
                        <Col md={12}
                            lg={12}
                            xl={12} >
                            <ThrusthubHeader />
                            <Card>
                                <CardBody>
                                    <div className="heading mb-1">
                                        <h3 className="btn-link text-center">A2P Messaging (SMS) Profile Verification</h3>
                                    </div>

                                    <Stepper activeStep={step}>
                                        <Step label="Business Profile" />
                                        <Step label="Register US A2P Brand" />
                                        <Step label="Register Campaign Use Cases" />
                                        <Step label="Assign Phone" />
                                    </Stepper>

                                    {
                                        step == 0 && (
                                            <>
                                                <table className={`table table-striped  text-center ${queryParms.get('step') ? 'd-none' : ''}`}>
                                                    <thead className="">
                                                        <tr>
                                                            <th>&nbsp;</th>
                                                            <th style={{ borderTop: '5px solid #60c560' }}>Low Volume Standard Profile</th>
                                                            <th style={{ borderTop: '5px solid #f0ad4e' }}>Standard Profile</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td width={'33%'}>Registration cost</td>
                                                            <td width={'33%'}>$4 one-time fee for Brand, monthly Campaign cost varies by use case</td>
                                                            <td width={'33%'}>$44 one-time fee for Brand, monthly Campaign cost varies by use case</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Registration information required</td>
                                                            <td>Business information, incl. US EIN number or non-US business registration number</td>
                                                            <td>Business information, incl. US EIN number or non-US business registration number</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Number of phone numbers permitted</td>
                                                            <td> Greater than 1</td>
                                                            <td> Greater than 1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Number of SMS segments and MMS (total) per day to the US</td>
                                                            <td>Up to 6,000 to US, based on a hard limit of 2,000/day to T-Mobile</td>
                                                            <td>Up to 200,000/day to T-Mobile, based on Trust Score</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Message per second (MPS) throughput toward US</td>
                                                            <td>3.75 toward major US networks</td>
                                                            <td>Up to 225 toward major US networks, based on Trust Score</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Connect TrustHub Existing Profile</td>
                                                            <td>
                                                                <fieldset className="form-group">
                                                                    <div className="form-control border-0 p-0 h-auto ">
                                                                        <label className="mr-5">Already have a Low Volume Standard Brand?</label>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                checked={lowVolumeProfile == 'Yes' ? "checked" : ""}
                                                                                onChange={() => SetLowVolumeProfile('Yes')}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                value={'Yes'}
                                                                            />
                                                                            <label className="form-check-label">Yes</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                checked={lowVolumeProfile == 'No' ? "checked" : ""}
                                                                                onChange={() => SetLowVolumeProfile('No')}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                value={'No'}
                                                                            />
                                                                            <label className="form-check-label">No</label>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </td>
                                                            <td>
                                                                <fieldset className="form-group">
                                                                    <div className="form-control border-0 p-0 h-auto ">
                                                                        <label className="mr-5">Already have a Standard Brand Profile?</label>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                checked={highVolumeProfile == 'Yes' ? "checked" : ""}
                                                                                onChange={() => SethighVolumeProfile('Yes')}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                value={'Yes'}
                                                                            />
                                                                            <label className="form-check-label">Yes</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                checked={highVolumeProfile == 'No' ? "checked" : ""}
                                                                                onChange={() => SethighVolumeProfile('No')}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                value={'No'}
                                                                            />
                                                                            <label className="form-check-label">No</label>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                {
                                                                    lowVolumeProfile == 'Yes' ?

                                                                        <div className="form">

                                                                            <AvForm
                                                                                onValidSubmit={sync_already_created_account}
                                                                                className=" needs-validation row gx-3 gy-2 align-items-center"
                                                                            >
                                                                                <div className="col-md-10">

                                                                                    <AvField
                                                                                        name="a2p_registration_sid"
                                                                                        className="form-control "
                                                                                        type="text"
                                                                                        required
                                                                                        placeholder={"Enter US A2P Brand Registration SID"}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <button
                                                                                        className="btn btn-success"
                                                                                        type="submit"
                                                                                    >
                                                                                        Go
                                                                                    </button>
                                                                                </div>
                                                                            </AvForm>
                                                                        </div>
                                                                        :

                                                                        <button onClick={() => props.history.push('/trusthub?step=one&volume=low')} type="button" className="waves-effect btn btn-outline-dark btn-block">Register Low Volume Standard Profile</button>

                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    highVolumeProfile == 'Yes' ?

                                                                        <div className="form">

                                                                            <AvForm
                                                                                onValidSubmit={sync_already_created_account}
                                                                                className=" needs-validation row gx-3 gy-2 align-items-center"
                                                                            >
                                                                                <div className="col-md-10">

                                                                                    <AvField
                                                                                        name="a2p_registration_sid"
                                                                                        className="form-control "
                                                                                        type="text"
                                                                                        required
                                                                                        placeholder={"Enter US A2P Brand Registration SID"}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <button
                                                                                        className="btn btn-success"
                                                                                        type="submit"
                                                                                    >
                                                                                        Go
                                                                                    </button>
                                                                                </div>
                                                                            </AvForm>
                                                                        </div>
                                                                        :

                                                                        <button onClick={() => props.history.push('/trusthub?step=one&volume=high')} type="button" className="waves-effect btn btn-outline-dark btn-block">Register Standard Profile</button>

                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {
                                                    queryParms.get('step') == 'one' && (
                                                        <Accordion defaultActiveKey={"0"} className="border-0">
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header> Customer Profile information</Accordion.Header>
                                                                <Accordion.Body className="pt-3">
                                                                    <div className="alert alert-info fade show" role="alert"><strong>Submit Valid Information for Secondary Business Profile</strong> </div>
                                                                    <AvForm
                                                                        onValidSubmit={handleSubmit}
                                                                        className="needs-validation"
                                                                    >

                                                                        <Row>
                                                                            <Col md="6" className="mb-3">
                                                                                <AvField
                                                                                    name="legal_business_name"
                                                                                    label="Legal Business Name *"
                                                                                    className="form-control "
                                                                                    type="text"
                                                                                    value={profile.secondary_profile_data.legal_business_name}
                                                                                    required
                                                                                />
                                                                                <note>Enter the exact legal business name, as registered with the EIN. E.g. Twilio Inc. rather than Twilio</note>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <AvField
                                                                                    name="profile_friendly_name"
                                                                                    label="Profile Friendly Name"
                                                                                    value={profile.secondary_profile_data.profile_friendly_name}
                                                                                    className="form-control mb-3"
                                                                                    type="text"
                                                                                />
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <label>Business Identity *</label>
                                                                                <AvRadioGroup value={profile.secondary_profile_data.business_identitiy != '' ? profile.secondary_profile_data.business_identitiy : "direct_customer"} className="mb-3" name="business_identitiy" required>
                                                                                    <AvRadio label="Direct Customer My business uses Twilio to communicate internally or with our customers" value="direct_customer" />
                                                                                    <AvRadio label="ISV, Reseller, or Partner My business uses Twilio in a product that I sell to my customers." value="isv_reseller_or_partner" />
                                                                                    <AvRadio label="I don't know" value="unknown" />
                                                                                </AvRadioGroup>
                                                                            </Col>

                                                                            <Col md="6">
                                                                                <AvField
                                                                                    name="business_type"
                                                                                    label="Business Type"
                                                                                    className="form-control mb-3"
                                                                                    type="select"
                                                                                    required
                                                                                    defaulValue={profile.secondary_profile_data.business_type != '' ? profile.secondary_profile_data.business_type : "Sole Proprietorship"}
                                                                                >
                                                                                    <option value="">Select Type</option>
                                                                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                                                    <option value="Partnership">Partnership</option>
                                                                                    <option value="Corporation">Corporation</option>
                                                                                    <option value="Co-operative">Co-operative</option>
                                                                                    <option value="Limited Liability Corporation">Limited Liability Corporation</option>
                                                                                    <option value="Non-profit Corporation">Non-profit Corporation</option>
                                                                                </AvField>
                                                                            </Col>

                                                                            <Col md="6">
                                                                                <AvField
                                                                                    name="business_industry"
                                                                                    label="Business Industry"
                                                                                    className="form-control mb-3"
                                                                                    type="select"
                                                                                    required
                                                                                    value={profile.secondary_profile_data.business_type != '' ? profile.secondary_profile_data.business_type : "AUTOMOTIVE"}
                                                                                >
                                                                                    <option value="">Select Industry</option>
                                                                                    <option value="AUTOMOTIVE">Automotive</option>
                                                                                    <option value="AGRICULTURE">Agriculture</option>
                                                                                    <option value="BANKING">Banking</option>
                                                                                    <option value="CONSUMER">Consumer</option>
                                                                                    <option value="EDUCATION">Education</option>
                                                                                    <option value="ENGINEERING">Engineering</option>
                                                                                    <option value="ENERGY">Energy</option>
                                                                                    <option value="OIL_AND_GAS">Oil and Gas</option>
                                                                                    <option value="FAST_MOVING_CONSUMER_GOODS">Fast Moving Consumer goods</option>
                                                                                    <option value="FINANCIAL">Financial</option>
                                                                                    <option value="FINTECH">Fintech</option>
                                                                                    <option value="FOOD_AND_BEVERAGE">Food &amp; Beverage</option>
                                                                                    <option value="GOVERNMENT">Government</option>
                                                                                    <option value="HEALTHCARE">Healthcare</option>
                                                                                    <option value="HOSPITALITY">Hospitality</option>
                                                                                    <option value="INSURANCE">Insurance</option>
                                                                                    <option value="LEGAL">Legal</option>
                                                                                    <option value="MANUFACTURING">Manufacturing</option>
                                                                                    <option value="MEDIA">Media</option>
                                                                                    <option value="ONLINE">Online</option>
                                                                                    <option value="RAW_MATERIALS">Raw materials</option>
                                                                                    <option value="REAL_ESTATE">Real Estate</option>
                                                                                    <option value="RELIGION">Religion</option>
                                                                                    <option value="RETAIL">Retail</option>
                                                                                    <option value="JEWELRY">Jewelry</option>
                                                                                    <option value="TECHNOLOGY">Technology</option>
                                                                                    <option value="TELECOMMUNICATIONS">Telecommunication</option>
                                                                                    <option value="TRANSPORTATION">Transportation</option>
                                                                                    <option value="TRAVEL">Travel</option>
                                                                                    <option value="ELECTRONICS">Electronics</option>
                                                                                    <option value="NOT_FOR_PROFIT">Not for profit</option>
                                                                                </AvField>
                                                                            </Col>

                                                                            <Col md="6">
                                                                                <AvField
                                                                                    name="business_registration_identifier"
                                                                                    label="Business Registration ID Type"
                                                                                    className="form-control mb-3"
                                                                                    type="select"
                                                                                    required
                                                                                    disabled={volume == 'low' ? true : false}
                                                                                    value={volume == 'low' ? "EIN" : ""}
                                                                                >
                                                                                    <option value="">Select Type</option>
                                                                                    <option value="EIN">USA: Employer Identification Number (EIN)</option>
                                                                                    <option value="DUNS">USA: DUNS Number (Dun &amp; Bradstreet)</option>
                                                                                    <option value="CCN">Canada: Canadian Corporation Number (CCN)</option>
                                                                                    <option value="CN">Great Britain: Company Number</option>
                                                                                    <option value="ACN">Australia: Company Number from ASIC (ACN)</option>
                                                                                    <option value="CIN">India: Corporate Identity Number</option>
                                                                                    <option value="VAT">Estonia: VAT Number</option>
                                                                                    <option value="VATRN">Romania: VAT Registration Number</option>
                                                                                    <option value="RN">Israel: Registration Number</option>
                                                                                    <option value="Other">Other</option>
                                                                                </AvField>
                                                                            </Col>
                                                                            <Col md="6" className="mb-3">
                                                                                <label>Business Registration Number</label>
                                                                                <div className="input-group">
                                                                                    <AvInput name="business_registration_number" value={profile.secondary_profile_data.business_registration_number} required type="text" className="form-control" />
                                                                                    <div className="input-group-text"><i className="fas fa-info-circle"></i></div>
                                                                                </div>
                                                                                <note>Enter the EIN Tax ID as it appears in the EIN listing</note>
                                                                            </Col>

                                                                            <Col md="6">
                                                                                <label>Business Regions of Operations</label>
                                                                                <AvRadioGroup className="mb-3" value={profile.secondary_profile_data.business_regions_of_operation != '' ? profile.secondary_profile_data.business_regions_of_operation : "USA and Canada"} name="business_regions_of_operation" required>
                                                                                    <AvRadio label="Africa" value="AFRICA" />
                                                                                    <AvRadio label="Asia" value="ASIA" />
                                                                                    <AvRadio label="Europe" value="EUROPE" />
                                                                                    <AvRadio label="Latin America" value="LATIN_AMERICA" />
                                                                                    <AvRadio label="USA and Canada" value="USA_AND_CANADA" />
                                                                                </AvRadioGroup>
                                                                            </Col>

                                                                            <Col md="6" className="mb-3">
                                                                                <AvField
                                                                                    name="website_url"
                                                                                    label="Website Url"
                                                                                    className="form-control "
                                                                                    type="text"
                                                                                    value={profile.secondary_profile_data.website_url}
                                                                                    required
                                                                                />

                                                                                <AvField
                                                                                    name="social_media_profile_urls"
                                                                                    label="Social Media Profile URL"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    value={profile.secondary_profile_data.social_media_profile_urls != '' ? profile.secondary_profile_data.social_media_profile_urls : ""}
                                                                                    required
                                                                                    placeholder="i.e. LinkedIn, Facebook, Twitter"
                                                                                />
                                                                            </Col>

                                                                            <Col md={12}>We will contact your authorized representatives to verify your identities. Please ensure that they are contactable via email and phone.</Col>
                                                                            <Col md={12} className="mb-3"><h4>Authorized Representative #1</h4></Col>

                                                                            <Col md="6" className="mb-3">
                                                                                <AvField
                                                                                    name="last_name"
                                                                                    label="Last Name"
                                                                                    className="form-control "
                                                                                    type="text"
                                                                                    value={profile.secondary_profile_data.last_name != '' ? profile.secondary_profile_data.last_name : ""}
                                                                                    required
                                                                                />
                                                                            </Col>
                                                                            <Col md="6" className="mb-3">
                                                                                <AvField
                                                                                    name="email"
                                                                                    label="Email"
                                                                                    value={profile.secondary_profile_data.email != '' ? profile.secondary_profile_data.email : ""}
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    required
                                                                                />
                                                                            </Col>

                                                                            <Col md="6" className="mb-3">
                                                                                <AvField
                                                                                    name="first_name"
                                                                                    label="First Name"
                                                                                    value={profile.secondary_profile_data.first_name != '' ? profile.secondary_profile_data.first_name : ""}
                                                                                    className="form-control "
                                                                                    type="text"
                                                                                    required
                                                                                />
                                                                            </Col>
                                                                            <Col md="6" className="mb-3">
                                                                                <AvField
                                                                                    name="business_title"
                                                                                    label="Business Title"
                                                                                    value={profile.secondary_profile_data.business_title != '' ? profile.secondary_profile_data.business_title : ""}
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    required
                                                                                />
                                                                            </Col>

                                                                            <Col md="6">
                                                                                <AvField
                                                                                    name="authorized_representative"
                                                                                    label="Authorized Representative Type"
                                                                                    className="form-control mb-3"
                                                                                    type="select"
                                                                                    required
                                                                                    value={profile.secondary_profile_data.authorized_representative != '' ? profile.secondary_profile_data.authorized_representative : "individual"}
                                                                                >
                                                                                    <option value="">Select Type</option>
                                                                                    <option selected="" value="individual">Individual</option>
                                                                                    <option value="business">Business</option>
                                                                                </AvField>
                                                                            </Col>

                                                                            <Col md="6">
                                                                                <AvField
                                                                                    name="job_position"
                                                                                    label="Job Position"
                                                                                    className="form-control mb-3"
                                                                                    type="select"
                                                                                    required
                                                                                    value={profile.secondary_profile_data.job_position != '' ? profile.secondary_profile_data.job_position : "Director"}
                                                                                >
                                                                                    <option value="">Select Position</option>
                                                                                    <option value="Director">Director</option>
                                                                                    <option value="GM">GM</option>
                                                                                    <option value="VP">VP</option>
                                                                                    <option value="CEO">CEO</option>
                                                                                    <option value="CFO">CFO</option>
                                                                                    <option value="General Counsel">General Counsel</option>
                                                                                    <option value="Other">Other</option>
                                                                                </AvField>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <AvField
                                                                                    name="country_code"
                                                                                    label="Country Code"
                                                                                    className="form-control mb-3"
                                                                                    type="select"
                                                                                    required
                                                                                    value={profile.secondary_profile_data.country_code != '' ? profile.secondary_profile_data.country_code : "+1"}
                                                                                    onChange={(e) => setCountryCode(e.target.value)}
                                                                                >
                                                                                    <option value="">Select Code</option>
                                                                                    {
                                                                                        data.map((row, i) => {
                                                                                            return <option key={i} value={row.country_code}>{row.country_name} ({row.country_code})</option>;
                                                                                        })
                                                                                    }
                                                                                </AvField>
                                                                            </Col>
                                                                            <Col md="6" className="mb-3">
                                                                                <label> Phone Number</label>
                                                                                <div className="input-group">
                                                                                    <div className="input-group-text">{countryCode}</div>
                                                                                    <AvInput name="phone_number" value={profile.secondary_profile_data.phone_number} required type="text" className="form-control" />
                                                                                    <div className="input-group-text"><i className="fas fa-info-circle"></i></div>
                                                                                </div>
                                                                                <note>Valid Phone Format: xxxxxxxxxx</note>
                                                                            </Col>


                                                                            <Col md={12} className="mb-3">
                                                                                <AvGroup check>
                                                                                    <AvInput onChange={(e) => setHasAuthorized(e.target.checked)} value={hasAuthorized} type="checkbox" name="check_auth_new" />
                                                                                    <label check htmlFor="checkbox"> I do not have a second authorized representative</label>
                                                                                </AvGroup>
                                                                            </Col>

                                                                            {
                                                                                !hasAuthorized && (
                                                                                    <>
                                                                                        <Col md={12} className="mb-3"><h4>Authorized Representative #2</h4></Col>

                                                                                        <Col md="6" className="mb-3">
                                                                                            <AvField
                                                                                                name="last_name_2"
                                                                                                label="Last Name"
                                                                                                value={profile.secondary_profile_data.last_name_2}
                                                                                                className="form-control "
                                                                                                type="text"
                                                                                                required
                                                                                            />
                                                                                        </Col>
                                                                                        <Col md="6" className="mb-3">
                                                                                            <AvField
                                                                                                name="email_2"
                                                                                                label="Email"
                                                                                                value={profile.secondary_profile_data.email_2}
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                required
                                                                                            />
                                                                                        </Col>

                                                                                        <Col md="6" className="mb-3">
                                                                                            <AvField
                                                                                                name="first_name_2"
                                                                                                label="First Name"
                                                                                                value={profile.secondary_profile_data.first_name_2}
                                                                                                className="form-control "
                                                                                                type="text"
                                                                                                required
                                                                                            />
                                                                                        </Col>
                                                                                        <Col md="6" className="mb-3">
                                                                                            <AvField
                                                                                                name="business_title_2"
                                                                                                label="Business Title"
                                                                                                value={profile.secondary_profile_data.business_title_2}
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                required
                                                                                            />
                                                                                        </Col>

                                                                                        <Col md="6">
                                                                                            <AvField
                                                                                                name="authorized_representative_2"
                                                                                                label="Authorized Representative Type"
                                                                                                className="form-control mb-3"
                                                                                                type="select"
                                                                                                required
                                                                                                value={profile.secondary_profile_data.authorized_representative_2 != '' ? profile.secondary_profile_data.authorized_representative_2 : "individual"}
                                                                                            >
                                                                                                <option value="">Select Type</option>
                                                                                                <option selected="" value="individual">Individual</option>
                                                                                                <option value="business">Business</option>
                                                                                            </AvField>
                                                                                        </Col>

                                                                                        <Col md="6">
                                                                                            <AvField
                                                                                                name="job_position_2"
                                                                                                label="Job Position"
                                                                                                className="form-control mb-3"
                                                                                                type="select"
                                                                                                required
                                                                                                value={profile.secondary_profile_data.job_position_2 != '' ? profile.secondary_profile_data.job_position_2 : "Director"}
                                                                                            >
                                                                                                <option value="">Select Position</option>
                                                                                                <option value="Director">Director</option>
                                                                                                <option value="GM">GM</option>
                                                                                                <option value="VP">VP</option>
                                                                                                <option value="CEO">CEO</option>
                                                                                                <option value="CFO">CFO</option>
                                                                                                <option value="General Counsel">General Counsel</option>
                                                                                                <option value="Other">Other</option>
                                                                                            </AvField>
                                                                                        </Col>
                                                                                        <Col md="6">
                                                                                            <AvField
                                                                                                name="country_code_2"
                                                                                                label="Country Code"
                                                                                                className="form-control mb-3"
                                                                                                type="select"
                                                                                                required
                                                                                                value={profile.secondary_profile_data.country_code_2 != '' ? profile.secondary_profile_data.country_code_2 : "+1"}
                                                                                                onChange={(e) => setCountryCode2(e.target.value)}
                                                                                            >
                                                                                                <option value="">Select Code</option>
                                                                                                {
                                                                                                    data.map((row, i) => {
                                                                                                        return <option key={i} value={row.country_code}>{row.country_name} ({row.country_code})</option>;
                                                                                                    })
                                                                                                }
                                                                                            </AvField>
                                                                                        </Col>
                                                                                        <Col md="6" className="mb-3">
                                                                                            <label> Phone Number</label>
                                                                                            <div className="input-group">
                                                                                                <div className="input-group-text">{countryCode2}</div>
                                                                                                <AvInput name="phone_number_2" value={profile.secondary_profile_data.phone_number_2} required type="text" className="form-control" />
                                                                                                <div className="input-group-text"><i className="fas fa-info-circle"></i></div>
                                                                                            </div>
                                                                                            <note>Valid Phone Format: xxxxxxxxxx</note>
                                                                                        </Col>
                                                                                    </>
                                                                                )
                                                                            }


                                                                            <Col md={12} className="mb-3">
                                                                                <AvGroup check>
                                                                                    <AvInput disabled value={true} type="checkbox" name="confirm_contact_record" />
                                                                                    <label check htmlFor="checkbox">I confirm that my nominated authorized representatives agree to be contacted by Twilio.</label>
                                                                                </AvGroup>
                                                                            </Col>

                                                                            <Col md={12}>
                                                                                <div className="alert alert-info fade show mb-3" role="alert"><strong><i className="fas fa-info-circle"></i></strong>  We currently only accept physical addresses for Business Profiles. P.O. box addresses are not accepted.. </div>
                                                                            </Col>

                                                                            <Col md={12} className="mb-3"><h4>Enter new address</h4></Col>

                                                                            <Col md="6" className="mb-3">
                                                                                <AvField
                                                                                    name="address_friendly_name"
                                                                                    label="Address Friendly Name"
                                                                                    value={profile.secondary_profile_data.address_friendly_name}
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    required
                                                                                />
                                                                            </Col>

                                                                            <Col md="6" className="mb-3">
                                                                                <AvField
                                                                                    name="country"
                                                                                    label="Country"
                                                                                    className="form-control mb-3"
                                                                                    type="select"
                                                                                    required
                                                                                    onChange={(e) => setCountryCode2(e.target.value)}
                                                                                    value={profile.secondary_profile_data.country}
                                                                                >
                                                                                    <option value="">Select country</option>
                                                                                    {
                                                                                        data.map((row, i) => {
                                                                                            return <option key={i} value={row.iso_code}>{row.country_name}</option>;
                                                                                        })
                                                                                    }
                                                                                </AvField>
                                                                            </Col>

                                                                            <Col md="12" className="mb-3">
                                                                                <label><i title="Enter the first line of the business address as it appears in the EIN listing" className="fas fa-info-circle"></i> Street Address</label>
                                                                                <AvField
                                                                                    name="street_address"
                                                                                    value={profile.secondary_profile_data.street_address}
                                                                                    className="form-control"
                                                                                    required
                                                                                />
                                                                            </Col>

                                                                            <Col md="4" className="mb-3">
                                                                                <label><i title="Enter the city as it appears in the EIN listing" className="fas fa-info-circle"></i> City</label>
                                                                                <AvField
                                                                                    name="city"
                                                                                    value={profile.secondary_profile_data.city}
                                                                                    className="form-control"
                                                                                    required
                                                                                />
                                                                            </Col>
                                                                            <Col md="4" className="mb-3">
                                                                                <label><i title="Enter the state/province/region as it appears in the EIN listing" className="fas fa-info-circle"></i> State/Province/Region</label>
                                                                                <AvField
                                                                                    name="state"
                                                                                    value={profile.secondary_profile_data.state}
                                                                                    className="form-control"
                                                                                    required
                                                                                />
                                                                            </Col>
                                                                            <Col md="4" className="mb-3">
                                                                                <label><i title="Enter the postal code as it appears in the EIN listing" className="fas fa-info-circle"></i> Postal/Zip Code</label>
                                                                                <AvField
                                                                                    name="postal_code"
                                                                                    value={profile.secondary_profile_data.postal_code}
                                                                                    className="form-control"
                                                                                    required
                                                                                />
                                                                            </Col>

                                                                            {
                                                                                queryParms.get('type') == 'upgr' && (
                                                                                    <>
                                                                                        <Col md="6" className="mb-3">
                                                                                            <AvField
                                                                                                name="brand_friendly_name"
                                                                                                label="Brand Friendly Name"
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                required
                                                                                            />
                                                                                        </Col>

                                                                                        <Col md="6" className="mb-3">
                                                                                            <AvField
                                                                                                name="company_type"
                                                                                                label="Company Type"
                                                                                                className="form-control mb-3"
                                                                                                type="select"
                                                                                                required
                                                                                                value={'private'}
                                                                                            >
                                                                                                <option value="">Select Type</option>
                                                                                                <option value="private">private</option>
                                                                                                <option value="public">public</option>
                                                                                                <option value="non-profit">non-profit</option>
                                                                                                <option value="government">government</option>
                                                                                            </AvField>
                                                                                        </Col>
                                                                                        <Col md={12} className="mb-3">
                                                                                            <AvGroup check>
                                                                                                <AvInput disabled value={true} type="checkbox" name="brand_register_confirm" />
                                                                                                <label check htmlFor="checkbox">I Agreed on Brand Registeration charges $44 one time fees.</label>
                                                                                            </AvGroup>
                                                                                        </Col>

                                                                                    </>
                                                                                )
                                                                            }

                                                                        </Row>

                                                                        <Row>
                                                                            <Col md={6}>

                                                                                <button
                                                                                    className="btn btn-primary waves-effect waves-light btn-block mt-4"
                                                                                    type="submit"
                                                                                >
                                                                                    Submit Request
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => props.history.push("/trusthub/overview")}
                                                                                    className="btn btn-danger waves-effect waves-light btn-block mt-4 ml-5"
                                                                                    type="submit"
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </Col>

                                                                        </Row>


                                                                    </AvForm>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                    {
                                        step == 1 && (
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    <div className="widget-container fluid-height">
                                                        <div className="row">
                                                            <div className="col-md-6 border p-3">
                                                                <h4>1. Create Business Profile
                                                                    {
                                                                        profile.account_status == 'twilio-approved' ?
                                                                            <> <i className="fa fa-check-circle text-success" aria-hidden="true"></i> <span className="text-success">Approved</span></>
                                                                            :
                                                                            <span className="text-danger text-capitalize"> {profile.account_status}</span>


                                                                    }
                                                                </h4>
                                                                <p>Information about your business.</p>
                                                                <p>No Fees</p>
                                                            </div>
                                                            {
                                                                profile.account_status != 'twilio-approved' && (
                                                                    <div className="col-md-6 border p-3">

                                                                        <>
                                                                            {profile.account_status != 'in-review' && (
                                                                                <div className="alert alert-danger mt-1" role="alert">
                                                                                    {profile.failure_reason}
                                                                                </div>
                                                                            )}

                                                                            <button
                                                                                onClick={() => window.location.href = "/trusthub?step=one&volume=low&is_error=Yes"}
                                                                                className="btn btn-danger waves-effect waves-light btn-block"
                                                                                type="button"
                                                                            >
                                                                                <i className="fas fa-edit"></i> Edit Business Profile
                                                                            </button>
                                                                        </>

                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div className="col-md-12 border p-3">
                                                            <h4>2. Register US A2P Brand
                                                                {
                                                                    profile.brand_status == 'APPROVED' ?
                                                                        <> <i className="fa fa-check-circle text-success" aria-hidden="true"></i> <span className="text-success">Approved</span></>
                                                                        :
                                                                        <span className="text-danger text-capitalize"> {profile.brand_status}</span>
                                                                }

                                                                {
                                                                    profile.brand_status == 'DRAFT' && (profile.account_status == 'twilio-approved' || profile.account_status == 'in-review') && (
                                                                        <AvForm
                                                                            onValidSubmit={handleSubmitRegisterUSA2PBrand}
                                                                            className="needs-validation"
                                                                        >
                                                                            <Row>
                                                                                <Col md="4" className="mb-3 mt-3">
                                                                                    <AvField
                                                                                        name="brand_friendly_name"
                                                                                        label="Brand Friendly Name"
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        required
                                                                                    />

                                                                                    <AvField
                                                                                        name="type"
                                                                                        className="form-control"
                                                                                        type="hidden"
                                                                                        value={queryParms.get('volume') ? queryParms.get('volume') : 'low'}
                                                                                    />
                                                                                </Col>

                                                                                <Col md="4" className="mb-3 mt-3">
                                                                                    <AvField
                                                                                        name="company_type"
                                                                                        label="Company Type"
                                                                                        className="form-control mb-3"
                                                                                        type="select"
                                                                                        required
                                                                                        value={companyType}
                                                                                        onChange={(e) => setCompanyType(e.target.value)}
                                                                                    >
                                                                                        <option value="">Select Type</option>
                                                                                        <option value="private">private</option>
                                                                                        <option value="public">public</option>
                                                                                        <option value="non-profit">non-profit</option>
                                                                                        <option value="government">government</option>
                                                                                    </AvField>
                                                                                </Col>
                                                                                {
                                                                                    companyType == 'public' && (
                                                                                        <>
                                                                                            <Col md="4" className="mb-3 mt-3">
                                                                                                <AvField
                                                                                                    name="stock_exchange"
                                                                                                    label=" Stock Exchange"
                                                                                                    className="form-control mb-3"
                                                                                                    type="select"
                                                                                                    required
                                                                                                    value={stockExchange}
                                                                                                    onChange={(e) => setStockExchange(e.target.value)}
                                                                                                >
                                                                                                    <option value="">Select Exchange Type</option>
                                                                                                    <option value="AMEX">AMEX : American Stock Exchange</option>
                                                                                                    <option value="AMX">AMX : Armenia Securities Exchange</option>
                                                                                                    <option value="ASX">ASX : Australian Securities Exchange</option>
                                                                                                    <option value="B3">B3: Brazil Stock Exchange</option>
                                                                                                    <option value="BME">BME : Bolsas y Mercados Españoles</option>
                                                                                                    <option value="BSE">BSE : Bombay Stock Exchange</option>
                                                                                                    <option value="FRA">FRA : Frankfurt Stock Exchange</option>
                                                                                                    <option value="ICEX">ICEX : Indian Commodity Exchange</option>
                                                                                                    <option value="JPX">JPX : Japan Exchange Group</option>
                                                                                                    <option value="JSE">JSE : Jamaica Stock Exchange</option>
                                                                                                    <option value="KRX">KRX : Korea Exchange</option>
                                                                                                    <option value="LON">LON : London Stock Exchange</option>
                                                                                                    <option value="NASDAQ">NASDAQ</option>
                                                                                                    <option value="NSE">NSE : National Stock Exchange of India Ltd</option>
                                                                                                    <option value="NYSE">NYSE : New York Stock Exchange</option>
                                                                                                    <option value="NONE">None</option>
                                                                                                    <option value="OMX">OMX : NASDAQ Stockholm</option>
                                                                                                    <option value="SEHK">SEHK : Stock Exchange of Hong Kong</option>
                                                                                                    <option value="SGX">SGX : Singapore Exchange Limited</option>
                                                                                                    <option value="SSE">SSE : Shanghai Stock Exchange</option>
                                                                                                    <option value="STO">STO : Stockholm Stock Exchange</option>
                                                                                                    <option value="SWX">SWX : Swiss Stock Exchange</option>
                                                                                                    <option value="SZSE">SZSE : Shenzhen Stock Exchange</option>
                                                                                                    <option value="TSX">TSX : Toronto Stock Exchange</option>
                                                                                                    <option value="TWSE">TWSE : Taiwan Stock Exchange</option>
                                                                                                    <option value="VSE">VSE : Vadodara Stock Exchange Limited</option>
                                                                                                    <option value="OTHER">Other</option>
                                                                                                </AvField>
                                                                                            </Col>
                                                                                            <Col md="4" className="mb-3 mt-3">
                                                                                                <AvField
                                                                                                    name="stock_ticker"
                                                                                                    label="Stock Ticker"
                                                                                                    className="form-control"
                                                                                                    type="text"
                                                                                                    required
                                                                                                />
                                                                                            </Col>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                <Col md={12} className="mb-3">
                                                                                    <p>We’ll use your Standard Customer Profile to do this.</p>
                                                                                    <p>$4 one-time fee (Low-Volume Standard Brands only)</p>
                                                                                    <p>$44 one-time fee (Standard Brands only)</p>
                                                                                </Col>
                                                                                <Col md={12} className="mb-3">
                                                                                    <AvGroup check>
                                                                                        <AvInput disabled value={true} type="checkbox" name="brand_register_confirm" />
                                                                                        <label check htmlFor="checkbox">I Agreed on Brand Registeration charges {profile.profile_type == 'secondary_profile' ? '$44' : '$4'} one time fees.</label>
                                                                                    </AvGroup>
                                                                                </Col>

                                                                                <button
                                                                                    className="btn btn-primary waves-effect waves-light btn-block mt-4"
                                                                                    type="submit"
                                                                                >
                                                                                    Register US A2P Brand
                                                                                </button>
                                                                            </Row>
                                                                        </AvForm>

                                                                    )
                                                                }
                                                            </h4>

                                                        </div>
                                                        <div className="col-md-12 border p-3">
                                                            <h4>3. Register Campaign Use Cases
                                                            {
                                                                profile.campaign_status == 'VERIFIED' ? 
                                                                <> <i className="fa fa-check-circle text-success" aria-hidden="true"></i> <span className="text-success">Approved</span></>
                                                                : (profile.campaign_status =='IN_PROGRESS' || profile.campaign_status =='In review') ?
                                                                <> <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> <span className="text-warning">{profile.campaign_status}</span></>
                                                                :
                                                                <> <i className="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i> <span className="text-danger">{profile.campaign_status}</span></>
                                                            }
                                                            </h4>
                                                            <p>Campaign Monthly Fees: $1.50 - $10 depending on use case*</p>
                                                        </div>
                                                    </div>

                                                    <button
                                                        onClick={() => setStep(2)}
                                                        className="btn btn-primary waves-effect waves-light btn-block mt-4"
                                                        type="button"
                                                        disabled={profile.brand_status == 'APPROVED' ? false : true}
                                                    >
                                                        Next
                                                    </button>


                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        step == 2 && (
                                            <Row>
                                                <Col md={12}>
                                                    <h4> Register A2P campaign use case <span className="text-danger">
                                                    {
                                                        profile.campaign_status == 'VERIFIED' ? 
                                                        <> <i className="fa fa-check-circle text-success" aria-hidden="true"></i> <span className="text-success">Approved</span></>
                                                        : (profile.campaign_status =='IN_PROGRESS' || profile.campaign_status =='In review') ?
                                                        <> <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> <span className="text-warning">{profile.campaign_status}</span></>
                                                        :
                                                        <> <i className="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i> <span className="text-danger">{profile.campaign_status}</span></>
                                                    }
                                                </span></h4>
                                                </Col>
                                                <AvForm
                                                    onValidSubmit={handleUseCaseSubmit}
                                                    className="needs-validation"
                                                >
                                                    <Row>
                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="brand_id"
                                                                label="US A2P Brand"
                                                                className="form-control "
                                                                type="text"
                                                                readOnly={true}
                                                                value={profile.brand_id}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="packgtype"
                                                                label="Available A2P campaign use cases"
                                                                className="form-control "
                                                                type="text"
                                                                readOnly={true}
                                                                value={profile.profile_type}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md="6">
                                                            <AvField
                                                                name="campaign_type"
                                                                label="Campaign Type"
                                                                className="form-control mb-3"
                                                                type="select"
                                                                required
                                                            >
                                                                <option value="">Select Type</option>
                                                                <option value="2FA">2FA</option>
                                                                <option value="ACCOUNT_NOTIFICATION">Account Notifications</option>
                                                                <option value="AGENTS_FRANCHISES">Agents and Franchises</option>
                                                                <option value="CHARITY">Charity</option>
                                                                <option value="PROXY">Proxy</option>
                                                                <option value="CUSTOMER_CARE">Customer Care</option>
                                                                <option value="DELIVERY_NOTIFICATION">Delivery Notifications</option>
                                                                <option value="EMERGENCY">Emergency</option>
                                                                <option value="FRAUD_ALERT">Fraud Alert Messaging</option>
                                                                <option value="HIGHER_EDUCATION">Higher Education</option>
                                                                <option value="K12_EDUCATION">K-12 Education</option>
                                                                <option value="MARKETING" >Marketing</option>
                                                                <option value="LOW_VOLUME" selected="true">Low Volume Mixed</option>
                                                                <option value="MIXED">Mixed</option>
                                                                <option value="POLITICAL">Political</option>
                                                                <option value="POLLING_VOTING">Polling and voting</option>
                                                                <option value="PUBLIC_SERVICE_ANNOUNCEMENT">Public Service Announcement</option>
                                                                <option value="SECURITY_ALERT">Security Alert</option>
                                                                <option value="SOCIAL">Social</option>
                                                                <option value="SWEEPSTAKE">Sweepstake</option>
                                                            </AvField>
                                                        </Col>

                                                        <Col md="12" className="mb-3">
                                                            <AvField
                                                                name="use_case_description"
                                                                label="Campaign use case description"
                                                                className="form-control "
                                                                type="textarea"
                                                                required
                                                                value={'Standard US A2P Campaign for testing or small-scale sending.'}
                                                            />
                                                        </Col>
                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="message1"
                                                                label="Sample message #1 (Please edit)"
                                                                className="form-control "
                                                                type="textarea"
                                                                required
                                                                value={'Hello John, I see that you registered to get more information on my website. Can you talk? Here is my calendar link: www.mycalenderlink.com.'}
                                                            />
                                                            <note>Provide an example of a message that you will be sending with this campaign use case. This message sample will be used by carriers to help identify your traffic.</note>
                                                        </Col>
                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="message2"
                                                                label="Sample message #2 (Please edit)"
                                                                className="form-control "
                                                                type="textarea"
                                                                required
                                                                value={'Hello John, this is the best number to reach me at. 555-222-3333. - Bill'}
                                                            />
                                                            <note>Provide an example of a message that you will be sending with this campaign use case. This message sample will be used by carriers to help identify your traffic.</note>
                                                        </Col>


                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="message_flow"
                                                                label="Message Flow / Call to Action (Please edit)"
                                                                className="form-control "
                                                                type="textarea"
                                                                value={'End user consent is received by email today'}
                                                                required
                                                            />
                                                            <note>Message Flow is required!</note>
                                                        </Col>

                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="optInKeywords"
                                                                label="Opt In Keywords (Seperated by Comma)"
                                                                className="form-control "
                                                                type="textarea"
                                                                value={'START,BEGIN'}
                                                                required
                                                            />
                                                            <note>Opt In Keywords is required!</note>
                                                        </Col>

                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="optInMessage"
                                                                label="Opt In Message (Please edit)"
                                                                className="form-control "
                                                                type="textarea"
                                                                value={'Acme Corporation: You are now opted-in. For help, reply HELP. To opt-out, reply STOP.'}
                                                                required
                                                            />
                                                            <note>Opt In Message is required!</note>
                                                        </Col>

                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="optOutKeywords"
                                                                label="Opt Out Keywords (Seperated by Comma)"
                                                                className="form-control "
                                                                type="textarea"
                                                                value={'STOP,END'}
                                                                required
                                                            />
                                                            <note>Opt Out Keywords is required!</note>
                                                        </Col>

                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="optOutMessage"
                                                                label="Opt Out Message (Please edit)"
                                                                className="form-control "
                                                                type="textarea"
                                                                value={'You have successfully been unsubscribed from Acme Corporation. You will not receive any more messages from this number.'}
                                                                required
                                                            />
                                                            <note>Opt Out Message is required!</note>
                                                        </Col>

                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="helpKeywords"
                                                                label="Help Keywords (Seperated by Comma)"
                                                                className="form-control "
                                                                type="textarea"
                                                                value={'HELP,SUPPORT'}
                                                                required
                                                            />
                                                            <note>Help Keywords is required!</note>
                                                        </Col>

                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="helpMessage"
                                                                label="Help Message (Please edit)"
                                                                className="form-control "
                                                                type="textarea"
                                                                value={'Acme Corporation: Please visit www.example.com to get support. To opt-out, reply STOP.'}
                                                                required
                                                            />
                                                            <note>Help Message is required!</note>
                                                        </Col>
                                                        {profile.service_id != '' && (
                                                            <Col md="6" className="mb-3">
                                                                <AvField
                                                                    name="msg_service_id"
                                                                    label="Messaging Service"
                                                                    className="form-control "
                                                                    type="text"
                                                                    value={profile.service_id}
                                                                />
                                                                <note></note>
                                                            </Col>
                                                        )}


                                                        <Col md="6">
                                                            <AvField
                                                                name="selected_service_number"
                                                                label="Phone Number"
                                                                className="form-control mb-3"
                                                                type="select"
                                                                required
                                                            >
                                                                <option value="">Select Phone</option>
                                                                {
                                                                    numbers.notRegistered.length > 0 &&
                                                                    (
                                                                        numbers.notRegistered.map((row, i) => {
                                                                            return <option value={row.phoneSid}>{row.number}</option>
                                                                        })
                                                                    )
                                                                }
                                                            </AvField>
                                                            <note> At least one Twilio Phone Number to add to the Messaging Service's Sender Pool. <a target="_blank" style={{ fontWeight: "bold", cursor: "pointer" }} href="http://profitcoach.app/settings/numbers/list">Purchase Phone Number</a></note>
                                                        </Col>

                                                        <Col md="12" className="mb-3 mt-3">
                                                            <label>Message contents</label>
                                                            <AvGroup check>
                                                                <AvInput type="checkbox" name="embedded_link" />
                                                                <label check htmlFor="checkbox"> Messages will include embedded links.</label>
                                                            </AvGroup>

                                                            <AvGroup check>
                                                                <AvInput type="checkbox" name="embedded_phone" />
                                                                <label check htmlFor="checkbox"> Messages will include phone numbers.</label>
                                                            </AvGroup>

                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={6}>

                                                            <button
                                                                className="btn btn-primary waves-effect waves-light btn-block mt-4"
                                                                type="submit"
                                                                disabled={profile.campaign_status == 'VERIFIED' || profile.campaign_status == 'IN_PROGRESS' ? true : false}
                                                            >
                                                                Next
                                                            </button>

                                                        </Col>

                                                    </Row>
                                                </AvForm>

                                            </Row>
                                        )
                                    }

                                    {
                                        step == 3 && (
                                            <>
                                                <Row className="mt-3">
                                                    <Col md={8} className="mb-3">
                                                        <h4 className="text-link"> Register Phone Number(s)</h4>
                                                    </Col>
                                                    <Col md={4} className="text-end mb-3">
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light btn-block"
                                                            type="button"
                                                            onClick={() => setModal2(true)}
                                                        >
                                                            View Details
                                                        </button>

                                                        <Modal
                                                            isOpen={modal2}
                                                            fade={false}
                                                            toggle={() => setModal(false)}
                                                            size={'lg'}
                                                        >
                                                            <ModalHeader>Business Profile info
                                                                <button style={{ position: 'absolute', right: 0 }} className="pull-right btn btn-link" onClick={() => setModal2(false)}><i className="fas fa-times"></i></button>
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <Tabs
                                                                    defaultActiveKey="profile"
                                                                    id="uncontrolled-tab-example"
                                                                    className="mb-3 pt-0"
                                                                >
                                                                    <Tab eventKey="profile" title="Business Profile">
                                                                        <h4>Business Profile Details:</h4>
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Business name:</td>
                                                                                    <td>{profile.legal_business_name}</td>
                                                                                    <td>Friendly name:</td>
                                                                                    <td>{profile.friendly_name}</td>
                                                                                    <td>Profile Type:</td>
                                                                                    <td>{profile.profile_type}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td>Full Name:</td>
                                                                                    <td>{profile.s_profile_data.business_information ? profile.s_profile_data.business_information.first_name : ""} {profile.s_profile_data.business_information ? profile.s_profile_data.business_information.last_name : ""}</td>
                                                                                    <td>Email:</td>
                                                                                    <td>{profile.s_profile_data.business_information ? profile.s_profile_data.business_information.email : ""} </td>
                                                                                    <td>Phone Number:</td>
                                                                                    <td>{profile.s_profile_data.business_information ? profile.s_profile_data.business_information.phone_number : ""} </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <h4>Address Details:</h4>
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Address Country:</td>
                                                                                    <td>{profile.s_profile_data.business_address ? profile.s_profile_data.business_address.address_country : ""}</td>
                                                                                    <td>Address Street:</td>
                                                                                    <td colSpan={3}>{profile.s_profile_data.business_address ? profile.s_profile_data.business_address.address_street : ""}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td>Address City:</td>
                                                                                    <td>{profile.s_profile_data.business_address ? profile.s_profile_data.business_address.address_city : ""}</td>
                                                                                    <td>Address Region:</td>
                                                                                    <td>{profile.s_profile_data.business_address ? profile.s_profile_data.business_address.address_region : ""}</td>
                                                                                    <td>Address Postal Code:</td>
                                                                                    <td>{profile.s_profile_data.business_address ? profile.s_profile_data.business_address.address_postalCode : ""}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td>Address Friendly Name:</td>
                                                                                    <td colSpan={4}>{profile.s_profile_data.business_address ? profile.s_profile_data.business_address.address_friendly_name : ""}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </Tab>
                                                                    <Tab eventKey="brand" title="A2P Brand">
                                                                        <h4>Brand Details:</h4>
                                                                        <h6>Brand type : <span>{profile.profile_type}</span></h6>
                                                                    </Tab>
                                                                    <Tab eventKey="campaign" title="Campaign Use Cases">
                                                                        <h4>Campaign Use Cases Details:</h4>
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label>Description :</label>
                                                                                <textarea className="form-control" rows={5} readOnly value={profile.campaign_case ? profile.campaign_case.use_case_description : ""}></textarea>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label>Message1 :</label>
                                                                                <textarea className="form-control" rows={5} readOnly value={profile.campaign_case ? profile.campaign_case.message1 : ""}></textarea>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label>Message2 :</label>
                                                                                <textarea className="form-control" rows={5} readOnly value={profile.campaign_case ? profile.campaign_case.message2 : ""}></textarea>
                                                                            </div>

                                                                            <div className="form-group">
                                                                                <label>Has embedded phone :</label>
                                                                                <input className="form-control" rows={5} readOnly value={profile.campaign_case ? profile.campaign_case.hasEmbeddedPhone ? 'Yes' : 'No' : 'No'} />
                                                                            </div>

                                                                            <div className="form-group">
                                                                                <label>Has embedded links :</label>
                                                                                <input className="form-control" rows={5} readOnly value={profile.campaign_case ? profile.campaign_case.hasEmbeddedLinks ? 'Yes' : 'No' : 'No'} />
                                                                            </div>
                                                                        </form>
                                                                    </Tab>
                                                                </Tabs>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
                                                                <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
                                                            </ModalFooter>

                                                        </Modal>

                                                        <button
                                                            className="btn btn-success waves-effect waves-light btn-block ml-5"
                                                            type="button"
                                                            onClick={() => props.history.push('/trusthub/overview')}

                                                        >
                                                            View Products
                                                        </button>
                                                    </Col>
                                                    <AvForm
                                                        onValidSubmit={handleAssignPhoneSubmit}
                                                        // model={this.state.record}
                                                        className="needs-validation"
                                                    >
                                                        <Row>
                                                            <Col md="6" className="mb-3">
                                                                <AvField
                                                                    name="brand_id"
                                                                    label="US A2P Brand"
                                                                    className="form-control "
                                                                    type="text"
                                                                    readOnly={true}
                                                                    value={profile.brand_id}
                                                                    required
                                                                />
                                                            </Col>
                                                            <Col md="6" className="mb-3">
                                                                <AvField
                                                                    name="packgtype"
                                                                    label="Available A2P campaign use cases"
                                                                    className="form-control "
                                                                    type="text"
                                                                    readOnly={true}
                                                                    value={profile.profile_type}
                                                                    required
                                                                />
                                                            </Col>
                                                            <Col md="12" className="mb-3">
                                                                <label>Registered Phone Numbers:</label>
                                                                {
                                                                    numbers.registered.length > 0 ?
                                                                        <AvCheckboxGroup inline name="phone_number">
                                                                            {
                                                                                numbers.registered.map((row, i) => {
                                                                                    return <AvCheckbox checked readOnly label={row} value={row} />
                                                                                })
                                                                            }
                                                                        </AvCheckboxGroup>

                                                                        :
                                                                        <p className="text-danger">No register phone number number found
                                                                        </p>
                                                                }
                                                            </Col>

                                                            <Col md="12" className="mb-3">
                                                                <label>Un-registered Phone Numbers:</label>
                                                                {
                                                                    numbers.notRegistered.length > 0 ?
                                                                        <AvCheckboxGroup inline name="unregistered_phone_number">
                                                                            {
                                                                                numbers.notRegistered.map((row, i) => {
                                                                                    return <AvCheckbox label={row.number} value={row.phoneSid} />
                                                                                })
                                                                            }
                                                                        </AvCheckboxGroup>

                                                                        :
                                                                        <p className="text-danger">No un-register phone number number found
                                                                        </p>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md="4">
                                                                {
                                                                    numbers.notRegistered.length > 0 ?
                                                                        <button
                                                                            className="btn btn-primary waves-effect waves-light btn-block"
                                                                            type="submit"
                                                                            disabled={loaded ? false : true}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        :
                                                                        <button
                                                                            className="btn btn-success waves-effect waves-light btn-block ml-5"
                                                                            type="button"
                                                                            onClick={() => props.history.push('/dashboard')}

                                                                        >
                                                                            Finish
                                                                        </button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </AvForm>

                                                    {/* <div className="text-center">

                                                        <div className="alert alert-warning mt-4" >
                                                            You have registered for a STARTER profile that can register up to 5 phone numbers and send 3,000 SMS daily. To upgrade to <br /> STANDARD profile
                                                            <Link to={'/trusthub/submit_request?type=upgr'}> Click here</Link>
                                                        </div>

                                                        <h3><i className="fas fa-level-up"></i>Standard Profile.</h3>
                                                        <AvForm
                                                            className="needs-validation"
                                                        >
                                                            <div className="form-control border-0 p-0 h-auto ">
                                                                <label className="mr-5">Already have a Standard Profile?</label>
                                                                <AvRadioGroup value="Yes" name="haveSecondryProfile" inline onChange={(e) => sethaveSecondryProfile(e.target.value)} className="mb-3" required>
                                                                    <AvRadio label="Yes" value="Yes" />
                                                                    <AvRadio label="No" value="No" />
                                                                </AvRadioGroup>

                                                            </div>
                                                        </AvForm>

                                                        <div className="form">

                                                            <form className="row gx-3 gy-2 align-items-center">
                                                                {
                                                                    haveSecondryProfile == 'Yes' ? (
                                                                        <>
                                                                            <div className="col-md-10">
                                                                                <input type="text" name="brand_id" onKeyUp={(e) => setSecondaryBrandSid(e.target.value)} className="form-control" id="brand_id" placeholder="Enter US A2P Brand Registration SID" />
                                                                            </div>
                                                                            <div className="col-auto"><button onClick={() => upgradeViaSide()} type="button" className="btn btn-success">Upgrade To Standard Profile Now</button>
                                                                            </div>
                                                                        </>
                                                                    ) : (

                                                                        <div className="col-auto"><button type="button" onClick={() => props.history.push('/trusthub/upgrade')} className="btn btn-success">Click here to upgrade To standard-profile</button> </div>
                                                                    )}
                                                            </form>
                                                        </div>
                                                    </div> */}
                                                </Row>
                                            </>
                                        )
                                    }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {
                    alert2 && (

                        <SweetAlert success title="Wait" onConfirm={() => console.log('confirm')}>
                            Profile successfully submitted for review. Please follow the next steps after <b id="custom"></b> seconds.
                        </SweetAlert>


                    )
                }




            </div>

        </React.Fragment >
    );
};

export default TrustHub;
