import * as React from 'react';
import { ApiUrl } from '../../../config';
import { uToken } from '../../../useToken';
import swal from "sweetalert";
import Nav from 'react-bootstrap/Nav';
import { Accordion, Dropdown, Tab, Tabs, Tooltip } from "react-bootstrap";
import {
    AvForm,
    AvField
  } from "availity-reactstrap-validation";
  import {
    Row,
    Col,
    ModalBody,
  } from "reactstrap";
  import { Link } from "react-router-dom";

export default class TrainingTag extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id ? this.props.id : null,
            records: [],
            associated_records: [],
            selectedIds: [],
            selectAll: false,
            selectedIdsAssociated: [],
            selectAllAssociated: false,
        }
    }

    componentDidMount() {
      this.state.id && this.getmetaAssociated();
      this.state.id && this.getmeta();
    
    }
    getmetaAssociated = (queryString ="") => {
      fetch(`${ApiUrl}` + "get/user/assocTagsMeta" + queryString, {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ id: this.state.id}),
      })
          .then((response) => response.json())
          .then((response) => {
              
              if (response.status === true) {
                  this.setState({
                    associated_records: response.data.records,
                  });
              }
          })
          .catch((error) => {
              console.error("Error:", error);
          });
    };
    getmeta = (queryString ="") => {
      fetch(`${ApiUrl}` + "get/user/tagsMeta" + queryString, {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ id: this.state.id}),
      })
          .then((response) => response.json())
          .then((response) => {
              
              if (response.status === true) {
                  this.setState({
                    records: response.data.records,
                  });
              }
          })
          .catch((error) => {
              console.error("Error:", error);
          });
    };
     
  handleFilterAssociated = async (event, data) => {
    let queryString = Object.keys(data).map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    }).join("&");
    this.getmetaAssociated("?" + queryString);
  };
  handleFilter = async (event, data) => {
    let queryString = Object.keys(data).map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    }).join("&");
    this.getmeta("?" + queryString);
  };
  handleSelectAll = (event, type) => {
    const isChecked = event.target.checked;
    if(['associated_records'].includes(type)){
          this.setState({
              selectAllAssociated: isChecked,
              selectedIdsAssociated: isChecked
                  ? this.state.associated_records.map(assoc => assoc.id)
                  : [],
              selectAll: false,
              selectedIds: [],
          }); 
        
    }else{
        this.setState({
            selectAll: isChecked,
            selectedIds: isChecked
                ? this.state.records.map(record => record.id)
                : [],
            selectAllAssociated: false,
            selectedIdsAssociated: [],
        });
       
       
    }
};

handleCheckboxChange = (id, type) => {
    if(['associated_records'].includes(type)){
            this.setState(prevState => {
                const { selectedIdsAssociated } = prevState;
                const newSelectedIds = selectedIdsAssociated.includes(id)
                    ? selectedIdsAssociated.filter(selectedId => selectedId !== id)
                    : [...selectedIdsAssociated, id];
                return {
                    selectedIdsAssociated: newSelectedIds,
                    selectAllAssociated: newSelectedIds.length === this.state.associated_records.length,
                };
            });
    }else{
          this.setState(prevState => {
              const { selectedIds } = prevState;
              const newSelectedIds = selectedIds.includes(id)
                  ? selectedIds.filter(selectedId => selectedId !== id)
                  : [...selectedIds, id];
              return {
                  selectedIds: newSelectedIds,
                  selectAll: newSelectedIds.length === this.state.records.length,
              };
          });
       
    }
};
handleLinkClick = (action_type) => {
    const formData = new FormData();
    formData.append('user_id', this.state.id);
    let ids;
    switch (action_type) {
        case 'associated_records':
            ids = this.state.selectedIdsAssociated;
            break;
       
        default:
            ids = this.state.selectedIds;
    }
    
    formData.append('tag_ids', ids);
    formData.append('action_type', action_type)
  
    fetch(`${ApiUrl}` + `handle/userTrainingswitch/action`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ` + uToken(),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
            
            if (response.status === true) {
           
                    this.getmetaAssociated("", "associated_records"); 
                    this.getmeta("", "records");
                    this.setState({
                        selectAllAssociated: false,
                        selectedIdsAssociated: [],
                        selectAll: false,
                        selectedIds: [],
                    });
                this.props.callParentFunction(this.state.id);
               
            }else{
                swal({
                    text: response.message,
                    icon: "warning",
                  });
                  return false;
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
};
    render() {

        return (
            <ModalBody>
  
              <div className="row">
                <div className="col-lg-6 pe-4">
                  <div className="SideTags">
                    <label>Associated Training Tags</label>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="d-flex">
                          <label className="d-flex text-nowrap mb-0 me-3 align-items-center">Filter By</label>
                          <AvForm
                            onValidSubmit={this.handleFilterAssociated}
                            ref={(c) => (this.form_user = c)}
                            className="needs-validation d-flex w-100"
                          >
                            <AvField
                              className="form-control"
                              type="text"
                              placeholder="Search by Title"
                              name="user_input"
                            />
                          <button type='submit' className="btn btn-primary ms-3">Filter</button>
                          </AvForm>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between px-0 mb-3 mt-4 align-items-center TableTopArea">
                          <span><label className="mb-0 d-flex">
                                <input
                                  className="me-1"
                                  type="checkbox"
                                  checked={this.state.selectAllAssociated}
                                  onChange={(e) => this.handleSelectAll(e, 'associated_records')}
                                />  
                                Select All</label></span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="TrainingTabs">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                              <Col lg={5} className="pe-0">
                                <Nav variant="pills" className="flex-column TagsListings">
                                  {this.state.associated_records && this.state.associated_records.map((row, i) => {
                                    if( row.tag != null){
                                        return (
                                          <Nav.Item>
                                            <Nav.Link eventKey={i}>
                                              <input
                                                type="checkbox"
                                                checked={this.state.selectedIdsAssociated.includes(row.id)}
                                                onChange={() => this.handleCheckboxChange(row.id, 'associated_records')}
                                              />{row.user_id != '2' ? `${row.name} (My)` : row.name}</Nav.Link>
                                          </Nav.Item>
                                        )
                                      }
                                      })
                                    }
                                </Nav>
                              </Col>
                              <Col lg={7} className="ps-0">
                                <Tab.Content>
                                {this.state.associated_records && this.state.associated_records.map((row, i) => {
                                    return (
                                      <Tab.Pane eventKey={i}>
                                        <ul className="TrainingList"  key={i}>
                                          {
                                            row.trainings && row.trainings.map((sub, j) => {
                                              if(sub.title != ""){
                                              return (
                                                <li><Link to="#">{sub.title}</Link></li>
                                              )
                                            }
                                            })
                                          }
                                        </ul>
                                      </Tab.Pane>
                                    )
                                  })
                                }
                                 
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                    <div className="LeftRightArrow">
                        {this.state.associated_records.length === 0 ? (
                            <Link className="disable" to = '#'><i disabled class="typcn typcn-media-fast-forward"></i></Link>
                        ):(
                            <Link to = '#' onClick={() => this.handleLinkClick('associated_records')}><i class="typcn typcn-media-fast-forward"></i></Link>
                        )}
                          {this.state.records.length === 0 ? (
                            <Link className="disable" to = '#'><i disabled class="typcn typcn-media-rewind"></i></Link>
                        ):(
                            <Link to = '#' onClick={() => this.handleLinkClick('records')}><i class="typcn typcn-media-rewind"></i></Link>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 ps-4">
                  <div className="SideTags">
                    <label>Other Training Tags</label> 
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="d-flex">
                          <label className="d-flex text-nowrap mb-0 me-3 align-items-center">Filter By</label>
                          <AvForm
                            onValidSubmit={this.handleFilter}
                            ref={(c) => (this.form_user = c)}
                            className="needs-validation d-flex w-100"
                          >
                            <AvField
                              className="form-control"
                              type="text"
                              placeholder="Search by Title"
                              name="user_input"
                            />
                          <button type='submit' className="btn btn-primary ms-3">Filter</button>
                          </AvForm>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between px-0 mb-3 mt-4 align-items-center TableTopArea">
                          <span><label className="mb-0 d-flex">
                                <input
                                  className="me-1"
                                  type="checkbox"
                                  checked={this.state.selectAll}
                                  onChange={(e) => this.handleSelectAll(e, 'records')}
                                />  
                             Select All</label></span>
                         
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="TrainingTabs">
                        {this.props.user && this.props.user.package_id!=4 &&( 
                          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                              <Col lg={5} className="pe-0">
                                <Nav variant="pills" className="flex-column TagsListings">
                                  {this.state.records && this.state.records.map((row, i) => {
                                    if( row.tag != null){
                                      return (
                                        <Nav.Item>
                                          <Nav.Link eventKey={i}>
                                            <label>
                                              <input
                                                type="checkbox"
                                                checked={this.state.selectedIds.includes(row.id)}
                                                onChange={() => this.handleCheckboxChange(row.id, 'records')}
                                              />
                                              {row.user_id != '2' ? `${row.name} (My)` : row.name}
                                            </label>
                                          </Nav.Link>
                                        </Nav.Item>
                                      );
                                      
                                      }
                                      })
                                    }
                                </Nav>
                              </Col>
                              <Col lg={7} className="ps-0">
                                <Tab.Content>
                                  {this.state.records && this.state.records.map((row, i) => {
                                      return (
                                        <Tab.Pane eventKey={i}>
                                          <ul className="TrainingList"  key={i}>
                                            {
                                              row.trainings && row.trainings.map((sub, j) => {
                                                if(sub.status!="Delete" && sub.title != ""){
                                                return (
                                                  <li><Link to="#">{sub.title}</Link></li>
                                                )
                                              }
                                              })
                                            }
                                          </ul>
                                        </Tab.Pane>
                                      )
                                    })
                                  }
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                         )} 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
  
              {/* <AvForm
                onValidSubmit={this.handleTodoSubmit}
                className="needs-validation"
              >
                <div className="mb-3">
  
                </div>
                <ModalFooter className="d-flex justify-content-start pb-0">
                  <Button color="primary" type="submit">
                    Update
                  </Button>
                  <Button color="secondary" onClick={() => 
                    this.setState({training_modal: false })
                  }>
                    Cancel
                  </Button>
                </ModalFooter>
              </AvForm> */}
            </ModalBody>
        );
    };
};