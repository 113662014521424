import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import ReactDatatable from '@ashvin27/react-datatable';

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet"
import axios from "axios"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getPages, getPermission, getRoles, isAllowed, uInfo, uRole, uToken } from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"
import { Link } from "react-router-dom";

export default class UserOperation extends Component {


    constructor(props) {
        const obj = JSON.parse(localStorage.getItem("authUser"));

        super(props);
        this.state = {
            id: obj.id,
            record: {},
            page_name: this.props.match.params.id ? "API Credentials" : "API Credentials",

        }
    }


    componentDidMount() {
        console.log(this.props, 'a')
        this.getData();


    }
    getData = () => {
        fetch(`${ApiUrl}` + 'get/edit/apicredentials', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({ id: this.state.id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                console.log(response, 'api credentials data')
                if (response.status === true) {
                    this.setState({
                        record: response.data.record

                    })
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    handleSubmitsendgrid = async (event, values) => {
        if (this.state.id) {
            values.id = this.state.record.id ? this.state.record.id : -1
        }
        fetch(`${ApiUrl}` + 'edit/sendGrid', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }
                    // this.getData();
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    handleSubmittwilio = async (event, values) => {
        if (this.state.id) {
            values.id = this.state.record.id ? this.state.record.id : -1
        }
        fetch(`${ApiUrl}` + 'edit/twilio', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                console.log(response);
                if (response.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }
                    // this.getData();
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    handleSubmitstripe = async (event, values) => {
        if (this.state.id) {
            values.id = this.state.record.id ? this.state.record.id : -1
        }
        fetch(`${ApiUrl}` + 'edit/stripe', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }
                    // this.getData();
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }



    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title> {this.state.page_name} Tables </title> </Helmet>

                <Row>
                    <Col sm={6} >
                        <div className="page-title-box">
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0" >
                                <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button type="button"
                                color="secondary"
                                onClick={
                                    () => this.props.history.goBack()}>
                                Back </Button>

                        </div>
                    </Col>
                </Row>


                <ToastContainer />
                <Row>

                    <Col className="col-12">
                        <Card>
                            <AvForm onValidSubmit={this.handleSubmitsendgrid}
                                ref={c => (this.form = c)}
                                className="needs-validation">
                                <ModalBody>
                                    <div className="mb-3">
                                        <h4>
                                            SendGrid Credentials </h4>
                                        <AvField name="sendgrid_api_key_id"
                                            label="SENDGRID API ID"
                                            value={this.state.record.sendgrid_api_key_id}
                                            className="form-control"
                                            placeholder="SENDGRID API ID"
                                            required />
                                    </div>
                                    <div className="mb-3">
                                        <AvField name="sendgrid_api"
                                            label="SENDGRID API KEY"
                                            value={this.state.record.sendgrid_api}
                                            className="form-control"
                                            placeholder="SENDGRID API KEY"
                                            required />
                                    </div>

                                </ModalBody>
                                <ModalFooter>
                                    <Button type="button"
                                        color="secondary"
                                        onClick={
                                            () => this.props.history.goBack()}>
                                        Cancel </Button> < button className="btn btn-primary waves-effect waves-light"
                                            type="submit"> Submit </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>

                { /* /////////////////////////////////////////////// */}
                <Row>

                    <Col className="col-12">
                        <Card>

                            <AvForm onValidSubmit={this.handleSubmittwilio}
                                ref={c => (this.form = c)}
                                className="needs-validation">
                                <ModalBody>
                                    <div className="mb-3">
                                        <h4>Twilio Credentials </h4>
                                        <AvField name="twilio_sid"
                                            label="Twilio SID"
                                            value={this.state.record.twilio_sid}
                                            className="form-control"
                                            placeholder="Twilio SID"
                                            required />
                                    </div>
                                    <div className="mb-3">
                                        < AvField name="twilio_auth_token"
                                            label="Twilio Auth Token"
                                            value={this.state.record.twilio_auth_token}
                                            className="form-control"
                                            placeholder="Twilio Auth Token"
                                            required />
                                    </div>
                                    <div className="mb-3">
                                        <vField name="profile_sid"
                                            label="Primary Customer Profile SID"
                                            value={this.state.record.profile_sid}
                                            className="form-control"
                                            placeholder="Primary Customer Profile SID"
                                            required />
                                    </div>

                                </ModalBody>
                                <ModalFooter>
                                    <Button type="button"
                                        color="secondary"
                                        onClick={
                                            () => this.props.history.goBack()}>
                                        Cancel </Button> <button className="btn btn-primary waves-effect waves-light"
                                            type="submit"> Submit </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>

                { /* /////////////////////////////////////////////// */}
                <Row>

                    <Col className="col-12">
                        <Card>

                            <AvForm onValidSubmit={this.handleSubmitstripe}
                                ref={c => (this.form = c)}
                                className="needs-validation">
                                <ModalBody>
                                    <div className="mb-3">
                                        <h4>Stripe Credentials </h4>
                                        < AvField name="stripe_publishable_key"
                                            label="Stripe Publishable Key"
                                            value={this.state.record.stripe_publishable_key}
                                            className="form-control"
                                            placeholder="Stripe Publishable Key"
                                            required />
                                    </div> <div className="mb-3">
                                        <AvField name="stripe_secret_key"
                                            label="Stripe Secret Key"
                                            value={this.state.record.stripe_secret_key}
                                            className="form-control"
                                            placeholder="Stripe Secret Key"
                                            required />
                                    </div> <div className="mb-3">
                                        <AvField name="stripe_product_key"
                                            label="Stripe Product Key"
                                            value={this.state.record.stripe_product_key}
                                            className="form-control"
                                            placeholder="Stripe Product Key"
                                            required />
                                    </div>

                                </ModalBody>
                                <ModalFooter>
                                    <Button type="button"
                                        color="secondary"
                                        onClick={
                                            () => this.props.history.goBack()}>
                                        Cancel </Button> <button className="btn btn-primary waves-effect waves-light"
                                            type="submit"> Submit </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        )
    }
}