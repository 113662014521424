import React, { Component, Fragment } from "react";
// import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Tabs,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
  CardText,
  Tablist,
  TabPanel,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import ProfileSettings from "../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ModalTitle } from "react-bootstrap";
import swal from 'sweetalert';

export default class EmailSettings extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);

    this.state = {
      index: "1",
      tab: "1",
      prevtab: "1",
      webhookchangemodal: false,
      activeTabId: 0,
      id: obj.user_id,
      record: {},
      stripe: {},
      chargebee: {},
      authorize: {},

      page_name: this.props.match.params.id ? "Payment" : "Payment",
      setUpPermission: {
        permission: 0,
        remaining_coach: 0,
        packages: []
      }
    };
  }
  componentDidMount() {
    this.getData();
    this.getcurrentsetingdata();
    this.makeCoachPackagesPermission();
  }

  toggle(index) {
    if (index == 1) {
      this.setState({
        tab: this.state.prevtab,
        index: index,
        // webhookchangemodal: !this.state.settingchangemodal,
      });
    } else if (index == 2) {
      this.setState({
        tab: index,
        index: index,
      });
    }

  }

  makeCoachPackagesPermission = () => {
    fetch(`${ApiUrl}` + "make/coach/packages/permission", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            setUpPermission: response.data
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  changesetting() {
    this.setState({
      tab: this.state.index,
      prevtab: this.state.index,
      webhookchangemodal: false,
    });
    this.updatesetting(this.state.index);
  }

  updatesetting = (id) => {
    fetch(`${ApiUrl}` + "update/user/paymentsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/paymentsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            stripe: response.data.record.stripe,
            chargebee: response.data.record.chargebee,
            authorize: response.data.record.authorize,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getcurrentsetingdata = () => {
    fetch(`${ApiUrl}` + "get/user/paymentsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            tab: response.data.record.payment_setting,
            prevtab: response.data.record.payment_setting,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmitstripe = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/stripe", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }

          //toast.configure();
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitchargebee = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/chargebee", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          //toast.configure();
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitauthorize = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/authorize", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          //toast.configure();
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  submitSetupPrice = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/setup/fee", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col lg={2} className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg={10}>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-6">
                <Card className="mt-2 mb-2">
                  <CardBody className="py-0">
                    <Nav tabs className="pt-0">
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 1 ? "active" : ""}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Stripe
                        </NavLink>
                      </NavItem>
                      {/* {
                        this.state.setUpPermission.permission == 1 && (
                          <NavItem>
                            <NavLink
                              className={this.state.tab == 2 ? "active" : ""}
                              onClick={() => {
                                this.toggle("2");
                              }}
                            >
                              Setup Price
                            </NavLink>
                          </NavItem>
                        )
                      } */}
                    </Nav>
                  </CardBody>
                </Card>
              </Col>

              <TabContent activeTab={this.state.tab}>
                <TabPane tabId="1">
                  <Row>
                    <Col className="col-6">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitstripe}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody className="py-0">
                            <div className="mb-3">
                              <h4>Stripe Settings</h4>
                              <AvField
                                name="secret_key"
                                label="Secret Key"
                                type="password"
                                value={this.state.stripe.secret_key}
                                className="form-control"
                                placeholder="Secret Key"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="publishable_key"
                                label="Publish Key"
                                type="password"
                                value={this.state.stripe.publishable_key}
                                className="form-control"
                                placeholder="Publish Key"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="client_id"
                                label="Client Id (Optional)"
                                value={this.state.stripe.client_id}
                                className="form-control"
                                placeholder="Client Id (Optional)"
                              />
                            </div>
                            {this.state.stripe.product_id && (
                              <div className="mb-3">
                                <AvField
                                  name="product_id"
                                  label="Product Id (Auto Created)"
                                  readOnly
                                  value={this.state.stripe.product_id}
                                  className="form-control"
                                />
                              </div>
                            )}
                          </ModalBody>
                          <ModalFooter className="border-0 pt-0">
                            <Button
                              type="button"
                              color="secondary"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                    <Col className="col-6">
                      <Card className="mt-5">
                        <CardBody className="bg-light rounded-3 mt-2">
                          <p>
                            <i className="fas fa-question"> </i> Help
                          </p>
                          <p>
                            If you have not setup your stripe account
                            <span>
                              <a
                                className=""
                                href="https://dashboard.stripe.com/register"
                                target="_blank"
                              >
                                &nbsp;Click Here
                              </a>
                            </span>
                            &nbsp;to setup.
                          </p>

                          <p>
                            You can get your stripe credentials by clicking on
                            Account Setting and than API Keys. &nbsp;
                            <span>
                              <a
                                className=""
                                href="https://profit-coach-app.s3.us-west-1.amazonaws.com/StripeDetails/stripe-api-keys.png"
                                target="_blank"
                              >
                                See here
                              </a>
                            </span>
                          </p>

                          <p>
                            Add
                            <button
                              onClick={() =>
                                this.setState({
                                  webhookchangemodal: true
                                })
                              }
                              className="btn btn-link"
                            >
                              Webhook URL
                            </button>
                            in stripe under Developers Webhook and than
                            Add endpoint.
                            <span>
                              &nbsp;
                              <a
                                className=""
                                href="https://i.imgur.com/uBvfqF6.jpg"
                                target="_blank"
                              >
                                See here
                              </a>
                            </span>
                          </p>

                          <p>
                            If you have not setup your stripe client ID
                            <span>
                              &nbsp;
                              <a
                                className=""
                                href="https://dashboard.stripe.com/account/applications/settings"
                                target="_blank"
                              >
                                Click Here
                              </a>
                            </span>
                            &nbsp; to setup
                          </p>

                          <p>
                            You can get your stripe client ID by clicking on
                            Account Setting and than Connect settings.
                            <span>
                              &nbsp;
                              <a
                                className=""
                                href="https://profit-coach-app.s3.us-west-1.amazonaws.com/StripeDetails/stripe-connect.png"
                                target="_blank"
                              >
                                See here
                              </a>
                            </span>
                            &nbsp;
                          </p>

                          <p>
                            Copy stripe client ID from here
                            <span>
                              &nbsp;
                              <a
                                className=""
                                href="https://profit-coach-app.s3.us-west-1.amazonaws.com/StripeDetails/stripe-connect-copy.png"
                                target="_blank"
                              >
                                See
                              </a>
                            </span>
                            &nbsp;
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col className="col-6">
                      <Card>
                        <form onSubmit={this.submitSetupPrice} method="post"
                          encType="multipart/form-data" className="form-horizontal" id="setup_fee-myform">
                          <table className="table table-stripped">
                            <thead>
                              <tr>
                                <th>Id#</th>
                                <th>Title</th>
                                <th>Sub Title</th>
                                <th>Price ($)</th>
                                <th>Setup Price ($)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.setUpPermission.packages.map((row, i) => {
                                  return (
                                    <tr>
                                      <td>{row.id} </td>
                                      <td>{row.name}</td>
                                      <td>{row.sub_title}</td>
                                      <td><input type={'number'} step={'any'} className="form-control" name={`price[id_${row.id}]`}
                                        defaultValue={row.price} required min={1} /></td>
                                      <td><input type={'number'} step={'any'} className="form-control" name={`setup[id_${row.id}]`}
                                        defaultValue={row.setup_fee} required min={1} /></td>
                                    </tr>
                                  )
                                })
                              }
                              <input type={'hidden'} name="package_setup" defaultValue={'submit'} />
                            </tbody>
                          </table>

                          <ModalFooter>
                            <Button
                              type="button"
                              color="secondary"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </form>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
          </Col>
        </Row>
        <Modal isOpen={this.state.webhookchangemodal} fade={false}>
          <ModalTitle>
            <h6 className="p-1">WebHook URL</h6>
          </ModalTitle>
          <ModalBody>
            <p> https://ws.profitcoach.app/webhook/stripe</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() =>
                this.setState({
                  webhookchangemodal: false,
                })
              }
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
