import React, { Component, Fragment } from "react";
import { hasRole, uInfo, uToken } from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";

export default class LeadExistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info_message: [`No duplicates found for ${props.value}.`],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${ApiUrl}` + "check/lead/existancegloablly", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        client_id: this.props.client_id,
        coach_id: this.props.coach_id,
        value: this.props.value,
        type: this.props.type,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (
            response.data.info_message &&
            response.data.info_message.length > 0
          ) {
            this.setState({
              info_message: response.data.info_message,
            });
          }
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <div>
        <div className="alert alert-info" role="alert">
          {/* {this.state.info_message && this.state.info_message.map((message, index) => (
            <div key={index}>{message}</div>
          ))} */}
          {this.state.info_message &&
            this.state.info_message.map((message, index) => {
              const formattedMessage = message
                .split(/(Leads:|Prospects:|Clients:)/)
                .map((part, partIndex) => {
                  if (
                    part === "Leads:" ||
                    part === "Prospects:" ||
                    part === "Clients:"
                  ) {
                    return (
                      <strong key={partIndex} className="fw-bolder">
                        {part}
                      </strong>
                    );
                  }
                  return part;
                });

              return <div key={index}>{formattedMessage}</div>;
            })}
        </div>
        {/* Other content here */}
      </div>
    );
  }
}
