import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import { uInfo } from "../../useToken";


class Example extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: props.randomValue, items: props.steps };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    //this.items = props.steps;
    this.totalStep = props.totalStep;
    //this.setState({ items: props.steps });
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }


  //   componentDidUpdate = (prevProps, prevState) => {
  //     console.log(prevProps.randomValue, this.props.randomValue, 'ddddd')
  //     if (prevProps.randomValue != this.props.randomValue) {

  //         this.setState({
  //             items: this.props.items,
  //         })
  //     }
  // }

  render() {
    const { activeIndex } = this.state;
    console.log(activeIndex, 'acti')

    const slides = this.state.items.map((row, i) => {
      return (
        <CarouselItem
          className="custom-tag slider-tracker"
          tag="div"
          key={row.sr}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <div className='approval'>
            {
              row.status == 'in-review' && (
                <span className="badge bg-info align-center mr-3 mb-2 lh-2 ">Awaiting Approval</span>
              )
            }
          </div>
          <div className='steps'>
            <div className={`TaskNumber px-2 ${row.status}`}>{row.sr}</div>
            <div className="TaskDesription-Ptracker" style={{ height : row.status == 'in-review' ? 'calc(100vh - 470px)' : ''}}>
              <h3>{row.title}</h3>

              <p className='html-content-step' dangerouslySetInnerHTML={{ __html: row.description }} />
              <div className="complete-box mt-2 d-flex justify-content-between">
                <div className="mark-verify">
                  {
                    row.status == 'complete' ?
                      <><input type={'checkbox'} name={'complete_action'} key={i} checked readOnly ></input> VERIFIED</>
                      :
                      <><input disabled={uInfo().type == 'Client' ? true : false} id={`complete_${row.id}`} onChange={(e) => e.target.checked && this.props.actionStepComplete(row.id)} type={'checkbox'} name={'complete_action'} key={i}></input> VERIFICATION</>
                  }
                </div>
                <div className="step-button" style={{ width: '205px', padding: '0' }}>

                  <div className='carouser-btn-custom d-flex justify-content-between p5'>

                    {
                      this.state.activeIndex > 0 ? (
                        <button className='btn btn-sm btn-primary' onClick={this.previous}>Back</button>
                      )
                        : (
                          <button disabled className='btn btn-sm btn-primary'>Back</button>
                        )
                    }
                    <button disabled={row.status == 'complete' || row.status == 'assigned' || row.status == 'in-review' ? true : false} onClick={() => {
                      this.props.setSendStepModal(row)
                      this.props.triggerActiveIndex(this.state.activeIndex)
                    }} className={`btn btn-sm code-green ${row.status == 'pending' && uInfo().type == 'Client' && ('d-none')}`}  >
                      {row.status == 'complete' || row.status == 'assigned' || row.status == 'in-review' ? row.status.toUpperCase() : "Send Action Step"}</button>
                    {
                      this.state.activeIndex === this.state.items.length - 1 ?
                        <button disabled className='btn btn-sm btn-primary'>Next</button>
                        :
                        <button className='btn btn-sm btn-primary' onClick={this.next}>Next</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </CarouselItem>
      );
    });

    return (
      <div className='TasksAsign tracker-list'>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          interval={null}
        >
          <CarouselIndicators items={this.state.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}


          {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} /> */}
          <small>{activeIndex + 1} of {this.totalStep}</small>
        </Carousel>


      </div>
    );
  }
}

export default Example;