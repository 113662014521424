import React, { useEffect, useState, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import Helmet from "react-helmet"



// Redux
import { Link } from "react-router-dom"

// import images
import { ProductName, logoLightPng, ApiUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { dcrypt } from "../../useToken";
import Loader from "react-loader";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentComponent from "../../components/Custom/PaymentComponent";
const SignupPayment = props => {
    // handleValidSubmit
    const [package_id] = useState(dcrypt(props.match.params.package_id));
    const [user_id] = useState(dcrypt(props.match.params.user_id));
    const [loaded, setLoaded] = useState(true);
    // const stripePromise = loadStripe('pk_test_D2JT2LYR7qdEBSZ0nTxd1hh5');
    const childRef = useRef(null);
    const formRef = useRef(null); // Add formRef here
    const [publishableKey, setPublishableKey] = useState(null);


    const handleValidSubmit = async (event, values) => {
        if (childRef.current) {
            const result = await childRef.current.handlePayment(event);
            if(result.error == 1){
                toast(result.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                return;
            }else{
                values.token = result.token;
            }
        }

        setLoaded(false);
        values.package_id = package_id;
        values.user_id = user_id;
        values.payment_method = 'stripe';
        values.cardnumber = '';
        values.cvc = '';
        values.expirationdateMM = '';    
        values.expirationdateYYYY = '';
        fetch(`${ApiUrl}` + 'signup/payment', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((res) => {
                setLoaded(true);
                if (res.status === true) {
                    props.history.push({
                        pathname: `/login`,
                        search: '?msg=Account created successfully',
                    });
                } else {
                    toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                }

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        getStripeKey();
    }, []);

    const getStripeKey = () => {
        fetch(`${ApiUrl}` + "get/stripe/key", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id: 3 }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setPublishableKey(response.data.key);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const stripePromise = publishableKey ? loadStripe(publishableKey) : null;

    // useEffect(() => {
    //     if (childRef.current) {
    //         // Trigger form submission after child component is mounted
    //         const event = new Event('submit', { cancelable: true });
    //         formRef.current.dispatchEvent(event);
    //     }
    // }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title> Register </title>
            </Helmet>

            <Loader loaded={loaded}></Loader>

            <div className="account-pages my-5 pt-sm-5" >

                <Container >
                    <ToastContainer />
                

                    <Row className="justify-content-center" >

                        <Col md={8}
                            lg={8}
                            xl={8} >

                            <Card className="overflow-hidden" >

                                <CardBody className="pt-0" >

                                    <h3 className="text-center mt-5 mb-4" >

                                        <Link to="/"
                                            className="d-block auth-logo" >

                                            <img src={logoLightPng}
                                                alt=""
                                                height="50"
                                                className="auth-logo-dark" />
                                        </Link>
                                    </h3>
                                    <div className="p-3" >
                                        <h4 className="text-muted font-size-18 mb-1 text-center" > Register </h4>
                                        <p className="text-muted text-center" > Get your  {ProductName} account now. </p>
                                        <AvForm className="form-horizontal mt-4"
                                            ref={formRef}
                                            onValidSubmit={
                                                (e, v) => {
                                                    handleValidSubmit(e, v)
                                                }
                                            }>


                                            {
                                                stripePromise && (

                                                    <Elements stripe={stripePromise}>
                                                        <PaymentComponent ref={childRef} />
                                                    </Elements>
                                                )
                                            }



                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    {/* <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="payment_type"
                                                    label="Payment Type"
                                                    value={this.state.record.payment_type}
                                                    required={this.state.isRequired}
                                                >
                                                    <option value={""}> Select Payment Type </option>
                                                    <option value={"recurring"}> Recurring </option>
                                                    <option value={"one_time"}> One Time </option>
 
                                                </AvField> */}
                                                    <AvInput type='hidden' name='payment_type' value='recurring' />
                                                </div>
                                                {/* <div className="col-md-6 mb-3">
                                                    <AvField
                                                        name="cardnumber"
                                                        label="Card Number"
                                                        className="form-control"
                                                        Placeholder="Enter Card Number"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="control-label"> CVC</label>
                                                    <AvField
                                                        name="cvc"
                                                        className="form-control"
                                                        type="number"
                                                        Placeholder="CVC"
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <AvField
                                                        name="expirationdateMM"
                                                        label="MM"
                                                        className="form-control"
                                                        type="number"
                                                        Placeholder="MM"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <AvField
                                                        name="expirationdateYYYY"
                                                        label="YYYY"
                                                        className="form-control"
                                                        type="number"
                                                        Placeholder="YYYY"
                                                        required
                                                    />
                                                </div> */}
                                                <div className="col-md-12 mb-3">
                                                    <AvField
                                                        name="address_line1"
                                                        label="Billing Address"
                                                        className="form-control"
                                                        type="textarea"
                                                        Placeholder="Billing Addresss"
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <AvField
                                                        name="address_city"
                                                        label="Billing City"
                                                        className="form-control"
                                                        type="text"
                                                        Placeholder="Billing City"
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <AvField
                                                        name="address_state"
                                                        label="Billing State"
                                                        className="form-control"
                                                        type="text"
                                                        Placeholder="Billing State"
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <AvField
                                                        name="address_zip"
                                                        label="Billing Zip"
                                                        className="form-control"
                                                        type="text"
                                                        Placeholder="Billing Zip"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row mt-4" >
                                                <div className="col-12 text-end" >
                                                    <button disabled={loaded ? false : true} className="btn btn-primary waves-effect waves-light"
                                                        type="submit" > Register
                                                    </button>
                                                </div>
                                            </div>

                                            {/* <div className="mb-0 row" >
                                                <div className="col-12 mt-4" >
                                                    <p className="text-muted mb-0 font-size-14" >
                                                        By registering you agree to the PG Virtual Office
                                                        <Link to="#"
                                                            className="text-primary" > Terms of Use </Link></p>
                                                </div>
                                            </div> */}
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center" >
                                <p>
                                    Already have an account ? {" "}
                                    <Link to="/login"
                                        className="text-primary" > {" "}
                                        Login
                                    </Link>{" "}
                                </p>
                                <p> ©{new Date().getFullYear()} {ProductName}
                                    <span className="d-none d-sm-inline-block" > </span>
                                    { /* <span className="d-none d-sm-inline-block"> - Created <i className="mdi mdi-heart text-danger"></i> by Zamsol.</span> */}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SignupPayment