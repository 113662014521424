import React, { useEffect, useState } from "react";

import { Link, Redirect, useHistory } from "react-router-dom";

// reactstrap
import {
    Row,
    Col,
    Container,
} from "reactstrap";


import { ApiUrl, logoLightPng } from "../../../config.js";
import { bcrypt, uInfo, uToken } from "../../../useToken";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { toast, ToastContainer, Flip } from "react-toastify";
import { AvForm } from "availity-reactstrap-validation";
import AvField from "availity-reactstrap-validation/lib/AvField";
import ReactSelect from "react-select";
import countries from "../../../common/data/countries";
import Incall from "../../Incall";
//i18n

const Header = (props) => {
    const [menu, setMenu] = useState(false);
    const [isSearch, setSearch] = useState(false);
    const [socialDrp, setsocialDrp] = useState(false);

    const [show, setShow] = useState(false);
    const [existing, setExisting] = useState([]);
    const [isClient, setIsClient] = useState(true);

    const [countryCode, setCountryCode] = useState("");
    const [groupId, setGroupId] = useState("");
    const [groups, setGroups] = useState([]);
    let history = useHistory();

    useEffect(() => {
        getGroups();
    }, []);

    const searchClient = (search) => {
        if (search != "") {
            fetch(`${ApiUrl}` + "get/clients", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ filter_value: search, campaign_id: props.campaign.campaign_id }),
            })
                .then((response) => response.json())
                //Then with the data from the response in JSON...
                .then((response) => {
                    // console.log(response, "data");
                    if (response.status === true) {
                        setExisting(response.data.records);
                        setIsClient(response.data.records.length > 0 ? true : false);
                    } else {
                        toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            setExisting([]);
            setIsClient(true);
        }
    };
    const getGroups = () => {
        fetch(`${ApiUrl}` + "groups", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setGroups(response.data.records);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async (event, values) => {
        // props.navigation.history.push("/client/list");

        fetch(`${ApiUrl}` + "edit/client", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {

                if (data.status === true) {
                    let res = data.data;
                    //console.log(res.client_id, 'client_id');
                    // props.navigation.history.push(
                    //     "/client/dialer/" +
                    //     bcrypt(res.client_id) +
                    //     "/" +
                    //     bcrypt(res.coach_id)
                    // );
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleCountry = (selectedCountry) => {
        setCountryCode(selectedCountry.code);
    };

    const handleGroup = (selectedGroup) => {
        setGroupId(selectedGroup.group_id);
    };

    const popover = (
        <Popover id="popover-basic" className="client-popover">
            <Popover.Header as="h3">Add a Client</Popover.Header>
            <Popover.Body>
                <AvForm
                    onValidSubmit={handleSubmit}
                    // model={this.state.record}
                    className="needs-validation"
                >
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <AvField
                                name="first_name"
                                label="First Name"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <AvField
                                name="last_name"
                                label="Last Name"
                                className="form-control"
                            />
                        </div>

                        <div className="col-md-12 mb-3">
                            <label className="control-label">Group</label>
                            <ReactSelect
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.group_id}
                                value={groups.filter(({ group_id }) => group_id === groupId)}
                                isMulti={false}
                                options={groups}
                                classNamePrefix="select2-selection"
                                name="groups_id"
                                onChange={(e) => {
                                    handleGroup(e);
                                }}
                            />
                            <AvField required type="hidden" name="group_id" value={groupId} />
                        </div>
                        <div className="col-md-12 mb-3">
                            <AvField
                                name="email"
                                label="Email"
                                className="form-control"
                                type="email"
                            />
                        </div>
                        <div className="col-md-5 mb-3">
                            <label className="control-label">Country</label>
                            <ReactSelect
                                getOptionLabel={(option) =>
                                    option.label + " (" + option.code + ")"
                                }
                                getOptionValue={(option) => option.code}
                                value={countries.filter(({ code }) => code === countryCode)}
                                isMulti={false}
                                options={countries}
                                classNamePrefix="select2-selection"
                                name="country_id"
                                onChange={(e) => {
                                    handleCountry(e);
                                }}
                            />
                            <AvField
                                type="hidden"
                                name="country_code"
                                required
                                value={countryCode}
                            />
                        </div>
                        <div className="col-md-7 mb-3">
                            <AvField
                                name="mobile"
                                label="Mobile"
                                className="form-control"
                                placeholder="Enter mobile without country code"
                                type="number"
                                validate={{
                                    // required: { value: true, errorMessage: 'This field is invalid' },
                                    pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <a className="btn" href="/client/add">
                                Advanced
                            </a>
                        </div>

                        <div className="col-md-6 mb-3">
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </AvForm>
            </Popover.Body>
        </Popover>
    );

    return (



        <Row>

            <Col md={{ size: 10, offset: 1 }} className="">
                <div className="call_durt">
                    <div className="user_info user_info_b">User: <span>{props.campaign.user_name} </span></div>
                    <span className="divd"></span>
                    <div className="user_info user_info_b">Campaign: <span>{props.campaign.title}</span></div>
                    <span className="divd"></span>
                    <div className="user_info user_info_b">Total Contacts: <span id="show_total_contacts">{props.campaign.total_clients}</span></div>
                    <span className="divd"></span>
                    <div className="user_info user_info_b"> Caller ID:  <span className="campaign-number"></span>
                        <select name="from_number" >
                            <option value={props.campaign.phone_number}>{props.campaign.phone_number}</option>
                        </select>
                    </div>
                    <span className="divd"></span>
                    <div className="user_info user_info_b">Lead Attempts: {props.campaign.client_attempts}<span></span></div>
                </div>

                <div className={`float-end ${props.type == 'quick-dialer' ? '' : 'd-none'}`}>

                    <button className="btn btn-warning mt-3 mr-5" onClick={() => history.push({
                        pathname: '/lead/list',
                        state: { back: 'this is from back' }
                    })}>
                        Back to leads list
                    </button>

                    {
                        props.prevClientId > 0 && (
                            <button className="btn btn-primary mt-3 mr-5" onClick={() => {
                                const currentUrl = window.location.href;
                                const hasGoParameter = currentUrl.includes('&go='); 

                                if (!hasGoParameter) {
                                    // If the URL doesn't have the &go= parameter, add it
                                    window.location.href = currentUrl + '&go=' + props.prevClientId;
                                } else {
                                    // If the URL already has &go= parameter, replace the existing value
                                    const updatedUrl = currentUrl.replace(/&go=[^&]*/, '&go=' + props.prevClientId);
                                    window.location.href = updatedUrl;
                                }
                            }
                            }>
                                Back
                            </button>

                        )
                    }

                    {
                        (props.nextClientId > 0 || props.prevClientId > 0)  && (
                            <button className="btn btn-info mt-3" onClick={() => {
                                const currentUrl = window.location.href;
                                const hasGoParameter = currentUrl.includes('&go='); 
                                
                                if(!hasGoParameter){
                                    window.location.reload()
                                }else{
                                    const updatedUrl = currentUrl.replace(/&go=[^&]*/, '');
                                    window.location.href = updatedUrl;
                                }
                            }}>
                                Next
                            </button>
                        )
                    }
                </div>
                <div className={`float-end ${props.type != 'quick-dialer' ? '' : 'd-none'}`}>
                    {(uInfo().type == "Coach" || uInfo().type == "CoachPartner") && (
                        <div className="app-search d-inline-block search" style={{ width: '79%' }}>
                            <div className="position-relative d-flex">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    onChange={(e) => searchClient(e.target.value)}
                                    onBlur={() =>
                                        setTimeout(() => {
                                            setExisting([]);
                                        }, 1000)
                                    }
                                />
                                <OverlayTrigger
                                    rootClose={true}
                                    trigger="click"
                                    placement="bottom-start"
                                    overlay={popover}
                                >
                                    <button
                                        onClick={() => setShow(!show)}
                                        type="button"
                                        className="btn"
                                    >
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    )}

                    <ul className="client-search-bar">
                        {!isClient && <li className="text-primary">No data found</li>}
                        {existing.map((row, i) => {
                            return (
                                <li key={i}>
                                    <a
                                        href={
                                            `/campaign/outbounddialer/start?runId=${bcrypt(props.campaign.campaign_id)}&dialer_type=powerdialer&client=${bcrypt(row.client_id)}`
                                        }
                                    >
                                        <div className="d-grid-template">
                                            <div className="info">
                                                <i className="fas fa-user"></i>
                                                {row.first_name} {row.last_name}
                                            </div>

                                            <div className="info">
                                                <i className="fas fa-mobile"></i>
                                                {row.mobile}
                                            </div>
                                        </div>

                                        <div className="d-grid-template">
                                            <div className="info">
                                                <i className="fas fa-user"></i> {row.email}
                                            </div>

                                            <div className="info">
                                                <i className="fas fa-phone"></i>
                                                {row.phone}
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>


                    <Link
                        to={"/campaign/list"}
                        className="btn noti-icon header-btn waves-effect display-inline"
                    >
                        <i className="fa fa-arrow-left"></i>

                    </Link>



                    <div className="dropdown d-inline-block d-lg-none ms-2">
                        <button
                            type="button"
                            className="btn header-item noti-icon waves-effect"
                            id="page-header-search-dropdown"
                            onClick={() => setSearch(!isSearch)}
                        >
                            <i className="mdi mdi-magnify" />
                        </button>
                        <div
                            className={
                                isSearch
                                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            }
                            aria-labelledby="page-header-search-dropdown"
                        >
                            <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={"Search..."}
                                            aria-label="Recipient's username"
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="submit">
                                                <i className="mdi mdi-magnify" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </Col>
            {
                <Incall />
            }
        </Row >


    );
};

export default Header;
