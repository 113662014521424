import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup } from "availity-reactstrap-validation";
import {
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select"
import countries from "../../common/data/countries";
import AsyncSelect from 'react-select/async';

export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            pipelineStatus: [],
            permissions: [],
            permission: [],
            tokens: [],
            notificationEmail: {
                cc_email: '',
                token: '',
                subject: '',
                body: ''
            }


        };
    }

    componentDidMount() {
        this.getFormData();
    }

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/contract/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    var tokens_group = [
                        { label: 'Default Field', options: response.data.tokens.default_fields },
                        { label: 'Custom Field', options: response.data.tokens.custom_fields }
                    ];

                    this.setState({
                        permissions: response.data.coachPartners,
                        pipelineStatus: response.data.pipelineStatus,
                        tokens: tokens_group
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    handleSubmit = async (event, values) => {
        if (this.state.record.submit_notification == 'Y') {
            values.submit_notification_sub = JSON.stringify(this.state.notificationEmail);
        }

        values.permission = this.state.permission.toString();

        fetch(`${ApiUrl}` + "add/contract", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, 'Editing');
                if (data.status === true) {
                    this.form && this.form.reset();
                    this.props.cancelSlider();
                    this.props.history.goBack();
                    //  toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handlePermission = (selectedPermission) => {

        var filter = [];
        if (selectedPermission) {
            selectedPermission.map(fil => {
                filter.push(fil.user_id);
            })
        }

        this.setState({
            permission: filter.toString(),
            selectedPermission: selectedPermission
        })
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                // model={this.state.record}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <ModalBody className="row">
                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="name"
                                            value={this.state.record.name}
                                            label="Enter name"
                                            className="form-control"
                                            required
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="deal_status_assigned"
                                            value={this.state.record.deal_status_assigned}
                                            label="Deal Status ( When Contract assigned to Client )"
                                            className="form-control"
                                            type="select"
                                            required
                                        >
                                            <option value={''}>Select Status</option>
                                            {
                                                this.state.pipelineStatus.map((row, i) => {
                                                    return <option value={row.status_id} key={i}>{row.title}</option>
                                                })
                                            }
                                        </AvField>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="deal_status_submitted"
                                            value={this.state.record.deal_status_submitted}
                                            label="Deal Status ( When Client Submits Contract )"
                                            className="form-control"
                                            type="select"
                                            required
                                        >
                                            <option value={''}>Select Status</option>
                                            {
                                                this.state.pipelineStatus.map((row, i) => {
                                                    return <option value={row.status_id} key={i}>{row.title}</option>
                                                })
                                            }
                                        </AvField>
                                    </div>


                                    <div className="col-md-12 mb-3 d-none">
                                        <label className="control-label">Assign to</label>
                                        <Select
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.user_id}
                                            isMulti={true}
                                            value={this.state.selectedPermission}
                                            options={this.state.permissions}
                                            classNamePrefix="select2-selection"
                                            onChange={(e) => {
                                                this.handlePermission(e)
                                            }}
                                        />
                                        <AvField type="hidden" name="permission" value={this.state.permission} />


                                    </div>

                                    <div className="col-md-12 mb-3 d-flex">
                                        <label className="control-label pr-3">Signature : </label>
                                        <AvRadioGroup value={this.state.record.signature} inline name="signature" required>
                                            <AvRadio label="Enabled" value="Y" />
                                            <AvRadio label="Disabled" value="N" />
                                        </AvRadioGroup>
                                    </div>

                                    <div className="col-md-12 mb-3 d-flex">
                                        <label className="control-label pr-3">Contract Submit Notifications : </label>
                                        <AvRadioGroup onChange={(e) =>
                                            this.setState(prevState => {
                                                let record = Object.assign({}, prevState.record);
                                                record.submit_notification = e.target.value;
                                                return { record };
                                            })
                                        } value={this.state.record.submit_notification} inline name="submit_notification" required>
                                            <AvRadio label="Yes" value="Y" />
                                            <AvRadio label="No" value="N" />
                                        </AvRadioGroup>
                                    </div>
                                    {
                                        this.state.record.submit_notification == 'Y' && (
                                            <>
                                                <h5 className="text-link">Email Notification</h5>
                                                <div className="mb-3">
                                                    <AvField
                                                        label="CC Email"
                                                        className="form-control"
                                                        type="text"
                                                        name="cc_email"
                                                        required
                                                        value={this.state.notificationEmail.cc_email}
                                                        onChange={(e) => this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.cc_email = e.target.value;
                                                            return { notificationEmail };
                                                        })}
                                                    />
                                                </div>

                                                <div className="col-md-12 mb-1">
                                                    <Select
                                                        getOptionLabel={option => option.label}
                                                        getOptionValue={option => option.key}
                                                        // value={options.filter(({ id }) => id === this.state.country_code)}
                                                        isMulti={false}
                                                        options={this.state.tokens}
                                                        classNamePrefix="select2-selection"
                                                        name="country_id"
                                                        placeholder="Select Token"
                                                        onChange={(e) => {
                                                            this.setState(prevState => {
                                                                let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                                notificationEmail.token = `{${e.key}}`;
                                                                return { notificationEmail };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <button onClick={() =>
                                                        this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.body = this.state.notificationEmail.token + '' + this.state.notificationEmail.body;
                                                            return { notificationEmail };
                                                        })}
                                                        type="button" className="btn btn-primary">Insert Body</button>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <button onClick={() =>
                                                        this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.subject = this.state.notificationEmail.token + '' + this.state.notificationEmail.subject;
                                                            return { notificationEmail };
                                                        })}
                                                        type="button" className="btn btn-primary">Insert Subject</button>
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        className="form-control"
                                                        type="text"
                                                        label="Subject"
                                                        name="subject"
                                                        placeholder="Subject"
                                                        required
                                                        value={this.state.notificationEmail.subject}
                                                        onChange={(e) => this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.subject = e.target.value;
                                                            return { notificationEmail };
                                                        })}
                                                    />
                                                </div>



                                                <div className="mb-3">
                                                    <AvField
                                                        className="form-control"
                                                        type="textarea"
                                                        label="Email Body"
                                                        name="body"
                                                        placeholder="Enter body"
                                                        required
                                                        value={this.state.notificationEmail.body}
                                                        rows={5}
                                                        onChange={(e) => this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.body = e.target.value;
                                                            return { notificationEmail };
                                                        })}
                                                    />

                                                </div>
                                            </>
                                        )
                                    }


                                </ModalBody>
                                <ModalFooter>
                                    <button
                                        className="btn btn-primary waves-effect waves-light"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
