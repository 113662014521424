import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link, useHistory } from "react-router-dom";

export default function BookmarkedTrainings({ trainings, onRemoveBookmark }) {
  return (
    <>
      <ul className="RecentlyViewed BookMark BookMarkDetails">
        {trainings.map((training) => (
          <li className="mb-3 py-auto">
            <div className="d-flex justify-content-between">
              <div className="ms-2">
                <h6>
                  <Link to={training.type == "quiz" ? `/trainingsreview/${training.encoded_link}` : `/trainings/${training.encoded_link}`} style={{ color: "inherit" }}>
                    {training.training_title}
                  </Link>
                </h6>
                <p>{training.course_title}</p>
                <p>
                  <span>
                    <i className="mdi mdi-chevron-right" />
                  </span>
                  {training.module_title}
                </p>
                <p>
                  <span>
                    <i className="mdi mdi-chevron-right" />
                  </span>
                  {training.lesson_title}
                </p>
              </div>
              <div className="my-auto me-2">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">Remove as bookmark</Tooltip>
                  }
                >
                  <i
                    className="fas fa-times text-danger "
                    style={{ cursor: "pointer" }}
                    onClick={() => onRemoveBookmark(training.step_id)}
                  ></i>
                </OverlayTrigger>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
