import React from "react";
import { withRouter } from "react-router-dom";

// i18n
import AdminTagsList from "./AdminTagsList";
import CoachTagsList from "./CoachTagsList";
import CoachPartnerTagsList from "./CoachPartnerTagsList";
import { uInfo } from "../../../useToken";

const List = (props) => {
  return (
    <>
      {uInfo().type === 'WlAdmin' && (
        <AdminTagsList {...props} />
      )}
      {(uInfo().type === 'Coach') && (
        <CoachTagsList {...props} />
      )}
      {(uInfo().type === 'CoachPartner') && (
        <CoachPartnerTagsList {...props} />
      )}
    </>
  );
};

export default withRouter(List);
