import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo, 
    uRole,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import swal from 'sweetalert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
export default class CoachPartnerTagsList extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.location.search);
        const id = params.get('id');
        this.state = {
            id: id? dcrypt(id): null,
            records: [],
            record: {},
            trainings: [],
            associated_trainings: [],
            users: [],
            associated_users: [],
            roles: [],
            total_pages: 0,
            modal_1: false,
          
            filter: {},
            filter_user: {},
            page_name: " Training Tags List",
            customDate: true,
            modal_2: false,
            selectedIds: [],
            selectAll: false,
            selectedIdsAssociated: [],
            selectAllAssociated: false,
            selectedUserIds: [],
            selectAllUsers: false,
            selectedUserIdsAssociated: [],
            selectAllAssociatedUserIds: false,
        };
      
        this.columns = [
            {
                text: "#",
                key: "id",
                sortable: false,
            },
            {
                text: "Title",
                key: "name",
                sortable: false,
            },
            {
                text: "Associated Training(s)",
                key: "trainings_name",
                cell: (record, index) => {
                    const trainingsName = record.trainings_name || ""; 
                    const shortTrainingsName = trainingsName.length > 60 ? trainingsName.substring(0, 60) + "..." : trainingsName; 
                    return <span title={trainingsName}>{shortTrainingsName}</span>;
                }
            },
            {
              text: "Total Clients",
              key: "total_client",
              sortable: false,
            },
            {
              text: "Status",
              key: "status",
              cell: (record, index) => {

                if (record.status == 1) { 
                    return <span className="badge bg-success">{"Active"}</span>
                  } else if (record.status == '0') {
                    return <span                    
                    className="badge bg-danger"
                    >{'Inactive'}</span>
                  } else {
                    return <span  className="badge bg-warning">{'Inactive'}</span>
                  }
               
              }
            },
            {
              text: "Created At",
              key: "created_at",
            },
            
        ];
        this.config = {
            key_column: "sr",
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
     
    }

 

  
 
    componentDidMount() {
        this.getData();
       
        if(this.state.id && !this.state.modal_2){
            this.setState({
                modal_2: true,
                record: {}
            });
        }
        this.state.id && this.getmeta(this.state.id);
    }


    deleteRecord = (record, index) => {
        swal({
          title: "Are you sure?",
          text: "You want to remove this record.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then((confirmation) => {
            if (confirmation) {
              fetch(`${ApiUrl}` + 'delete/trainingtags', { 
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ` + uToken()
                },
                body: JSON.stringify({ 'id': record.id }),
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data.status === true) {
                    this.getData();
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                  } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                  }
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            }
          });
    
    }
    handleFilterSubmit = async (event, values) => {
        this.setState({
          filter: values,
        });
        this.getData(this.props.match.params.type, values);
      };
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/trainingtags?login_user_type=coach" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
           
        })
            .then((response) => response.json())
            .then((response) => {
                
                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,

                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getmeta = (id) => {
        fetch(`${ApiUrl}` + "get/training/meta", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id}),
           
        })
            .then((response) => response.json())
            .then((response) => {
                
                if (response.status === true) {
                    this.setState({
                        record: response.data.record,
                        id: response.data.record.id,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
   
  
   
    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    searchFilter = () => {

    }

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("&" + queryString, this.state.filter);
    };

 
  

    handleSubmit= async (event, values) => {
        fetch(`${ApiUrl}` + "add/training/tag", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        modal_1: false,
                    });
                    this.getData();
                    
                    this.getmeta(response.data.record.id);
                    this.setState({
                        modal_2: true,
                    });
                  
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    render() {
        return (
            <ErrorBoundary>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>

                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name}</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name}</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <ToastContainer />
                
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardBody className="pb-0">
                          <AvForm
                            onValidSubmit={this.handleFilterSubmit}
                            ref={(c) => (this.form = c)}
                            className="needs-validation"
                          >
                            <Row>
                              <Col md="2">
                                <div className="mb-0">
                                  <AvField
                                    className="select form-control"
                                    type="select"
                                    name="date_range"
                                    label="Duration"
                                    onChange={(e) =>
                                      e.target.value == "Custom Date"
                                        ? this.setState({
                                          customDate: false,
                                        })
                                        : this.setState({
                                          customDate: true,
                                        })
                                    }
                                  >
                                    <option value=""> All Time </option>
                                    <option value="Current Week">
                                      Current Week{" "}
                                    </option>
                                    <option value="Last Week"> Last Week </option>
                                    <option value="Current Month">
                                      Current Month{" "}
                                    </option>
                                    <option value="Last Month"> Last Month </option>
                                    <option value="Last 3 Months">
                                      Last 3 Months{" "}
                                    </option>
                                    <option value="Last 6 Months">
                                      Last 6 Months{" "}
                                    </option>
                                    <option value="Current Year">
                                      Current Year{" "}
                                    </option>
                                    <option value="Last Year"> Last Year </option>
                                    <option value="Custom Date"> Custom Date </option>
                                  </AvField>
                                </div>
                              </Col>
                              <Col md="2">
                                <div className="mb-0">
                                  <AvField
                                    name="start_date"
                                    label="Start date"
                                    className="form-control"
                                    placeholder="Enter Email Address"
                                    type="date"
                                    disabled={this.state.customDate}
                                    required={!this.state.customDate}
                                  />
                                </div>
                              </Col>
                              <Col md="2">
                                <div className="mb-0">
                                  <AvField
                                    name="end_date"
                                    label="End date"
                                    className="form-control"
                                    placeholder="Enter Email Address"
                                    type="date"
                                    disabled={this.state.customDate}
                                    required={!this.state.customDate}
                                  />
                                </div>
                              </Col>
                              <Col md="2">
                              <div className="mb-3">
                                <AvField
                                className="select form-control"
                                type="select"
                                name="status"
                                label="Status"
                                id="s"
                                >
                                <option value={""}> Select Status </option>
                                <option value={"1"}> Active </option>
                                <option value={"0"}> Inactive </option>
                                </AvField>
                            </div>
                              </Col>

                              <Col md="4">
                                <div className="mb-0">
                                  <label className=""> {"Action"}</label>
                                  <div className="button-items">
                                    <Button className="btn-sm" color="primary" type="submit">
                                        Filter
                                    </Button>
                                    <Button
                                      onClick={() => this.cancelFilter()}
                                      outline
                                      color="secondary"
                                      className="waves-effect btn-sm"
                                      type="button"
                                    >
                                      Reset
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                      <Col className="col-12">
                          <Card>
                              <CardBody className="pt-0">
                                  <ReactDatatable
                                      id={''}
                                      key={this.state.id}
                                      config={this.config}
                                      records={this.state.records}
                                      columns={this.columns}
                                      dynamic={true}
                                      total_record={this.state.total_pages}
                                      onChange={this.tableChangeHandler}
                                  />
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
               
            </ErrorBoundary >
        );
    }
}
