import React, { useState, useEffect } from 'react';
import BadgesImg from './../../assets/PublicImages/pop-badge.png';
import {
    Collapse, Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { bcrypt, dcrypt, uInfo, uToken, currentDate } from '../../useToken';
import { ApiUrl, AttachementsUrl } from '../../config';
import { useHistory } from 'react-router-dom';
const PaymentModel = ({ initial = false, parentCategory }) => {
    const history = useHistory();
    const [isCourseUnlockedModel, setIsCourseUnlockedModel] = useState(initial);
    const [parentCategorydata, setParentCategoryData] = useState({});

    const [counts, setCounts] = useState({
        module: 0,
        lesson: 0,
        content: 0,
        quiz: 0
    });

    useEffect(() => {

        // console.log('payment --------- ', parentCategory)

        handlepaidorfreecatId(parentCategory)
        setParentCategoryData(parentCategory)

    }, [parentCategory]);
    useEffect(() => {
        console.log(initial)
    }, [isCourseUnlockedModel]);
    useEffect(() => {
        if (!isEmptyObject(parentCategorydata)) {
            console.log(parentCategorydata)
            //console.log(`${AttachementsUrl}user_${parentCategorydata.user_id}/training/${parentCategorydata.sub_cat[0].badge_image}`)
        }
    }, [parentCategorydata]);

    const handlepaidorfreecatId = (categories) => {
        const module = categories.sub_cat; //  modules
        const moduleLength = module.length; // Length of the modules array
        let lesson = 0;
        let contentquiz = 0;
        module.forEach(subCategory => {
            subCategory.sub_cat.forEach(subCat => {
                subCat.sub_steps.forEach(subStep => {
                    //console.log(subStep.type)
                    if (subStep.type === 'content') contentquiz++;
                    if (subStep.type === 'quiz') contentquiz++;
                });
            });
            lesson++;
        });
        setCounts(prevState => ({
            ...prevState,
            module: moduleLength,
            lesson: lesson,
            content: contentquiz
        }));
    };
    useEffect(() => {
        //console.log(counts)
    }, [counts]);
    const handleInvoiceGeneration = async (data) => {
        let bodyParams = {
            start_date: currentDate(),
            payment_method: 'stripe',
            invoice_for: uInfo().type,
            master_type: 'coaching',
            product_id: '0',
            training_product_id: '0',
            created_for_id: uInfo().user_id,
            package_id: '0',
            search_client: '0',
            invoice_currency_code: "USD",
            renewal_interval: "Monthly",
            type: "one_time",
            cat_id: parentCategory.cat_id,
            total_price: parentCategorydata.course_paid_price,
            items_data: [{ items: parentCategorydata.title, quantity: "1", price: parentCategorydata.course_paid_price, total: parentCategorydata.course_paid_price, readOnly: "0" }],
        };
        const response = await fetch(`${ApiUrl}` + "add/invoice/course", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(bodyParams),
        });
        const responseData = await response.json();
        if (responseData.status === true) {
            // window.open(responseData.data.link, "_self")
            history.push(responseData.data.link);
            console.log(responseData.data.link)
        }
    };
    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0; // Returns true if object is empty
    };

    return <Modal isOpen={isCourseUnlockedModel} fade={true} className="PopUpModal Purchese" centered={true} toggle={() => {
        setIsCourseUnlockedModel(false);
    }}
    >
        <ModalBody>
            <div className="PopUpModalBody">
                <div className="PopUpModalBodyTitle">
                    <h2>{parentCategorydata && parentCategorydata.title}</h2>
                </div>
                <div className='row'>
                    <div className="col-lg-7">
                        <ul className='PurchesingPoints'>
                            <li>Course Objective: {parentCategorydata && parentCategorydata.course_description}</li>
                            {/* <li>Course Objective: This course targeted for</li>
                            <li>Improving the profits by 160% it details the steps</li>
                            <li>and strategies required to achive the target sucessfuly</li> */}
                        </ul>
                    </div>
                    <div className='col-lg-5'>
                        <div className='CourseBadges text-center'>
                            {
                                !isEmptyObject(parentCategorydata) ?
                                    parentCategorydata.sub_cat.slice(0, 4).map((subCat, index) => (
                                        <img
                                            key={index}
                                            src={`${AttachementsUrl}user_${parentCategorydata.user_id}/training/${subCat.badge_image}`}
                                            alt={`Badge ${index}`}
                                        />
                                    ))

                                    : <img src={BadgesImg} alt="BadgesImg" />
                            }

                            <h4 className='mt-2'>Course Badges</h4>
                        </div>
                    </div>
                </div>
                <div className='col-lg-8 offset-lg-2'>
                    <div className='text-center EndText mt-5'>
                        <p>This course has {counts.module} Module {counts.lesson} lessons and {counts.content} steps + quizzes</p>
                        <button className='btn btn-success mt-3' onClick={handleInvoiceGeneration}>Buy now ${parentCategorydata?.course_paid_price ?? 0}</button>
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>;
};

export default PaymentModel;