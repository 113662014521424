import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

import ReactPlayer from "react-player";
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    dcrypt,
    bcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ResourcesSidebar from "./resources-sidebar";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
export default class ResourceContentEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            records: [],
            roles: [],
            total_pages: 0,
            modal: false,
            record: {},
            recordall: {},
            filter: {},
            page_name: "Resource Content",
            content_url: "",
            playing: true,
            volumne: "1",
            loader: true
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = this.state.id, data) => {
        fetch(`${ApiUrl}` + "view/training/content/" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        content_url: response.data.record,
                        recordall: response.data.recordall,
                    });
                    console.log(this.state.content_url);
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        submitData.append('id', this.state.id ? this.state.id : -1);
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        // submitData.append('cat_id', this.state.cat_id);


        this.setState({ loader: false });
        // values.section_id = this.state.section_id;
        // values.selected_file = this.state.selected_file;
        // values.cat_id = this.state.cat_id;
        // values.file_type = this.state.file_type;
        fetch(`${ApiUrl}` + "edit/resource/content", {
            method: "POST",
            headers: {
                // Accept: "application/json",
                // "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {


                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }

                    // this.getData();
                    this.props.history.goBack();
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

                this.setState({ loader: true });

            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });
                console.error("Error:", error);
            });
    };

    render() {
        return (
            <ErrorBoundary>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ResourcesSidebar menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4>{this.state.page_name}</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">
                                            {ProductName}
                                        </li>
                                        <li key={1} className="breadcrumb-item">
                                            <Link to="#">{this.state.page_name}</Link>
                                        </li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box text-align-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <ToastContainer />

                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Title: {this.state.recordall.title}</CardTitle>

                                        <form onSubmit={this.handleSubmit} method="post"
                                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                            <div className="form-group mb-3">
                                                <input
                                                    name="title"
                                                    label="Title"
                                                    className="form-control"
                                                    placeholder="Enter Title"
                                                    required
                                                    defaultValue={this.state.recordall.title}
                                                />
                                            </div>

                                            <div className="form-group mb-3">
                                                <textarea
                                                    name="short_description"
                                                    label="Description"
                                                    className="form-control"
                                                    placeholder="Enter description"
                                                    defaultValue={this.state.recordall.short_description}
                                                    rows={4}
                                                />
                                            </div>
                                            <div className="mb-3" >
                                                <SunEditor name="content"
                                                    setOptions={
                                                        {
                                                            height: 600,
                                                            width: '100%',
                                                            resizeEnable: 'false',
                                                            // height: 400,
                                                            buttonList: [
                                                                ["undo", "redo", "font", "fontSize", "formatBlock"],
                                                                [
                                                                    "bold",
                                                                    "underline",
                                                                    "italic",
                                                                    "strike",
                                                                    "subscript",
                                                                    "superscript",
                                                                    "removeFormat",
                                                                ],
                                                                [
                                                                    "fontColor",
                                                                    "hiliteColor",
                                                                    "outdent",
                                                                    "indent",
                                                                    "align",
                                                                    "horizontalRule",
                                                                    "list",
                                                                    "table",
                                                                ],
                                                                [
                                                                    "link",
                                                                    "image",
                                                                    "video",
                                                                    "fullScreen",
                                                                    "showBlocks",
                                                                    "codeView",
                                                                    "preview",
                                                                ],

                                                            ],
                                                            proportion: 'Constrain proportions assasa',
                                                        }
                                                    }
                                                    setContents={this.state.recordall.content}
                                                    onChange={this.handleChange}
                                                // appendContents={this.state.recordall.content}
                                                />

                                                {/* <AvField name="body"
                          value={this.state.editor}
                          type="hidden"
                          required /> */}
                                            </div>
                                            <ModalFooter>
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    onClick={() => this.props.history.goBack()}
                                                    disabled={this.state.loader ? false : true}
                                                >
                                                    Cancel
                                                </Button>
                                                <button
                                                    className="btn btn-primary waves-effect waves-light"
                                                    type="submit"
                                                    disabled={this.state.loader ? false : true}
                                                >
                                                    Submit
                                                </button>
                                            </ModalFooter>
                                        </form>
                                        {/* <div dangerouslySetInnerHTML={{ __html: this.state.recordall.content }} /> */}
                                    </CardBody>

                                </Card>

                                {/* <iframe width="100%" height="315" src={this.state.content_url} frameborder="0" allowfullscreen></iframe> */}
                                {/* 
                <ReactPlayer
                  width="100%"
                  url={this.state.content_url}
                  playing={this.state.playing}
                  onError={(e) => console.log("onError", e)}
                  onStart={() => console.log("onStart")}
                  controls={true}
                /> */}
                                {/* <div className="button-center" style={{textAlign: 'center'}}>
                  <button
                    className="btn btn-primary btn-sm mb-1 mr-50 mt-3"
                    onClick={this.handlePlayPause}
                  >
                    {this.state.playing ? "Pause" : "Play"}
                  </button>
                </div> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ErrorBoundary>
        );
    }
}
