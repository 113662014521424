import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Badge from 'react-bootstrap/Badge';
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { bcrypt, hasRole, isAllowed, uInfo, uRole, uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ProfileSettings from "../Authentication/profile-sidebar";
import swal from 'sweetalert';
export default class BlockedIp extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Id#",
                key: "id",
                sortable: false,
            },
            {
                text: "IP Address",
                key: "ip",
                sortable: false,
            },
            {
                text: "Submission Date",
                key: "created_at",
                sortable: false,
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                           

                            <button
                                type="button"
                                color="info"
                                className="btn btn-primary btn-sm mb-1 mx-1"
                                onClick={this.getIpRequestDetail.bind(this, record, index)}
                                title="Change status"

                            >
                                <i class="fas fa-eye"></i>
                            </button>
                            

                            <button
                                className="btn btn-danger btn-sm mb-1 ml-1"
                                onClick={this.deleteRecord.bind(this, record, index)}>
                                <i className="fas fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            sort: { column: "total_lead", order: "desc" },
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
        this.state = {
            records: [],
            total_pages: 0,
            lists: [],
            page_name: "Blocked IP ",
            filter: {},
            customDate: true,
            leads: [],
            approvalModal: false,
            approvalUser: {},
            approvalStatus: '',
        };
    }

    componentDidMount() {
        this.getData();
    }

    getIpRequestDetail = (record, index) => {
        fetch(`${ApiUrl}` + "get/ip/request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(record),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        lists: data.data.records,
                        approvalModal: true,
                        approvalUser: record,
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    changeApprovalStatus = (status) => {
        let record = this.state.approvalUser;

        swal({
            title: "Are you sure?",
            text: 'You want to perform this action',
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "update/domain/status", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ user_id: record.user_id, status: status, id: record.id })
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                this.setState({
                                    approvalUser: {},
                                    approvalModal: false
                                })
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                } else {
                    this.setState({
                        approvalUser: {},
                        approvalModal: false
                    })
                }
            });
    };



    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/blocked/ip" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        total_pages: data.data.total,
                        records: data.data.records,
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to perform this action",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {

                    fetch(`${ApiUrl}` + "unblock/ip/" + record.ip, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ` + uToken(),
                        },
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }


    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };
    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                    // return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} List</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">{ProductName}</li>
                                        <li key={1} className="breadcrumb-item">
                                            <Link to="#">{this.state.page_name}  List </Link>
                                        </li>
                                    </ol>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody className="pb-0">
                                        <AvForm
                                            onValidSubmit={this.handleFilterSubmit}
                                            ref={(c) => (this.form = c)}
                                            className="needs-validation"
                                        >
                                            <Row>
                                                <Col md="3">
                                                    <div className="mb-0">
                                                        <AvField
                                                            className="select form-control"
                                                            type="select"
                                                            name="date_range"
                                                            label="Date Filter"
                                                            onChange={(e) =>
                                                                e.target.value == "Custom Date"
                                                                    ? this.setState({
                                                                        customDate: false,
                                                                    })
                                                                    : this.setState({
                                                                        customDate: true,
                                                                    })
                                                            }
                                                        >
                                                            <option value=""> All Time </option>
                                                            <option value="Current Week"> Current Week </option>
                                                            <option value="Last Week"> Last Week </option>
                                                            <option value="Current Month"> Current Month </option>
                                                            <option value="Last Month"> Last Month </option>
                                                            <option value="Last 3 Months"> Last 3 Months </option>
                                                            <option value="Last 6 Months"> Last 6 Months </option>
                                                            <option value="Current Year"> Current Year </option>
                                                            <option value="Last Year"> Last Year </option>
                                                            <option value="Custom Date"> Custom Date </option>
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-0">
                                                        <AvField
                                                            name="start_date"
                                                            label="Start date"
                                                            className="form-control"
                                                            placeholder="Enter Email Address"
                                                            type="date"
                                                            disabled={this.state.customDate}
                                                            required={!this.state.customDate}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-0">
                                                        <AvField
                                                            name="end_date"
                                                            label="End date"
                                                            className="form-control"
                                                            placeholder="Enter Email Address"
                                                            type="date"
                                                            disabled={this.state.customDate}
                                                            required={!this.state.customDate}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="button-items mt-2">
                                                        <label className="w-100"></label>
                                                        <Button className="btn-sm" color="primary" type="submit">
                                                            Filter
                                                        </Button>
                                                        <Button
                                                            onClick={() => this.cancelFilter()}
                                                            outline
                                                            color="secondary"
                                                            className="waves-effect btn-sm"
                                                            type="button"
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <ToastContainer />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody className="pt-0">

                                        {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            dynamic={false}
                                            total_record={this.state.total_pages}
                                            onChange={this.tableChangeHandler}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal
                    isOpen={this.state.approvalModal}
                    fade={false}
                    toggle={() => this.setState({ approvalModal: false })}
                    size="xl"
                >
                    <ModalBody>
                       <table className="table table-stripped">
                        <thead>
                            <th>Id</th>
                            <th>Email</th>
                            <th>Domain Owner Email</th>
                            <th>Domain</th>
                            <th>Date</th>
                        </thead>
                        <tbody>
                            {
                                this.state.lists.map((row , i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{row.id}</td>
                                            <td>{row.email}</td>
                                            <td>{row.coach_email}</td>
                                            <td>{row.coach_domain}</td>
                                            <td>{row.created_at}</td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                       </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.setState({ approvalModal: false })}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

            </React.Fragment>
        );
    }
}
