import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import Helmet from "react-helmet"



// Redux
import { Link } from "react-router-dom"

// import images
import { ProductName, logoLightPng } from "../../config";

const Register = props => {
    // handleValidSubmit
    const handleValidSubmit = (event, values) => {
        console.log(values)
    }

    return (
        <React.Fragment>
            <Helmet>
                <title> Register </title>
            </Helmet>


            <div className="account-pages my-5 pt-sm-5" >

                <Container >

                    <Row className="justify-content-center" >

                        <Col md={8}
                            lg={6}
                            xl={5} >

                            <Card className="overflow-hidden" >

                                <CardBody className="pt-0" >

                                    <h3 className="text-center mt-5 mb-4" >

                                        <Link to="/"
                                            className="d-block auth-logo" >

                                            <img src={logoLightPng}
                                                alt=""
                                                height="50"
                                                className="auth-logo-dark" />
                                        </Link>
                                    </h3>
                                    <div className="p-3" >
                                        <h4 className="text-muted font-size-18 mb-1 text-center" > Free Register </h4>
                                        <p className="text-muted text-center" > Get your free {ProductName}
                                            account now. </p>
                                        <AvForm className="form-horizontal mt-4"
                                            onValidSubmit={
                                                (e, v) => {
                                                    handleValidSubmit(e, v)
                                                }
                                            }>
                                            {
                                                props.user && props.user ? (<Alert color="success" >
                                                    Register User Successfully </Alert>
                                                ) : null
                                            }

                                            {
                                                props.registrationError &&
                                                    props.registrationError ? (<Alert color="danger" > {props.registrationError} </Alert>
                                                ) : null
                                            }

                                            <div className="mb-3" >
                                                <AvField id="email"
                                                    name="email"
                                                    label="Email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3" >
                                                <AvField name="username"
                                                    label="Username"
                                                    type="text"
                                                    required placeholder="Enter username"
                                                />
                                            </div>
                                            <div className="mb-3" >
                                                <AvField name="password"
                                                    label="Password"
                                                    type="password"
                                                    required placeholder="Enter Password"
                                                />
                                            </div>

                                            <div className="mb-3 row mt-4" >
                                                <div className="col-12 text-end" >
                                                    <button className="btn btn-primary waves-effect waves-light"
                                                        type="submit" > Register
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mb-0 row" >
                                                <div className="col-12 mt-4" >
                                                    <p className="text-muted mb-0 font-size-14" >
                                                        By registering you agree to the PG Virtual Office
                                                        <Link to="#"
                                                            className="text-primary" > Terms of Use </Link></p>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center" >
                                <p>
                                    Already have an account ? {" "}
                                    <Link to="/login"
                                        className="text-primary" > {" "}
                                        Login
                                    </Link>{" "}
                                </p>
                                <p> ©{new Date().getFullYear()} {ProductName}
                                    <span className="d-none d-sm-inline-block" > </span>
                                    { /* <span className="d-none d-sm-inline-block"> - Created <i className="mdi mdi-heart text-danger"></i> by Zamsol.</span> */}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Register