import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
export default class smsTemplatesAddEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ?
                dcrypt(this.props.match.params.id) : null,
            permissions: [],
            record: {
                title: "",
                subject: "",
                body: "",
                type: "SMS",
            },
            selected_file: "",
            page_name: this.props.match.params.id ?
                "Edit SMS Template" : "Create SMS Template",
            customfields: [],

            field: "",
            appendContents: "",
            editor: "",
            smstokens: [],
            default_fields: [],
            contentType: 'SMS'
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.state.id != null && this.getData();
        this.onChangeTypeAction(this.state.record.type);
        this.getcoachdata();
    }
    changeHandler = (e) => {

        if (e.target.files[0].size < 5036070 && e.target.files[0].type.match('image.*')) {
            let selected_file = [];
            var reader = new FileReader();
            reader.onload = () => {
                selected_file.push(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);
            this.setState({
                selected_file: selected_file,
            });
        }else{
          toast('Invalid file', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
    };

    getcoachdata = () => {
        fetch(`${ApiUrl}` + "get/coachdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        default_fields: this.parsecustomfields(
                            response.data.record.default_fields
                        ),
                        permissions: response.data.coachpartners,
                    });

                    var custom_fields = this.parsecustomfields(
                        response.data.record.custom_fields
                    );
                    custom_fields.map((field) => {
                        this.setState({
                            customfields: [...this.state.customfields, field],
                        });
                    });

                    this.setState({
                        smstokens: [{
                            label: "Default Fields",
                            options: this.state.default_fields,
                        },
                        {
                            label: "Custom Fields",
                            options: this.state.customfields,
                        },
                        ],
                    });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    parsecustomfields = (data) => {
        return data.map((customfield) => {
            return { label: customfield.label, value: customfield.key };
        });
    };

    getData = () => {
        fetch(`${ApiUrl}` + "get/editsmstemplate", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        record: response.data.record,
                        // inputList: JSON.parse(response.data.record.description)
                    });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleSubmit = async (event, values) => {
        values.body = this.state.editor;
        values.media_file = this.state.selected_file;
        if (this.state.id) {
            values.id = this.state.record.id ? this.state.record.id : -1;
        }
        console.log(values, "debug");
        fetch(`${ApiUrl}` + "edit/smstemplate", {
            method: "POST",
            contentType: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            files: true,
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                        this.setState({
                            editor: "",
                        });
                    }
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    this.props.history.goBack();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    changeEditor = (field) => {
        this.setState({
            field: field,
        });

        this.appendcontent(field.value);
    };
    appendcontent = (value) => {
        this.setState({
            editor: this.state.editor + "{" + value + "}",
        });
    };

    handleChange = (e) => {
        this.setState({
            editor: e.target.value,
        });
    };
    onChangeTypeAction = (value) => {
        var x = document.getElementById("mediafile_field");
        if (value == "MMS") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }

        this.setState({
            contentType: value
        })
    };

    handlePermission = (selectedPermission) => {
        var filter = [];
        if (selectedPermission) {
            selectedPermission.map((fil) => {
                filter.push(fil.user_id);
            });
        }

        this.setState({
            permission: filter.toString(),
            selectedPermission: selectedPermission,
        });
    };

    render() {
        return (
            <React.Fragment >
                <Helmet >
                    <title > {this.state.page_name}Tables
                    </title>
                </ Helmet >
                <Row>
                    <Col sm={6} >
                        <div className="page-title-box" >
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0" >
                                <li key={0}
                                    className="breadcrumb-item active" >{ProductName} </li>
                                <li key={1}
                                    className="breadcrumb-item" >
                                    <Link to="#" > {this.state.page_name} </Link>
                                </ li >
                            </ol>
                        </div >
                    </Col>
                    <Col sm={6} >
                        <div className="page-title-box text-align-right" >
                            <Button type="button"
                                color="secondary"
                                onClick={
                                    () => this.props.history.goBack()
                                } >
                                Back
                            </Button>
                        </div >
                    </Col>
                </Row>
                <ToastContainer />
                <Row>
                    <Col className="col-12" >
                        <Card >
                            <AvForm onValidSubmit={this.handleSubmit}
                                model={this.state.record}
                                ref={
                                    (c) => (this.form = c)
                                }
                                className="needs-validation"
                                enctype="multipart/form-data" >
                                <ModalBody >
                                    <div className="mb-3" >
                                        <AvField name="name"
                                            value={this.state.record.name}
                                            label="Name"
                                            className="form-control"
                                            required />
                                    </div>
                                    <div className="mb-3" >
                                        <label > Type </label>
                                        <AvRadioGroup value={this.state.record.type}
                                            inline name="type"
                                            onChange={
                                                (e) => this.onChangeTypeAction(e.target.value)
                                            } >
                                            <AvRadio label="SMS"
                                                value="SMS" />
                                            <AvRadio label="MMS"
                                                value="MMS" />
                                        </AvRadioGroup>
                                    </div>
                                    <div id="mediafile_field" >
                                        <div className="mb-3" >
                                            <AvField name="media_file"
                                                value={this.state.record.media_file}
                                                label="Media File"
                                                className="form-control"
                                                type="file"
                                                accept="image/*"
                                                required={this.state.contentType == 'MMS' ? true : false}
                                                onChange={
                                                    (e) => this.changeHandler(e)
                                                }
                                            />
                                            <p className="text-info">
                                                Note: Maximum file size 5 MB - gif, jpeg, png content files fully supported, content types outside of these are not fully supported.
                                            </p>
                                        </div >
                                    </div>
                                    <div className="mb-3" >
                                        <AvGroup >
                                            <label > Token </label>
                                            <Select getOptionLabel={
                                                (option) => option.label
                                            }
                                                getOptionValue={
                                                    (option) => option.value
                                                }
                                                isMulti={false}
                                                options={this.state.smstokens}
                                                classNamePrefix="select form-control"
                                                style={
                                                    { position: "absolute" }
                                                }
                                                className={"is-touched is-dirty av-valid"}
                                                name="field"
                                                onChange={
                                                    (e) => {
                                                        this.changeEditor(e);
                                                    }
                                                }
                                            />
                                        </AvGroup>
                                    </div>
                                    <div className="mb-3" >
                                        <AvField name="body"
                                            value={this.state.editor}
                                            label="Body"
                                            className="form-control"
                                            type="textarea"
                                            required rows={5}
                                            //  appendContents={this.state.appendContents}

                                            onChange={
                                                (e) => this.handleChange(e)
                                            }
                                            cols={5}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3 d-none" >
                                        <label className="control-label" > Assign to </label>
                                        <Select getOptionLabel={
                                            (option) => option.name
                                        }
                                            getOptionValue={
                                                (option) => option.user_id
                                            }
                                            isMulti={true}
                                            value={this.state.selectedPermission}
                                            options={this.state.permissions}
                                            classNamePrefix="select form-control"
                                            style={
                                                { position: "absolute" }
                                            }
                                            className={"is-touched is-dirty av-valid"}
                                            onChange={
                                                (e) => {
                                                    this.handlePermission(e);
                                                }
                                            }
                                        />
                                        <AvField type="hidden"
                                            name="permission"
                                            value={this.state.permission}
                                        />
                                    </div>
                                </ModalBody>
                                <ModalFooter >
                                    <Button type="button"
                                        color="secondary"
                                        onClick={
                                            () => this.props.history.goBack()
                                        } >
                                        Cancel </Button>
                                    <button className="btn btn-primary waves-effect waves-light"
                                        type="submit" >
                                        Submit </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row >
                { /* /////////////////////////////////////////////// */}
            </React.Fragment>
        );
    }
}