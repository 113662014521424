import React, { useState } from 'react';
import TopBar from "./TopBar";
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';


const DialPad = () => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleButtonClick = (value) => {
    setPhoneNumber(prev => prev + value);
  };

  const handleCall = () => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      alert("Please enter a valid phone number.");
    }
  };

  const handleClear = () => {
    setPhoneNumber('');
  };

  const renderButton = (value) => (
    <button onClick={() => handleButtonClick(value)} className="dial-button">
      {value}
    </button>
  );

  return (
    <Container>
    <div className="dialpad">
    <div className="AddLead CallLog"><Link>Call logs</Link></div>
    <div className='position-ralative'>
      <input
        type="tel"
        placeholder="(98) 9016714574"
        value={phoneNumber}
        readOnly
        className="phone-input text-center"
      />
       <button onClick={handleClear} className="border-0">
       <svg
      fill="#fff"
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      id="cross"
      data-name="Flat Color"
      xmlns="http://www.w3.org/2000/svg"
      className="icon flat-color CrossSign"
    >
      <path
        id="primary"
        d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/>
    </svg>
    </button>
      
      </div>
      <div className="AddLead"><Link>Add Lead</Link></div>
      <div className="button-grid">
        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map((value) => renderButton(value))}
        <button className='border-0'></button>
        <button onClick={handleCall} className="call-button">
        <svg
      width="25px"
      height="25px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      className="si-glyph si-glyph-call"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <title>Call Icon</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M14.031,11.852 C13.603,11.313 12.908,10.532 12.313,10.458 C11.951,10.413 11.535,10.713 11.125,10.996 C11.045,11.036 10.427,11.404 10.352,11.426 C9.956,11.539 9.111,11.572 8.6,11.106 C8.108,10.656 7.33,9.823 6.702,9.06 C6.102,8.274 5.473,7.329 5.151,6.749 C4.815,6.148 5.057,5.353 5.265,5.003 C5.303,4.94 5.763,4.467 5.866,4.357 L5.881,4.375 C6.262,4.055 6.661,3.73 6.706,3.378 C6.78,2.792 6.181,1.939 5.753,1.399 C5.325,0.858 4.662,-0.089 3.759,0.045 C3.419,0.095 3.126,0.214 2.837,0.385 L2.829,0.376 C2.823,0.38 2.795,0.402 2.781,0.413 C2.772,0.418 2.764,0.421 2.756,0.426 L2.759,0.43 C2.593,0.558 2.119,0.912 2.065,0.96 C1.479,1.481 0.597,2.708 1.279,4.915 C1.785,6.555 2.864,8.481 4.334,10.429 L4.326,10.436 C4.398,10.53 4.472,10.615 4.547,10.706 C4.617,10.799 4.686,10.891 4.758,10.983 L4.768,10.976 C6.328,12.855 7.964,14.357 9.457,15.243 C11.467,16.435 12.896,15.898 13.556,15.471 C13.618,15.43 14.09,15.063 14.25,14.942 L14.254,14.946 C14.26,14.94 14.264,14.932 14.272,14.926 C14.284,14.917 14.31,14.897 14.315,14.893 L14.309,14.885 C14.551,14.651 14.745,14.401 14.879,14.086 C15.23,13.257 14.459,12.393 14.031,11.852 L14.031,11.852 Z"
            fill="#ffffff"
            className="si-glyph-fill"
          />
        </g>
      </g>
    </svg>
        </button>
        <button onClick={handleClear} className="border-0"><i className='mdi mdi-backspace'></i></button>
      </div>
      <div className='d-flex'>
      </div>
    </div>
    </Container>
  );
};

const CallDial = () => {
  return (
    <>
    <TopBar/>
    <div style={{ padding: '20px', background: '#fff' }}>
      <DialPad />
    </div>
    </>
  );
};

export default CallDial;
