import React, { Component } from "react";
import $ from "jquery";
import Froala from "../Froala";
import EmailSideBar from "./email-sidebar";
import Select from 'react-select';
import { toast, Flip } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from 'react-bootstrap-sweetalert';
import { bcrypt, uInfo, uToken, } from "../../useToken";
import { ApiUrl } from "../../config";
import { Row, Col, Card, Button, CardBody } from "reactstrap";
class EmailCompose extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            existing: [],
            isClient: true,
            selectedEmail: '',
            show: false,
            verifiedFromEmail: [],
            defaultFromEmail: null,
            verifiedFromEmailId: '',
            smsTemplates: [],
            tokens: [],
            subject: '',
            ccEmails: [],
            bccEmails: [],
            ccSearchValue: '',
            showCC: false,
            showBCC: false,
            bccSearchValue: '',
            existingCC: [],
            existingBCC: [],
            smsBody: '',
            token: '',
            selectedClient: {},
            html: '',
            folder_Menu: false,
            tag_Menu: false,
            more_Menu: false,
            showAlert: false,
            alertType: '',
            alertTitle: '',
            alertText: '',
            files: [],
        };

    }
    componentDidMount() {
        this.getData();
        this.getFormData();
        this.getEmailTemplate();
        this.getVerifiedFromEmail();
    }


    toggleSearch = () => {
        this.setState((prevState) => ({ isSearch: !prevState.isSearch }));
    };

    toggleShow = () => {
        this.setState((prevState) => ({ show: !prevState.show }));
    };
    handleSelectClient = (row) => {
        this.setState({
            selectedClient: row,
            existing: []
        }, () => {
            console.log('State updated:', this.state);
        });
    }

    handleInputChange = (e) => {
        const value = e.target.value;
        this.setState({ selectedClient: value }, () => {
            this.searchClient(value);
        });
    }
    searchCCClient = (search) => {
        if (search !== "") {
            fetch(`${ApiUrl}` + "get/clients", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ filter_value: search }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        this.setState({
                            existingCC: response.data.records,
                        });
                    } else {
                        toast(response.message, {
                            hideProgressBar: true,
                            position: "top-center",
                            transition: Flip,
                            className: 'toast-custom-cls error',
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            this.setState({
                existingCC: [],
            });
        }
    };

    searchBCCClient = (search) => {
        if (search !== "") {
            fetch(`${ApiUrl}` + "get/clients", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ filter_value: search }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        this.setState({
                            existingBCC: response.data.records,
                        });
                    } else {
                        toast(response.message, {
                            hideProgressBar: true,
                            position: "top-center",
                            transition: Flip,
                            className: 'toast-custom-cls error',
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            this.setState({
                existingBCC: [],
            });
        }
    };

    handleCCInputChange = (e) => {
        const value = e.target.value;
        this.setState({ ccSearchValue: value }, () => {
            this.searchCCClient(value);
        });
    };

    handleBCCInputChange = (e) => {
        const value = e.target.value;
        this.setState({ bccSearchValue: value }, () => {
            this.searchBCCClient(value);
        });
    };



    handleEmailSubmit = (event) => {
        event.preventDefault();

        if (!this.state.selectedClient || !this.state.selectedClient.email || !this.state.selectedClient.client_id) {
            this.setState({
                showAlert: true,
                alertType: 'error',
                alertTitle: 'Error',
                alertText: 'Please select a valid email.',
            });
            return;
        }

        const formData = new FormData();
        formData.append('tos', this.state.selectedClient.email);
        formData.append('cc', this.state.ccEmails.join(","));
        formData.append('bcc', this.state.bccEmails.join(","));
        formData.append('client_id', this.state.selectedClient.client_id);
        formData.append('type', 'email');
        formData.append('setting_id', this.state.verifiedFromEmailId);
        formData.append('subject', this.state.subject);
        formData.append('body', this.state.html !== "" ? document.getElementById("custom-template").innerHTML : this.state.smsBody);

        if (this.state.files.length > 0) {
            this.state.files.forEach(file => {
                formData.append('fileAttachments[]', file);
            });
        }

        fetch(`${ApiUrl}company/email/activity`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${uToken()}`,
            },
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.setState({
                        showAlert: true,
                        alertType: 'success',
                        alertTitle: 'Success',
                        alertText: 'Email sent successfully.',
                    });
                    setTimeout(() => {
                        this.props.history.push('/email/sent');
                    }, 2000);
                } else {
                    this.setState({
                        showAlert: true,
                        alertType: 'error',
                        alertTitle: 'Error',
                        alertText: data.message,
                    });
                }
            })
            .catch(error => {
                console.error("Error:", error);
                this.setState({
                    showAlert: true,
                    alertType: 'error',
                    alertTitle: 'Error',
                    alertText: 'An error occurred while sending the email.',
                });
            });
    };

    handleSelectClientForCC = (row) => {
        const { ccEmails } = this.state;
        this.setState({
            ccEmails: [...ccEmails, row],
            existingCC: [],
            ccSearchValue: '',
        });
    };

    handleSelectClientForBCC = (row) => {
        const { bccEmails } = this.state;
        this.setState({
            bccEmails: [...bccEmails, row],
            existingBCC: [],
            bccSearchValue: '',
        });
    };

    searchClient = (search) => {
        if (search !== "") {
            fetch(`${ApiUrl}` + "get/clients", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ filter_value: search }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        this.setState({
                            existing: response.data.records,
                            isClient: response.data.records.length > 0,
                        });
                    } else {
                        toast(response.message, {
                            hideProgressBar: true,
                            position: "top-center",
                            transition: Flip,
                            className: 'toast-custom-cls error',
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            this.setState({
                existing: [],
                isClient: true,
            });
        }
    };

    handleBody = async (event, values) => {
        console.log('Values:', values);
        this.setState({
            smsBody: values,
        });
    }
    handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        this.setState(prevState => ({
            files: [...prevState.files, ...selectedFiles]
        }));
    };
    removeFile = (index) => {
        this.setState(prevState => {
            const updatedFiles = prevState.files.filter((file, i) => i !== index);
            return { files: updatedFiles };
        });
    };
    getEmailTemplateHtml = (id) => {
        fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((response) => {
                this.setState({ html: response.theme })
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getFormData = () => {
        fetch(`${ApiUrl}get/quick/dialer`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        quickDialer: response.data.records,
                        phoneNumbers: response.data.phoneNumbers,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    getData = (queryString = "", data) => {
        const query = queryString === "" ? "?lead_type=client&linked_user_id=0" : queryString + "&lead_type=client";
        fetch(`${ApiUrl}leads${query}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        customFieldData: { client_ids: [] },
                        coachPartners: response.data.coach_partners
                    });
                    $('input[type="checkbox"]:checked').prop('checked', false);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };
    getSmsTemplate = () => {
        fetch(`${ApiUrl}get/sms/template`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    const templates = this.groupArrayOfObjects(data.data.templates, "type");
                    const templates_group = [
                        { label: "SMS", options: templates.SMS },
                        { label: "MMS", options: templates.MMS },
                    ];

                    const tokens_group = [
                        {
                            label: "Default Field",
                            options: data.data.tokens.default_fields,
                        },
                        {
                            label: "Custom Field",
                            options: data.data.tokens.custom_fields,
                        },
                    ];

                    this.setState({
                        smsTemplates: templates_group,
                        tokens: tokens_group,
                        phoneNumbers: data.data.phoneNumbers
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    getEmailTemplate = () => {
        fetch(`${ApiUrl}get/email/template`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    const tokens_group = [
                        {
                            label: "Default Field",
                            options: data.data.tokens.default_fields,
                        },
                        {
                            label: "Custom Field",
                            options: data.data.tokens.custom_fields,
                        },
                    ];

                    this.setState({
                        tokens: tokens_group,
                        smsTemplates: data.data.templates
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    getVerifiedFromEmail = () => {
        fetch(`${ApiUrl}verify/email/list`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    const defaultFromEmail = data.data.records.filter(item => item.is_default === 1);
                    this.setState({
                        verifiedFromEmail: data.data.records,
                        defaultFromEmail: defaultFromEmail,
                        verifiedFromEmailId: defaultFromEmail[0]?.setting_id
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    groupArrayOfObjects = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            return result;
        }, {});
    };
    toggleCC = () => {
        this.setState(prevState => ({ showCC: !prevState.showCC }));
    };

    toggleBCC = () => {
        this.setState(prevState => ({ showBCC: !prevState.showBCC }));
    };

    handleAlertConfirm = () => {
        this.setState({ showAlert: false });
    };
    render() {
        const { selectedClient, existing, isClient, showAlert, alertType, alertTitle, alertText, ccEmails, bccEmails, showCC, showBCC } = this.state;

        return (

            <React.Fragment>
                <style>
                    {`.froala #edit { padding: 0px;  }`}
                </style>
                <Row>
                    <div>
                        {showAlert && (
                            <SweetAlert
                                type={alertType}
                                title={alertTitle}
                                onConfirm={this.handleAlertConfirm}
                            >
                                {alertText}
                            </SweetAlert>
                        )}
                    </div>

                    <Col xs="12">
                        <EmailSideBar />

                        <div className="email-rightbar mb-3 1">
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleEmailSubmit} autoComplete="off" className="needs-validation">
                                        <div className="col-md-12">
                                            <div className="MainTZone ClientDetails Two 6">
                                                <label>From Email</label>
                                                <Select
                                                    getOptionLabel={(option) => `${option.from_name} - ${option.from_email} - ${option.type}`}
                                                    getOptionValue={(option) => option.setting_id}
                                                    isMulti={false}
                                                    options={this.state.verifiedFromEmail}
                                                    classNamePrefix="select2-selection"
                                                    placeholder={"Select From Email"}
                                                    value={this.state.defaultFromEmail}
                                                    name="froms"
                                                    onChange={(option) => {
                                                        this.setState({
                                                            defaultFromEmail: option,
                                                            verifiedFromEmailId: option.setting_id
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                        <label>To Email</label>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                
                                                <div style={{ flex: 1 }}>
                                                    <AvField
                                                        style={{ borderColor: 'hsl(0, 0%, 80%)', borderWidth: '1px', borderStyle: 'solid' }}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        value={selectedClient.email}
                                                        required
                                                        name="tos"
                                                        onChange={this.handleInputChange}
                                                        onBlur={() =>
                                                            setTimeout(() => {
                                                                this.setState({ existing: [] });
                                                            }, 1000)
                                                        }
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    <button
                                                        type="button"
                                                        onClick={this.toggleCC}
                                                        style={{
                                                            margin: '8px 10px 0 8px',
                                                            background: 'transparent',
                                                            border: '1px solid #d3d3d3',
                                                            borderRadius: '4px',
                                                            color: '#1a73e8',
                                                            cursor: 'pointer',
                                                            padding: '6px 10px',
                                                            fontSize: '14px',
                                                            transition: 'background-color 0.2s',
                                                        }}
                                                        onMouseEnter={e => (e.currentTarget.style.backgroundColor = '#f1f1f1')}
                                                        onMouseLeave={e => (e.currentTarget.style.backgroundColor = 'transparent')}
                                                    >
                                                        Cc
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={this.toggleBCC}
                                                        style={{
                                                            margin: '8px 0 0 0',
                                                            background: 'transparent',
                                                            border: '1px solid #d3d3d3',
                                                            borderRadius: '4px',
                                                            color: '#1a73e8',
                                                            cursor: 'pointer',
                                                            padding: '6px 10px',
                                                            fontSize: '14px',
                                                            transition: 'background-color 0.2s',
                                                        }}
                                                        onMouseEnter={e => (e.currentTarget.style.backgroundColor = '#f1f1f1')}
                                                        onMouseLeave={e => (e.currentTarget.style.backgroundColor = 'transparent')}
                                                    >
                                                        Bcc
                                                    </button>
                                                </div>

                                            </div>
                                            <ul style={{ position: 'static' }} className="client-search-bar">
                                                {!isClient && <li className="text-primary">No data found</li>}
                                                {existing.map((row, i) => (
                                                    <li key={i} onClick={() => this.handleSelectClient(row)}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="info me-3">
                                                                <i className="fas fa-user me-1"></i>
                                                                {row.first_name} {row.last_name}
                                                            </div>
                                                            <div className="info d-flex align-items-center">
                                                                <i className="fas fa-envelope me-1"></i>
                                                                {row.email}
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>



                                            {showCC && (
                                                <div style={{ marginBottom: '10px' }}>
                                                    <label>Cc Email</label>
                                                    <Select
                                                        isMulti
                                                        name="cc"
                                                        options={this.state.existingCC.map(row => ({
                                                            value: row.email,
                                                            label: `${row.first_name} ${row.last_name} - ${row.email}`
                                                        }))}
                                                        value={ccEmails.map(email => ({
                                                            value: email,
                                                            label: email
                                                        }))}
                                                        onChange={selectedOptions => {
                                                            const selectedEmails = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                            this.setState({ ccEmails: selectedEmails });
                                                        }}
                                                        onInputChange={this.searchCCClient}
                                                        placeholder="Search CC emails..."
                                                        classNamePrefix="select2-selection"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderColor: 'hsl(0, 0%, 80%)',
                                                                borderWidth: '1px',
                                                                borderStyle: 'solid',
                                                                borderRadius: '4px',
                                                                minHeight: '38px'
                                                            }),
                                                            multiValue: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: '#e0e0e0',
                                                            }),
                                                            multiValueLabel: (provided) => ({
                                                                ...provided,
                                                                color: '#000',
                                                            }),
                                                            multiValueRemove: (provided) => ({
                                                                ...provided,
                                                                color: '#000',
                                                                ':hover': {
                                                                    backgroundColor: '#f00',
                                                                    color: 'white',
                                                                },
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {showBCC && (
                                                <div style={{ marginBottom: '10px' }}>
                                                    <label>Bcc Email</label>
                                                    <Select
                                                        isMulti
                                                        name="bcc"
                                                        options={this.state.existingBCC.map(row => ({
                                                            value: row.email,
                                                            label: `${row.first_name} ${row.last_name} - ${row.email}`
                                                        }))}
                                                        value={bccEmails.map(email => ({
                                                            value: email,
                                                            label: email
                                                        }))}
                                                        onChange={selectedOptions => {
                                                            const selectedEmails = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                            this.setState({ bccEmails: selectedEmails });
                                                        }}
                                                        onInputChange={this.searchBCCClient}
                                                        placeholder="Search BCC emails..."
                                                        classNamePrefix="select2-selection"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderColor: 'hsl(0, 0%, 80%)',
                                                                borderWidth: '1px',
                                                                borderStyle: 'solid',
                                                                borderRadius: '4px',
                                                                minHeight: '38px'
                                                            }),
                                                            multiValue: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: '#e0e0e0',
                                                            }),
                                                            multiValueLabel: (provided) => ({
                                                                ...provided,
                                                                color: '#000',
                                                            }),
                                                            multiValueRemove: (provided) => ({
                                                                ...provided,
                                                                color: '#000',
                                                                ':hover': {
                                                                    backgroundColor: '#f00',
                                                                    color: 'white',
                                                                },
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                            )}

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="CommonSelectTwo Zindex">
                                                    <label>Select Template</label>
                                                    <Select
                                                        getOptionLabel={(option) => option.title}
                                                        getOptionValue={(option) => option.id}
                                                        // value={options.filter(({ id }) => id === this.state.country_code)}
                                                        isMulti={false}
                                                        options={this.state.smsTemplates}
                                                        classNamePrefix="select2-selection"

                                                        name="country_id"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                subject: e.title
                                                            })
                                                            this.getEmailTemplateHtml(e.id);


                                                        }}
                                                    />
                                                    <AvField type="hidden" name="activity" value={"Email: sent"} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <div className="NotHeight">
                                                            <label>Select Token</label>
                                                            <div className="CommonSelectTwo NoFixHeight 1">
                                                                <Select
                                                                    getOptionLabel={(option) => option.label}
                                                                    getOptionValue={(option) => option.key}
                                                                    // value={options.filter(({ id }) => id === this.state.country_code)}
                                                                    isMulti={false}
                                                                    options={this.state.tokens}
                                                                    classNamePrefix="select2-selection"
                                                                    name="country_id"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            token: `{${e.key}}`
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* <AvField type="hidden" name="country_code" value={token} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* {this.state.html == "" && (
                                                        <div className="col-md-2">
                                                            <button
                                                                onClick={() => this.setState({ smsBody: `${this.state.smsBody} ${this.state.token}` })}
                                                                type="button"
                                                                className="btn btn-primary"
                                                            >
                                                                Insert Body
                                                            </button>
                                                        </div>
                                                    )} */}
                                        <div className="row">

                                            <div className="col-lg-2">
                                                <button
                                                    onClick={() => this.setState({ subject: `${this.state.subject} ${this.state.token}` })}
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    Insert Subject
                                                </button>
                                            </div>
                                            <div className="col-lg-10">

                                                <div className="mb-3">
                                                    <AvField
                                                        className="form-control"
                                                        type="text"
                                                        name="subject"
                                                        placeholder="Subject"
                                                        required
                                                        value={this.state.subject}
                                                        onChange={(e) => this.setState({ subject: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.html == "" ? (
                                            <div className="mb-3">
                                                {/* <AvField
                                                    className="form-control"
                                                    type="textarea"
                                                    name="body"
                                                    placeholder="Enter body"
                                                    required
                                                    value={this.state.smsBody}
                                                    onChange={(e) => this.setState({ smsBody: e.target.value })}
                                                /> */}

                                                <Froala
                                                    btnId={'add-script-content'}
                                                    handleSubmit={this.handleBody}
                                                    use_token={'users'}

                                                />

                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    id="custom-template"
                                                    className="height-500 custom-scroll"
                                                    dangerouslySetInnerHTML={{ __html: this.state.html }}
                                                ></div>
                                                {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                                            </>
                                        )}
                                        <div className="mb-3">
                                            <label>Attach File(s)</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                multiple
                                                onChange={this.handleFileChange}
                                            />
                                        </div>

                                        {this.state.files.length > 0 && (
                                            <div style={{ marginBottom: '16px' }}>
                                                <h6 style={{ fontSize: '14px', marginBottom: '8px', color: '#5f6368' }}>Attached File(s)</h6>
                                                <ul style={{ padding: 0, listStyleType: 'none' }}>
                                                    {this.state.files.map((file, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                padding: '8px 12px',
                                                                marginBottom: '8px',
                                                                backgroundColor: '#f1f3f4',
                                                                borderRadius: '12px',
                                                                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                                                                maxWidth: '500px',
                                                                position: 'relative',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontSize: '14px',
                                                                    color: '#202124',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    maxWidth: '500px',
                                                                }}
                                                            >
                                                                {file.name}
                                                            </span>
                                                            <button
                                                                type="button"
                                                                onClick={() => this.removeFile(index)}
                                                                style={{
                                                                    border: 'none',
                                                                    background: 'none',
                                                                    cursor: 'pointer',
                                                                    padding: '4px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    position: 'absolute',
                                                                    right: '6px',
                                                                    top: '6px',
                                                                }}
                                                            >
                                                                <span
                                                                    className="mdi mdi-close"
                                                                    style={{ fontSize: '18px', color: '#5f6368' }}
                                                                ></span>
                                                            </button>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}


                                        <div className="my-3 d-flex SubmitBtn justify-content-start text-end">
                                            <Button color="primary" id="add-script-content" type="submit">
                                                Send
                                            </Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
export default EmailCompose;