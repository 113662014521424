import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet"
import { dcrypt, getPages, getPermission, getRoles, isAllowed, uInfo, uRole, uToken } from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Loader from "react-loader";


export default class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {
                title: '',
                body: '',
                meta_value: {},
            },
            page_name: '',
            name: 'Landing Page',
            loader: false
        }
    }

    componentDidMount() {
        this.getData();
    }


    getData = () => {
        fetch(`${ApiUrl}` + 'get/user/meta', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({ meta_key: 'landing_page' }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                console.log(response, 'data')
                if (response.status === true) {
                    this.setState({
                        record: response.data.record,
                        // inputList: JSON.parse(response.data.record.description)
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                this.setState({
                    loader: true
                })

            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({
                    loader: true
                })
                console.error('Error:', error);
            });

    }


    handleSubmit = async (event) => {
        this.setState({
            loader: false
        });
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('meta_key', 'landing_page');
        submitData.append('id', this.state.record.id);

        fetch(`${ApiUrl}` + "update/user/meta", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                this.setState({
                    loader: true
                });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({
                    loader: true
                });
                console.error("Error:", error);
            });
    }


    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.page_name} {this.state.name} </title>
                </Helmet>

                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} {this.state.name}</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                                <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_name} {this.state.name}</Link></li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                            >
                                Back
                            </Button>

                        </div>
                    </Col>
                </Row>
                <div className="dialer">
                    <Loader loaded={this.state.loader}></Loader>
                </div>
                {/* {"heading":"","banner":"","video_url":"","domain":"","favicon":"","title":"","meta_description":"","text_color":"#FFFFFF","overlay_color":"#00803780","thumbnail":"" , "bottom_color": "#00002a" , "top_color": "#008037"} */}
                <Row>
                    <Col className="col-12">
                        <Card>
                            <form method="post" onSubmit={this.handleSubmit}
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label>Meta Title </label>
                                            <input name="title" defaultValue={this.state.record.meta_value.title ?? ''} className="form-control " type="text" placeholder="Enter"></input>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label>Meta description </label>
                                            <textarea name="meta_description" defaultValue={this.state.record.meta_value.meta_description ?? ''} className="form-control " type="text" placeholder="Enter"></textarea>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label>Favicon</label>
                                            <div className="row">
                                                <div className="col-lg-11">
                                                    <input name="favicon" className="form-control"
                                                        accept="image/*"
                                                        type="file" placeholder="https://"
                                                    />
                                                </div>
                                                <div className="col-lg-1">
                                                    {this.state.record.meta_value.favicon != '' && (
                                                        <img width={'50'} id="favicon" src={this.state.record.meta_value.favicon} className="img img-thumbnail" alt="Not Found" />
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label>Upload Thumbnail </label>
                                            <div className="row">
                                                <div className="col-lg-11">
                                                    <input name="thumbnail" className="form-control"
                                                        accept="image/*"
                                                        type="file" placeholder="https://"
                                                    />
                                                </div>
                                                <div className="col-lg-1">
                                                    {this.state.record.meta_value.thumbnail != '' && (
                                                        <img width={'50'} id="thumbnail" src={this.state.record.meta_value.thumbnail} className="img img-thumbnail" alt="Not Found" />
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label>Heading</label>
                                            <textarea name="heading" defaultValue={this.state.record.meta_value.heading ?? ''} className="form-control " type="text" placeholder="Enter"></textarea>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label>Heading Color </label>
                                            {
                                                this.state.record.meta_value.text_color && (
                                                    <input name="text_color" defaultValue={`${this.state.record.meta_value.text_color}`} className="form-control " type="color" placeholder="Enter"></input>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label>Background Banner </label>
                                            <div className="row">
                                                <div className="col-lg-11">
                                                    <input name="banner" className="form-control"
                                                        accept="image/*"
                                                        type="file" placeholder="https://"
                                                    />
                                                </div>
                                                <div className="col-lg-1">

                                                    {this.state.record.meta_value.banner != '' && (
                                                        <img width={'50'} id="banner" src={this.state.record.meta_value.banner} className="img img-thumbnail" alt="Not Found" />
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group mt-2">
                                                    <label>Overlay Color</label>
                                                    {
                                                        this.state.record.meta_value.overlay_color && (
                                                            <input name="overlay_color" defaultValue={this.state.record.meta_value.overlay_color} className="form-control " type="color" placeholder="Enter"></input>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group mt-2">
                                                    <label>Nav Color</label>
                                                    {
                                                        this.state.record.meta_value.top_color && (
                                                            <input name="top_color" defaultValue={this.state.record.meta_value.top_color} className="form-control " type="color" placeholder="Enter"></input>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group mt-2">
                                                    <label>Footer Color</label>
                                                    {
                                                        this.state.record.meta_value.bottom_color && (
                                                            <input name="bottom_color" defaultValue={this.state.record.meta_value.bottom_color} className="form-control " type="color" placeholder="Enter"></input>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label>Video </label>

                                            <input name="video_url" className="form-control"
                                                accept="video/*"
                                                type="file" placeholder="https://"
                                            />

                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="submit" className="btn btn-md btn-primary mt-2 Green"> Update &nbsp;<i className="fas fa-check"></i></button>
                                    </div>
                                </div>
                            </form>
                        </Card>
                    </Col>
                </Row>

                {/* /////////////////////////////////////////////// */}


            </React.Fragment >
        )
    }
}

