import React, { Component, Fragment } from "react";
import {
  Row, Col,
  Card,
  CardBody,
}
  from "reactstrap";
import SectionAccordion from "./SectionAccordion";
import "react-toastify/dist/ReactToastify.css";

import {
  uToken,
  bcrypt, 
  uInfo
} from "../../../../useToken";
import { ApiUrl} from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
export default class smstemplate extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      total_pages: 0,
      records: [],
      id: obj.user_id,
      record: {},
      recordrvm: {},
      page_name: this.props.match.params.id ? "Products" : "Products",
    };
  }
  componentDidMount() { 

    let url = `/resourcesDrive/my-resources/${bcrypt(uInfo().user_id)}`;
    this.props.history.push(url);
    this.getData();
  }
  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/resourcescategories" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
        
          this.setState({
            records: response.data.records,
          });

        } else {
           toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h3>Resources </h3>
                  </CardBody>
                  <Row className="px-2">
                    <div className="accordion">
                      {this.state.records.map((row, i) => {
                        return (
                          <SectionAccordion
                            image={row.image}
                            title={row.title}
                            content={row.title}
                            cat_id={row.cat_id}
                          />
                        );
                      })}
                    </div> 
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
