import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { bcrypt, uToken, uInfo, currentDate } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import swal from "sweetalert";

export default class BookContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      addon: {},
      loader: true,
      page_name: "Pathway To Profit - W Graphics Book",
      modal: false,
      addon_modal: false,
      payment_modal: false,
    };
  }

  componentDidMount() {
    this.getData();
    this.getAddonDetail();
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/pathwaytoProfit/book", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getAddonDetail = () => {
    fetch(`${ApiUrl}` + "get/edit/package", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ package_for: "CoachAddon" }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          this.setState({
            addon: response.data.record,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  submitCustomBook = (event) => {
    this.setState({
      loader: false,
    });
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    console.log("submitData", submitData);

    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "add/custom/book", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            row: {
              id: "",
              title: "",
              description: "",
              custom_title: "",
              image: 0,
              type: "Pdf",
            },
            modal: false,
          });

          this.getData();
        } else {
          swal({
            title: data.message,
            icon: "warning",
          });
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        this.setState({ loader: true });
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });

        console.error("Error:", error);
      });
  };

  submitAudioBook = (event) => {
    event.preventDefault();

    const fileInput = event.target.elements.file;
    const file = fileInput.files[0];

    if (file && file.size > 20 * 1024 * 1024) {
      swal({
        title: "File size must be less than 20MB",
        icon: "warning",
      });
      return;
    }

    // this.setState({
    //   loader: false,
    // });
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    console.log("submitData", submitData);

    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "add/audio/book", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.setState({ addon_modal: false });
          swal({
            title: data.message,
            icon: "warning",
          });
          // this.setState({
          //   row: {
          //     id: "",
          //     title: "",
          //     description: "",
          //     custom_title: "",
          //     image: 0,
          //   },
          //   modal: false,
          // });

          this.getData();
        } else {
          swal({
            title: data.message,
            icon: "warning",
          });
        }
        this.setState({ loader: true });
      })
      .catch((error) => {
        this.setState({ loader: true });

        console.error("Error:", error);
      });
  };

  removeAudioBook = () => {
    console.log("in delete");
    // event.preventDefault();

    this.setState({
      loader: false,
    });

    fetch(`${ApiUrl}` + "remove/audio/book", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          swal({
            title: data.message,
            icon: "warning",
          });
          this.getData();
        } else {
          swal({
            title: data.message,
            icon: "warning",
          });
        }
        this.setState({ loader: true });
      })
      .catch((error) => {
        this.setState({ loader: true });

        console.error("Error:", error);
      });
  };

  resetBook = () => {
    swal({
      title: "Are you sure?",
      text: "You want to use default book.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}` + "add/custom/book", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ pdf_book_type: "Default" }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
              this.getData();
            } else {
              swal({
                title: response.message,
                icon: "warning",
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };

  createInvoice = (event) => {
    event.preventDefault();
    fetch(`${ApiUrl}` + "add/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        package_id: this.state.addon.package_id,
        product_id: "0",
        master_type: "package",
        created_for_id: uInfo().user_id,
        invoice_currency_code: "USD",
        invoice_for: "Coach",
        items_data: [
          {
            items: this.state.addon.name,
            price: this.state.addon.price,
            quantity: "1",
            readOnly: "0",
            total: this.state.addon.price,
          },
        ],
        payment_method: "stripe",
        renewal_interval: "None",
        start_date: currentDate(),
        total_price: this.state.addon.price,
        type: "one_time",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          // this.props.history.push(data.payment_link)
          this.setState({ payment_modal: false });
          this.getData();
          swal({
            title:
              "Invoice has been sent to your email, please pay it to upload audio book",
            icon: "success",
          });

          //   if (this.state.id != null) {
          //     this.props.history.goBack()
          //   } else {
          //     this.props.history.push('/invoice/list')
          //   }
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Loader loaded={this.state.loader}></Loader>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>

              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  {this.state.record?.pdf_book_type != "Custom" ? (
                    <button
                      onClick={() =>
                        this.setState({
                          modal: true,
                        })
                      }
                      className="btn btn-sm btn-info"
                      title="Upload custom PDF Book"
                    >
                      Upload Custom Book
                    </button>
                  ) : (
                    <button
                      onClick={() => this.resetBook()}
                      className="btn btn-sm btn-danger"
                      title="Upload custom PDF Book"
                    >
                      Use Default Book
                    </button>
                  )}

                  {this.state.addon != "" &&
                    !this.state.record?.audio_book &&
                    (this.state.record?.addon_paid === "Paid" ? (
                      <button
                        onClick={() => this.setState({ addon_modal: true })}
                        className="ms-3 btn btn-sm btn-success"
                        title="Upload Audio Book"
                      >
                        Upload Audio Book
                      </button>
                    ) : (
                      <button
                        onClick={() => this.setState({ payment_modal: true })}
                        className="ms-3 btn btn-sm btn-success"
                        title="Upload Audio Book"
                      >
                        Upload Audio Book
                      </button>
                    ))}
                </div>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <table className="table table-border">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Format</th>
                      <th>Preview Link</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.record && (
                      <tr  key={1}>
                        <td className="py-2">
                          {this.state.record?.pdf_book_type === "Custom"
                            ? this.state.record?.custom_title ??
                              "Book(Pathway To Profit - W Graphics)"
                            : this.state.record?.book_title ??
                              "Book(Pathway To Profit - W Graphics)"}
                        </td>
                        <td>{this.state.record?.pdf_book_type ?? "Default"}</td>
                        <td>PDF</td>
                        {this.state.record?.pdf_book_type != "Custom" ? (
                          <td>
                            <a
                              className="border-0"
                              href={`${this.state.record?.book_portrait}`}
                              target={"_blank"}
                            >
                              <i class="fas fa-file-pdf"></i> Preview
                            </a>
                          </td>
                        ) : (
                          <td>
                            <a
                              className="border-0"
                              href={`${this.state.record?.book_portrait_custom}`}
                              target={"_blank"}
                            >
                              <i class="fas fa-file-pdf"></i> Preview
                            </a>
                          </td>
                        )}

                        {this.state.record?.pdf_book_type != "Custom" ? (
                          <td>
                            <a
                              className="border-0 ml-3"
                              href={`${this.state.record?.book_landscape}`}
                              title={"Download PDF Lanscape Orientation Layout"}
                              target={"_blank"}
                            >
                              <i class="fas fa-download"></i>
                            </a>
                            <a
                              className="border-0 ml-5"
                              href={`${this.state.record?.book_portrait}`}
                              title={"Download PDF Portrait Orientation Layout"}
                              target={"_blank"}
                            >
                              <i class="fas fa-cloud-download-alt ml-2"></i>
                            </a>
                          </td>
                        ) : (
                          <td>
                            <a
                              className="border-0 ml-3"
                              href={`${this.state.record?.book_landscape_custom}`}
                              title={"a PDF Lanscape Orientation Layout"}
                              target={"_blank"}
                            >
                              <i class="fas fa-download"></i>
                            </a>
                            <a
                              className="border-0 ml-5"
                              href={`${this.state.record?.book_portrait_custom}`}
                              title={"a PDF Portrait Orientation Layout"}
                              target={"_blank"}
                            >
                              <i class="fas fa-cloud-download-alt ml-2"></i>
                            </a>
                          </td>
                        )}
                      </tr>
                    )}

                    {this.state.record?.audio_book && (
                      <tr key={2}>
                        <td>{this.state.record.audio_book.file_name.replace(/\.[^/.]+$/, '')}</td>
                        <td>Custom</td>
                        <td>Audio</td>
                        <td>
                          <audio
                            className="w-100"
                            controls
                            src={this.state.record.audio_book.path}
                            style={{ height: 30 + "px" }}
                          ></audio>
                        </td>

                        <td>
                          <a
                            className="border-0 ml-3"
                            href={`${this.state.record.audio_book.path}`}
                            title={"a PDF Lanscape Orientation Layout"}
                            target={"_blank"}
                          >
                            <i class="fas fa-download"></i>
                          </a>
                          <a
                            className="border-0 ml-2"
                            onClick={() => this.removeAudioBook()}
                          >
                            {" "}
                            <i class="fas fa-trash "></i>
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>

          <Modal
            isOpen={this.state.modal}
            fade={false}
            toggle={() => this.setState({ modal: false })}
            backdrop={"static"}
          >
            <form
              onSubmit={this.submitCustomBook}
              method="post"
              encType="multipart/form-data"
              className="form-horizontal"
              id="selection-myform"
            >
              <ModalBody>
                <Row>
                  <input
                    name="pdf_book_type"
                    defaultValue={"Custom"}
                    type="hidden"
                  />
                  <Col md="12">
                    <div className="form-group mb-3">
                      <label>Custom Book Title</label>
                      <input
                        name="custom_title"
                        type="text"
                        className="form-control"
                        placeholder="enter your custom Book Title"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label>Custom Book PDF</label>
                      <input
                        name="file"
                        className="form-control"
                        placeholder=""
                        type={"file"}
                        accept={"application/pdf"}
                        requured
                      />
                    </div>
                    <note className={"text-danger"}>
                      Make sure file size less than 20MB
                    </note>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => this.setState({ modal: false })}
                >
                  Cancel
                </Button>
                <Button
                  className="Green"
                  type="submit"
                  disabled={!this.state.loader ? true : false}
                >
                  Submit
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/* AUDIO BOOK MODAL */}
          <Modal
            isOpen={this.state.addon_modal}
            fade={false}
            toggle={() => this.setState({ addon_modal: false })}
            backdrop={"static"}
          >
            <form
              onSubmit={this.submitAudioBook}
              method="post"
              encType="multipart/form-data"
              className="form-horizontal"
              id="selection-myform"
            >
              <ModalBody>
                <Row>
                  <Col md="12">
                    <div className="form-group mb-3">
                      <label>Audio Book Title</label>
                      <input
                        name="custom_title"
                        type="text"
                        className="form-control"
                        placeholder="Enter your custom audio book title"
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label>Audio Book File</label>
                      <input
                        name="file"
                        className="form-control"
                        type="file"
                        accept=".wav, .mp3"
                        required
                      />
                    </div>
                    <note className={"text-danger"}>
                      Make sure audio size is less than 20MB
                    </note>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => this.setState({ addon_modal: false })}
                >
                  Cancel
                </Button>
                <Button
                  className="Green"
                  type="submit"
                  disabled={!this.state.loader}
                >
                  Submit
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/* AUDIO BOOK PAYMENT MODAL */}
          <Modal
            isOpen={this.state.payment_modal}
            fade={false}
            toggle={() => this.setState({ payment_modal: false })}
            backdrop={"static"}
          >
            <ModalHeader>Add-on Detail</ModalHeader>
            <ModalBody>
              <h5 className="color-dark">{this.state.addon.name}</h5>
              <p>{this.state.addon.description}</p>
              <h6 className="color-dark mt-2">
                Price: ${this.state.addon.price}
              </h6>
            </ModalBody>
            <ModalFooter>
              {this.state.record?.addon_paid == "Unpaid" ? (
                <>
                  <p>
                    We've sent an invoice to your email. Please complete the
                    payment to continue uploading your audiobook. Thank you!
                  </p>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.setState({ payment_modal: false })}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.setState({ payment_modal: false })}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="Green"
                    type="button"
                    disabled={!this.state.loader}
                    onClick={this.createInvoice}
                  >
                    Get Invoice: ${this.state.addon.price}
                  </Button>
                </>
              )}
            </ModalFooter>
          </Modal>
        </Row>
      </React.Fragment>
    );
  }
}
