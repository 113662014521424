import React, { useEffect, useState, useRef, Fragment } from "react";
import { Row, Col, Card, CardTitle } from "reactstrap";
import Carousel from "react-bootstrap/Carousel";

import "../../assets/css/courses_list.css";
import { ApiUrl, AttachementsUrl } from "../../config";
import { uToken } from "../../useToken";
import swal from "sweetalert";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/courses_list.css";
import RoadMap from "../../assets/images/roadmap.png";
import { Link, useLocation, useHistory } from "react-router-dom";

export default function CoachTrainingPath() {
  const [badges, setBadges] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState([]);

  const [completedModules, setCompletedModules] = useState([]);
  const [upcomingModules, setUpcomingModules] = useState([]);
  const [currentModules, setCurrentModules] = useState([]);

  const getCoachTrainingPath = () => {
    fetch(`${ApiUrl}` + "get/coach/training/path", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          if (response.data.records && response.data.records.length > 0) {
            setBadges(response.data.records);
            setSelectedBadge(response.data.records[0]);
          }
        } else {
          setBadges([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getCoachTrainingPath();
  }, []);

  useEffect(() => {
    if (selectedBadge) {
      setCompletedModules(selectedBadge.completed_modules);
      setUpcomingModules(selectedBadge.upcoming_modules);
      setCurrentModules(selectedBadge.current_module);
    }
  }, [selectedBadge]);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    const badge = badges[selectedIndex];
    setSelectedBadge(badge);
  };

  const CoachCertificates = ({ badges }) => {

    const isSingleItem = badges.length === 1;

    return (
      <div className="TraningRoadMapImg">
        <Carousel
          className="TraningRoadMap"
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}

          prevIcon={!isSingleItem && <span className="carousel-control-prev-icon" />} 
          nextIcon={!isSingleItem && <span className="carousel-control-next-icon" />}
        >
          {badges.map((badge, index) => {
            return (
              <Carousel.Item key={index} style={{}}>
                <Row className="SliderContent">
                  <Col lg={8}>
                    <Row>
                      <Col lg={4}>
                        <Card
                          className="certification_card d-flex flex-column BoxShadow ms-1"
                          key={badge.id}
                          style={{
                            borderTop: `6px solid ${badge.background_color}`,
                            borderBottom: `6px solid ${badge.background_color}`,
                          }}
                        >
                          <div className="RecentlyViewed text-center d-flex flex-grow-1 flex-column justify-content-center">
                            <div className="mb-2 px-2">
                              <div className="SliderContent text-center">
                                <h5 className=" fw-bold pb-1 ">
                                  {badge.title}
                                </h5>
                              </div>
                              <img
                                src={`${AttachementsUrl}user_${badge.wl_admin}/training_badges/${badge.image}`}
                                alt={badge.title}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col lg={8}>
                        <p className="text-justify">{badge.description}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Carousel.Item>
            );
          })}
        </Carousel>

        <div className="RoadMapPoints">
          <img src={RoadMap} />
          <div className="RoadMapSteps">
            {/* <div className="Step One">
              <img src="http://192.168.5.55/profitcoach/crmdoc/user_2/training_badges/badge_1730452845.png" />
              <div className="PointContent">
                <h5>PROFITUNIVERSITY</h5>
                <h3>The Virtual MBA</h3>
              </div>
            </div> */}

            {upcomingModules &&
              upcomingModules.map((module, index) => (
                <div className={`Step ${index === 0 ? "Two" : "One"}`}>
                  {module.badge_image ? (
                    <img
                      src={`${AttachementsUrl}/user_${module.user_id}/training/${module.badge_image}`}
                    />
                  ) : (
                    module.image && (
                      <img
                        src={`${AttachementsUrl}/user_${module.user_id}/training/${module.image}`}
                      />
                    )
                  )}

                  <Link to={`/trainings/${module.encoded_link}`}>
                    <div className="PointContent">
                      <h5>{module.course_title}</h5>
                      <h3>{module.title}</h3>
                    </div>
                  </Link>
                </div>
              ))}

            {currentModules && (
              <div className="Step Three">
                {currentModules.badge_image ? (
                  <img
                    src={`${AttachementsUrl}/user_${currentModules.user_id}/training/${currentModules.badge_image}`}
                  />
                ) : (
                  currentModules.image && (
                    <img
                      src={`${AttachementsUrl}/user_${currentModules.user_id}/training/${currentModules.image}`}
                    />
                  )
                )}

                <Link to={`/trainings/${currentModules.encoded_link}`}>
                  <div className="PointContent">
                    <h3>{currentModules.title}</h3>
                    <h5>{currentModules.course_title}</h5>
                  </div>
                </Link>
              </div>
            )}

            {completedModules &&
              completedModules.map((module, index) => (
                <div className={`Step ${index === 0 ? "Four" : "Five"}`}>
                  {module.badge_image ? (
                    <img
                      src={`${AttachementsUrl}/user_${module.user_id}/training/${module.badge_image}`}
                    />
                  ) : (
                    module.image && (
                      <img
                        src={`${AttachementsUrl}/user_${module.user_id}/training/${module.image}`}
                      />
                    )
                  )}

                  <Link to={`/trainings/${module.encoded_link}`}>
                    <div className="PointContent">
                      {index === 0 ? (
                        <>
                          <h5>{module.course_title}</h5>
                          <h3>{module.title}</h3>
                        </>
                      ) : (
                        <>
                          <h3>{module.title}</h3>
                          <h5>{module.course_title}</h5>
                        </>
                      )}
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Row>
        <Col lg="12">
          <div className="mb-0">
            <div className="TraningRightContent DashboardCard TraningCoachDash">
              <Row>
                <Col sm={6}>
                  <CardTitle className="mb-3 fw-bold card-title">
                    Training Certifications
                  </CardTitle>
                </Col>
              </Row>

              <Row>
                {badges && badges.length > 0 ? (
                  <CoachCertificates badges={badges} />
                ) : (
                  <h5 className="text-center">
                    There are no modules in progress. Begin training to earn
                    certifications.
                  </h5>
                )}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
