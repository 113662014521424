import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
} from "reactstrap";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import ThrusthubHeader from "./header.js";
import { Stepper, Step } from "react-form-stepper";
import { ApiUrl } from "../../config.js";
import { uToken } from "../../useToken.js";
import { toast, ToastContainer, Flip } from "react-toastify";
import Loader from "react-loader";

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TrusthubUpgrade = (props) => {
    const [step, SetStep] = useState(0);
    const [starterProfile, SetStarterProfile] = useState('No');
    const [haveSecondryProfile, sethaveSecondryProfile] = useState(false);
    const [countryCode, setCountryCode] = useState('+1');
    const [countryCode2, setCountryCode2] = useState('+1');
    const [loaded, setLoaded] = useState(true);
    const [hasAuthorized, setHasAuthorized] = useState(false);

    const [data, setData] = useState([]);
    const queryParms = useQuery();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        fetch(`${ApiUrl}` + "get/country", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setData(response.data);
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async (event, values) => {
        if(queryParms.get('type')){
            values.request_type = queryParms.get('type');
        }
        setLoaded(false);
        fetch(`${ApiUrl}` + "trusthub/upgrade/profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
        .then((response) => response.json())
        .then((response) => { 
            setLoaded(true);
            if (response.status === true) { 
                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' }); 
                setTimeout(
                    () => props.history.push('/trusthub/overview'),
                    4000
                );
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }

    return (
        <React.Fragment>
            <div className="trusthub" >
                <Container fluid>
                <ToastContainer/>
                    <Loader loaded={loaded}></Loader>
                    <Row>
                        <Col md={12}
                            lg={12}
                            xl={12} >
                            <ThrusthubHeader />
                            <Card>
                                <CardBody>
                                    <div className="alert alert-info fade show" role="alert"><strong>Submit Valid Information for Secondary Business Profile</strong> </div>
                                    <AvForm
                                        onValidSubmit={handleSubmit}
                                        className="needs-validation"
                                    >
                                        <Row>
                                            {
                                                queryParms.get('type') == 'business_profile' && (
                                                    <Col md="12">
                                                        <AvGroup check>
                                                    
                                                                <AvInput type="checkbox" name="haveSecondryProfile" onChange={() => sethaveSecondryProfile(!haveSecondryProfile)} />
                                                                <label check htmlFor="checkbox"> Have already Secondary Profile?</label>
                                                        </AvGroup>
                                                    </Col>
                                                )
                                            }
                                           </Row>
                                    {
                                        !haveSecondryProfile  && (
                                        <Row>
                                            <Col md="6" className="mb-3">
                                                <AvField
                                                    name="legal_business_name"
                                                    label="Legal Business Name *"
                                                    className="form-control "
                                                    type="text"
                                                    required
                                                />
                                                <note>Enter the exact legal business name, as registered with the EIN. E.g. Twilio Inc. rather than Twilio</note>
                                            </Col>
                                            <Col md="6">
                                                <AvField
                                                    name="profile_friendly_name"
                                                    label="Profile Friendly Name"
                                                    className="form-control mb-3"
                                                    type="text"
                                                />
                                            </Col>
                                            <Col md="12">
                                                <label>Business Identity *</label>
                                                <AvRadioGroup className="mb-3" value="Direct Customer My business uses Twilio to communicate internally or with our customers" name="business_identitiy" required>
                                                    <AvRadio label="Direct Customer My business uses Twilio to communicate internally or with our customers" value="Direct Customer My business uses Twilio to communicate internally or with our customers" />
                                                    <AvRadio label="ISV, Reseller, or Partner My business uses Twilio in a product that I sell to my customers." value="ISV, Reseller, or Partner My business uses Twilio in a product that I sell to my customers." />
                                                    <AvRadio label="I don't know" value="I don't know" />
                                                </AvRadioGroup>
                                            </Col>

                                            <Col md="6">
                                                <AvField
                                                    name="business_type"
                                                    label="Business Type"
                                                    className="form-control mb-3"
                                                    type="select"
                                                    required
                                                    value="Sole Proprietorship"
                                                >
                                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                    <option value="Partnership">Partnership</option>
                                                    <option value="Corporation">Corporation</option>
                                                    <option value="Co-operative">Co-operative</option>
                                                    <option value="Limited Liability Corporation">Limited Liability Corporation</option>
                                                    <option value="Non-profit Corporation">Non-profit Corporation</option>
                                                </AvField>
                                            </Col>

                                            <Col md="6">
                                                <AvField
                                                    name="business_industry"
                                                    label="Business Industry"
                                                    className="form-control mb-3"
                                                    type="select"
                                                    required
                                                    value="AUTOMOTIVE"
                                                >
                                                    <option value="AUTOMOTIVE">Automotive</option>
                                                    <option value="AGRICULTURE">Agriculture</option>
                                                    <option value="BANKING">Banking</option>
                                                    <option value="CONSUMER">Consumer</option>
                                                    <option value="EDUCATION">Education</option>
                                                    <option value="ENGINEERING">Engineering</option>
                                                    <option value="ENERGY">Energy</option>
                                                    <option value="OIL_AND_GAS">Oil and Gas</option>
                                                    <option value="FAST_MOVING_CONSUMER_GOODS">Fast Moving Consumer goods</option>
                                                    <option value="FINANCIAL">Financial</option>
                                                    <option value="FINTECH">Fintech</option>
                                                    <option value="FOOD_AND_BEVERAGE">Food &amp; Beverage</option>
                                                    <option value="GOVERNMENT">Government</option>
                                                    <option value="HEALTHCARE">Healthcare</option>
                                                    <option value="HOSPITALITY">Hospitality</option>
                                                    <option value="INSURANCE">Insurance</option>
                                                    <option value="LEGAL">Legal</option>
                                                    <option value="MANUFACTURING">Manufacturing</option>
                                                    <option value="MEDIA">Media</option>
                                                    <option value="ONLINE">Online</option>
                                                    <option value="RAW_MATERIALS">Raw materials</option>
                                                    <option value="REAL_ESTATE">Real Estate</option>
                                                    <option value="RELIGION">Religion</option>
                                                    <option value="RETAIL">Retail</option>
                                                    <option value="JEWELRY">Jewelry</option>
                                                    <option value="TECHNOLOGY">Technology</option>
                                                    <option value="TELECOMMUNICATIONS">Telecommunication</option>
                                                    <option value="TRANSPORTATION">Transportation</option>
                                                    <option value="TRAVEL">Travel</option>
                                                    <option value="ELECTRONICS">Electronics</option>
                                                    <option value="NOT_FOR_PROFIT">Not for profit</option>
                                                </AvField>
                                            </Col>

                                            <Col md="6">
                                                <AvField
                                                    name="business_registration_identifier"
                                                    label="Business Registration ID Type"
                                                    className="form-control mb-3"
                                                    type="select"
                                                    required
                                                    value="EIN"
                                                >
                                                    <option value="EIN">USA: Employer Identification Number (EIN)</option>
                                                    <option value="DUNS">USA: DUNS Number (Dun &amp; Bradstreet)</option>
                                                    <option value="CCN">Canada: Canadian Corporation Number (CCN)</option>
                                                    <option value="CN">Great Britain: Company Number</option>
                                                    <option value="ACN">Australia: Company Number from ASIC (ACN)</option>
                                                    <option value="CIN">India: Corporate Identity Number</option>
                                                    <option value="VAT">Estonia: VAT Number</option>
                                                    <option value="VATRN">Romania: VAT Registration Number</option>
                                                    <option value="RN">Israel: Registration Number</option>
                                                    <option value="Other">Other</option>
                                                </AvField>
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <label>Business Registration Number</label>
                                                <div className="input-group">
                                                    <AvInput name="business_registration_number" required type="text" className="form-control" />
                                                    <div className="input-group-text"><i className="fas fa-info-circle"></i></div>
                                                </div>
                                                <note>Enter the EIN Tax ID as it appears in the EIN listing</note>
                                            </Col>

                                            <Col md="6">
                                                <label>Business Regions of Operations</label>
                                                <AvRadioGroup className="mb-3" value="USA and Canada" name="business_regions_of_operation" required>
                                                    <AvRadio label="Africa" value="Africa" />
                                                    <AvRadio label="Asia" value="Asia" />
                                                    <AvRadio label="Europe" value="Europe" />
                                                    <AvRadio label="Latin America" value="Latin America" />
                                                    <AvRadio label="USA and Canada" value="USA and Canada" />
                                                </AvRadioGroup>
                                            </Col>

                                            <Col md="6" className="mb-3">
                                                <AvField
                                                    name="website_url"
                                                    label="Website Url"
                                                    className="form-control "
                                                    type="text"
                                                    required
                                                />

                                                <AvField
                                                    name="social_media_profile_urls"
                                                    label="Social Media Profile URL"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="i.e. LinkedIn, Facebook, Twitter"
                                                />
                                            </Col>

                                            <Col md={12}>We will contact your authorized representatives to verify your identities. Please ensure that they are contactable via email and phone.</Col>
                                            <Col md={12} className="mb-3"><h4>Authorized Representative #1</h4></Col>

                                            <Col md="6" className="mb-3">
                                                <AvField
                                                    name="last_name"
                                                    label="Last Name"
                                                    className="form-control "
                                                    type="text"
                                                    required
                                                />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    className="form-control"
                                                    type="text"
                                                    required
                                                />
                                            </Col>

                                            <Col md="6" className="mb-3">
                                                <AvField
                                                    name="first_name"
                                                    label="First Name"
                                                    className="form-control "
                                                    type="text"
                                                    required
                                                />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <AvField
                                                    name="business_title"
                                                    label="Business Title"
                                                    className="form-control"
                                                    type="text"
                                                    required
                                                />
                                            </Col>

                                            <Col md="6">
                                                <AvField
                                                    name="authorized_representative"
                                                    label="Authorized Representative Type"
                                                    className="form-control mb-3"
                                                    type="select"
                                                    required
                                                    value="individual"
                                                >
                                                    <option selected="" value="individual">Individual</option>
                                                    <option value="business">Business</option>
                                                </AvField>
                                            </Col>

                                            <Col md="6">
                                                <AvField
                                                    name="job_position"
                                                    label="Job Position"
                                                    className="form-control mb-3"
                                                    type="select"
                                                    required
                                                    value="Director"
                                                >
                                                    <option value="Director">Director</option>
                                                    <option value="GM">GM</option>
                                                    <option value="VP">VP</option>
                                                    <option value="CEO">CEO</option>
                                                    <option value="CFO">CFO</option>
                                                    <option value="General Counsel">General Counsel</option>
                                                    <option value="Other">Other</option>
                                                </AvField>
                                            </Col>
                                            <Col md="6">
                                                <AvField
                                                    name="country_code"
                                                    label="Country Code"
                                                    className="form-control mb-3"
                                                    type="select"
                                                    required
                                                    value="+1"
                                                    onChange={(e) => setCountryCode(e.target.value)}
                                                >
                                                    {
                                                        data.map((row, i) => {
                                                            return <option key={i} value={row.country_code}>{row.country_name} ({row.country_code})</option>;
                                                        })
                                                    }
                                                </AvField>
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <label> Phone Number</label>
                                                <div className="input-group">
                                                    <div className="input-group-text">{countryCode}</div>
                                                    <AvInput name="phone_number" required type="text" className="form-control" />
                                                    <div className="input-group-text"><i className="fas fa-info-circle"></i></div>
                                                </div>
                                                <note>Valid Phone Format: xxxxxxxxxx</note>
                                            </Col>


                                            <Col md={12} className="mb-3">
                                                <AvGroup check>
                                                    
                                                    <AvInput onChange={(e) => setHasAuthorized(!hasAuthorized)} value={hasAuthorized} type="checkbox" name="check_auth_new" />
                                                    <label check htmlFor="checkbox"> I do not have a second authorized representative</label>
                                                </AvGroup>
                                            </Col>

                                            {
                                                !hasAuthorized && (
                                                    <>
                                                        <Col md={12} className="mb-3"><h4>Authorized Representative #2</h4></Col>

                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="last_name_2"
                                                                label="Last Name"
                                                                className="form-control "
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="email_2"
                                                                label="Email"
                                                                className="form-control"
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>

                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="first_name_2"
                                                                label="First Name"
                                                                className="form-control "
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="business_title_2"
                                                                label="Business Title"
                                                                className="form-control"
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>

                                                        <Col md="6">
                                                            <AvField
                                                                name="authorized_representative_2"
                                                                label="Authorized Representative Type"
                                                                className="form-control mb-3"
                                                                type="select"
                                                                required
                                                                value="individual"
                                                            >
                                                                <option selected="" value="individual">Individual</option>
                                                                <option value="business">Business</option>
                                                            </AvField>
                                                        </Col>

                                                        <Col md="6">
                                                            <AvField
                                                                name="job_position_2"
                                                                label="Job Position"
                                                                className="form-control mb-3"
                                                                type="select"
                                                                required
                                                                value="Director"
                                                            >
                                                                <option value="Director">Director</option>
                                                                <option value="GM">GM</option>
                                                                <option value="VP">VP</option>
                                                                <option value="CEO">CEO</option>
                                                                <option value="CFO">CFO</option>
                                                                <option value="General Counsel">General Counsel</option>
                                                                <option value="Other">Other</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col md="6">
                                                            <AvField
                                                                name="country_code_2"
                                                                label="Country Code"
                                                                className="form-control mb-3"
                                                                type="select"
                                                                required
                                                                value="+1"
                                                                onChange={(e) => setCountryCode2(e.target.value)}
                                                            >
                                                                {
                                                                    data.map((row, i) => {
                                                                        return <option key={i} value={row.country_code}>{row.country_name} ({row.country_code})</option>;
                                                                    })
                                                                }
                                                            </AvField>
                                                        </Col>
                                                        <Col md="6" className="mb-3">
                                                            <label> Phone Number</label>
                                                            <div className="input-group">
                                                                <div className="input-group-text">{countryCode2}</div>
                                                                <AvInput name="phone_number_2" required type="text" className="form-control" />
                                                                <div className="input-group-text"><i className="fas fa-info-circle"></i></div>
                                                            </div>
                                                            <note>Valid Phone Format: xxxxxxxxxx</note>
                                                        </Col>
                                                    </>
                                                )
                                            }


                                            <Col md={12} className="mb-3">
                                                <AvGroup check>
                                                    <AvInput disabled value={true} type="checkbox" name="confirm_contact_record" />
                                                    <label check htmlFor="checkbox">I confirm that my nominated authorized representatives agree to be contacted by Twilio.</label>
                                                </AvGroup>
                                            </Col>

                                            <Col md={12}>
                                                <div className="alert alert-info fade show mb-3" role="alert"><strong><i className="fas fa-info-circle"></i></strong>  We currently only accept physical addresses for Business Profiles. P.O. box addresses are not accepted.. </div>
                                            </Col>

                                            <Col md={12} className="mb-3"><h4>Enter new address</h4></Col>

                                            <Col md="6" className="mb-3">
                                                <AvField
                                                    name="address_friendly_name"
                                                    label="Address Friendly Name"
                                                    className="form-control"
                                                    type="text"
                                                    required
                                                />
                                            </Col>

                                            <Col md="6" className="mb-3">
                                                <AvField
                                                    name="country"
                                                    label="Country"
                                                    className="form-control mb-3"
                                                    type="select"
                                                    required
                                                    onChange={(e) => setCountryCode2(e.target.value)}
                                                >
                                                    <option>Select a country</option>
                                                    {
                                                        data.map((row, i) => {
                                                            return <option key={i} value={row.country_name}>{row.country_name}</option>;
                                                        })
                                                    }
                                                </AvField>
                                            </Col>

                                            <Col md="12" className="mb-3">
                                                <label><i title="Enter the first line of the business address as it appears in the EIN listing" className="fas fa-info-circle"></i> Street Address</label>
                                                <AvField
                                                    name="street_address"
                                                    className="form-control"
                                                    required
                                                />
                                            </Col>

                                            <Col md="4" className="mb-3">
                                                <label><i title="Enter the city as it appears in the EIN listing" className="fas fa-info-circle"></i> City</label>
                                                <AvField
                                                    name="city"
                                                    className="form-control"
                                                    required
                                                />
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <label><i title="Enter the state/province/region as it appears in the EIN listing" className="fas fa-info-circle"></i> State/Province/Region</label>
                                                <AvField
                                                    name="state"
                                                    className="form-control"
                                                    required
                                                />
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <label><i title="Enter the postal code as it appears in the EIN listing" className="fas fa-info-circle"></i> Postal/Zip Code</label>
                                                <AvField
                                                    name="postal_code"
                                                    className="form-control"
                                                    required
                                                />
                                            </Col>

                                            {
                                                queryParms.get('type') == 'upgr' && (
                                                    <>
                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="brand_friendly_name"
                                                                label="Brand Friendly Name"
                                                                className="form-control"
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>

                                                        <Col md="6" className="mb-3">
                                                            <AvField
                                                                name="company_type"
                                                                label="Company Type"
                                                                className="form-control mb-3"
                                                                type="select"
                                                                required
                                                                value={'private'}
                                                            >
                                                                <option value="private">private</option>
                                                                <option value="public">public</option>
                                                                <option value="non-profit">non-profit</option>
                                                                <option value="government">government</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col md={12} className="mb-3">
                                                            <AvGroup check>
                                                                <AvInput disabled value={true} type="checkbox" name="brand_register_confirm" />
                                                                <label check htmlFor="checkbox">I Agreed on Brand Registeration charges $44 one time fees.</label>
                                                            </AvGroup>
                                                        </Col>

                                                    </>
                                                )
                                            }

                                        </Row>
                                    )}
                                        <Row>
                                            <Col md={6}>

                                                <button
                                                    className="btn btn-primary waves-effect waves-light btn-block mt-4"
                                                    type="submit"
                                                >
                                                    Submit Request
                                                </button>
                                                <button 
                                                    onClick={() => props.history.goBack()}
                                                    className="btn btn-danger waves-effect waves-light btn-block mt-4 ml-5"
                                                    type="submit"
                                                >
                                                    Cancel
                                                </button>
                                            </Col>

                                        </Row>


                                    </AvForm>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>



            </div>

        </React.Fragment >
    );
};

export default TrusthubUpgrade;
