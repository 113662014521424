import React , {useEffect} from "react"

import {
  Row,
  Col,
  Container
} from "reactstrap"

// Pages Components
import TopBar from "./TopBar";
import Appointments from "./Appointments";
import HomeTabs from "./HomeTabs";
import Activity from "./Activity"
import Navbar from "./navbar";
import Messages from "./Messages";

const Dashboard = (props) => {

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  useEffect(() => {
   // props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
  },)

  const reports = [
    { total: "90", Desc: "Average Daily New Leads"},
    { total: "340", Desc: "Average Daily Messages" },
    { total: "130", Desc: "Average Daily Messages" },
    { total: "90", Desc: "Average Daily New Leads"},
  ]
  const appointments = [
    { date: "12 Oct, 2024", time: "12:45 PM", Desc: "Appointment Title"},
    { date: "12 Oct, 2024", time: "12:45 PM", Desc: "Appointment Title"},
    { date: "12 Oct, 2024", time: "12:45 PM", Desc: "Appointment Title"},
    { date: "12 Oct, 2024", time: "12:45 PM", Desc: "Appointment Title"},

  ]

  return (
    <React.Fragment>      
      
        <TopBar/>
        <Appointments appointments={appointments}/>
        <Activity reports={reports} />
        <HomeTabs />
    </React.Fragment>
    
    
  )
}

export default Dashboard;