import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import ProfileSettings from "../../Authentication/profile-sidebar";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {uToken, dcrypt} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
export default class CreateUpdateSetting extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
  
    this.state = {
      tab: "1",
      tab_name: "SendGrid",
      activeTabId: 0,
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {},
      sendgrid: {},
      customstmp: {},
      gmail: {},
      outlook: {},

      page_name: this.props.match.params.id ? `Edit` : "Create",
    };
  }
  componentDidMount() {
    this.state.id && this.getData();
    //this.getcurrentsetingdata();
  }

  toggle(index) {
    this.setState({
      tab: index,
    });
  }


  updatesetting = (id) => {
    fetch(`${ApiUrl}` + "update/user/emailsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response, "Test");

        if (response.status === true) {
          this.setState({
            sendgrid: response.data.record.sendgrid ?? [],
            outlook: response.data.record.outlook ?? [],
            gmail: response.data.record.gmail ?? [],
            customstmp: response.data.record.custom ?? [],
            tab: response.data.record.tab_index,
            tab_name: response.data.record.tab_name,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.id;
    }
    fetch(`${ApiUrl}` + "edit/email/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())

      .then((response) => {
          if (response.status === true) {
              swal({
                text: 'Action performed successfully',
                icon: "success",
                buttons: true,
                dangerMode: false,
              }).then((confirmation) => {
                if (confirmation) {
                  this.props.history.push("/settings/email/list/");
                }
              });
          } else {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
      })
      .catch((error) => {
      });
  };

  handleSubmitcustom = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/customstmp", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmitGmail= async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/gmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} {this.state.tab_name?this.state.tab_name:"Sendgrid"} Email Setting </title>
        </Helmet>
        <Helmet>
          <title>{this.state.page_name} {this.state.tab_name?this.state.tab_name:"Sendgrid"} Email Setting</title>
        </Helmet>

        <Row>
          <Col lg={2} className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg={10}>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              {!this.state.id && (
                <Col className="col-lg-12">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={this.state.tab == 1 ? "active" : ""}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        SendGrid
                      </NavLink>
                    </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 2 ? "active" : ""}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Custom STMP
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 3 ? "active" : ""}
                          onClick={() => {
                            this.toggle("3");
                          }}
                        >
                          Gmail
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 4 ? "active" : ""}
                          onClick={() => {
                            this.toggle("4");
                          }}
                        >
                          Microsoft Outlook
                        </NavLink>
                      </NavItem>
                    
                  </Nav>
                </Col>
              )}
              <TabContent activeTab={this.state.tab}>
            
                  <TabPane tabId="1">
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <AvForm
                            onValidSubmit={this.handleSubmit}
                            ref={(c) => (this.form = c)}
                            className="needs-validation"
                          >
                            <AvField name="type" type="hidden" value={"sendgrid"}/>
                            <ModalBody>
                              <Row>
                                <Col lg={12}>
                                <h4> SendGrid Settings </h4>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    
                                    <AvField
                                      name="from_name"
                                      label="From Name"
                                      value={this.state.sendgrid.from_name}
                                      className="form-control"
                                      placeholder="From Name"
                                      required
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <AvField
                                      name="from_email"
                                      type="email"
                                      label="From Email"
                                      value={this.state.sendgrid.from_email}
                                      className="form-control"
                                      placeholder="From Email"
                                      required
                                    />
                                  </div>
                                </Col>
                                <div className="mb-3">
                                  <AvField
                                    name="sendgrid_api_key"
                                    type="password"
                                    label="SendGrid API Key"
                                    value={this.state.sendgrid.sendgrid_api_key}
                                    className="form-control"
                                    placeholder="SendGrid API Key"
                                    required
                                  />
                                </div>
                                <div className="mb-3">
                                  <AvField
                                    name="to_email"
                                    label="To Email"
                                    type="email"
                                    value={this.state.sendgrid.to_email}
                                    className="form-control"
                                    placeholder="To Email"
                                    required
                                  />
                                </div>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                              >
                                Cancel
                              </Button>
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </AvForm>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
               
                <TabPane tabId={"2"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmit}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <AvField name="type" type="hidden" value={"custom"}/>
                          <ModalBody>
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <AvField
                                  name="server_name"
                                  label="Server Name"
                                  value={this.state.customstmp.server_name}
                                  className="form-control"
                                  placeholder="Server Name"
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <AvField
                                  name="server_port"
                                  label="Server Port"
                                  value={this.state.customstmp.server_port}
                                  className="form-control"
                                  placeholder="Server Port"
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <AvField
                                  name="server_protocol"
                                  label="Server Protocol"
                                  value={this.state.customstmp.server_protocol}
                                  className="form-control"
                                  placeholder="Server Protocol"
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <label> Authentication </label>
                                <AvRadioGroup
                                  value={
                                    this.state.customstmp.server_authentication
                                  }
                                  inline
                                  name="server_authentication"
                                  required
                                >
                                  <AvRadio label="Yes" value="yes" />
                                  <AvRadio label="No" value="no" />
                                </AvRadioGroup>
                              </div>
                              <div className="col-md-6 mb-3">
                                <AvField
                                  name="user_email"
                                  label="User Email/Name"
                                  value={this.state.customstmp.user_email}
                                  className="form-control"
                                  placeholder="User Email"
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <AvField
                                  name="password"
                                  label="Password"
                                  value={this.state.customstmp.password}
                                  className="form-control"
                                  placeholder="Password"
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <AvField
                                  name="from_name"
                                  label="From Name"
                                  value={this.state.customstmp.from_name}
                                  className="form-control"
                                  placeholder="From Name"
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <AvField
                                  name="from_email"
                                  label="From Email"
                                  value={this.state.customstmp.from_email}
                                  className="form-control"
                                  placeholder="From Email"
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <AvField
                                  name="reply_to"
                                  label="Reply To"
                                  value={this.state.customstmp.reply_to}
                                  className="form-control"
                                  placeholder="Reply To"
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <AvField
                                  name="test_email"
                                  label="Test To Email"
                                  value={this.state.customstmp.test_email}
                                  className="form-control"
                                  placeholder="Enter test to email to trigger test email"
                                  required
                                />
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="secondary"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"3"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitGmail}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody>
                            <div className="mb-3">
                              <h4> Gmail Settings </h4>
                              <AvField
                                name="from_name"
                                label="From Name"
                                value={this.state.gmail.from_name}
                                className="form-control"
                                placeholder="From Name"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="from_email"
                                label="From Email"
                                value={this.state.gmail.from_email}
                                className="form-control"
                                placeholder="From Email"
                                required
                              />
                            </div>
                           
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="secondary"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"4"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitGmail}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody>
                            <div className="mb-3">
                              <h4> Gmail Settings </h4>
                              <AvField
                                name="from_name"
                                label="From Name"
                                value={this.state.gmail.from_name}
                                className="form-control"
                                placeholder="From Name"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="from_email"
                                label="From Email"
                                value={this.state.gmail.from_email}
                                className="form-control"
                                placeholder="From Email"
                                required
                              />
                            </div>
                           
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="secondary"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            
            </Row>
          </Col>
        </Row >
      </React.Fragment >
    );
  }
}
